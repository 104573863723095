import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    activityHistory: {},
    loading: false,
    error: null,
}

const UserActivityHistory = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_ACTIVITY_HISTORY_INIT:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_USER_ACTIVITY_HISTORY_SUCCESS:
            return {
                ...state,
                activityHistory: action.activityHistory,
                loading: false,
                error: null
            }

        case actionTypes.FETCH_USER_ACTIVITY_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                activityHistory: {}
            }

        case actionTypes.RESET_STATE:
            return {
                ...state,
                loading: false,
                error: null,
                activityHistory: {}
            }

        default:
            return { ...state }
    }
}

export default UserActivityHistory;
