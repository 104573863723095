import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS ,USER_ACTIVITY_LOGS} from '../../../constants/AppConstants';
import { _CALLPOSTAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import {
  fetchUserPrintHistoryRequest,
  fetchUserPrintHistorySuccess,
  fetchUserPrintHistoryFailure,
} from './GetUserPrintHistorySlice';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _FETCHUSERPRINTHISTORY(customerId) {
  const endpoint = _BROTHER_PLUS_API_ENDPOINTS.FETCH_USER_PRINT_HISTORY;
  const logDetails = {
    action: USER_ACTIVITY_LOGS.BROTHERPLUS_FETCH_PRINT_HISTORY,
    description: `Fetch Brother plus print history for customer ${customerId}`,
  };
  const servicePortalUser= await UserActivityLogsPayload(logDetails);
  const payload = {
    userId: customerId,
    servicePortalUser
  };

  return _CALLPOSTAPI(endpoint, payload)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

function* fetchUserPrintHistorySaga(action) {
  try {
    const customerId = action.payload;
    const printHistory = yield call(_FETCHUSERPRINTHISTORY, customerId);
    if (printHistory?.actionStatus.toUpperCase() === SUCCESS) {
      yield put(fetchUserPrintHistorySuccess(printHistory));
    } else {
      yield put(fetchUserPrintHistoryFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(fetchUserPrintHistoryFailure(error.message));
  }
}

export default function* watchFetchUserPrintHistoryService() {
  yield takeEvery(fetchUserPrintHistoryRequest.type, fetchUserPrintHistorySaga);
}
