import * as actionTypes from '../constants/ActionTypes/MyPlan';

const initialState = {
    plans: {},
    selected_plan: {},
    printerType:null,
    loading: false,
    error: null,
  }

const MyPlan = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PLANS:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.FETCH_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.plans,
                printerType:action.printerType,
                loading: false
            }
        
        case actionTypes.FETCH_PLANS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        case actionTypes.SELECT_PLAN:
            return{
                ...state,
                loading:false,
                selected_plan: action.selectedPlanDetails,
            }
        default:
            return {...state}
    }
}

export default MyPlan