import { createSlice } from '@reduxjs/toolkit';

const featureSwitchSlice = createSlice({
  name: 'featureSwitch',
  initialState: {
    loading: false,
    featureSwitch: null,
    error: true,
  },
  reducers: {
    fetchFeatureSwitchRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFeatureSwitchSuccess(state, action) {
      state.loading = false;
      state.featureSwitch = action.payload;
      state.error=false;
    },
    fetchFeatureSwitchFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.featureSwitch=false;
    },
  },
});

export const { fetchFeatureSwitchRequest, fetchFeatureSwitchSuccess, fetchFeatureSwitchFailure } = featureSwitchSlice.actions;
export default featureSwitchSlice.reducer;
