import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../constants/ActionTypes/MyPlan';
import { _CALLGETAPI } from '../api/apiHandler'
import { _API_ENDPOINT, LOCALSESSIONDATAKEYS } from '../constants/AppConstants';
import { getLocalSessionData, getUserDataByKey, setLocalSessionData } from '../utils/Authentication/handleUserSession';

function _FETCHPLANAPI(deviceModel, UID, deviceId, promotion, planAction){
     const endpoint = _API_ENDPOINT.FETCH_PLAN;

    let payload;
    const personalOrBusinessAccount = getUserDataByKey('businessModel')
    if(promotion){
        payload = {
            params: {
                plantype: 'new',
                model: deviceModel,
                includeHiddenPlans: (planAction) ? 'true' : 'false',
                userId: UID,
                deviceID: deviceId,
                personalOrBusiness: personalOrBusinessAccount
            }
          }
          if (promotion.length === 1) {
    
            payload.params.promotions = promotion[0];
          } else {
            promotion.forEach((promo, index) => {
              payload.params[`promotions[${index}]`] = promo;
            });
          }
    }
    else{
        payload = {
            params: {
                plantype: 'new',
                model: deviceModel,
                includeHiddenPlans: (planAction) ? 'true' : 'false',
                userId: UID,
                deviceID: deviceId,
                personalOrBusiness: personalOrBusinessAccount
            }
        }
    }
    
     return _CALLGETAPI(endpoint, payload)
    .then(response => {
        const printerTypefromLocalStorage = getLocalSessionData(LOCALSESSIONDATAKEYS.PRINTER_TYPE)
        if(printerTypefromLocalStorage === null || printerTypefromLocalStorage === "null" || printerTypefromLocalStorage === undefined || printerTypefromLocalStorage === '') {
            setLocalSessionData(LOCALSESSIONDATAKEYS.PRINTER_TYPE, response?.printerType);
        }
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchPlans(action) {
    try {
        const deviceModel = action.deviceModel;
        const promotion = action.promotion;
        if(deviceModel){
            const plans = yield call(_FETCHPLANAPI, deviceModel, action.UID, action.deviceID, promotion&&promotion, action.planAction);
            yield put({type: actionTypes.FETCH_PLANS_SUCCESS, plans: plans.plans,printerType:plans.printerType});
        } else {
            yield put({
                type: actionTypes.FETCH_PLANS_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_PLANS_ERROR, message: e.message});
        }
    }
}

function* MyPlanMiddleware() {
    yield takeEvery(actionTypes.FETCH_PLANS, fetchPlans);
 }
 
 export default MyPlanMiddleware;
