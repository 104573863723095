import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import DisplayUpdateAccountConfirmation from "../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation";
import updatePersonalDetailsIcon from "../../../assets/images/update-personal-details-confirmation-icon.svg"
import { routePath } from "../../../constants/Routes";
import { logoutProcess } from "../../../utils/Authentication/AppLogout";
import { LOCALSESSIONDATAKEYS, USER_PROFILE_EDIT_TYPE, refreshBtn, tryAgainBtn } from "../../../constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCustomerProfile } from "../../../actions/MyAccount";
import Loader from "../../../components/shared/loader/Loader";
import { FetchUserAccount } from "../../../actions/UserAccount";
import { getLocalSessionData } from "../../../utils/Authentication/handleUserSession";
import { Is_BrotherPlus_Signup } from "../../../constants/AppMethod";

const CustomerEditProfile = () => {
    const { t } = useTranslation(["translation"]);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    let userprofile_editSUCCESS, userprofile_editERROR = false;
    let header, info, btnclickFn;
    const MULTIPLE_ATTEMPT_ERR_CODE = "AADB2C90157";
    const [callUpdateProfile, SetUpdateProfileCall] = useState(true);
    const [callUserAccount, SetUserAccountCall] = useState(true);
    const profileDetailsResp = useSelector(state => state.UpdateCustomerProfile);
    const userAccountResp = useSelector(state => state.UserAccount);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup(); 

    const onAccountConfirm = () => {
        history.push(routePath.MY_ACCOUNT);
    }
    const onLogout = () => {
        logoutProcess();
    }
    const handlePhoneNumbers = (number) => {
        let res = '';
        if(number){
            const extractFirst2Letters = number.slice(0, 2);
            if(extractFirst2Letters === "00"){
                res = '+' + number.slice(2);
            } else {
                res = number
            }
        }
        return res;
    }

    if(location?.hash){
        userprofile_editSUCCESS = (location.hash.includes('id_token')) ? true : false;
        userprofile_editERROR = (location.hash.includes('error')) ? true : false;
        if((location.hash.includes(USER_PROFILE_EDIT_TYPE.password))){
            header = t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION.EDIT_USER_PASSWORD_HEADER");
            info = (location.hash.includes('id_token')) 
            ? t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.EDIT_USER_PASSWORD_INFO_SUCCESS"): t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.EDIT_USER_PASSWORD_INFO_ERROR");
            btnclickFn = onLogout;
        } else {
            header = t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION.EDIT_USER_PROFILE_HEADER");
            info = (location.hash.includes('id_token')) 
            ? t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.EDIT_USER_PROFILE_INFO_SUCCESS") : (location.hash.includes(MULTIPLE_ATTEMPT_ERR_CODE)) 
                ? t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.EDIT_USER_PROFILE_MULTIPLE_ATTEMPT_ERR_INFO")
                : t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.EDIT_USER_PROFILE_INFO_ERROR");
            btnclickFn = onAccountConfirm;
        }
    }
    if(userAccountResp.error){
        return <ErrorPage errorInfo={userAccountResp.error}></ErrorPage>
    }
    if(userAccountResp.success){
         return (
            <DisplayUpdateAccountConfirmation
              updateAddressHeader={header}
              updateAddressInfo={info}
              updateAddressBtn={t('ECOPRO_BTNS.CONTINUE')}
              onUpdateClick={() => btnclickFn()}
              noThanksStatus=""
              image={updatePersonalDetailsIcon}
            ></DisplayUpdateAccountConfirmation>
        )
    }
    if(profileDetailsResp.error){
        return <ErrorPage errorInfo={profileDetailsResp.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.MY_ACCOUNT}></ErrorPage>
    }
    if(profileDetailsResp.success || IsBrotherPlus_Signup){
        if(callUserAccount){
            const ADB2CID = getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID)
            dispatch(FetchUserAccount(ADB2CID, ""));
            SetUserAccountCall(false);
        }
    }
    if(userprofile_editSUCCESS){
        const urlString = location?.hash;
        const paramsString = urlString.split("#")[1];
        const urlSearchParams = new URLSearchParams(paramsString);
        const idToken = urlSearchParams.get("id_token");
        const decodedToken = jwt_decode(idToken);
        if(location.hash.includes(USER_PROFILE_EDIT_TYPE.password)){
            return(
                <DisplayUpdateAccountConfirmation
                updateAddressHeader={header}
                updateAddressInfo={info}
                updateAddressBtn={t('ECOPRO_BTNS.CONTINUE')}
                onUpdateClick={() => btnclickFn()}
                noThanksStatus=""
                image={updatePersonalDetailsIcon}
                ></DisplayUpdateAccountConfirmation>
            )
        } else {
            const payload = {
                accountDetails:  {
                    personalDetails: {
                        firstName: decodedToken['given_name'] || "",
                        lastName: decodedToken['family_name'] || "",
                        emailAddress: decodedToken['signInNames.emailAddress'],
                        telephoneNumber: handlePhoneNumbers(decodedToken['extension_Phone']) || "",
                        mobileNumber: handlePhoneNumbers(decodedToken['extension_mobile_number']) || "",
                        offers: false
                    },
                    billingAddress: {
                        address: "",
                        addressLine2: "",
                        city: "",
                        country: decodedToken['Country'],
                        postcode: "",
                        company: "",
                        province: "",
                        sameAsShippingAddress: false,
                        termsAndPrivacy: false,
                        taxId: decodedToken["extension_taxCode"] || "",
                        vatId: decodedToken["extension_vatNumber"] || "",
                        sapBpId: "",
                        businessModel: (decodedToken["extension_isPersonalAccount"]) ? 0 : 1
                    },
                },
            };
            if(callUpdateProfile && !IsBrotherPlus_Signup){
                dispatch(UpdateCustomerProfile(payload));
                SetUpdateProfileCall(false);
            }
            return (
                <Loader></Loader>
            );
        } 
    }
    else if(userprofile_editERROR){
        if(location.hash.includes(MULTIPLE_ATTEMPT_ERR_CODE)){
            btnclickFn = onLogout;
            setTimeout(() => {
                onLogout();
              }, 4000)
        }else{
            btnclickFn = onAccountConfirm;
        }
        return (
            <DisplayUpdateAccountConfirmation
              updateAddressHeader={header}
              updateAddressInfo={info}
              updateAddressBtn={t('ECOPRO_BTNS.CONTINUE')}
              onUpdateClick={() => btnclickFn()}
              noThanksStatus=""
              image={updatePersonalDetailsIcon}
            ></DisplayUpdateAccountConfirmation>
          );
    }
    else{
        return(
            <ErrorPage></ErrorPage>
        )
    }
}

export default CustomerEditProfile;