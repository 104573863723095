import React from 'react';
import { Bar } from 'react-chartjs-2';
import { emptyObjectCheck, extractKeysAsArray, extractValuesAsArray, getChartColors } from '../../../constants/AppMethod';
import { useTranslation } from 'react-i18next';
import { getLocalSessionData } from '../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS } from '../../../constants/AppConstants';
import { AvgPagesUsedSkeletonDashboard } from '../../../pages/PrintActivity/PrintActivity';

const SupplyLevels = (props) => {
  const {getDeviceStatusResponse, isChildComp, getDevicesResponse} = props
    
  const { t } = useTranslation(["translation"]);

  const localStorageDeviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);

  const matchingDevice = getDevicesResponse?.devices?.devices.find(
    (device) => device.deviceId === localStorageDeviceId
  );

  const chartLabels = extractKeysAsArray(getDeviceStatusResponse?.response?.remainingConsumables, matchingDevice?.printerType)
  const chartValues = extractValuesAsArray(getDeviceStatusResponse?.response?.remainingConsumables, matchingDevice?.printerType)
  const chartColors = getChartColors(chartLabels)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: "right",
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          color: "#000",
          callback: function(value) {
            return `${value}%`;
          }
        },
        grid: {
          drawTicks: true,
          display: true,
          tickBorderDash: [2, 2],
          drawBorder: true,
          borderColor: "#DDDDDD",
          borderWidth: 1,
          borderDash: [2, 2],
        },
      },
      y1: {
        position: "left",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        position: "bottom",
        ticks: {
          font: {
            size: 14,
            family: "Arial",
          },
          tickBorderDash: [2, 2],
          color: "#000",
          callback: function(value, index) {
            const label = chartLabels[index] || ''; // Fallback if index is out of bounds
            return label.charAt(0).toUpperCase() + label.slice(1);
          }
        },
        grid: {
            display:false,
          tickBorderDash: [2, 2],
          drawBorder: true,
          borderColor: "#DDDDDD",
          borderWidth: 1,
          borderDash: [2, 2],
          color: "#DDDDDD",
          lineWidth: (ctx) => (ctx.index % 6 ? 0.5 : 0),
        },
      },
      x1: {
        position: "top",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      autocolors: false,
      datalabels:{
        display:false
      },
      legend: {
        display: false,
      },
    },
  };
    
  const data = {
    legend: {
      display: false,
    },
    labels: chartLabels,
    datasets: [
      {
        data: chartValues,
        backgroundColor: chartColors,
        barThickness: 75,
        borderRadius: 0,
      },
    ],
  };
  return (
    <div className='print-activity-avg-pages-used-wrapper displayed-as-child'>
      <div className='average-pages-used-wrapper'>
      <div className="average-pages-used-content">
        <div className="average-page-trends h2">{t("BROTHER_PLUS.DASHBOARD.SUPPLY_LEVEL.INK_USED")}</div>
        <div className="pages-printed-sub-title body-medium">{t("BROTHER_PLUS.DASHBOARD.SUPPLY_LEVEL.ESTIMATED_LEVEL")}</div>
        {
          isChildComp && getDeviceStatusResponse?.loader
          ? <>
              <div className="loader-wrapper">
                <AvgPagesUsedSkeletonDashboard />
              </div>
            </>
          : 
         isChildComp && getDeviceStatusResponse?.error ?
          <p className="fc-g mt-10 info-error-message">
            {getDeviceStatusResponse?.error ? getDeviceStatusResponse?.error : t("BROTHER_PLUS.DASHBOARD.SUPPLY_LEVEL.ERROR")}
          </p>
        :
        getDeviceStatusResponse?.success && !emptyObjectCheck(getDeviceStatusResponse?.response?.remainingConsumables || {}) ?
        <div className="trends-bar-chart-wrapper">
          <Bar
            id="chart"
            data={data}
             height={150}
            // width={300}
            options={options}
            style={{ width: '100%' }}
          />
        </div>
        :
        <div className="info-error-message"><p>{t('ECOPRO_NEW_DASHBOARD.NO_DATA_DISPLAYED')}</p></div>
        }
      </div>
      </div>
    </div>
  );
};

export default SupplyLevels;