import { createSlice } from '@reduxjs/toolkit';

const getPrintHistorySlice = createSlice({
  name: 'getPrintHistory',
  initialState: {
    loading: false,
    printHistory: null,
    error: null,
    success:false
  },
  reducers: {
    getPrintHistoryRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getPrintHistorySuccess(state, action) {
      state.loading = false;
      state.success = true
      state.printHistory = action.payload;
    },
    getPrintHistoryFailure(state, action) {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    },
  },
});

export const { getPrintHistoryRequest, getPrintHistorySuccess, getPrintHistoryFailure } = getPrintHistorySlice.actions;
export default getPrintHistorySlice.reducer;

