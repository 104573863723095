import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Progress } from 'reactstrap';
import { formatNumberOnLocale } from '../../../../constants/AppMethod';
import currencies from '../../../../constants/CurrencyTypes';
import { setLocalSessionData } from '../../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';

const PagesOverages = (props) => {
    const {deviceInfoResponse} = props
    const subscriptionInfo = deviceInfoResponse?.response
    const { t } = useTranslation(["translation"]);

    let planPurchacedOveragePages = 0, planPrintedOveragePages= 0, overagePrice = 0, planCurrency = '',
    overageBlockPages= 0;

    const checkDeviceInfoProps = (key, dataObject) => {
      const defaultVal = (key === "planName") ? 'N/A' : (key === "currency") ? '' : 0;
      return (dataObject.hasOwnProperty(key) && dataObject[key]) ? dataObject[key] : defaultVal;
    }

    if(deviceInfoResponse?.success && Object.keys(subscriptionInfo).length > 0 && subscriptionInfo?.service !== null){
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentPlan')
          && subscriptionInfo?.service?.currentPlan !== null) {
          overagePrice = checkDeviceInfoProps("overagePrice", subscriptionInfo.service.currentPlan);
          planCurrency = checkDeviceInfoProps("currency", subscriptionInfo.service.currentPlan);
          overageBlockPages = checkDeviceInfoProps("overageBlockPages", subscriptionInfo.service.currentPlan);
          setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY,planCurrency)
      }
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentUsage') 
          && subscriptionInfo?.service?.currentUsage !== null) {
          planPurchacedOveragePages = checkDeviceInfoProps("purchasedOveragePages", subscriptionInfo.service.currentUsage);
          planPrintedOveragePages = checkDeviceInfoProps("printedOveragePages", subscriptionInfo.service.currentUsage);
      }
  }

  let overagesProgressVal = 0;

    overagesProgressVal = ((planPrintedOveragePages * 100) / planPurchacedOveragePages) || 0;

    return (
        <div className="pages-overages-wrapper">
              <div className="header">
                <h2 className="h2">{t("DASHBOARD.OVERAGES")}</h2>
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoResponse?.loader ? (
                  <Skeleton width="10%" />
                ) : deviceInfoResponse?.success ?(
                  <p className="body-medium">
                    {planPrintedOveragePages}/{planPurchacedOveragePages}
                  </p>
                ):null}
                {deviceInfoResponse?.loader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : deviceInfoResponse?.success ?(
                  <Progress value={overagesProgressVal} />
                ): null
                // <div className="info-error-message pages-left-error">{t("ERROR_CONTENT.NO_DATA")}</div>
                }

                {deviceInfoResponse?.success && planCurrency && (
                  <span className="body-small">
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(overagePrice, currencies[planCurrency]?.code),
                    })}
                  </span>
                )}
                {deviceInfoResponse?.success && !planCurrency &&
                  (deviceInfoResponse?.loader ? null : (
                    <span className="body-small">
                      {t("DASHBOARD.PAGES_PRICE", {
                        plan_pages: overageBlockPages,
                        plan_price: formatNumberOnLocale(overagePrice),
                      })}
                    </span>
                ))}
              </div>
            </div>
    );
};

export default PagesOverages;