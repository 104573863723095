import { all } from "@redux-saga/core/effects";
import CustomerDashboardInfo from "./CustomerDashboard/CustomerDashboardInfo";
import UpdateOrderConsumables from "./CustomerDashboard/UpdateOrderConsumables";
import EnableDeviceSwap from "./CustomerDashboard/EnableDeviceSwap";
import FetchEmailLogs from "./CustomerDashboard/FetchEmailLogs";
import SupplyOrderLogs from "./CustomerDashboard/SupplyOrderLogs";
import CustomerSubscriptionDetails from "./CustomerDashboard/SubscriptionDetails";
import CustomerDeviceAndDeviceUsageDetails from "./CustomerDashboard/DeviceAndDeviceUsageDetails";
import UserActivityHistory from "./CustomerDashboard/UserActivityHistory";
import UserPreEnrollmentInfo from "./CustomerDashboard/UserPreEnrollmentInfo";

export default function* CsAgentSagas() {
    yield all([
        CustomerDashboardInfo(),
        EnableDeviceSwap(),
        UpdateOrderConsumables(),
        FetchEmailLogs(),
        SupplyOrderLogs(),
        CustomerSubscriptionDetails(),
        CustomerDeviceAndDeviceUsageDetails(),
        UserActivityHistory(),
        UserPreEnrollmentInfo(),
    ])
}
