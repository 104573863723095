import React from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../../shared/loader/Loader";
import './UserActivityHistory.css'

const UserActivityHistory = ({ activityHistory, loading, error }) => {
    const { t } = useTranslation(["CSAgenttranslation"]);
    
    if (loading) {
        return <Loader />;
    }

    if (error) {
      return <div className="email-log-error">{t("ERROR_MESSAGES.NO_USER_ACTIVITY_HISTORY")}</div>;
    }
return (
  <div className="mt-40">
      {
        activityHistory && (
          <div className="user-activity-history">
            {activityHistory?.activityHistory?.length > 0? (
            <table className="table">
            <thead>
              <tr>
                <th scope="col">{t("CUSTOMER_DASHBOARD.USER_ACTIVITY_HISTORY.SERIAL_NUMBER")}</th>
                <th scope="col">{t("CUSTOMER_DASHBOARD.USER_ACTIVITY_HISTORY.DEVICE_ID")}</th>
                <th scope="col">{t("CUSTOMER_DASHBOARD.USER_ACTIVITY_HISTORY.CATEGORY")}</th>
                <th scope="col">{t("CUSTOMER_DASHBOARD.USER_ACTIVITY_HISTORY.DESCRIPTION_ID")}</th>
                <th scope="col">{t("CUSTOMER_DASHBOARD.USER_ACTIVITY_HISTORY.TARGET")}</th>
                <th scope="col">{t("CUSTOMER_DASHBOARD.USER_ACTIVITY_HISTORY.TIME")}</th>
              </tr>
            </thead>
            <tbody>
              {activityHistory?.activityHistory?.map((activity, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{activity.deviceId}</td>
                  <td>{activity.category}</td>
                  <td>{activity.descriptionId}</td>
                  <td>{activity.target || t("NOT_AVAILABLE")}</td>
                  <td>{new Date(activity.time).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
            </table>
          ) 
          : (
              <div className="email-log-error">{t("ERROR_CONTENT.USER_ACTIVITY_HISTORY")}</div>
            )}
          </div>
        )
      }
  </div>
)
};
export default UserActivityHistory;
