import React, { useEffect } from 'react';
import { FetchOrderHistory } from '../../../../actions/Dashboard';
import { Is_BrotherPlus_Signup } from '../../../../constants/AppMethod';
import { useDispatch, useSelector } from 'react-redux';
import DashboardOrdersHistory from '../../../DashboardOrdersHistory/DashboardOrdersHistory';
import { getUserSessionData } from '../../../../utils/Authentication/handleUserSession';
import { ECOPROTOKEN_CLAIMS } from '../../../../constants/AppConstants';

const EcoproOrderHistory = () => {
    const dispatch = useDispatch()
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const OrdersHistory = useSelector(state => state.DashboardOrderHistory);
    const EnrollmentStatusResult = useSelector(state => state.EnrollmentStatus);
    const BrotherPlus_RegisterServiceResp = useSelector(state => state.BROTHERPLUS.RegisterService);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

    useEffect(()=>{
        dispatch(FetchOrderHistory(UID));
    },[dispatch,UID])

    return (
        <div>
            <DashboardOrdersHistory 
                orderHistoryResult = {OrdersHistory}
                orderHistory= {OrdersHistory.orderHistory} 
                isDashboardHomepage={true}
               cname= {(EnrollmentStatusResult.enrollment_completed ||(IsBrotherPlus_Signup && BrotherPlus_RegisterServiceResp?.success) ) ? 'active-dashboard-blocks':'disabled-dashboard-blocks'}
            ></DashboardOrdersHistory>
        </div>
    );
};

export default EcoproOrderHistory;