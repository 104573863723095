import React, { useContext, useEffect } from "react";
import "../../../pages/Dashboard/AccountInfo/Accountinfo.css"
import { useTranslation } from "react-i18next";
import { getPlanTranslation, getPrinterTypeTranslation, Is_BrotherPlus_Signup } from "../../../constants/AppMethod";
import { setLocalSessionData } from "../../../utils/Authentication/handleUserSession";
import { LOCALSESSIONDATAKEYS } from "../../../constants/AppConstants";
import { DisplayDate } from "../../../utils/DisplayDate";

const EnrolledDeviceInfoContext = React.createContext();

const EnrolledDevice = (props) => {
    const deviceInfo = props;
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

    useEffect(()=>{
        if(deviceInfo?.deviceModel && IsBrotherPlus_Signup){
            setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, deviceInfo?.deviceModel);
        }
        if(deviceInfo?.deviceSerialNumber && IsBrotherPlus_Signup){
            setLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER, deviceInfo?.deviceSerialNumber);
        }
    },[IsBrotherPlus_Signup, deviceInfo?.deviceModel, deviceInfo?.deviceSerialNumber])

    return (
        <EnrolledDeviceInfoContext.Provider value={deviceInfo}>
            <EnrolledDeviceDesktopView />
            <EnrolledDeviceMobileView />
        </EnrolledDeviceInfoContext.Provider>
    )
}

const EnrolledDeviceContents = (props) => {
    const { t } = useTranslation(["translation"]);
    const deviceInfo = useContext(EnrolledDeviceInfoContext);
    const { deviceModel, deviceSerialNumber, subscriptionDate, subscriptionDateText, planName, planPages, deviceWarrantyStatusInfo} = deviceInfo;
    const { isMobile = false} = props;
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    return (
        <div className="enrolled-device-details-contents body-medium">
            <div className={`enrolled-device-name ${(isMobile) ? 'space-bottom' : 'no-space'}`}>
                <p className={`device-model ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.DEVICE_NAME')}: <span>{deviceModel}</span></p>
                <p className="device-serialnumber">{t('DASHBOARD.MY_ACCOUNT_LANDING.SERIAL_NUMBER')}: <span>{deviceSerialNumber}</span></p>
            </div>
            {(subscriptionDate) ?
            <div className={`enrolled-device-date caption ${(isMobile) ? 'space-bottom' : 'no-space'}`}>
                <p className={`device-date-label ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>
                    {subscriptionDateText} : {(subscriptionDate) ? DisplayDate(subscriptionDate, { year: 'numeric', month: '2-digit', day: '2-digit' }) : 'N/A'}
                </p>
            </div> : null}
            {!IsBrotherPlus_Signup ?
                <div className="enrolled-device-warranty-status-wrapper">
                    <p className={`device-warranty-label ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>
                        {t('DASHBOARD.ENROLLED_DEVICE_SECTION.WARRANTY_STATUS')} : <span>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.ECOPRO_SUBSCRIPTION')}, {getPlanTranslation(planName)} - {t("DASHBOARD.PAGES_PER_MONTH", { plan_pages: planPages })}</span>
                    </p>
                </div>
            :
                <div className="enrolled-device-warranty-status-wrapper">
                    <p className={`device-warranty-label ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>
                        {t('DASHBOARD.ENROLLED_DEVICE_SECTION.WARRANTY_STATUS')} : {deviceWarrantyStatusInfo}
                    </p>
                </div>
            }
            {
                (IsBrotherPlus_Signup) ? <div className="enrolled-device-warranty-status-wrapper">
                    <p className={`device-warranty-label ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>{t('BROTHER_PLUS.DASHBOARD.ENROLLED_DEVICE_SECTION.CURRENT_PLAN')}: <span>{t('BROTHER_PLUS.DASHBOARD.ENROLLED_DEVICE_SECTION.BROTHER_PLUS_PROGRAM')}</span></p>
                </div> : null
            }
            
        </div>
    )
}

const EnrolledDeviceDesktopView = () => {
    const { t } = useTranslation(["translation"]);
    const deviceInfo = useContext(EnrolledDeviceInfoContext);
    const { deviceImagePath, deviceStatus, networkStatus, printerType} = deviceInfo;
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

    return (
        <div className="enrolled-device-outer-wrapper show-lg">
            <div className={`enrolled-device-wrapper ${IsBrotherPlus_Signup ? 'brother-plus-enrolled-device-wrapper':""}`}>
                <div className="enrolled-device-image-wrapper">
                    <img src={deviceImagePath} alt="printer"/>
                </div>
                <div className="enrolled-device-details-wrapper">
                    <div className="enrolled-device-details-header">
                        <h2 className="h2">{getPrinterTypeTranslation(printerType)}</h2>
                        <div className="enrolled-device-status-wrapper body-small"> {t('DASHBOARD.ENROLLED_DEVICE_SECTION.DEVICE_STATUS')}:
                            { (IsBrotherPlus_Signup ? networkStatus === "online" : deviceStatus && networkStatus === "online") 
                                ?  (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                        <circle cx="3.5" cy="3.5" r="3.5" fill="#00D72B"/>
                                    </svg>
                                    <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.ONLINE')}</p>
                                </>
                            ) :
                            (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                        <circle cx="3.5" cy="3.5" r="3.5" fill="#FF0000"/>
                                    </svg>
                                    <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.OFFLINE')}</p>
                                </>
                            )
                            }
                        </div>
                    </div>
                    <EnrolledDeviceContents />
                </div>
            </div>
        </div>
    )
}

const EnrolledDeviceMobileView = () => {
    const { t } = useTranslation(["translation"]);
    const deviceInfo = useContext(EnrolledDeviceInfoContext);
    const { deviceImagePath, deviceStatus, networkStatus, printerType} = deviceInfo;
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    return (
        <div className="enrolled-device-outer-wrapper show-xs">
            <div className="enrolled-device-wrapper">
                <div className="enrolled-device-header-img-wrapper">
                    <div className="enrolled-device-details-header mobile-font">
                            <h2>{getPrinterTypeTranslation(printerType)}</h2>
                            <div className="enrolled-device-status-wrapper body-small">
                                {(IsBrotherPlus_Signup ? networkStatus === "online" : deviceStatus && networkStatus === "online") 
                                    ?  (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                                    <circle cx="3.5" cy="3.5" r="3.5" fill="#00D72B"/>
                                                </svg>
                                                <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.ONLINE')}</p>
                                            </>
                                        ) : 
                                        (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                                    <circle cx="3.5" cy="3.5" r="3.5" fill="#FF0000"/>
                                                </svg>
                                                <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.OFFLINE')}</p>
                                            </>
                                        )
                                }
                            </div>
                    </div>
                    <div className="enrolled-device-image-wrapper">
                        <img src={deviceImagePath} alt="printer"/>
                    </div>
                </div>
                <div className="enrolled-device-details-wrapper">
                    <EnrolledDeviceContents isMobile = {true} />
                </div>
            </div>
        </div>
    )
}

export default EnrolledDevice;