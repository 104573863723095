import React from 'react';
import { useTranslation } from 'react-i18next';
import rightArrow from "../../assets/images/rightArrow.svg";
import { LOCALSESSIONDATAKEYS, featureSwitchName } from '../../constants/AppConstants';
import { getPlanTranslation, Is_BrotherPlus_Signup } from '../../constants/AppMethod';
import { routePath } from '../../constants/Routes';
import { getFeatureSwitchByKey, getLocalSessionData } from '../../utils/Authentication/handleUserSession';


const MyPlan = (props) => {
  const {moveToEditPage, paymentCardNumber, planName, planPages} = props;
    const { t } = useTranslation(["translation"]);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup()

    const isChangePlanDisplayed = () => {
      const termEndDate = getLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE)
      if(IsBrotherPlus_Signup){
        return false
      }
      else{
        if(getFeatureSwitchByKey(featureSwitchName.CHANGE_PLAN)){
          if(termEndDate){
            return false
          }
          else{
            return true
          }
        }
        else{
          return false
        }
      }
    }
    return (
        <div className="row my-account-content plan-border" id="my-plan">
          <div className="f-16 fc-g fw-b ta-left mb-30">
            {t("DASHBOARD.MY_ACCOUNT_LANDING.MY_PLAN_HEADER")}
          </div>
          <div className={`row ${isChangePlanDisplayed() ? "pointer-cursor":undefined}`} onClick={() => isChangePlanDisplayed() ? moveToEditPage(routePath.CHANGE_PLAN, "changePlan"):undefined}>
            <div className="col-10 pb-15 plan-type">
              <div className="plan-type-header">
                {t("DASHBOARD.MY_ACCOUNT_LANDING.PLAN_TYPE")}
              </div>
              {IsBrotherPlus_Signup 
                ?
                <div className="plan-occasional">{planName}</div>
                :
                planName && planPages
                ?
                (<div className="plan-occasional">{t('DASHBOARD.CURRENT_PLAN_NAME', { plan_name: getPlanTranslation(planName), plan_pages: planPages })}</div>)
                : 
                (<div className="plan-occasional"></div>)
              }
            </div>
            {isChangePlanDisplayed() ?
            <div className="col my-account-edit-btn pb-15">
              <img src={rightArrow} alt="edit billing address" />
            </div> 
            :null
            }
          </div>
          {paymentCardNumber &&
          <div
            className="row pointer-cursor"
            onClick={() => moveToEditPage(routePath.EDIT_ACCOUNT, "paymentDetails")}
          >
            <div className="col-10 mt-15">
              <div className="plan-type-header">
                {t("DASHBOARD.MY_ACCOUNT_LANDING.PAYMENT_TYPE")}
              </div>
              <div className="plan-occasional">
                {paymentCardNumber}
              </div>
            </div>
            <div className="col my-account-edit-btn mt-15">
              <img src={rightArrow} alt="edit billing address" />
            </div>
          </div>}
        </div>
    );
};

export default MyPlan;