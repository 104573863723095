import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../shared/loader/Loader";
import { useTranslation } from "react-i18next";
import EcoProButton from "../../../../shared/button/button";
import './SupplyOrderLogs.css';

const SupplyOrderLogs = (props) => {
  const { handleSupplyOrderLogs } = props;
  const { t } = useTranslation(['CSAgenttranslation']);
  const supplyOrderLogs = useSelector(state => state.CSAGENT.SupplyOrderLogs);
  return (
      supplyOrderLogs.loading ? <Loader></Loader> :
      supplyOrderLogs.success ? 
      <div className="email-log-container">
        <div className="supply-order-logs-refresh-btn">
            <EcoProButton cname="default white-bg-btn" onBtnClick={() => handleSupplyOrderLogs() }>{t('REFRESH_BTN_TEXT')}</EcoProButton>
        </div>
        <table className="table cs-agent-supply-order-logs-table">
          <thead>
            <tr>
              <th scope="col">{t('SUPPLY_ORDER_LOGS.TABLE_HEAD.DATETIME')}</th>
              <th scope="col">{t('SUPPLY_ORDER_LOGS.TABLE_HEAD.ITEMS')}</th>
              <th scope="col">{t('SUPPLY_ORDER_LOGS.TABLE_HEAD.STATUS')}</th>
              <th scope="col">{t('SUPPLY_ORDER_LOGS.TABLE_HEAD.ORDER_ID')}</th>
            </tr>
          </thead>
          <tbody>
            {supplyOrderLogs?.supplyOrderLogs?.result?.map((supplyLogItem) => (
              <tr>
                <td>{supplyLogItem.date}</td>
                <td>
                  { 
                    supplyLogItem.items && 
                    <table className="table supply-order-product-item-table">
                      <thead>
                        <tr>
                          <th>{t('SUPPLY_ORDER_LOGS.TABLE_BODY_HEAD.PRODUCT_CODE')}</th>
                          <th>{t('SUPPLY_ORDER_LOGS.TABLE_BODY_HEAD.PRODUCT_QUANTITY')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          JSON.parse(supplyLogItem.items).map((productItem) => (
                            <tr>
                              <td>{productItem.ProductCode}</td>
                              <td>{productItem.ProductQuantity}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  }
                </td>
                <td>{supplyLogItem.status}</td>
                <td>{(supplyLogItem.orderId) ? supplyLogItem.orderId : 'NA'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> :
      supplyOrderLogs.error ? 
      <div className="email-log-error">{t("ERROR_CONTENT.SUPPLY_ORDER_LOGS")}</div>
      : null
  );
}

export default SupplyOrderLogs;