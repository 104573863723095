import { useContext } from "react";
import { useSelector } from "react-redux";
import { FormContext } from "../../../../components/Form/FormBrother";
import "./WebFormInput.css";

function WebFormInput(props) {
  const {
    bsClassName,
    label,
    type = "text",
    name,
    placeholder,
    id,
    required,
    errorMsg,
    minLength,
    maxLength,
    readOnly,
    postCodeLoading,
    requiredLabel
  } = props;

  const result = useSelector((state) => state.MyAccount);

  const formContext = useContext(FormContext);
  const { formContent, handleFormChange, showSDI } = formContext;
  return (
    <>
      <div className={`${bsClassName} form-floating ${name === 'eInvoiceId' && !showSDI ? 'd-none' : 'show'}`}>
        <input
          type={type}
          className={`form-control ${
            (result.showZipError === true && name === "postcode") ||
            (result.emailError === true && name === "emailAddress") ||
            (result.sdiError === true && name === "eInvoiceId")
              ? "err-input"
              : null
          }`}
          id={id}
          name={name}
          value={formContent[name]}
          onChange={handleFormChange}
          onPaste={handleFormChange}
          placeholder={placeholder}
          required={(name === "company" && showSDI) ? true :  (name === "eInvoiceId" && !showSDI) ? false : required}
          minLength={minLength}
          maxLength={maxLength}
          readOnly={readOnly}
        />
        <label htmlFor={id}>       
          {name === "company" && showSDI ? requiredLabel: label}
        </label>
        {postCodeLoading &&
        <div className="web-form-input-spinner-wrapper">
          <div className="spinner-border" role="status">
          </div>
        </div>
        }
        <div
          className={`invalid-feedback ${
            (result.showZipError === true && name === "postcode") ||
            (result.emailError === true && name === "emailAddress") ||
            (result.sdiError === true && name === "eInvoiceId")
              ? "display-error"
              : null
          }`}
        >
          {errorMsg}
        </div>
      </div>
    </>
  );
}

export default WebFormInput;
