import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignUp";
import { _CALLGETAPI } from '../../../api/apiHandler';
import { LOCALSESSIONDATAKEYS, WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import { getLocalSessionData } from '../../../utils/Authentication/handleUserSession';
import { call_GetUserRegisterationType } from '../../Authentication/SignIn_Auth';

function _GETUSERBOCACCOUNTDETAILS(){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_USER_BOC_ID_BY_ADB2CID;
    const payload = {
        params: {
            adb2cId: getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID)
        }
      }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getUserBOCAccountDetails(){
    try {
        const BOCResp = yield call(_GETUSERBOCACCOUNTDETAILS);
        const BOC_UID = BOCResp.userId;
        (BOC_UID)
        ? yield* call_GetUserRegisterationType(BOC_UID, 'SIGN_UP', BOCResp)  
        : yield put({type: actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_SUCCESS, userData: BOCResp});
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_ERROR, message: e.message});
        }
    }
}
function* GetUserBOCIdByADB2CId(){
    yield takeEvery(actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_INIT, getUserBOCAccountDetails);
}
export default GetUserBOCIdByADB2CId;
export { _GETUSERBOCACCOUNTDETAILS };