import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { _CALLPOSTAPI } from '../../api/apiHandler'
import { _API_ENDPOINT,_BROTHER_PLUS_API_ENDPOINTS, SUCCESS, ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from '../../constants/AppConstants';
import i18next from 'i18next';
import { getLocalSessionData, getUserSessionData } from '../../utils/Authentication/handleUserSession';
import { Is_BrotherPlus_Signup } from '../../constants/AppMethod';

function _ADDUSERFEEDBACK(feedbackPayload){
    const isBrotherPlus = Is_BrotherPlus_Signup();
    const endpoint = isBrotherPlus ? _BROTHER_PLUS_API_ENDPOINTS.ADD_BROTHERPLUS_USER_FEEDBACK :  _API_ENDPOINT.SIGNUP_USER_FEEDBACK;
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const payload = {...feedbackPayload, "UserId": UID, "DeviceId": deviceID};
 
    return _CALLPOSTAPI(endpoint, payload )
    .then(response => {
       return response;
    })
    .catch((error) => {
        throw error;
    }); 
}

function* handleAddUserFeedback(action){
    try{
        const feedbackResult = yield call(_ADDUSERFEEDBACK, action.feedbackData);
        if(feedbackResult.actionStatus.toUpperCase() === SUCCESS){
            const successType = (action.feedbackType === 'ecopro-feedback-desktop') ? actionTypes.SIGNUP_USER_FEEDBACK_SUCCESS_DESKTOP : actionTypes.SIGNUP_USER_FEEDBACK_SUCCESS;
            yield put({type: successType, feedbackResult: feedbackResult})        
        }
        else{
            const errorType = (action.feedbackType === 'ecopro-feedback-desktop') ? actionTypes.SIGNUP_USER_FEEDBACK_ERROR_DESKTOP : actionTypes.SIGNUP_USER_FEEDBACK_ERROR;
            yield put({type: errorType, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            const errorType = (action.feedbackType === 'ecopro-feedback-desktop') ? actionTypes.SIGNUP_USER_FEEDBACK_ERROR_DESKTOP : actionTypes.SIGNUP_USER_FEEDBACK_ERROR;
            yield put({type: errorType, message: e.message});
        }
    }
}

function* SignUpUserFeedback(){
    yield takeEvery(actionTypes.SIGNUP_USER_FEEDBACK, handleAddUserFeedback)
}

export default SignUpUserFeedback;