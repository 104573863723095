import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CSAGENT_API_ENDPOINTS, FAILURE, USER_ACTIVITY_LOGS } from "../../../constants/AppConstants";
import { _CALLPOSTAPI } from "../../../api/apiHandler";
import i18next from "i18next";
import UserActivityLogsPayload from "../../../utils/AdminPortal/UserActivityLogsPayload";

async function _CALLUSERPREENROLLMENTINFOAPI(customerId) {
    const endpoint = _CSAGENT_API_ENDPOINTS.GET_USER_PRE_ENROLLMENT_INFO;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_USER_PRE_ENROLLMENT_INFO,
        description: `Fetched preenrollment info for the customer ${customerId}`
    }
    const payload = {
        userId: customerId,
        servicePortalUser: await UserActivityLogsPayload(logDetails),
    };
    return _CALLPOSTAPI(endpoint, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
}

function* handleUserPreEnrollmentInfo(action) {
    try {
        const customerId = action.customerID;
        const preEnrollmentDetails = yield call(_CALLUSERPREENROLLMENTINFOAPI, customerId);
       
        if (preEnrollmentDetails.actionStatus.toUpperCase() !== FAILURE) {
            yield put({ 
                type: actionTypes.GET_USER_PRE_ENROLLMENT_INFO_SUCCESS, 
                preEnrollmentDetails: preEnrollmentDetails 
            });
        } else {
            yield put({ 
                type: actionTypes.GET_USER_PRE_ENROLLMENT_INFO_ERROR, 
                message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR') 
            });
        }
    } catch (e) {
        yield put({ 
            type: actionTypes.GET_USER_PRE_ENROLLMENT_INFO_ERROR, 
            message: e.message 
        });
    }
}

function* UserPreEnrollmentInfo() {
    yield takeLatest(actionTypes.GET_USER_PRE_ENROLLMENT_INFO_INIT, handleUserPreEnrollmentInfo);
}

export default UserPreEnrollmentInfo;
