import React, {useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { GetCancellationFee, UpdateShippingAddress, ReactivateSubscription, ResetCancelSubscriptionResult, ResetSubscriptionReactivationResult } from '../../../actions/Dashboard';
import { ManageSubscription } from '../../../actions/MyAccount';
import ErrorPage from '../../../components/shared/errorPage/ErrorPage';
import Loader from '../../../components/shared/loader/Loader';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn, SUBSCRIPTION_TYPE, tryAgainBtn, UNIQUE_ID_GA, featureSwitchName } from '../../../constants/AppConstants';
import { routePath } from '../../../constants/Routes';
import warning from '../../../assets/images/warning.svg';
import DisplayUpdateAccountConfirmation from '../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import updateShippingAddrsConfirmationIcon from '../../../assets/images/update-shipping-as-billing-confirmation-icon.svg';
import updateBillingAddrsConfirmationIcon from '../../../assets/images/update-billing-as-shipping-confirmation-icon.svg';
import { DisplayDate } from '../../../utils/DisplayDate';
import { getLangCode } from '../../../utils/Internationalization/handleAppInternationalization';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { getFeatureSwitchByKey } from '../../../utils/Authentication/handleUserSession';
import { Is_Both_EcoPro_And_BrotherPlus_RegistrationType, Is_BrotherPlus_Signup, formatNumberOnLocale, isDelayedCancellationEnabled, verifyItalyBusinessUser } from '../../../constants/AppMethod';
import currencies from '../../../constants/CurrencyTypes';
import { getLocalSessionData, getUserSessionData } from '../../../utils/Authentication/handleUserSession';
import AdminFormCheckSwitchType from '../../../components/AdminPortal/Shared/FormElements/FormCheckSwitchType/FormCheckSwitchType';
import convertHtmlToReact from '@hedgedoc/html-to-react';


const UpdateAccountConfirmation = (props) => {
  const history = useHistory();
  const paymentMethodId = "";
  const { t } = useTranslation(["translation"]);
  const uid = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const [updateSubmitStatus, setUpdateSubmitStatus] = useState(false);
  const [rejectSubmitStatus, setRejectSubmitStatus] = useState(false);
  const [brotherPlus_CancellationToggle_Status, setBrotherPlus_CancellationToggle_Status] = useState(true);
  const dispatch = useDispatch();
  const ManageSubscriptionResult = useSelector((state)=>state.ManageSubscription)
  const updateShippingResult = useSelector((state) => state.UpdateShipping);
  const MyAccount = useSelector((state) => state.MyAccount);
  const DeviceInfo = useSelector((state) => state.DashboardDeviceInfo)
  const canelSubscriptionStatusResult = useSelector(state => state.GetCancelSubscriptionStatus)
  const reactivatedSubscriptionResult = useSelector(state => state.ReactivateSubscription)
  const dateDisplayOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const isdelayCancellationFeatureSwitch = getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION) ? true : false
  const isBothRegType = Is_Both_EcoPro_And_BrotherPlus_RegistrationType();
  const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

  const ZuoraPaymentInfo = useSelector(
    (state) => state.ZuoraPaymentInfoRendering
  );
  const [shippingState,setShippingState] = useState(null)
  const [billingState, setBillingState] = useState(null);
  const cancellationFeesResult = useSelector((state) => state.GetCancellationFees)
  const [cancellationFee, setCancellationFee] = useState({
    price:"",
  })

  const langCode = getLangCode()

  const [modal, setModal] = useState(false);
  
  useEffect(()=>{
    if(props.location.state.accountClicked === 'cancelPlan'){
      const minimumTermEndData = getLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE)
      if(minimumTermEndData){
        dispatch(GetCancellationFee(uid, deviceId))
      }
    }
   },[deviceId, dispatch, props?.location?.state?.accountClicked, uid])

   useEffect(() => {
    if (cancellationFeesResult?.success) {
        const cancellationFeeObj = cancellationFeesResult?.cancellationFee;
        if (cancellationFeeObj) {
            const { cancellationFee, currency } = cancellationFeeObj;
            const currencySymbol = currencies[currency]?.code;

            setCancellationFee(prevSelections => ({
                ...prevSelections,
                price: formatNumberOnLocale(cancellationFee, currencySymbol),
            }));
        }
    }
   }, [cancellationFeesResult?.success, cancellationFeesResult?.cancellationFee]);

   useEffect(() => {
       if (reactivatedSubscriptionResult?.success) {
           setModal(true)
           dispatch(ResetCancelSubscriptionResult())
       }
   }, [reactivatedSubscriptionResult?.success, dispatch])

   const toggle = () => {
       dispatch(ResetSubscriptionReactivationResult())
       setModal(!modal);
       history.push({
           pathname: routePath.DASHBOARD
       })
   }

  const onReject = () => {
      if (props.location.state.accountClicked === "shippingAddress") {
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(UpdateShippingAddress(subscriptionPayload));
        setRejectSubmitStatus(true)
      } else if (props.location.state.accountClicked === "billingAddress") {
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
        setRejectSubmitStatus(true)
      }
    };
    
    if(updateShippingResult.success && shippingState === true){
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
        setShippingState(false)
    }

    if(ManageSubscriptionResult.success && billingState === true){
      const subscriptionPayload = {
        accountDetails: MyAccount.account,
      };
      dispatch(UpdateShippingAddress(subscriptionPayload));
      setBillingState(false)
    }
    
    const onUpdate = () => {
      if (props.location.state.accountClicked === "shippingAddress") {
        const isItalyBusinessUser = verifyItalyBusinessUser(MyAccount?.account.billingAddress.country)
        if(isItalyBusinessUser){
          if(!(MyAccount?.account?.shippingAddress?.company)){
            MyAccount.account.billingAddress = {
              address: MyAccount?.account?.shippingAddress?.address,
              addressLine2: MyAccount?.account?.shippingAddress?.addressLine2,
              city: MyAccount?.account?.shippingAddress?.city,
              country: MyAccount?.account?.shippingAddress?.country,
              postcode: MyAccount?.account?.shippingAddress?.postcode,
              company: MyAccount?.account?.billingAddress?.company,
              province: MyAccount?.account?.shippingAddress?.province,
              eInvoiceId: MyAccount?.account?.billingAddress?.eInvoiceId
            }
          }
          else{
            MyAccount.account.billingAddress = {
              address: MyAccount?.account?.shippingAddress?.address,
              addressLine2: MyAccount?.account?.shippingAddress?.addressLine2,
              city: MyAccount?.account?.shippingAddress?.city,
              country: MyAccount?.account?.shippingAddress?.country,
              postcode: MyAccount?.account?.shippingAddress?.postcode,
              company: MyAccount?.account?.shippingAddress?.company,
              province: MyAccount?.account?.shippingAddress?.province,
              eInvoiceId: MyAccount?.account?.billingAddress?.eInvoiceId
            }
          }
        }
        else{
          MyAccount.account.billingAddress = MyAccount.account.shippingAddress;
        }

        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(UpdateShippingAddress(subscriptionPayload))
        setShippingState(true)
        setUpdateSubmitStatus(true)
      }
      if (props.location.state.accountClicked === "billingAddress") {
        MyAccount.account.shippingAddress = MyAccount.account.billingAddress;
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
        setBillingState(true)
        setUpdateSubmitStatus(true)
      }        
    };
    const onConfirmCancelPlan = (isReactivated) => {
      if(isReactivated){
        dispatch(ReactivateSubscription())
      }
      else{
      history.push({
        pathname:routePath.CANCEL_PLAN_FEEDBACK,
        state:{'customerConfirmStatus': true, 'isBrotherPlusCancellationEnabled': (isBothRegType) ? !brotherPlus_CancellationToggle_Status : false}})
      }
    }
    const onStopCancellation = () => {
      dispatch(ResetCancelSubscriptionResult())
      history.push(routePath.DASHBOARD)
    }

    const getDisplayTxt_For_Cancellation = (formattedBillingEndDate) => {
      let text = t("EDIT_FORM.UPDATE_ACCOUNT_INFO.CANCEL_PLAN");
      if(isDelayedCancellationEnabled(DeviceInfo?.deviceInfo?.service?.activatedDate)){
        text = <Trans i18nKey="DELAY_CANCELLATION.DELAY_CANCEL_PLAN" formattedBillingEndDate={formattedBillingEndDate} >
        If you cancel now, your subscription will finish at the end of your last billing month on <strong>{{formattedBillingEndDate}}</strong> and you will no longer receive the benefits your EcoPro plan provides.
        </Trans>;
      } else {
        if(isBothRegType){
          text = t('BROTHER_PLUS.CANCEL_PLAN.ECOPRO_CANCELLATION_TEXT_FOR_BOTH_THE_MEMBERSHIP.SUB_HEADER');
        } else if (IsBrotherPlus_Signup) {
          text = t("BROTHER_PLUS.CANCEL_PLAN.BPLUS_CANCEL_PLAN")
        } else {
          text = t("EDIT_FORM.UPDATE_ACCOUNT_INFO.CANCEL_PLAN");
        }
      }
      return text;
    }
    const handleBrotherplusCancellationToggle = () => {
      setBrotherPlus_CancellationToggle_Status(!brotherPlus_CancellationToggle_Status);
    }
    const handleEcoProCancellation_With_BrotherPlus_template = () => {
      return (
        <div className='cancellation-with-brotherplus-ecopro-membership-wrapper'>
            <div className='list f-12'>{convertHtmlToReact(t('BROTHER_PLUS.CANCEL_PLAN.ECOPRO_CANCELLATION_TEXT_FOR_BOTH_THE_MEMBERSHIP.BROTHER_PLUS_BENEFITS_LIST'), [true])}</div>
            <div className='toggle-content-wrapper'>
                <p>{t('BROTHER_PLUS.CANCEL_PLAN.ECOPRO_CANCELLATION_TEXT_FOR_BOTH_THE_MEMBERSHIP.TOGGLE_SWITCH_CONTENT')}</p>
                <AdminFormCheckSwitchType 
                   inputID = "brother-plus-cancellation-toggle"
                   inputName = "brother-plus-cancellation-toggle"
                   labelText = {null}
                   onChange={() => handleBrotherplusCancellationToggle()}
                   isChecked={brotherPlus_CancellationToggle_Status}
                />
            </div>
        </div>
      )
    }
        
    if(reactivatedSubscriptionResult.loading){
      return (
        <div className={`loader-overlay-wrapper`}>
          <Loader slowResponse={true} cancelSubscription={true} setOverlay={true}></Loader>
       </div>
      )
    }
    if(modal){
      return (
        <div>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader>{t("DELAY_CANCELLATION.REACTIVATE_SUCCESSFUL_MODAL")}</ModalHeader>
                <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    {t("ECOPRO_BTNS.CLOSE")}
                </Button>
                </ModalFooter>
            </Modal>
            </div>
      )
    }
    if (ZuoraPaymentInfo.loading || updateShippingResult.loading || ManageSubscriptionResult.loading || cancellationFeesResult.loading) {
      return <Loader></Loader>;
    }
    if(ManageSubscriptionResult.error){
      return <ErrorPage errorInfo={ManageSubscriptionResult.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.MY_ACCOUNT}></ErrorPage>
    }
    if(ZuoraPaymentInfo.error){
      return <ErrorPage errorInfo={ZuoraPaymentInfo.error} showRefreshBtn={refreshBtn.SHOW} ></ErrorPage>
    }
    if (updateShippingResult.error) {
      return (
        <ErrorPage
          errorInfo={updateShippingResult.error}
          showRefreshBtn={refreshBtn.HIDE}
          showTryAgain={tryAgainBtn.SHOW}
          to={routePath.MY_ACCOUNT}
        ></ErrorPage>
      );
    }
    if (props.location.state.accountClicked === "shippingAddress" && updateSubmitStatus === false && rejectSubmitStatus === false) {
      return(
        <DisplayUpdateAccountConfirmation
          updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_HEADER.SHIPPING_SAME")}
          updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_INFO.SHIPPING_SAME")}
          updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.SHIPPING_SAME")}
          onUpdateClick={() => onUpdate()}
          onRejectClick={() => onReject()}
          noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.NO_THANKS")}
          image={updateBillingAddrsConfirmationIcon}
          onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-update-both-as-shipping"}
          onRejectBtnID={UNIQUE_ID_GA.myAccount+"-reject-billing"}
        ></DisplayUpdateAccountConfirmation>
      )
    }
    else if (props.location.state.accountClicked === "billingAddress" && updateSubmitStatus === false && rejectSubmitStatus === false) {
      return(
        <DisplayUpdateAccountConfirmation
          updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_HEADER.BILLING_SAME")}
          updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_INFO.BILLING_SAME")}
          updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.BILLING_SAME")}
          onUpdateClick={() => onUpdate()}
          onRejectClick={() => onReject()}
          noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.NO_THANKS")}
          image={updateShippingAddrsConfirmationIcon}
          onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-update-both-as-billing"}
          onRejectBtnID={UNIQUE_ID_GA.myAccount+"-reject-shipping"}
        ></DisplayUpdateAccountConfirmation>
      )
    }
    else if(props.location.state.accountClicked === 'cancelPlan'){
      const billingCycleEndDateFromCancelSubscription = canelSubscriptionStatusResult?.success && canelSubscriptionStatusResult?.cancelSubscriptionStatus?.billingCycleEndDate
      const formattedBillingEndDate = DisplayDate(billingCycleEndDateFromCancelSubscription,  dateDisplayOptions, langCode)
      if(canelSubscriptionStatusResult?.success && billingCycleEndDateFromCancelSubscription && isdelayCancellationFeatureSwitch){
        return (
          <DisplayUpdateAccountConfirmation
          updateAddressHeader={t("DELAY_CANCELLATION.UPDATE_REACTIVATE_SUBSCRIPTION")}
          updateAddressInfo={<Trans i18nKey="DELAY_CANCELLATION.CANCEL_SUBSCRIPTION_ALERT" formattedBillingEndDate={formattedBillingEndDate}>
          You have cancelled your subscription. Your plan will get deactivated by the end of you last billing month, on <strong>{{formattedBillingEndDate}}</strong>. If you change your mind and wish to continue receiving the benefits of EcoPro, you can reactivate it below.
          </Trans>}
          updateAddressBtn={t("DELAY_CANCELLATION.REACTIVATE_SUBSCRIPTION")}
          onUpdateClick={() => onConfirmCancelPlan(true)}
          onRejectClick={() => onStopCancellation()}
          noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.NO_THANKS")}
          image={warning}
        ></DisplayUpdateAccountConfirmation>
        )
      } else {
        const billingEndDate = DeviceInfo?.deviceInfo?.service?.currentUsage?.usageCycleEndDate
        const formattedBillingEndDate = DisplayDate(billingEndDate,  dateDisplayOptions, langCode)
      return (
         <DisplayUpdateAccountConfirmation
                updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_HEADER.CANCEL_PLAN")}
                updateAddressInfo={getDisplayTxt_For_Cancellation(formattedBillingEndDate)}
                subInfo={getLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE) ? t("MINIMUM_TERM_PERIOD.MINIMUM_TERM_PERIOD_CANCELLATION", {price: cancellationFee?.price }) : null}
                ecoproCancellation_with_brotherplus_template ={(isBothRegType) ? handleEcoProCancellation_With_BrotherPlus_template() : null}
                updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CANCEL_PLAN")}
                onUpdateClick={() => onConfirmCancelPlan()}
                onRejectClick={() => onStopCancellation()}
                noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.STOP_CANCEL_PLAN")}
                image={warning}
              ></DisplayUpdateAccountConfirmation>
          
      )
    }
    }
    else if (updateSubmitStatus === true && updateShippingResult.success ===  true && ManageSubscriptionResult.success === true) {
      return (
        <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
        state:{accountClicked:"shippingBillingAddress"}}}></Redirect>
      )
    }
    else if(rejectSubmitStatus === true && props.location.state.accountClicked === "shippingAddress" && updateShippingResult.success){
      return (
        <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
        state:{accountClicked:"shippingAddress"}}} ></Redirect>
      )
    }
    else if(rejectSubmitStatus === true && props.location.state.accountClicked === "billingAddress" && ManageSubscriptionResult.success){
      return (
        <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
        state:{accountClicked:"billingAddress"}}}></Redirect>
      )
    }
    else{
      return (
        <ErrorPage/>
      )
    }
  };

export default UpdateAccountConfirmation;
