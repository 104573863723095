import { all } from "redux-saga/effects";
import watchFetchCancelService from "./brotherPlus/cancelService/cancelServiceSaga";
import watchRegisterService from "./brotherPlus/EnrollmentService/RegisterService/RegisterServiceSaga";
import watchGetDevicesService from "./brotherPlus/getDevices/getDevicesSaga";
import watchGetDeviceStatusService from "./brotherPlus/getDeviceStatus/getDeviceStatusSaga";
import watchGetPrintHistoryService from "./brotherPlus/getPrintHistory/getPrintHistorySaga";
import watchGetUserPromoCodeService from "./brotherPlus/getUserPromoCode/getUserPromoCodeSaga";
import watchFetchBOCDeviceConnectionService from "./CSAgentPortal/BOCDeviceConnectionServiceSaga";
import watchAddUserActivityLogService from "./brotherPlus/AddUserActivityLogService/addUserActivityLogServiceSaga";
import watchFetchUserDeviceStatusService from "./CSAgentPortal/BrotherPlus/GetUserDeviceStatusSaga";
import watchFetchUserSubscriptionDetailsService from "./CSAgentPortal/BrotherPlus/GetUserSubscriptionDetailsSaga";
import watchFetchUserPrintHistoryService from "./CSAgentPortal/BrotherPlus/GetUserPrintHistorySaga";
import watchValidateUserPromoCodeService from "./brotherPlus/ValidateUserPromoCode/validateUserPromoCodeSaga";


export default function* brotherPlusSagasIndex() {
    yield all([
        watchFetchCancelService(),
        watchRegisterService(),
        watchGetDevicesService(),
        watchGetDeviceStatusService(),
        watchGetPrintHistoryService(),
        watchGetUserPromoCodeService(),
        watchFetchBOCDeviceConnectionService(),
        watchAddUserActivityLogService(),
        watchFetchUserDeviceStatusService(),
        watchFetchUserSubscriptionDetailsService(),
        watchFetchUserPrintHistoryService(),
        watchValidateUserPromoCodeService()
    ])
}