import * as actionTypes from '../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

export const FetchCustomerData = (deviceSerialNumber = null, ADB2CAttributeName = null, ADB2CAttributeValue = null) => ({
    type: actionTypes.CUSTOMER_INFO_CALL_INIT,
    deviceSerialNumber,
    ADB2CAttributeName,
    ADB2CAttributeValue
})
export const FetchCustomerDataResetState = () => ({
    type: actionTypes.RESET_STATE
})
export const UpdateOrderConsumables = (customerID, customerDeviceId, consumablesData) => ({
    type: actionTypes.UPDATE_ORDER_CONSUMABLES,
    customerID,
    customerDeviceId,
    consumablesData
})
export const ResetOrderConsumable = () => ({
    type: actionTypes.RESET_ORDER_CONSUMABLES,
})
export const EnableDeviceSwap = (customerID, customerDeviceId, swapStatus, isSwapEligible) => ({
    type: actionTypes.ENABLE_DEVICE_SWAP_INIT,
    customerID,
    customerDeviceId,
    swapStatus,
    isSwapEligible
})
export const ResetSwapDevice = () => ({
    type: actionTypes.SWAP_RESET_STATE
})
export const FetchEmailLogs = (customerID) => ({
    type: actionTypes.FETCH_EMAIL_LOGS,
    customerID
})

export const FetchSupplyOrderLogs = (customerID) => ({
    type: actionTypes.FETCH_SUPPLY_ORDERS_LOGS,
    customerID
})

export const FetchSubscriptionDetails = (customerID) => ({
    type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_INIT,
    customerID
})

export const FetchDeviceAndUsageDetails = (customerID, loadedTab = null) => ({
    type: actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_INIT,
    customerID, 
    loadedTab
})

export const FetchUserActivityHistory = (customerID) =>({
    type:actionTypes.FETCH_USER_ACTIVITY_HISTORY_INIT,
    customerID
})

export const GetUserPreEnrollmentData = (customerID) =>({
    type:actionTypes.GET_USER_PRE_ENROLLMENT_INFO_INIT,
    customerID
})