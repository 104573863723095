import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { _BROTHER_PLUS_API_ENDPOINTS, FAILURE } from '../../constants/AppConstants';
import { _CALLGETAPI } from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { EnrollmentStatus_BrotherPlus } from '../../actions/Dashboard';

const useBrotherPlusUpdateDeviceStatus = ({ deviceId, userId, setDeviceStatus }) => {
    const { t } = useTranslation(["translation"]);
    const dispatch = useDispatch();
    const updateState = useCallback(async () => {
        const endpoint = _BROTHER_PLUS_API_ENDPOINTS.GET_DEVICE_STATUS;
        const payload = {
            params: {
                deviceId: deviceId,
                userId: userId,
            }
        };
        try {
            if (deviceId && userId) {
                const response = await _CALLGETAPI(endpoint, payload);
                const data = await response;
                setDeviceStatus(prev => ({ ...prev, loader: false }));
                if (data.actionStatus.toUpperCase() !== FAILURE) {
                    setDeviceStatus(prev => ({ ...prev, success: true, response: data }));
                    dispatch(EnrollmentStatus_BrotherPlus(true));
                } else if (!data.service) {
                    setDeviceStatus(prev => ({ ...prev, error: t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED') }));
                } else {
                    setDeviceStatus(prev => ({ ...prev, error: t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR') }));
                }
            } else {
                setDeviceStatus(prev => ({ ...prev, loader: false }));
                setDeviceStatus(prev => ({ ...prev, error: t('ERROR_CONTENT.INFO_GENERAL') }));
            }
        } catch (e) {
            if (!(e instanceof TypeError) && e.message !== '401') {
                setDeviceStatus(prev => ({ ...prev, error: e.message }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [deviceId, userId, setDeviceStatus, t]);

    return updateState;
};

export default useBrotherPlusUpdateDeviceStatus;
