import { call, put, takeLatest } from "redux-saga/effects";
import { 
    GetUserRegistrationServiceFailure, 
    GetUserRegistrationServiceInit, 
    GetUserRegistrationServiceSuccess } from "./UserRegistrationSlice";
import { _CALLGETAPI } from "../../api/apiHandler";
import { _API_ENDPOINT, LOCALSESSIONDATAKEYS, SUCCESS } from "../../constants/AppConstants";
import i18next from "i18next";
import { handleError } from "../SagaError";
import { getLocalSessionData, storeUserRegistration } from "../../utils/Authentication/handleUserSession";

function __USERREGISTERATIONSERVICE(UID){
  const endpoint = _API_ENDPOINT.GET_USER_REGISTRATION_TYPE;
  const payload = {
      params: {
          'userId':UID
      }
  }
  return _CALLGETAPI(endpoint, payload)
  .then(response => {
      return response;
  })
  .catch((error) => {
      throw error;
  });
}

function* handleUserRegistrationService(){
  try {
    const UID = getLocalSessionData(LOCALSESSIONDATAKEYS.UID);
    const userregistrationInfo = yield call(__USERREGISTERATIONSERVICE, UID);
    storeUserRegistration(userregistrationInfo);
    (userregistrationInfo.actionStatus.toUpperCase() === SUCCESS)
    ? yield put(GetUserRegistrationServiceSuccess(userregistrationInfo))
    :  yield* handleError(GetUserRegistrationServiceFailure, i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"));
  } catch (error) {
    yield* handleError(GetUserRegistrationServiceFailure, error.message);
  }
}
export default function* watchGetUserRegistrationService() {
    yield takeLatest(GetUserRegistrationServiceInit.type, handleUserRegistrationService);
}

export { __USERREGISTERATIONSERVICE };