import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import { getPrintHistoryFailure, getPrintHistoryRequest, getPrintHistorySuccess } from './getPrintHistorySlice';


function _GETPRINTHISTORY(userId, deviceId){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.GET_PRINT_HISTORY;
    const payload = {
        params:{
            userId: userId,
            deviceId: deviceId
        }
    }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getPrintHistorySaga(action) {
  try {
    const userId = action.payload.userId
    const deviceId = action.payload.deviceId
    const printHistory = yield call(_GETPRINTHISTORY, userId, deviceId);
    if(printHistory?.actionStatus.toUpperCase() === SUCCESS){
      yield put(getPrintHistorySuccess(printHistory));
    }
    else{
      yield put(getPrintHistoryFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(getPrintHistoryFailure(error.message));
  }
}

export default function* watchGetPrintHistoryService() {
  yield takeEvery(getPrintHistoryRequest.type, getPrintHistorySaga);
}
