import React, { useState } from "react";
import Grid from "../../../components/AdminPortal/Shared/Grid/Grid";
import EcoProButton from "../../../components/shared/button/button";
import { Trans, useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import PageHeader from "../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTESIInvoices, retryInvoice, retryInvoiceReset } from "../../../actions/AdminPortal/InvoicePosting";
import AdminAlert from "../../../components/AdminPortal/AdminAlert/AdminAlert";
import Loader from "../../../components/shared/loader/Loader";
import AdminOverlayModal from "../../../components/AdminPortal/AdminOverlayModal/AdminOverlayModal";
import './InvoicePosting.css';

const InvoicePosting = () => {
  const { t } = useTranslation("Admintranslation")
  const dispatch = useDispatch();
  const  invoicePostingData = useSelector(state => state.ADMIN.TESIInvoice_VIEW);
  const  retryInvoiceResp = useSelector(state => state.ADMIN.TESIInvoice_RETRY);
  const INVOICE_TYPE = { 'invoice': 'Invoice', 'credit_memo': 'CreditMemo'};
  const [invoiceListType, setInvoiceListType] = useState(INVOICE_TYPE.invoice);
  const [selectedFile, setSelectedFileForRetry] = useState(null);
  const [modalProps, setModalProps] = useState({});

  useEffect(() => {
      setModalProps({isOpen : false});
  }, [dispatch])

  const handleInvoiceType = (invoiceDatatype) => {
    setInvoiceListType(invoiceDatatype);
  }

  const fetchInvoiceRecords = () => {
    dispatch(fetchTESIInvoices(invoiceListType));
  }

  const handleRetryInvoice = (fileName) => {
    setSelectedFileForRetry(fileName);
    dispatch(retryInvoice(fileName, invoiceListType))
  }
  const toggleAdminOverlayModal = () => {
    setModalProps(prevState => ({ isOpen: !prevState.isOpen }));
  }
  const handleModalConfirm = () => {
    setModalProps({isOpen : false});
    dispatch(fetchTESIInvoices(invoiceListType));
  }

  if(retryInvoiceResp.success || retryInvoiceResp.error){
    const modal_content = (retryInvoiceResp.success) 
    ? <Trans i18nKey="MANAGE_TESI_INVOICE_POSTING.RETRY_MODAL_SUCCESS.MODAL_CONTENT" selectedFile={selectedFile}>
        The file <b className="selected-file-success">{selectedFile}</b> was transferred successfully and deleted from Azure blob storage.
      </Trans>
    : <Trans i18nKey="MANAGE_TESI_INVOICE_POSTING.RETRY_MODAL_ERROR.MODAL_CONTENT" selectedFile={selectedFile}>
        An issue occured while transferring the file <b className="selected-file-error">{selectedFile}</b> and unable to delete the same from Azure blob storage.
      </Trans>

    
    setModalProps({
      isOpen : true, 
      toggleAdminOverlayModal: () => toggleAdminOverlayModal(),
      modal_title: (retryInvoiceResp.success) ? t("MANAGE_TESI_INVOICE_POSTING.RETRY_MODAL_SUCCESS.MODAL_TITLE") : t("MANAGE_TESI_INVOICE_POSTING.RETRY_MODAL_ERROR.MODAL_TITLE"),
      modal_sub_title:  "",
      modal_content: modal_content,
      showCancelBtn : false,
      showConfirmBtn: true,
      confirm_btn_text: t("BTN_TEXTS.OK"),
      handleModalConfirm:() => handleModalConfirm(),
      modalClassNames: (retryInvoiceResp.success) ? "invoice-posting-modal-success" : "invoice-posting-modal-error"
    });
    dispatch(retryInvoiceReset());
  }
  return (
      <Container>
          { (invoicePostingData.loading || retryInvoiceResp.loading ) && <Loader setOverlay={true}></Loader>}
          <div className="admin-users-header">
            <PageHeader pageTitle={t("MANAGE_TESI_INVOICE_POSTING.PAGE_TITLE")}></PageHeader>
          </div>
          { invoicePostingData.error && <AdminAlert alert_variant="alert-danger">{invoicePostingData.error}</AdminAlert>}
          { invoicePostingData.success && <AdminAlert alert_variant="alert-success">{t("ADMIN_COMMON_SUCCESS_MSG.FETCH_SUCCESS")}</AdminAlert>}
          <div className="invoice-type-switch-container mt-30">
            <div><h5>{t("MANAGE_TESI_INVOICE_POSTING.CHOOSE_TAX_DATA_TYPE")}: </h5></div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="invoice-type" id="invoice-type" onClick = {(event) => handleInvoiceType(INVOICE_TYPE.invoice)} value={INVOICE_TYPE.invoice} checked={(invoiceListType === INVOICE_TYPE.invoice)}/>
              <label className="form-check-label" for="inlineRadio1">{t("MANAGE_TESI_INVOICE_POSTING.TAX_DATA_TYPE.INVOICE")}</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="invoice-type" id="invoice-type" onClick = {(event) => handleInvoiceType(INVOICE_TYPE.credit_memo)} value={INVOICE_TYPE.credit_memo} checked={(invoiceListType === INVOICE_TYPE.credit_memo)}/>
              <label className="form-check-label" for="inlineRadio2">{t("MANAGE_TESI_INVOICE_POSTING.TAX_DATA_TYPE.CREDIT_MEMO")}</label>
            </div>
            <div className="invoice-data-fetch-btn-wrapper">
              <EcoProButton cname="default" onBtnClick={() => fetchInvoiceRecords()}>{t("BTN_TEXTS.SUBMIT")}</EcoProButton>
            </div>
          </div>
          <div className="mt-30 admin-manage-invoice-posting-grid-wrapper">
            <Grid cname="admin-manage-invoice-posting">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th>{t("MANAGE_TESI_INVOICE_POSTING.COL_NAMES.FILENAME")}</th>
                  <th>{t("MANAGE_TESI_INVOICE_POSTING.COL_NAMES.CREATED_ON")}</th>
                  <th>{t("MANAGE_TESI_INVOICE_POSTING.COL_NAMES.ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {invoicePostingData.data.length > 0 &&
                  invoicePostingData.data.map((record, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{record.fileName}</td>
                      <td>{new Date(record.createdOn).toDateString()}</td>
                      <td className="grid-action-btn-wrapper">
                        <EcoProButton cname="default" type="button" onBtnClick={()=>handleRetryInvoice(record.fileName)}>{t("MANAGE_TESI_INVOICE_POSTING.RETRY")}</EcoProButton>
                      </td>
                    </tr>
                  ))}
                {
                  ((invoicePostingData.data && invoicePostingData.data.length === 0) 
                  || (invoicePostingData.error) || Object.keys(invoicePostingData.data).length === 0) 
                  && <tr>
                    <td colSpan={6} className="grid-no-records">
                      {t("NO_RECORDS")}
                    </td>
                  </tr>
                }
              </tbody>
            </Grid>
          </div>
          <AdminOverlayModal {...modalProps}/>
      </Container>
  );
};

export default InvoicePosting;
