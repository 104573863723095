import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { EnableDeviceSwap, ResetOrderConsumable, UpdateOrderConsumables } from "../../../../../actions/CSAgentPortal/CustomerDashboard";
import EcoProButton from "../../../../shared/button/button";
import EcoProOverlayModal from "../../../../shared/modal/OverlayModal/EcoProOverlayModal";
import './Overview.css';
import Loader from "../../../../shared/loader/Loader";
import { ORDER_PARTS_CONSUMABLES, ORDER_SUPPLIES_CONSUMABLES } from "../../../../../constants/AppConstants";
import EcoproAlert from "../../../../shared/alert/alert";
import AlertDanger from '../../../../../assets/images/alert-danger.svg'
import { printerTypes } from "../../../../../constants/PrinterTypes";
import { currentUserRolesArr } from "../../../../../constants/AppMethod";
import { canAccess_SwapDevice_OrderSupplies } from "../../../../../constants/UserRoles";

const Overview = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const dispatch = useDispatch();
    const NOT_AVAILABLE = 'N/A';
    const [modalState, setModalState] = useState(false);
    const [modalProps, setModalProps] = useState({
        modal_title: "",
        modal_sub_title: "",
        modal_content: "",
        cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
        confirm_btn_text: t("MODAL_BUTTON_TXT.CONFIRM"),
        toggleModal: "",
        customClassName: "",
        confirm_btn_onClick: "",
        type: "normal"
    })
    const [orderSuppliesState, setOrderSuppliesState] = useState(null);
    const [swapDeviceState, setSwapDeviceState] = useState(null);
    const [isOrderAlertOpen, setOrderAlertOpen] = useState(true);
    const [counter, setCounter] = useState({})
    const swapDeviceRes = useSelector(state => state.CSAGENT.EnableDeviceSwap);
    const orderConsumablesRes = useSelector(state => state.CSAGENT.UpdateOrderConsumables)

    //Current Plan info
    const planPages = props?.deviceInfo?.activeDevice?.currentUsage?.planPages || 0;
    const planPagesLeft = props?.deviceInfo?.activeDevice?.currentUsage?.printedPlanPages || 0;
    const rollOverPages = props?.deviceInfo?.activeDevice?.currentUsage?.givenRolloverPages || 0;
    const printedRolloverPages = props?.deviceInfo?.activeDevice?.currentUsage?.printedRolloverPages || 0;
    const overagePages = props?.deviceInfo?.activeDevice?.currentUsage?.purchasedOveragePages || 0;
    const printedOveragePages = props?.deviceInfo?.activeDevice?.currentUsage?.printedOveragePages || 0;

    //Device info
    const deviceModel = props?.deviceInfo?.activeDevice?.modelNumber || NOT_AVAILABLE;
    const deviceSerialNumber = props?.deviceInfo?.activeDevice?.serialNumber || NOT_AVAILABLE;
    const printerType = props?.deviceInfo?.activeDevice?.printerType;
    const isSwapEligible = props?.deviceInfo?.activeDevice?.isSwapEnabled;

    //Subscription status
    const CustomerSubscriptionStatus = props?.subscriptionInfo?.subscriptionDetails?.[0].subscriptionStatus || null;
    /**
     * Extracted Customer info
     */
    const { userId: customerId, deviceId: customerDeviceId } = props || {};

    
    const cancelSwapBtnStatus =  swapDeviceRes?.device_swap_status;
    const INACTIVE = 'INACTIVE';
    const currentuserRoles = currentUserRolesArr();
    const CAN_ACCESS_SWAPDEVICE_ORDERSUPPLIES = canAccess_SwapDevice_OrderSupplies(CustomerSubscriptionStatus?.toUpperCase(), currentuserRoles);
    
    const scrollToTop = () => {
        window.scrollTo(0,0);
    }
    const toggleModal = (event) => {
        setCounter({})
        setModalState(modalState);
    }
    const swapDevice = (type) => {
        scrollToTop();
        setModalState(false);
        let swap_status = type === "swap" ? true : false;
        setSwapDeviceState(true);
        dispatch(EnableDeviceSwap(customerId, customerDeviceId, swap_status, isSwapEligible));
    }

    const handleSwapDevice = (type) => {
        setModalState(true);
        dispatch(ResetOrderConsumable()); 
        setOrderAlertOpen(true);
        let modal_title = type === "swap" ? t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_MODAL_TITLE") : t("CUSTOMER_DASHBOARD.OVERVIEW.CANCEL_SWAP_MODAL_TITLE")
        let modal_content = type === "swap" ? t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_MODAL_CONTENT") : t("CUSTOMER_DASHBOARD.OVERVIEW.CANCEL_SWAP_MODAL_CONTENT")
        setModalProps({
            modal_state: modalState,
            modal_title: modal_title,
            modal_content: modal_content,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CONFIRM"),
            toggleModal: toggleModal,
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: ()=>swapDevice(type),
            type:"normal"
        })
    }
    const incrementCounter = (e) => {
        const valuecounter = (counter[e.target.name]) ? counter[e.target.name] + 1 : 0+1;
        setCounter({...counter, [e.target.name]: valuecounter}) ;
    }
    const decrementCounter = (e) => {
        const valuecounter = (counter[e.target.name]) ? counter[e.target.name] - 1 : 0 ;
        setCounter({...counter, [e.target.name]: valuecounter}) ;
    }
    const handleOrderParts = () => {
        setModalState(true);
        dispatch(ResetOrderConsumable()); 
        setOrderAlertOpen(true);
        setModalProps({
            modal_type:"order_parts",
            modal_title: `${t("CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.ORDER_PARTS")} - ${deviceModel}`,
            modal_sub_title: t("CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.ORDER_PARTS_SUBITITLE"),
            modal_content: ORDER_PARTS_CONSUMABLES,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:  t("CUSTOMER_DASHBOARD.OVERVIEW.CONFIRM_ORDER"),
            toggleModal: toggleModal,
            customClassName: "customer-dashboard-order-parts-modal",
            confirm_btn_onClick: triggerOrderPartsApiCall,
            type: "quantity",
            modal_table_title: t("CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.PART_NAME"),
            modal_table_quantity: t("CUSTOMER_DASHBOARD.OVERVIEW.QUANTITY")
        });
    }
    const handleOrderSupplies = () => {
        setModalState(true);
        dispatch(ResetOrderConsumable()); 
        setOrderAlertOpen(true);
        setModalProps({
            modal_type:"order_supplies",
            modal_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_SUPPLIES"),
            modal_sub_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.SELECT_SUBTITLE"),
            modal_content: ORDER_SUPPLIES_CONSUMABLES,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:   t("CUSTOMER_DASHBOARD.OVERVIEW.CONFIRM_ORDER"),
            toggleModal: toggleModal,
            customClassName: "customer-dashboard-order-parts-modal",
            confirm_btn_onClick: triggerOrderPartsApiCall,
            type: "quantity",
            modal_table_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.SUPPLY_TYPE"),
            modal_table_quantity: t("CUSTOMER_DASHBOARD.OVERVIEW.QUANTITY")
        });
    }
    const triggerOrderPartsApiCall = (type,data) => {
        scrollToTop();
        setModalState(false);
        let consumablePayload = []
        Object.keys(data).map(key => {
            for(let i = 1;i<=data[key];i++)
            consumablePayload.push(key)
            return data
        })
        setOrderSuppliesState(true)
        setCounter({})
        dispatch(UpdateOrderConsumables(customerId, customerDeviceId,consumablePayload))
    }

    const modalClose = () => {
        setModalState(false)
    }
    if(props?.cancelOrderResponse?.success){
        setModalState(true);
        setModalProps({
            modal_title: t("CONFIRM_CANCELLATION.CANCELLATION_SUCCESS_MODAL_TITLE"),
            modal_content:  t("CONFIRM_CANCELLATION.CANCELLATION_SUCCESS_MODAL_CONTENT"),
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CLOSE"),
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: modalClose,
            type: "normal",
        });
    }
    if(swapDeviceRes?.success && swapDeviceState){
        const content = (swapDeviceRes.swap_status_requested) ?  t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_DEVICE.ENABLE_SWAP_CONFIRMED_CONTENT") : t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_DEVICE.DISABLE_SWAP_CONFIRMED_CONTENT");
        setModalState(true);
        setModalProps({
            modal_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_DEVICE.REQUEST_CONFIRMED"),
            modal_content: content,
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CLOSE"),
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: modalClose,
            type: "normal",
        });
        setSwapDeviceState(false);
    }
    if(orderConsumablesRes?.success && orderSuppliesState){
        setModalState(true);
        setModalProps({
            modal_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_CONFIRMED"),
            modal_content: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_CONFIRMED_CONTENT"),
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CLOSE"),
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: modalClose,
            type: "normal",
        });
        setOrderSuppliesState(false)
    }
    const dismissOrderAlert = () => {
        setOrderAlertOpen(false);
    }

    return (
        <div className="overview-container mt-40">
            {
                (orderConsumablesRes.loading 
                || swapDeviceRes.loading 
                || props?.cancelOrderResponse?.loading) && <Loader setOverlay={true}></Loader>

            }
            {
                (props?.cancelOrderResponse?.error) && 
                    <EcoproAlert
                    alertImg = {AlertDanger} 
                    dismissAlert={dismissOrderAlert} 
                    alertSubHeader={props.cancelOrderResponse.error}
                    ></EcoproAlert>
            }
            { 
                (orderConsumablesRes.error || swapDeviceRes.error) && isOrderAlertOpen &&
                <EcoproAlert
                    alertImg = {AlertDanger} 
                    dismissAlert={dismissOrderAlert} 
                    alertSubHeader={t('CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')}
                ></EcoproAlert>
            }
            {
                CustomerSubscriptionStatus && CustomerSubscriptionStatus.indexOf(INACTIVE) > -1 &&
                <EcoproAlert 
                alertClass = "dashboard-info"
                alertSubHeader={t('CUSTOMER_DASHBOARD.OVERVIEW.INACTIVE_INFO', {serial_number: CustomerSubscriptionStatus?.split(":")[1].replace(")", "").trim()})}
                showAlertDismiss = {false}
                ></EcoproAlert>

            }
            <div>
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.TITLE')}</h1>
                    </div>
                </div>
                <Row className="device-page-info-wrapper">
                    <Col className="blocks move-left">
                        <div className="block-title">
                            <h5>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.PAGES_USED')}</h5>
                        </div>
                        <div className="block-content">
                            <p><span>{planPagesLeft}</span> /{planPages}</p>
                        </div>
                    </Col>
                    <Col className="blocks move-left">
                        <div className="block-title">
                            <h5>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.ROLLOVER')}</h5>
                        </div>
                        <div className="block-content">
                            <p><span>{printedRolloverPages}</span> /{rollOverPages}</p>
                        </div>
                    </Col>
                    <Col className="blocks move-left">
                        <div className="block-title">
                            <h5>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.OVERAGES')}</h5>
                        </div>
                        <div className="block-content">
                            <p><span>{printedOveragePages}</span> /{overagePages}</p>
                        </div>
                    </Col>
                </Row>
                <div className="device-details-wrapper mt-40">
                    <div className="header">
                        <div className="title">
                            <h1>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.TITLE')}</h1>
                        </div>
                    </div>
                    <div className="device-details-info mt-15">
                        <p>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.MODEL_NUMBER')}: <span>{deviceModel}</span></p>
                        <p>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.SERIAL_NUMBER')}: <span>{deviceSerialNumber}</span></p>
                    </div>
                { CAN_ACCESS_SWAPDEVICE_ORDERSUPPLIES && <div className="device-details-btn-wrapper">
                        {
                            cancelSwapBtnStatus && !isSwapEligible &&
                            <EcoProButton cname="default white-bg-btn" onBtnClick={() => handleSwapDevice("cancel-swap") }>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.CANCEL_DEVICE_SWAP')}</EcoProButton>
                        }
                        {
                            !cancelSwapBtnStatus && !isSwapEligible &&
                            <EcoProButton cname="default" onBtnClick={() => handleSwapDevice("swap")}>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.SWAP_DEVICE')}</EcoProButton>

                        }
                        {
                            cancelSwapBtnStatus && isSwapEligible &&
                            <EcoProButton cname="default" onBtnClick={() => handleSwapDevice("swap")}>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.SWAP_DEVICE')}</EcoProButton>

                        }
                        {
                            !cancelSwapBtnStatus && isSwapEligible &&
                            <EcoProButton cname="default white-bg-btn" onBtnClick={() => handleSwapDevice("cancel-swap") }>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.CANCEL_DEVICE_SWAP')}</EcoProButton>
                        }
                        {
                            printerType === printerTypes.INKJET?
                            null
                            :
                            <EcoProButton cname="default" onBtnClick={(event) => handleOrderParts(event)}>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.ORDER_PARTS')}</EcoProButton>
                        }
                    </div> }
                </div>
                <div className="cs-agent order-history-section device-details-wrapper mt-40">
                    <div className="header">
                        <div className="title">
                            <h1>{t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.SUPPLIES_ORDER_HISTORY")}</h1>
                        </div>
                    </div>
                    { CAN_ACCESS_SWAPDEVICE_ORDERSUPPLIES && <div className="device-details-btn-wrapper mt-10 mb-60">
                            <EcoProButton cname="default" onBtnClick={(event) => handleOrderSupplies(event)}>{t('CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_SUPPLIES')}</EcoProButton> 
                    </div> }
                </div>
                <EcoProOverlayModal 
                    {...modalProps} 
                    modal_state={modalState}
                    incrementCounter={incrementCounter}
                    decrementCounter={decrementCounter}
                    counter={counter}
                 ></EcoProOverlayModal>
            </div>
        </div>
    )
}

export default Overview;
