import React from "react";
import { useTranslation } from "react-i18next";
import AveragePagesUsed from "../../../../../CustomerDashboard/AveragePagesUsed/AveragePagesUsed";
import Loader from "../../../../../shared/loader/Loader";

const PrintHistory = ({getUserPrintHistory}) => {
    const {loading, error}=getUserPrintHistory;
    const history=getUserPrintHistory?.printHistory?.history
    const {t} = useTranslation("CSAgenttranslation");

    if (loading) {
        return <Loader/>;
    }
    if (error) {
        return <div className="email-log-error">{t("ERROR_MESSAGES.PRINT_HISTORY_NOT_FOUND")}</div>;
    }
    
    return(
        <div className="overview-container customer-details-wrapper mt-40">
        <AveragePagesUsed printHistoryResult={history} source={"brother-plus"}/>
        </div>
    )
}

export default PrintHistory;