import convertHtmlToReact from '@hedgedoc/html-to-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EcoProButton from '../../../../shared/button/button';
import easyPayBannerImage from "../../../../../assets/images/BrotherPlus/easy-pay-plan-banner.svg"
import { setLocalSessionData } from '../../../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS, SIGNUPTYPE } from '../../../../../constants/AppConstants';
import { WebCustomerRoutePaths } from '../../../../../constants/Routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './EasyPayPlan.css'

const EasyPayPlan = (props) => {
    const {source} = props
    const { t } = useTranslation(["translation"]);
    const history = useHistory();

    const handleRedirect = () => {
        setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, SIGNUPTYPE.BROTHER_PLUS);
        if(source === "landing-page"){
            history.push(`${WebCustomerRoutePaths.SELECT_PRINTER}?src=sitecore_brotherplus&plan=brotherplusfreeplan`)
        }
        else{
            const redirectInfo = {
              pathname: WebCustomerRoutePaths.WEB_REDIRECT,
              state: { signupType: SIGNUPTYPE.BROTHER_PLUS }
            };
            history.push(redirectInfo);
        }
      };

    return (
        <div className='easy-pay-container'>
            <div className='try-new-benefit body-large'>
                {<Trans i18nKey="BROTHER_PLUS.EASY_PAY_BANNER.TRY_NEW_BENEFIT">
                    Not ready to commit yet? Try our <span className='easy-pay-new'>new</span> <span className='easy-pay-benefit'>benefits membership for free!</span>
                </Trans>}
            </div>
            <div className='easy-pay-wrapper'>
            <div className='easy-pay-content'>
                <div className='easy-pay-title body-large'>{t("BROTHER_PLUS.EASY_PAY_BANNER.EASY_PAY_TITLE")}</div>
                <div className='easy-pay-list-wrapper body-medium'>
                    <div>
                        {<Trans i18nKey="BROTHER_PLUS.EASY_PAY_BANNER.EASY_PAY_INFO">
                            Become a member and get benefits when you want. <span className='easy-pay-discount'>Get 10% DISCOUNT off any purchase from B+ stores</span> when
                        </Trans>}
                    </div>
                    <ul>
                        {convertHtmlToReact(t("BROTHER_PLUS.EASY_PAY_BANNER.EASY_PAY_LIST"), [true])}
                    </ul>
                </div>
                <div className="button-wrapper">
                    <EcoProButton cname="easy-pay-avail-button body-small" onBtnClick={handleRedirect}>
                        {t("BROTHER_PLUS.EASY_PAY_BANNER.AVAIL_DISCOUNT")}
                    </EcoProButton>
                </div>
            </div>
            <div className='easy-pay-image-wrapper'>
                <img alt="upgrade-img" title="upgrade-img" src={easyPayBannerImage} />
            </div>
            </div>
        </div>
    );
};

export default EasyPayPlan;