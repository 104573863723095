import { ECOPROTOKEN_CLAIMS, featureSwitchName, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { getPromotionsArray } from "../../constants/AppMethod";
import { getFeatureSwitchByKey, getLocalSessionData, getUserDataByKey, getUserSessionData } from "../Authentication/handleUserSession";

const VerifyPreEnrollmentPayload = (preEnrollmentInfo) => {
  const isBonusPagesFeatureEnabled=getFeatureSwitchByKey(featureSwitchName.BONUS_PAGES) ? true : false;
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const model = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
  const { preEnrollId, PlanId, DiscountPlanIds } = preEnrollmentInfo;
  const personalOrBusinessAccount = getUserDataByKey('businessModel')
  const promotion=getPromotionsArray(model,isBonusPagesFeatureEnabled)
  const payload={
    userId: UID || null,
    deviceid: deviceId || null,
    preEnrollId: preEnrollId || null,
    planType: "new",
    model: model || null,
    personalOrBusiness: personalOrBusinessAccount,
    preEnrollmentDetailsViewModel: {
      PlanId: PlanId || null,
      DiscountPlanIds: DiscountPlanIds || null,
    },

  }
  if (promotion && Array.isArray(promotion)) {
    if (promotion.length === 1) {
      payload.promotions = [promotion[0]];
    } else if (promotion.length > 1) {
      promotion.forEach((promo, index) => {
        payload[`promotions[${index}]`] = [promo];
      });
    }
  } else {
    payload.promotions = [];
  }
  
  return payload;
};

export default VerifyPreEnrollmentPayload;