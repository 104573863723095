import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardBody, Nav, NavItem, NavLink, TabContent,TabPane } from 'reactstrap';
import EcoProButton from "../../../components/shared/button/button";
import CustomerDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/CustomerDetails/CustomerDetails";
import SubscriptionDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/SubscriptionDetails/SubscriptionDetails";
import { CSAgentRoutePaths } from "../../../constants/Routes";
import { 
    FetchCustomerData, 
    FetchCustomerDataResetState, 
    FetchDeviceAndUsageDetails, 
    FetchEmailLogs, 
    FetchSubscriptionDetails, 
    FetchSupplyOrderLogs, 
    FetchUserActivityHistory, 
    GetUserPreEnrollmentData} from "../../../actions/CSAgentPortal/CustomerDashboard";
import './CustomerDashboard.css';
import Loader from "../../../components/shared/loader/Loader";
import { Helmet } from "react-helmet";
import EmailLogs from "../../../components/CSAgent/CustomerDashboard/TabContents/EmailLogs/EmailLogs";
import SupplyOrderLogs from "../../../components/CSAgent/CustomerDashboard/TabContents/SupplyOrderLogs/SupplyOrderLogs";
import PrintActivity from "../../PrintActivity/PrintActivity";
import { canAccess_SDTicketButton, userRoles } from "../../../constants/UserRoles";
import BOCDeviceConnection from "../../../components/CSAgent/CustomerDashboard/TabContents/BOCDeviceConnection/BOCDeviceConnection";
import DeviceAndDeviceUsageDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/DeviceAndUsageDetails/DeviceAndUsageDetails";
import UserActivityHistory from "../../../components/CSAgent/CustomerDashboard/TabContents/UserActivityHistory/UserActivityHistory";
import { GetPrintActivity } from "../../../actions/PrintActivity";
import { Accordion, Card, CardHeader } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import EcoProSDTicket from "../../../components/CSAgent/CustomerDashboard/EcoProSDTicket/EcoProSDTicket";
import { currentUserRolesArr } from "../../../constants/AppMethod";
import PreEnrollmentDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/PreEnrollmentDetails/PreEnrollmentDetails";
import { fetchUserSubscriptionDetailsRequest } from "../../../store/CSAgentPortal/BrotherPlus/GetUserSubscriptionDetailsSlice";
import { fetchUserPrintHistoryRequest } from "../../../store/CSAgentPortal/BrotherPlus/GetUserPrintHistorySlice";
import { fetchUserDeviceStatusRequest } from "../../../store/CSAgentPortal/BrotherPlus/GetUserDeviceStatusSlice";
import DeviceDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/BrotherPlus/DeviceDetails/DeviceDetails";
import PrintHistory from "../../../components/CSAgent/CustomerDashboard/TabContents/BrotherPlus/PrintHistory/PrintHistory";
import BrotherPlusSubscriptionDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/BrotherPlus/SubscriptionDetails/SubscriptionDetails";

const CardTemplate = ({header, children}) => {
    return (
        <Card color="light">
            <CardHeader>{header}</CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    )
}
const CustomerDashboard = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const dispatch = useDispatch();
    const history = useHistory();
    const currentuserRoles = currentUserRolesArr();
    const CAN_ACCESS_SD_TICKET = canAccess_SDTicketButton(currentuserRoles);
    //Ecopro Tabs
    const CUSTOMER_DETAILS = "customer_details"; 
    const SUBSCRIPTION_DETAILS = "subscription_details";
    const EMAIL_LOGS = "email_logs";
    const SUPPLY_ORDER_LOGS = "supply_order_logs";
    const BOC_DEVICE_CONNECTION = "boc_device_connection";
    const DEVICE_AND_USAGE_DETAILS="device_and_usage_details";
    const USER_ACTIVITY_HISTORY="user_Activity_history";
    const PRINT_ACTIVITY = "print_activity";
    const PRE_ENROLLMENT_INFO = "preenrollment_info";
    //Brotherplus Tabs
    const BROTHERPLUS_DEVICE_DETAILS="brother_Plus_device_details";
    const BROTHERPLUS_PRINT_HISTORY="brother_plus_print_history";
    const BROTHERPLUS_SUBSCRIPTION_DETAILS="brother_plus_subscription_details";
    
    const [activeTab, setActiveTab] = useState(CUSTOMER_DETAILS);
    const dashboardInfoRes = useSelector(state => state.CSAGENT.CustomerDashboardInfo);
    const emailLogsRes = useSelector(state => state.CSAGENT.FetchEmailLogs);
    const MAILID = 'MailId';
    const BPID = "BusinessPartnerId"
    const searchTerms = props.location?.state?.searchTerms
    const dashboardInfo = history.action === "POP" ? dashboardInfoRes : props.location.state.dashboardInfo
    const { activityHistory, loading, error} = useSelector((state) => state.CSAGENT.UserActivityHistory);
    const goBack = () => {
        dispatch(FetchCustomerDataResetState());
        history.push(CSAgentRoutePaths.CUSTOMER_SEARCH);
    }
   
    useEffect(()=>{
        if(history.action === "POP" && searchTerms){
            if(Object.keys(searchTerms).includes(MAILID) || Object.keys(searchTerms).includes(BPID)){
                dispatch(FetchCustomerData(null, Object.keys(searchTerms).toString(), Object.values(searchTerms).toString()))
            } else {
                dispatch(FetchCustomerData(Object.values(searchTerms).toString()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])

    const getUserDeviceStatus = useSelector((state) => state.BROTHERPLUS.getUserDeviceStatus);
    const getUserPrintHistory = useSelector((state) => state.BROTHERPLUS.getUserPrintHistory);
    const getUserSubscriptionDetails = useSelector((state) => state.BROTHERPLUS.getUserSubscriptionDetails);

    const fetchTabContents = (tabType) => {
        const Customer_UID = dashboardInfo?.customer_data?.userId;
        setActiveTab(tabType);
        switch(tabType){
            case EMAIL_LOGS:
                dispatch(FetchEmailLogs(Customer_UID));
            break;
            case SUPPLY_ORDER_LOGS:
                dispatch(FetchSupplyOrderLogs(Customer_UID));
            break;
            case PRINT_ACTIVITY:
                const Customer_DeviceID = dashboardInfo?.customer_data?.deviceId;
                const customer_CountryCode = dashboardInfo?.customer_data?.countryCode;
                const IS_CSAGENT = true;
                dispatch(GetPrintActivity(Customer_UID, Customer_DeviceID, IS_CSAGENT, customer_CountryCode));
            break;
            case USER_ACTIVITY_HISTORY:
                dispatch(FetchUserActivityHistory(Customer_UID));
            break;
            case DEVICE_AND_USAGE_DETAILS:
                dispatch(FetchDeviceAndUsageDetails(Customer_UID, DEVICE_AND_USAGE_DETAILS));
                dispatch(FetchSubscriptionDetails(Customer_UID));
            break;
            case SUBSCRIPTION_DETAILS:
                dispatch(FetchSubscriptionDetails(Customer_UID));
            break;
            case BOC_DEVICE_CONNECTION:
                dispatch(FetchDeviceAndUsageDetails(Customer_UID, BOC_DEVICE_CONNECTION));
            break;
            case PRE_ENROLLMENT_INFO:
                dispatch(GetUserPreEnrollmentData(Customer_UID));
            break;
            case BROTHERPLUS_PRINT_HISTORY:
                dispatch(fetchUserPrintHistoryRequest(Customer_UID))
            break;
            case BROTHERPLUS_SUBSCRIPTION_DETAILS:
                dispatch(fetchUserSubscriptionDetailsRequest(Customer_UID))
            break;
            case BROTHERPLUS_DEVICE_DETAILS:
                dispatch(fetchUserDeviceStatusRequest(Customer_UID))
            break;
            default:
                setActiveTab(CUSTOMER_DETAILS);
        }
    }

    if(dashboardInfo.loading) {
        return <Loader></Loader>
    }
   
    return (
        <>
        <Helmet>
          <title>{t("PAGE_TITLE.CUSTOMER_DASHBOARD")}</title>
        </Helmet>
        <div className="row customer-service-dashboard mt-40">
            <div className="csagent-dashboard-common-action-btn-wrapper">
                {
                    (CAN_ACCESS_SD_TICKET)
                    ? <EcoProSDTicket customerInfo = {dashboardInfo.customer_data}/>
                    : <div></div>
                }
                <div className="customer-end-session-btn-wrapper">
                    <h2>{dashboardInfo.customer_data?.displayName}</h2>
                    <EcoProButton cname="default" onBtnClick={() => goBack()}>{t('CUSTOMER_DASHBOARD.TAB_ITEMS.GO_BACK')}</EcoProButton>
                </div>
            </div>
            <div className="col-4 customer-dashboard-right">
                <Accordion flush open={true}>
                    <AccordionItem>
                        <AccordionHeader defaultActiveKey="1">EcoPro</AccordionHeader>
                                <AccordionBody eventKey="1">
                            <div className="csagent-dashboard-right tab-btn-wrapper">
                                <div>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink active={activeTab === CUSTOMER_DETAILS} onClick={() => setActiveTab(CUSTOMER_DETAILS)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.CUSTOMER_DETAILS')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === PRE_ENROLLMENT_INFO} onClick={() => fetchTabContents(PRE_ENROLLMENT_INFO)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.PRE_ENROLLMENT_INFO')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === DEVICE_AND_USAGE_DETAILS} onClick={() => fetchTabContents(DEVICE_AND_USAGE_DETAILS)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.DEVICE_AND_USAGE_DETAILS')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === SUBSCRIPTION_DETAILS} onClick={() => fetchTabContents(SUBSCRIPTION_DETAILS)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.SUBSCRIPTION_BILLING')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === EMAIL_LOGS} onClick={() => fetchTabContents(EMAIL_LOGS)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.EMAIL_LOGS')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === SUPPLY_ORDER_LOGS} onClick={() => fetchTabContents(SUPPLY_ORDER_LOGS)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.SUPPLY_ORDER_LOGS')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === USER_ACTIVITY_HISTORY} onClick={() => fetchTabContents(USER_ACTIVITY_HISTORY)}>
                                            {t('CUSTOMER_DASHBOARD.TAB_ITEMS.USER_ACTIVITY_HISTORY')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink 
                                                active={activeTab === BOC_DEVICE_CONNECTION} 
                                                onClick={() => fetchTabContents(BOC_DEVICE_CONNECTION)}
                                            >
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.BOC_DEVICE_CONNECTION')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink active={activeTab === PRINT_ACTIVITY} onClick={() => fetchTabContents(PRINT_ACTIVITY)}>
                                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.PRINT_ACTIVITY')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader defaultActiveKey="2">Brother Plus</AccordionHeader>
                                <AccordionBody eventKey="2">
                        <div className="csagent-dashboard-right tab-btn-wrapper">
                            <div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink active={activeTab === BROTHERPLUS_SUBSCRIPTION_DETAILS} onClick={() => fetchTabContents(BROTHERPLUS_SUBSCRIPTION_DETAILS)}>
                                            {t('CUSTOMER_DASHBOARD.TAB_ITEMS.BROTHERPLUS_SUBSCRIPTION_DETAILS')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === BROTHERPLUS_DEVICE_DETAILS} onClick={() => fetchTabContents(BROTHERPLUS_DEVICE_DETAILS)}>
                                            {t('CUSTOMER_DASHBOARD.TAB_ITEMS.BROTHERPLUS_DEVICE_DETAILS')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === BROTHERPLUS_PRINT_HISTORY} onClick={() => fetchTabContents(BROTHERPLUS_PRINT_HISTORY)}>
                                            {t('CUSTOMER_DASHBOARD.TAB_ITEMS.BROTHERPLUS_PRINT_HISTORY')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="col-8 customer-dashboard-left">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={CUSTOMER_DETAILS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.CUSTOMER_DETAILS')}
                            children={<CustomerDetails {...dashboardInfo.customer_data} error={dashboardInfo.error}/>}
                        />
                    </TabPane>
                    <TabPane tabId={PRE_ENROLLMENT_INFO}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.PRE_ENROLLMENT_INFO')}
                            children={<PreEnrollmentDetails />}
                        />
                    </TabPane>
                    <TabPane tabId={DEVICE_AND_USAGE_DETAILS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.DEVICE_AND_USAGE_DETAILS')}
                            children={<DeviceAndDeviceUsageDetails customerInfo = {dashboardInfo.customer_data} />}
                        />
                    </TabPane>
                    <TabPane tabId={SUBSCRIPTION_DETAILS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.SUBSCRIPTION_BILLING')}
                            children={<SubscriptionDetails customerInfo = {dashboardInfo.customer_data}/>}
                        />
                    </TabPane>
                    <TabPane tabId={EMAIL_LOGS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.EMAIL_LOGS')}
                            children={<EmailLogs emailLogRes={emailLogsRes}/>}
                        />
                    </TabPane>
                    <TabPane tabId={SUPPLY_ORDER_LOGS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.SUPPLY_ORDER_LOGS')}
                            children={<SupplyOrderLogs handleSupplyOrderLogs = {() => fetchTabContents(SUPPLY_ORDER_LOGS)}/>}
                        />
                    </TabPane>
                    <TabPane tabId={USER_ACTIVITY_HISTORY}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.USER_ACTIVITY_HISTORY')}
                            children={ <UserActivityHistory 
                                activityHistory={activityHistory} 
                                loading={loading} 
                                error={error} 
                             />}
                        />
                    </TabPane>
                    <TabPane tabId={BOC_DEVICE_CONNECTION}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.BOC_DEVICE_CONNECTION')}
                            children={<BOCDeviceConnection 
                                {...dashboardInfo.customer_data}
                             />}
                        />
                    </TabPane>
                    <TabPane tabId={PRINT_ACTIVITY}>
                        <CardTemplate 
                            header={t("PRINT_ACTIVITY.PRINT_ACTIVITY_HEADER")}
                            children={<PrintActivity 
                                role={userRoles.CSAGENT} 
                                CustomerUID={dashboardInfo?.customer_data?.userId} 
                                customerDeviceID={dashboardInfo?.customer_data?.deviceId}
                                customerCountryCode={dashboardInfo?.customer_data?.countryCode}
                            />}
                        />
                    </TabPane>

                    <TabPane tabId={BROTHERPLUS_SUBSCRIPTION_DETAILS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.BROTHERPLUS_SUBSCRIPTION_DETAILS')}
                            children={<BrotherPlusSubscriptionDetails
                                getUserSubscriptionDetails={getUserSubscriptionDetails}

                            />}
                        />
                    </TabPane>
                    <TabPane tabId={BROTHERPLUS_DEVICE_DETAILS}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.BROTHERPLUS_DEVICE_DETAILS')}
                            children={ <DeviceDetails {...(getUserDeviceStatus && { getUserDeviceStatus })} />}
                           
                        />
                    </TabPane>
                    <TabPane tabId={BROTHERPLUS_PRINT_HISTORY}>
                        <CardTemplate 
                            header={t('CUSTOMER_DASHBOARD.TAB_ITEMS.BROTHERPLUS_PRINT_HISTORY')}
                            children={<PrintHistory getUserPrintHistory={getUserPrintHistory}/>}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </div>
        </>
    )
}

export default CustomerDashboard;