import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import './DashboardPopup.css';
import { Container } from "reactstrap";
import EcoProButton from "../../shared/button/button";
import PartyHornIcon from "../../../assets/images/BrotherPlus/party-horn-regular-icon.svg";
import { Trans, useTranslation } from "react-i18next";
import { getCountryCode } from "../../../utils/Internationalization/handleAppInternationalization";
import { Is_BrotherPlus_Signup, getPersonalOrBusinessTerms } from "../../../constants/AppMethod";
import { addUserActivityLogRequest } from "../../../store/brotherPlus/AddUserActivityLogService/addUserActivityLogServiceSlice";

const DashboardPopup = ({handleModalContinue}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);
    const [isChecked, setIsChecked] = useState(false);
    const handleTermsAndConditions = (event) => {
        setIsChecked(event.target.checked);
    }
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

    const countryCode = getCountryCode();
    const allowedCountries = ['DE', 'AT', 'CH'];
    let bothPersonalAndBusiness = (allowedCountries.includes(countryCode) && IsBrotherPlus_Signup) ? true : false;
    let termsAndConditionLink = getPersonalOrBusinessTerms(bothPersonalAndBusiness);
    const onPopUpSubmit=()=>{
        dispatch(addUserActivityLogRequest());
        handleModalContinue();
    }
    return (
        <Container className="brother-plus-dashboard-popup">
            <img src={PartyHornIcon} alt="party-horn" title="party-horn"/>
            <h1>{t("BROTHER_PLUS.ALMOST_THERE_MODAL.ALMOST_THERE")}</h1>
            <p className="ta-left">{t("BROTHER_PLUS.ALMOST_THERE_MODAL.ONE_LAST_STEP")}</p>
            <div className="brother-plus-benefits-wrapper ta-left">
                <ul>
                    <li>{t("BROTHER_PLUS.ALMOST_THERE_MODAL.BENEFITS.POINT_1")}</li>
                    <li>{t("BROTHER_PLUS.ALMOST_THERE_MODAL.BENEFITS.POINT_2")}</li>
                    <li>{t("BROTHER_PLUS.ALMOST_THERE_MODAL.BENEFITS.POINT_3")}</li>
                    <li>{t("BROTHER_PLUS.ALMOST_THERE_MODAL.BENEFITS.POINT_4")}</li>
                </ul>
            </div>
            <div className="popup-terms-and-conditions ta-left">
                <input type="checkbox" name="terms-and-conditions" onChange={handleTermsAndConditions} checked={isChecked}/>
                <label className="form-check-label" htmlFor="terms-and-conditions"><Trans i18nKey="BROTHER_PLUS.ALMOST_THERE_MODAL.TERMS_AND_CONDITIONS">
                        I accept  <a  className="caveat-txt-link" href={termsAndConditionLink} target = "_blank"  rel="noreferrer">terms and conditions </a> 
                    </Trans></label>
            </div>
            <div className="continue-btn-wrapper">
                <EcoProButton cname="default" disabled={(isChecked) ? false : true} onBtnClick={() => onPopUpSubmit()}>{t("ECOPRO_BTNS.CONTINUE")}</EcoProButton>
            </div>
        </Container>
    )
}

export default DashboardPopup;
