import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CSAGENT_API_ENDPOINTS, FAILURE, USER_ACTIVITY_LOGS } from "../../../constants/AppConstants";
import { _CALLPOSTAPI } from "../../../api/apiHandler";
import i18next from "i18next";
import UserActivityLogsPayload from "../../../utils/AdminPortal/UserActivityLogsPayload";

async function _CALLSUBSCRIPTIONDETAILSAPI(customerId) {
    const endpoint = _CSAGENT_API_ENDPOINTS.FETCH_SUBSCRIPTION_DETAILS;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_SUBSCRIPTION_DETAILS,
        description: `Fetched subscription info for the customer ${customerId}`
    }
    const payload = {
        userId: customerId,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
      }
    return _CALLPOSTAPI(endpoint,payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleSubscriptionDetails(action){
    try{
        const customerId = action.customerID;
        const subscriptionInfo = yield call(_CALLSUBSCRIPTIONDETAILSAPI, customerId);
        if(subscriptionInfo.actionStatus.toUpperCase() !== FAILURE){
            yield put({type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCESS, subscriptionInfo: subscriptionInfo});
        } else {
            yield put({type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_ERROR, message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    } catch(e){
        yield put({type: actionTypes.FETCH_SUBSCRIPTION_DETAILS_ERROR, message: e.message});
    }
}
function* CustomerSubscriptionDetails(){
    yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_DETAILS_INIT, handleSubscriptionDetails)
}

export default CustomerSubscriptionDetails;