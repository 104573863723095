import React, { useEffect } from "react";
import banner_EN from "../../../assets/images/easySetup/banner/banner_GB.png";
import banner_DE from "../../../assets/images/easySetup/banner/banner_DE.png";
import banner_FR from "../../../assets/images/easySetup/banner/banner_FR.png";
import banner_IT from "../../../assets/images/easySetup/banner/banner_IT.png";
import banner_NL from "../../../assets/images/easySetup/banner/banner_NL.png";
import sixMonthBannerImg from "../../../assets/images/easySetup/six-month-trial.png"
import "./EasySetupBanner.css";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "../../../utils/CustomHooks/UseQuery";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/shared/loader/Loader";
import { fetchFeatureSwitchByMultipleValuesRequest } from "../../../store/FeatureSwitchByMultipleValues/FeatureSwitchByMultipleValuesSlice";


const EasySetupBanner = () => {
const { t } = useTranslation(["translation"]);
const queryParams = useQuery();
const dispatch = useDispatch();
const languageCode = queryParams.get("language");
const FeatureSwitchByMultipleValues = useSelector((state) => state.FeatureSwitchByMultipleValues)
const { featureSwitchByMultipleValues, loading } = FeatureSwitchByMultipleValues;
const PromotionBanners = featureSwitchByMultipleValues?.PromotionBanners || false;
const BonusPages = featureSwitchByMultipleValues?.BonusPages || false

const imageUrlCountry = {
  EN: banner_EN,
  DE: banner_DE,
  FR: banner_FR,
  IT: banner_IT,
  NL: banner_NL,
};

const getImagesOnCountryCode = () => {
  if (languageCode) {
    return imageUrlCountry[languageCode.toUpperCase()];
  }
};

useEffect(() => {
  dispatch(fetchFeatureSwitchByMultipleValuesRequest())
}, [dispatch]);

if (loading) {
  return <Loader></Loader>;
}

return (PromotionBanners || BonusPages) ? (
  <>
    <div className="easy-setup-six-months-banner-container">
      <div className="column-a">
        <img src={sixMonthBannerImg} alt="banner" />
      </div>
      <div className="column-b-six-month-promotion">
        <h1 className="header-2">
          {
            (PromotionBanners || BonusPages)
              ?
              <>
                {PromotionBanners
                  ?
                  <>
                    {BonusPages ?

                      <>
                        <p  className="bonus-savemore">{t("PROMOTION_BANNER.SAVE_MORE")}</p>
                        <Trans i18nKey="EASY_SETUP.SIX_MONTH_PROMOTION.HEADER_2">
                          Get a 6 months
                          <span className="free-trial">FREE EcoPro trial</span>
                        </Trans>
                        <span>{t("EASY_SETUP.BONUS_PAGES.HUNDRED_PAGES_FREE")}</span>
                      </>
                      :
                      <>
                        <p  className="bonus-savemore">{t("EASY_SETUP.SIX_MONTH_PROMOTION.HEADER_1")}</p>
                        <Trans i18nKey="EASY_SETUP.SIX_MONTH_PROMOTION.HEADER_2">
                          Get a 6 months
                          <span className="free-trial">FREE EcoPro trial</span>
                        </Trans>
                      </>

                    }
                  </>

                  :
                  <>
                    {
                      BonusPages ?
                        <>
                          <p className="bonus-savemore">{t("PROMOTION_BANNER.SAVE_MORE")}</p>
                          <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
                          <span>{t("EASY_SETUP.BONUS_PAGES.HUNDRED_PAGES_FREE")}</span>
                         </>
                        :
                        <>
                        <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
                        </>
                    }
                  </>
                }
              </>
              :
              <>
              </>
          }
        </h1>
        <p>{t("EASY_SETUP.SIX_MONTH_PROMOTION.SUB_HEADER")}</p>
        <ul className="bullet-points">
          <li>{t("EASY_SETUP.BANNER.LINE2")}</li>
          <li>{t("EASY_SETUP.BANNER.LINE3")}</li>
          <li>{t("EASY_SETUP.BANNER.LINE4")}</li>
        </ul>
      </div>
    </div>
  </>
) : (
  <div className="easy-setup-banner-container">
    <div className="column-a">
      <img src={getImagesOnCountryCode()} alt="banner" />
    </div>
    <div className="column-b-banner">
 
      <h1>{t("EASY_SETUP.BANNER.HEADER")}</h1>
      <p className="twomonths_text">
      <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
     </p>
      <p>{t("EASY_SETUP.BANNER.LINE1")}</p>
      <p>{t("EASY_SETUP.BANNER.LINE2")}</p>
      <p>{t("EASY_SETUP.BANNER.LINE3")}</p>
      <p>{t("EASY_SETUP.BANNER.LINE4")}</p>
      <div className="learn-more-link">
        <a href="/#" target="_blank" rel="noreferrer">
          {t("EASY_SETUP.BANNER.LEARN_MORE")}
        </a>
      </div>
    </div>
  </div>
);
};

export default EasySetupBanner;

