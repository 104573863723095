import React from 'react';
import './DisplayPrinter.css'
import FreeDelivery from '../../../../../assets/images/hardware-inclusive/free-delivery.svg';
import AutomatedSupply from '../../../../../assets/images/hardware-inclusive/automated-supply.svg';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DisplayPrinter = (props) => {
    const {printerModel, printerImage} = props
    const {t} = useTranslation(['translation'])
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <button type="button" className="slick-prev">Previous</button>,
        nextArrow: <button type="button" className="slick-next">Next</button>
    };
    return (
        <>
            <div className='cp-printer-img-wrapper'>
                <div className="slider-container">
                <Slider {...settings}>
                        {printerImage?.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt={`Printer ${index + 1}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className='pi-device-sent'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.DEVICE_SENT",{printerModel:printerModel})}</div>
            <div className='pi-delivery-supply-wrapper'>
                <div className='pi-free-deliver'>
                    <img src={FreeDelivery} alt="free-deliver"/><span>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.FREE_DELIVERY")}</span>
                </div>
                <div className='pi-automated-supply'>
                    <img src={AutomatedSupply} alt="automated-supply"/><span>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.AUTOMATED_SUPPLY")}</span>
                </div>
            </div>
        </>
    );
};

export default DisplayPrinter;