import { 
    WEB_CUSTOMER_API_ENDPOINT, 
    _ADMINISTRATOR_API_ENDPOINTS, 
    _API_ENDPOINT, 
    _HARDWARE_API_ENDPOINTS 
} from "../constants/AppConstants";

const ApiEndpoints_ExcludeAuthTokenValidation = [
    _API_ENDPOINT.BOL_API,
    WEB_CUSTOMER_API_ENDPOINT.GET_PRINTERS_TYPE_LIST, 
    WEB_CUSTOMER_API_ENDPOINT.GET_PRINTERS_MODEL_LIST, 
    WEB_CUSTOMER_API_ENDPOINT.FETCH_PLAN,
    _API_ENDPOINT.FETCH_PLAN_LANDING, 
    _API_ENDPOINT.GET_BENEFIT_OF_SUPPLIES, 
    WEB_CUSTOMER_API_ENDPOINT.GET_PROMOTION_BANNER_STATUS, 
    _ADMINISTRATOR_API_ENDPOINTS.GET_FEATURE_SWITCH_ITEMS_VALUE,
    _HARDWARE_API_ENDPOINTS.GET_HW_PRINTER_BY_FEATURES,
    _HARDWARE_API_ENDPOINTS.GET_HW_PRINTER_FEATURES_BY_PRINTER_TYPE,
    _HARDWARE_API_ENDPOINTS.GET_HW_SERVICE_PLAN,
    _ADMINISTRATOR_API_ENDPOINTS.GET_FEATURE_SWITCH_ITEMS_VALUES
]

export default ApiEndpoints_ExcludeAuthTokenValidation;