import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { fetchDeviceList } from "../../actions/SelectDevice";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn, SUBSCRIPTIONKIND, USER_REGISTRATION_TYPE_KEYS } from "../../constants/AppConstants";
import Loader from "../../components/shared/loader/Loader";
import { getUserSessionData, retreiveUserRegistrationType, setLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { routePath } from "../../constants/Routes";
import PaymentComplete from "../PaymentComplete/PaymentComplete";
import { getSignupTypeBy_SubscriptionKind, Is_BrotherPlus_Signup } from "../../constants/AppMethod";

const CustomerRedirection = (props) => {
  const { t } = useTranslation(["translation"]);
  const { RouterLinkStateProp, upgradeTo_EcoProProcess }  = props;
  const history = useHistory();
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const dispatch = useDispatch();
  const deviceListResult = useSelector((state) => state.SelectDevice);
  const UserAccount = useSelector((state) => state.UserAccount);
  const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
  const IsBrotherPlus_registrationType = retreiveUserRegistrationType(USER_REGISTRATION_TYPE_KEYS.IS_BROTHER_PLUS);
  const IsUpgradeToEcoPro = upgradeTo_EcoProProcess;

  const setSignupType = () => {
    const subscriptionkind = UserAccount.userAccount.subscriptionKind;
    const signUpType = getSignupTypeBy_SubscriptionKind(subscriptionkind);
    setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, signUpType);
  }
  useEffect(() => {
    /**
     * This block handles the Brotherplus process
     * - Logic
     *    - If Brotherplus enrollment is completed then customer will be redirected to B+ dashboard
     *    - If Brotherplus enrollment is initiated then customer will be redirected to connect                  device dashbaord for pin enrollment
     */
    if((IsBrotherPlus_Signup || IsBrotherPlus_registrationType) && (!IsUpgradeToEcoPro)){
      history.push((IsBrotherPlus_registrationType) ? routePath.DASHBOARD : routePath.CONNECT_DEVICE_DASHBOARD);
    } 
    /**
     * This block bypasses the user to place order, complete enrollment for Web signup process.
     * - Logic:
     *   - Before the implementation of Pin enrollment process/Connect device dashboard page, 
     *     the customer will complete pre-enrollment through web signup and continue device selection
     *     and connection through mobile connect app
     * - Steps for this flow:  
     *    - Run part 1 of the web signup powershell script
     *    - Complete the pre enrollment and close the window
     *    - Run part 2 of the web signup powershell script
     *    - customer will be redirect to device selection page
     *    - From there the web signup place order will be continued
     */
    else if((UserAccount.userAccount.subscriptionKind === SUBSCRIPTIONKIND.WEB
      || UserAccount.userAccount.subscriptionKind === SUBSCRIPTIONKIND.HARDWARE_INCLUSIVE)
      && (!IsUpgradeToEcoPro)
    ){
        setSignupType();
        history.push(routePath.CONNECT_DEVICE);
    }
    /**
     * This block handles the mobile connect flow  
    */ 
    else if(UserAccount.userAccount.businessPartnerId || IsUpgradeToEcoPro){
        dispatch(fetchDeviceList(UID));
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, UID]);

  if(!UserAccount.userAccount.businessPartnerId && !IsUpgradeToEcoPro){
    return <ErrorPage errorInfo={t('ERROR_CONTENT.CANT_CREATE_SUBSCRIPTION')}></ErrorPage>
  }

  if (deviceListResult.loading) {
    return <Loader></Loader>;
  }
  if (deviceListResult.error) {
    return (
      <ErrorPage
        errorInfo={deviceListResult.error}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
  if (deviceListResult.deviceList?.devices && deviceListResult.deviceList?.devices.length === 0) {
    return (
      <ErrorPage
        errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
 
  if (deviceListResult.success === true && deviceListResult.deviceList?.devices) {
        const currentPlan = Object.values(deviceListResult.deviceList.devices).filter((val) => {
          return (val.hasOwnProperty('service') && val.service) ? val.service.currentPlan : "";
        })
        if (currentPlan.length > 0) {
          if(RouterLinkStateProp && RouterLinkStateProp === 'SUBSCRIPTION_NETWORK_ERR_VALIDATION'){
            return <PaymentComplete isSubscriptionCompleted = {true}></PaymentComplete>
          } else {
            const pathHistory = JSON.parse(sessionStorage.getItem('USER_PATH_HISTORY'));
            if(pathHistory && pathHistory['path'] && pathHistory['isSubscribed'] === true){
              return <Redirect to={pathHistory['path'] }></Redirect>
            } else{
              return <Redirect to={routePath.DASHBOARD}></Redirect>
            }
          }
        } else if (deviceListResult.deviceList?.devices.length === 1) {
          return <Redirect to={{ pathname: routePath.PLANS,
            state: {
              'deviceId': deviceListResult.deviceList?.devices[0].deviceId,
              'device_model': deviceListResult.deviceList?.devices[0].model,
              'serialNumber': deviceListResult.deviceList?.devices[0].serialNumber,
              'networkStatus': deviceListResult.deviceList?.devices[0].networkStatus,
              'deviceLength':"1"
            }
            }}></Redirect>
        }
  } 
  if (
    deviceListResult.success === true &&
    deviceListResult.deviceList?.devices.length > 1 &&
    deviceListResult.deviceList?.devices.map((device) => device.hasOwnProperty("model"))
  ) {
    return <Redirect to={{ pathname: routePath.CONFIRM_DEVICE, state: { deviceListResult: deviceListResult }}}></Redirect>
  } 
  return <Loader></Loader>
};

export default CustomerRedirection;
