import { createSlice } from '@reduxjs/toolkit';

const addUserActivityLogSlice = createSlice({
  name: 'addUserActivityLog',
  initialState: {
    loading: false,
    userActivityLog: null,
    error: null,
    success:false
  },
  reducers: {
    addUserActivityLogRequest(state) {
      state.loading = true;
      state.error = null;
    },
    addUserActivityLogSuccess(state, action) {
      state.loading = false;
      state.success = true
      state.userActivityLog = action.payload;
    },
    addUserActivityLogFailure(state, action) {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    },
  },
});

export const { addUserActivityLogRequest, addUserActivityLogSuccess, addUserActivityLogFailure } = addUserActivityLogSlice.actions;
export default addUserActivityLogSlice.reducer;

