import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ecopro_logo from "../../../assets/images/teaser_ecopro_logo.png";
import brotherplus_logo from "../../../assets/images/BrotherPlus/brother_plus_hamburgermenu_logo.png";
import { ReactComponent as RightArrowImg } from "../../../assets/images/rightArrow.svg";
import { ReactComponent as DownArrowImg } from "../../../assets/images/downArrow.svg";
import { ReactComponent as LogoutImg } from "../../../assets/images/logout.svg";

import { Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import './hamburgerMenuDesktopApp.css';
import useNavItems from "../../../utils/Dashboard/HamburgerNavItems";
import { Is_BrotherPlus_Signup } from "../../../constants/AppMethod";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SignOut } from "../../../actions/Dashboard";
import { logoutProcess } from "../../../utils/Authentication/AppLogout";
import { LOCALSESSIONDATAKEYS, UNIQUE_ID_GA } from "../../../constants/AppConstants";
import { useTranslation } from "react-i18next";
import { getLocalSessionData } from "../../../utils/Authentication/handleUserSession";


const HamburgerMenuDesktopApp = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()
    const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
    const [expandedItem, setExpandedItem] = useState(null);
    const [activeNavItem, setActiveNavItem] = useState(null);
    const [subItemId, setSubItemId] = useState(null)
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    const { t } = useTranslation(['translation']);
    const BrotherPlus_RegisterServiceResp = useSelector(state => state.BROTHERPLUS.RegisterService);
    const is_enrollment_completed = JSON.parse(getLocalSessionData(LOCALSESSIONDATAKEYS.ENROLLMENT_STATUS_COMPLETED))


    useEffect(() => {
        setActiveNavItem(location.pathname);
        setExpandedItem(location.pathname);
    }, [location.pathname])

    useEffect(() => {
        setExpandedItem(expandedItem)
    },[expandedItem])

    const handleLogout = () => {
        dispatch(SignOut());
        logoutProcess();
    };

    const handleItemClick = (itemId, itemLink) => {
        if(itemId === UNIQUE_ID_GA.logout){
            handleLogout()
        }
        else{
            setExpandedItem(itemLink);
            history.push(itemLink);
        }
    };

    const handleSubItemClick = (itemId) => {
        setSubItemId(itemId)
        const element = document.getElementById(itemId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' }); 
        }
    }
    
    const scenario = IsBrotherPlus_Signup ? "brotherplus" : "ecopro"
    const { section1, section2 } = useNavItems(scenario);

    const handleMenuNavigation = () => {
        console.log({is_enrollment_completed});
        console.log({IsBrotherPlus_Signup});
        console.log(enrollmentStatus.enrollment_completed_BrotherPlus);
        console.log(BrotherPlus_RegisterServiceResp?.success)
        return (
            is_enrollment_completed
                || (IsBrotherPlus_Signup 
                    && (enrollmentStatus.enrollment_completed_BrotherPlus || BrotherPlus_RegisterServiceResp?.success)
                    )
            ) ? 'navigation-enabled' : 'navigation-disabled'
    }
 
    return (
        <div className="hamburger-menu-desktop-wrapper hamburger-menu-desktop-view">
            <div className="header">
                <div className="title offcanvas-title">
                    {
                        (IsBrotherPlus_Signup) 
                        ? <img className="brotherplus-logo" src={brotherplus_logo} alt="BrotherPlus Logo" />
                        : <img className="ecopro-logo" src={ecopro_logo} alt="EcoPro Logo" />
                    }
                </div>
            </div>
            <div className="body offcanvas-body">
                <div className="navbar-nav">
                    <div className="container">
                        { section1.map((val) => (
                            val.item_enabled &&  
                            <div key={val.item_id} className={`nav-item ${(IsBrotherPlus_Signup) ? 'brother-plus-menu-container' : 'ecopro-menu-container'}`}>
                                <a id={val.item_id}
                                href={val.item_link}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleItemClick(val.item_id, val.item_link, val.subItems && val.subItems.length > 0);
                                }} 
                                className={`${(activeNavItem === val.item_link) ? 'active-link': 'inactive-link'} ${handleMenuNavigation()}`}>
                                    <Row className="row-margin">
                                        <div className="col-10 row sidebar-nav-item-text-wrapper">
                                            <div className="col">{val.item_logo}</div>
                                            <span className={`col-10 `}>{val.item_name}</span>
                                        </div>
                                        <div className="col arrow">
                                        {location.pathname === val.item_link && val.subItems ? <DownArrowImg /> : <RightArrowImg />}
                                        </div>
                                    </Row>
                                </a>
                                {location.pathname === val.item_link && val.subItems && (
                                    <div className="sub-items">
                                        {val.subItems.map(subItem => (
                                            <a key={subItem.item_id} 
                                            href={`${subItem.item_link}#${subItem.item_id}`} 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleSubItemClick(subItem.item_id)
                                                
                                            }}
                                            className={`${(subItemId === subItem.item_id) ? 'active-link': 'inactive-link'} sub-item`}>
                                                <Row className="sub-row-margin">
                                                    <div className="col-10 row">
                                                        <span className="col-10">{subItem.item_name}</span>
                                                    </div>
                                                    <div className="col arrow">
                                                        <RightArrowImg />
                                                    </div>
                                                </Row>
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {section2.length===0 ? <></>  : <div className="divider"></div>}
                    <div className="container">
                    { section2.map((val) => (
                            val.item_enabled &&  <a 
                            id={val.item_id} 
                            href={val.item_link}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleItemClick(val.item_id, val.item_link, val.subItems && val.subItems.length > 0);
                                }} 
                            className={`${(activeNavItem === val.item_link) ? 'active-link': 'inactive-link'} ${handleMenuNavigation()}`}>
                            <Row className="row-margin">
                                <div className="col-10 row">
                                    <div className="col">{val.item_logo}</div>
                                    <span className="col-10">{val.item_name}</span>
                                </div>
                                <div className="col arrow">
                                    <RightArrowImg />
                                </div>
                            </Row>
                            </a>
                        ))}
                    </div>
                    <div className="divider"></div>
                    <div className="container">
                        <Row className="row-margin logout-dashboard" onClick={()=>handleLogout()}>
                            <div className="col-10 row">
                                <div className="col">{<LogoutImg />}</div>
                                <span className="col-10">{t("NAVIGATION_MENU.LOGOUT")}</span>
                            </div>
                            <div className="col arrow">
                                <RightArrowImg />
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HamburgerMenuDesktopApp;