import React from "react";
import { useTranslation } from "react-i18next";
import { routePath } from "../../constants/Routes";
import { ReactComponent as DashboardImg } from "../../assets/images/dashboard.svg";
import { ReactComponent as UserImg } from "../../assets/images/user.svg";
import { ReactComponent as EcoSavingImg } from "../../assets/images/eco-saving.svg";
import { ReactComponent as OrderHistoryImg } from "../../assets/images/order-history.svg";
import { ReactComponent as PrintActivityImg } from "../../assets/images/print.svg";
import { ReactComponent as HelpSupportImg } from "../../assets/images/circle-question-regular.svg";
import { ReactComponent as FeedbackImg } from "../../assets/images/gear-feedback.svg";
import { ReactComponent as LogoutImg } from "../../assets/images/logout.svg";
import { getFeatureSwitchByKey } from "../Authentication/handleUserSession";
import { UNIQUE_ID_GA, featureSwitchName } from "../../constants/AppConstants";

const useNavItems = (scenario) => {
    const { t } = useTranslation(['translation']);
    const commonNavItems = [
        {
            item_name: t("NAVIGATION_MENU.DASHBOARD"),
            item_logo: <DashboardImg />,
            item_link: routePath.DASHBOARD,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.dashboard
        },
        {
            item_name: t("DASHBOARD.USER_PROFILE.ACCOUNT"),
            item_logo: <UserImg />,
            item_link: routePath.MY_ACCOUNT,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.myAccount,
            subItems: [
                { item_id: 'account-personal-details', item_link: routePath.MY_ACCOUNT, item_name: t("DASHBOARD.MY_ACCOUNT_LANDING.PERSONAL_DETAILS_HEADER") },
                { item_id: 'account-address', item_link: routePath.MY_ACCOUNT, item_name: "Address" },
                { item_id: 'my-plan', item_link: routePath.MY_ACCOUNT, item_name: t("DASHBOARD.MY_ACCOUNT_LANDING.MY_PLAN_HEADER") },
                { item_id: 'my-device', item_link: routePath.MY_ACCOUNT, item_name: t("DASHBOARD.MY_ACCOUNT_LANDING.MY_DEVICE_HEADER") },
            ].filter((item) => {
                // If scenario is 'brotherplus', exclude the 'account-address' item
                if (scenario === 'brotherplus' && item.item_id === 'account-address') {
                    return false;
                }
                return true;
            }),
        }
    ];

    const ecoProNavItems = [
        ...commonNavItems,
        {
            item_name: t("NAVIGATION_MENU.PRINT_ACTIVITY"),
            item_logo: <PrintActivityImg />,
            item_link: routePath.PRINT_ACTIVITY,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.printActivity
        },
        {
            item_name: t("NAVIGATION_MENU.ECO_SAVINGS"),
            item_logo: <EcoSavingImg />,
            item_link: routePath.CARBON_OFFSETTING,
            item_enabled: (getFeatureSwitchByKey(featureSwitchName.CARBON_SAVING)) ? true : false,
            item_id: UNIQUE_ID_GA.ecoSaving
        },
        {
            item_name: t("NAVIGATION_MENU.ORDER_HISTORY"),
            item_logo: <OrderHistoryImg />,
            item_link: routePath.ORDERS,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.orderHistory
        }
    ];

    const brotherPlusNavItems = [
        ...commonNavItems
    ]

    const supportAndFeddback = [
        {
            item_name: t("DASHBOARD.USER_PROFILE.HELP_SUPPORT"),
            item_logo: <HelpSupportImg />,
            item_link: routePath.HELP_SUPPORT,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.helpAndSupport
        },
        {
            item_name: t("NAVIGATION_MENU.FEEDBACK"),
            item_logo: <FeedbackImg />,
            item_link: routePath.ECOPRO_FEEDBACK,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.feedback
        }
    ];

    const liveChatAndLogout = [
        {
            item_name: t("NAVIGATION_MENU.LOGOUT"),
            item_logo: <LogoutImg />,
            item_link: routePath.LOGOUT,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.logout
        }
    ];

    switch (scenario) {
        case 'ecopro':
            return {
                section1: ecoProNavItems,
                section2: supportAndFeddback,
                section3: liveChatAndLogout
            };
        case 'brotherplus':
            return {
                section1: brotherPlusNavItems,
                section2: supportAndFeddback,
                section3: liveChatAndLogout
            };
        default:
            return { section1: [], section2: [], section3: [] };
    }
};

export default useNavItems;
