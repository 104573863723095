import React from "react";
import PageFooter from "../components/shared/footer/PageFooter";
import PageHeader from "../components/shared/header/PageHeader";
import { useIsAuthenticated } from "@azure/msal-react";
import HamburgerMenuDesktopApp from "../components/shared/hamburgerMenuDesktopApp/hamburgerMenuDesktopApp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { routePath } from "../constants/Routes";

const CustomerLayout = ({children, isSubscribed = false}) => {
    const isAuthenticated = useIsAuthenticated();
    const currentURL = useLocation();
    const isDashboardPage = () => {
        return (currentURL?.pathname === routePath.DASHBOARD) ?  true : false;
    }
    return (
        <>
            <PageHeader isSubscriptionCompletedRoute={isSubscribed} WebHeaderCName={(isSubscribed) ? 'post-enrollment-header' : 'pre-enrollment-header'}/>
                <div className={`${(isSubscribed) ? 'post-enrollment-wrapper' : 'pre-enrollment-wrapper'}`}>
                    {( isAuthenticated &&  isSubscribed ) ? 
                    <div className="hamburger-menu-desktop-container"><HamburgerMenuDesktopApp /></div>
                      : null} 
                    <div className={`${(isSubscribed) ?  (isDashboardPage() ? 'postenrollment-pages-content-wrapper-full-width' : 'postenrollment-pages-content-wrapper-centre-positioning') : 'preenrollment-pages-content-wrapper'}`}>
                        {children} 
                    </div>
                </div>
            {!isAuthenticated && !isSubscribed &&
            <PageFooter cname={(isSubscribed) ? 'post-enrollment-footer' : 'pre-enrollment-footer'}/>
            }
        </>
    )
}

export default CustomerLayout;