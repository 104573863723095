import React, { useState } from 'react';
import './ShenlongTeaser.css'
import ecoproLogo from '../../../assets/images/teaser_ecopro_logo.png';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from 'react';
import { formatNumberOnLocale } from '../../../constants/AppMethod';
import currencies from '../../../constants/CurrencyTypes';
import { useQuery } from '../../../utils/CustomHooks/UseQuery';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/shared/loader/Loader';
import { LOCALSESSIONDATAKEYS, READY_PROMOTION } from '../../../constants/AppConstants';
import { ENVSettings, EXTERNAL_LINK_ENDPOINT } from '../../../constants/ENVSettings';
import { deviceWithDCP, deviceWithHL, deviceWithMFC } from '../../../constants/BenefitOfSupplyConstant';
import ErrorPage from '../../../components/shared/errorPage/ErrorPage';
import { getBenefitOfSupplies } from '../../../actions/MyPlan';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GetPromotionBannerStatus } from '../../../actions/Web/Customer/SignUp';
import { fetchFeatureSwitchByMultipleValuesRequest } from '../../../store/FeatureSwitchByMultipleValues/FeatureSwitchByMultipleValuesSlice';

const PromotionBannerModal = () => {
    const FeatureSwitchByMultipleValues = useSelector((state) => state.FeatureSwitchByMultipleValues)
    const { featureSwitchByMultipleValues } = FeatureSwitchByMultipleValues;
    const  PromotionBanners = featureSwitchByMultipleValues?.PromotionBanners || false;
    const BonusPages= featureSwitchByMultipleValues?.BonusPages || false
    const { t } = useTranslation(["translation"]);
    const [modalState, setModalState] = useState(true);
    const toggleModal  = () => {
        setModalState(!modalState);
    }
    const closeBtn = <svg xmlns="http://www.w3.org/2000/svg" onClick={() => toggleModal()} width="14" height="13" viewBox="0 0 14 13" fill="none">
        <line x1="1.41421" y1="1" x2="12" y2="11.5858" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <line x1="2" y1="11.5858" x2="12.5858" y2="1" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>


    return (
        <div className='teaser-promotion-wrapper'>
            <Modal isOpen={modalState} toggle={() => toggleModal()} backdrop={false} size="sm" className="teaser-page-prmotion-banner-modal" >
                <ModalHeader close={closeBtn}></ModalHeader>
                <ModalBody>
                {
                    PromotionBanners ?
                        <>
                            <Trans i18nKey="PROMOTION_BANNER.LIMITED_OFFER">
                                Limited-time offer: Exclusive  <strong>6 months Free</strong> EcoPro subscription
                            </Trans>
                            {BonusPages ?<> <p>{t("PROMOTION_BANNER.BONUS_PAGES")}</p>
                             </>: null}
 
                        </>
                        :
                        <>
 
                            {
                                BonusPages ?
                                    <>
                                         <p>{t("PROMOTION_BANNER.SAVE_MORE")}</p>
                                         <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
                                        <p>{t("PROMOTION_BANNER.BONUS_PAGES")}</p>
                                        <p>{t("PROMOTION_BANNER.LIMITED_TIME_OFFER")}</p>
                                    </>
                                    :
                                    <>
                                        <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
                                    </>
                            }
 
 
 
                        </>
                }

                </ModalBody>
            </Modal>
        </div>
    )
}

const ShenlongTeaser = () => {
    const { t } = useTranslation(["translation"]);
    const dispatch = useDispatch();
    const queryParams = useQuery();
    const printerModel = queryParams.get("device_model");
    const countryCode = queryParams.get("country");
    const languageCode = queryParams.get("language");
    const planResult = useSelector(state => state.GetBenefitOfSupplies);

    const [trialmonths, setTrialmonths] = useState("")
    const [planCurrency, setPlanCurrency] = useState("")
    const [planPrice, setPlanPrice] = useState("")
    const [marketingUrl, setMarketingUrl] = useState("")
    const bannerResult = useSelector(state => state.GetPromotionBannerStatus)

    useEffect(()=> {
        localStorage.setItem(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE, `${languageCode}-${countryCode}`);
        dispatch(GetPromotionBannerStatus())
        dispatch(fetchFeatureSwitchByMultipleValuesRequest())
        const extractedModel = printerModel ? fetchOnlyPrinterModelFromUrl(printerModel):""
        if(extractedModel){
            if(extractedModel[extractedModel.length - 1] === "E"){
                dispatch(getBenefitOfSupplies(extractedModel,READY_PROMOTION.promotion));
            }
            else{
                dispatch(getBenefitOfSupplies(extractedModel))
            }
        } 
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        let plansList;
    let least_plan_price;
    let planCurrency;
        if(planResult.success ){
            if(planResult.hasOwnProperty("plans") 
            &&  planResult.plans.plans.length > 0 
            ){
                plansList =  planResult.hasOwnProperty("plans")
                && planResult.plans.plans.length > 0  
                    ? planResult.plans.plans : null
                plansList && plansList?.map((plan) => {
                    if(plan.discount && plan?.discount?.length>0){
                        setTrialmonths(findPromotionTypeBenefitOfSupplies(plan.discount))
                        planCurrency = currencies[plan.currency]?.code
                        setPlanCurrency(currencies[plan.currency]?.code)
                    }
                    return plan
                })
                least_plan_price = Math.min(
                    ...plansList.map((element) => {
                    return element?.price;
                    })
                );
                setPlanPrice(formatNumberOnLocale(least_plan_price, currencies[planCurrency]?.code, `${languageCode}-${countryCode}`))
                
                const baseurl = (!ENVSettings.MAIN_BASE_URL) ? window.env[`MAIN_BASEURL_${countryCode}`] : ENVSettings.MAIN_BASE_URL;
                let marketingUrl;
                if(languageCode.toLowerCase() === "fr"){
                    marketingUrl = `${baseurl}${EXTERNAL_LINK_ENDPOINT.FrenchMarketingPage}`
                }
                else{
                    marketingUrl = `${baseurl}${EXTERNAL_LINK_ENDPOINT.marketingPage}`
                }        
                setMarketingUrl(marketingUrl)
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[planResult,countryCode])

    const fetchOnlyPrinterModelFromUrl = (printerModel) => {
        const words = printerModel?.split(" "); // split sentence into an array of words
        const filteredWords = words?.filter(word => word.includes(deviceWithMFC) || word.includes(deviceWithDCP) || word.includes(deviceWithHL)); // filter words that contain "MFC", "DCP","HL"
        const extractedModel = filteredWords[0]; 
        return extractedModel
    }
    if (planResult.loading) {
        return <Loader></Loader>;
    }
    if(planResult.error){
        return <ErrorPage/>
    }


    const getPlanTranslation = (toBeTranslatedData) => {

        
          if(toBeTranslatedData === "XmonthsFreePromotion"){
            return "2"
          }
          else if(toBeTranslatedData === "ReadyPromotion"){
            return "4"
          }
          else if(toBeTranslatedData === "OnetimeBonus100Page"){
            return "2"
          }
    }

    const findPromotionTypeBenefitOfSupplies = (discounts) => {
        let promotion = ""

        for (let i = 0; i < discounts.length; i++) {
            if(discounts[i].planName === READY_PROMOTION.discountName){
            promotion = getPlanTranslation(discounts[i].planName)
            break;
            }
            promotion = getPlanTranslation(discounts[i].planName)
        }
        return promotion
    }

    const freeTrialMonths = bannerResult?.data?.displayPromotionBanner ? "6" : trialmonths

    return (
        <div className='benefit-of-supplies' id="promotion-page">
            <div className='supply-service-img'>
                <img src={planResult?.plans?.deviceBannerImageUrl} alt="supply service"/>
            </div>
            <div>
                <img src={ecoproLogo} alt="supply service"/>
            </div>
            <div className="mt-15 fw-b f-16">{t("BENEFITS_OF_SUPPLIES.HEADER")}</div>
            <div className='f-14 supply-service-info'>
                <ul>
                    <li>{t("BENEFITS_OF_SUPPLIES.POINT_1")}</li>
                    <li>{t("BENEFITS_OF_SUPPLIES.POINT_2")}</li>
                    <li>{t("BENEFITS_OF_SUPPLIES.POINT_3")}</li>
                    {countryCode === "IT" ? null :
                    <li>{t("BENEFITS_OF_SUPPLIES.POINT_4")}</li>}
                </ul>
            </div>
            <div className='mt-10 f-14 promotion-content'>
                {t("BENEFITS_OF_SUPPLIES.PROMOTION_CONTENT")}
            </div>
            <div className='supply-service-tc f-14'>
                <div className='promotion-content'>
                    <Trans i18nKey="BENEFITS_OF_SUPPLIES.STARTING_FROM" planCurrency={planCurrency} planPrice={planPrice}>
                        Starting from as low as <span>{{planPrice}}</span>. T&Cs apply.
                    </Trans>
                </div>
            </div>
            <div className='try-free'>
                <Trans i18nKey="BENEFITS_OF_SUPPLIES.TRY_FREE" freeTrialMonths={freeTrialMonths}>
                    Try <span>{{freeTrialMonths}}</span> months FREE.
                </Trans>
            </div>
            <div className='link-wrapper'>
                <a href={marketingUrl} id="promotion_marketing_page" className='f-14' target="_blank" rel="noopener noreferrer">
                    {t("BENEFITS_OF_SUPPLIES.LEARN_MORE")}
                </a>
            </div>
              <PromotionBannerModal />

           
            
        </div>
    );
};

export default ShenlongTeaser;