import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Progress } from "reactstrap";
import { setLocalSessionData } from '../../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';

const PagesRolledOver = (props) => {
    const {deviceInfoResponse} = props
    const subscriptionInfo = deviceInfoResponse?.response
    const { t } = useTranslation(["translation"]);
    let planGivenRolloverPages= 0, planPrintedRolloverPages= 0,  planCurrency = '';

    const checkDeviceInfoProps = (key, dataObject) => {
      const defaultVal = (key === "planName") ? 'N/A' : (key === "currency") ? '' : 0;
      return (dataObject.hasOwnProperty(key) && dataObject[key]) ? dataObject[key] : defaultVal;
    }

    if(deviceInfoResponse?.success && Object.keys(subscriptionInfo).length > 0 && subscriptionInfo?.service !== null){
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentPlan')
          && subscriptionInfo?.service?.currentPlan !== null) {
          planCurrency = checkDeviceInfoProps("currency", subscriptionInfo.service.currentPlan);
          setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY,planCurrency)
      }
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentUsage') 
          && subscriptionInfo?.service?.currentUsage !== null) {
          planGivenRolloverPages = checkDeviceInfoProps("givenRolloverPages", subscriptionInfo.service.currentUsage);
          planPrintedRolloverPages = checkDeviceInfoProps("printedRolloverPages", subscriptionInfo.service.currentUsage);
      }
  }
    let pagesRolledOverProgressVal = 0;

    pagesRolledOverProgressVal =
    (planPrintedRolloverPages * 100) / planGivenRolloverPages || 0;
    return (
        <div className="pages-rolled-over">
            <div className="header">
            <h2 className="h2">{t("DASHBOARD.PAGES_ROLLED_OVER")}</h2>
            </div>
            <div className="pages-rolled-over-progress">
            {deviceInfoResponse?.loader ? (
                <Skeleton width="10%" />
            ) : deviceInfoResponse?.success ?
            (
                <p className="body-medium">
                {planPrintedRolloverPages}/{planGivenRolloverPages}
                </p>
            ):null}
            {deviceInfoResponse?.loader ? (
                <Skeleton className="rolled-over-overages-skeleton" />
            ) : deviceInfoResponse?.success ? (
                <Progress value={pagesRolledOverProgressVal} />
            )
            : null
            //  <p className="info-error-message">{t("ERROR_CONTENT.NO_DATA")}</p>
             }
            </div>
        </div>
    );
};

export default PagesRolledOver;