import React from 'react';
import RightArrow from '../../../../assets/images/rightArrowIcon.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { routePath } from '../../../../constants/Routes';
import { useTranslation } from 'react-i18next';
import './EcoproChangeDevice.css'

const EcoproChangeDevice = () => {

    const history = useHistory()
    const {t} = useTranslation(["translation"])
    const moveToSwapDevice = () => {
        history.push(routePath.CHANGE_DEVICE)
    }

    return (
        <div className='ecopro-change-device-wrapper'>
            <div className="change-device h1" onClick={() => moveToSwapDevice()}>{t('DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE')}</div>
            <div className='chage-device-img-wrapper'><img src={RightArrow} alt="right-arrow"/></div>
        </div>
    );
};

export default EcoproChangeDevice;