import { createSlice } from '@reduxjs/toolkit';

const validateUserPromoCodeSlice = createSlice({
  name: 'validateUserPromoCode',
  initialState: {
    loading: false,
    validationResult: null,
    error: null,
    success: false,
  },
  reducers: {
    validateUserPromoCodeRequest(state) {
      state.loading = true;
      state.error = null;
    },
    validateUserPromoCodeSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.validationResult = action.payload;
    },
    validateUserPromoCodeFailure(state, action) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  validateUserPromoCodeRequest,
  validateUserPromoCodeSuccess,
  validateUserPromoCodeFailure,
} = validateUserPromoCodeSlice.actions;
export default validateUserPromoCodeSlice.reducer;