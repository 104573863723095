import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CALLPOSTAPI } from '../../../api/apiHandler'
import { FAILURE, USER_ACTIVITY_LOGS, _CSAGENT_API_ENDPOINTS } from '../../../constants/AppConstants';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _CALLFETCHESUPPLYORDERLOGS(customerID){
    const endpoint = _CSAGENT_API_ENDPOINTS.SUPPLY_ORDER_LOGS;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_SUPPLY_ORDER_CONSUMABLES,
        description: `Fetch the supply order consumables list for userID: ${customerID}`
    }
    const payload = {
        userId: customerID,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
    }
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleSupplyOrderLogs(action){
    try {
        
        const customerId = action.customerID;
        if(customerId){
            const supplyOrderLogs =  yield call(_CALLFETCHESUPPLYORDERLOGS, customerId);
           if(supplyOrderLogs.actionStatus.toUpperCase() !== FAILURE){
                yield put({type: actionTypes.FETCH_SUPPLY_ORDERS_LOGS_SUCCESS, supplyOrderLogs: supplyOrderLogs});
           } else {
                yield put({
                    type: actionTypes.FETCH_SUPPLY_ORDERS_LOGS_ERROR, 
                    message: supplyOrderLogs.actionMessage
                });
           }
        } else {
            yield put({
                type: actionTypes.FETCH_SUPPLY_ORDERS_LOGS_ERROR, 
                message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
            });
        }
    } catch (e) {
        yield put({type: actionTypes.FETCH_SUPPLY_ORDERS_LOGS_ERROR, message: e.message});
    }
}

function* SupplyOrderLogs(){
    yield takeEvery(actionTypes.FETCH_SUPPLY_ORDERS_LOGS, handleSupplyOrderLogs);
}

export default SupplyOrderLogs;