import { createSlice } from '@reduxjs/toolkit';

const getDeviceStatusSlice = createSlice({
  name: 'getDeviceStatus',
  initialState: {
    loading: false,
    devices: null,
    success:false,
    error: null,
  },
  reducers: {
    getDeviceStatusRequest(state) {
      state.loading = true;
      state.success = false
      state.error = null;
    },
    getDeviceStatusSuccess(state, action) {
      state.loading = false;
      state.success = true
      state.deviceStatus = action.payload;
    },
    getDeviceStatusFailure(state, action) {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    },
  },
});

export const { getDeviceStatusRequest, getDeviceStatusSuccess, getDeviceStatusFailure } = getDeviceStatusSlice.actions;
export default getDeviceStatusSlice.reducer;
