import { call, put, takeEvery } from 'redux-saga/effects';
import { _ADMINISTRATOR_API_ENDPOINTS} from '../../constants/AppConstants';
import { _CALLGETAPI } from '../../api/apiHandler';
import { fetchFeatureSwitchFailure, fetchFeatureSwitchRequest, fetchFeatureSwitchSuccess } from '../FeatureSwitch/FeatureSwitchslice';


function _FEATURESWITCH(featureName){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.GET_FEATURE_SWITCH_ITEMS_VALUE;
    const payload = {
        params: {
            featureName: featureName
        }
    }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchFeatureSwitchSaga(action) {
  try {
    const featureName=action.payload;
    const featureSwitchService = yield call(_FEATURESWITCH,featureName);
    yield put(fetchFeatureSwitchSuccess(featureSwitchService));
  } catch (error) {
    yield put(fetchFeatureSwitchFailure(error.message));
  }
}



export default function* watchFetchFeatureSwitch() {
  yield takeEvery(fetchFeatureSwitchRequest.type, fetchFeatureSwitchSaga);
}
