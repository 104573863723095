import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import { getUserPromoCodeFailure, getUserPromoCodeRequest, getUserPromoCodeSuccess } from './getUserPromoCodeSlice';


function _GETUSERPROMOCODE(userId, deviceId){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.GET_USER_PROMO_CODE
    const payload = {
        params:{
            userId: userId,
            deviceId: deviceId
        }
    }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getUserPromoCodeSaga(action) {
  try {
    const userId = action.payload.userId
    const deviceId = action.payload.deviceId
    const userPromoCode = yield call(_GETUSERPROMOCODE, userId, deviceId);
    if(userPromoCode?.actionStatus.toUpperCase() === SUCCESS){
      yield put(getUserPromoCodeSuccess(userPromoCode));
    }
    else{
      yield put(getUserPromoCodeFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(getUserPromoCodeFailure(error.message));
  }
}

export default function* watchGetUserPromoCodeService() {
  yield takeEvery(getUserPromoCodeRequest.type, getUserPromoCodeSaga);
}
