import { Trans } from "react-i18next";
import i18next from "i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import validator from 'validator';
import { getLangCode, extractCountryCode, getCountryCode, extractLangcodeFromDomain } from "../utils/Internationalization/handleAppInternationalization";
import { routePath, routePrefix, WebCustomerRoutePaths } from "./Routes";
import { BONUSPAGE_PROMOTION, COUNTRIES_WITH_SDI, ECOPROTOKEN_CLAIMS, featureSwitchName, HUNDRED_PAGES_BONUS_DISCOUNT_PLAN_NAME, LOCALSESSIONDATAKEYS, ONE_TIME_BONUS, READY_PROMOTION, SIGNUPTYPE, SITECORE_TERMS_AND_CONDITION, SUBSCRIPTIONKIND, USER_REGISTRATION_TYPE_KEYS } from "./AppConstants";
import { getFeatureSwitchByKey, getLocalSessionData, getUserDataByKey, getUserSessionData, retreiveUserRegistrationType, WEB_handleSelectedPlanLocalData } from "../utils/Authentication/handleUserSession";
import { EXTERNAL_LINK_ENDPOINT, ENVSettings } from "./ENVSettings";
import { ctrylangCombinations, languageCodes, multiLingualCountries } from "./Country";
import { printerTypes } from "./PrinterTypes";
import { DisplayDate } from "../utils/DisplayDate";
import { userRoles } from "./UserRoles";
import SubscriptionOrderPayload from "../utils/MyAccount/SubscriptionOrderPayload";

export const getPromotionsArray = (deviceModel, bonusPagesFeature) => {
    const promotions = [];

    if (deviceModel && deviceModel[deviceModel.length - 1] === "E") {
      promotions.push(READY_PROMOTION.promotion);
    }
    if (bonusPagesFeature) {
      promotions.push(BONUSPAGE_PROMOTION.promotion);
    }
   else {
      const plans = WEB_handleSelectedPlanLocalData('Get');
    const OneTimeBonus=ONE_TIME_BONUS

      if (plans && plans.discount) {
        const hasBonusPageDiscount = plans.discount.some(
          (discount) => discount.name === OneTimeBonus
        );

        if (hasBonusPageDiscount) {
          promotions.push(BONUSPAGE_PROMOTION.promotion);
        }
      }
    }

    return promotions.length > 0 ? promotions : null;
};

export const addPromotionsToPayload = (payload, promotion) => {

  if (!promotion || promotion.length === 0) {
    return;
  }
  if (!payload.params) {
    payload.params = {}; 
  }

  if (promotion.length === 1) {
    
    payload.params.promotions = promotion[0];
  } else {
    promotion.forEach((promo, index) => {
      payload.params[`promotions[${index}]`] = promo;
    });
  }
};


export const getPlanTranslation = (toBeTranslatedData, signup, source, printerTypeFromContent=null) => {
  let printerType;
  if(printerTypeFromContent){
    printerType = printerTypeFromContent
  }
  else{
    printerType = getLocalSessionData(LOCALSESSIONDATAKEYS.PRINTER_TYPE)
  }
  let freeTrialMonths = ""
  if(toBeTranslatedData === "6MonthsFree"){
    freeTrialMonths = "6"
  }
  else if(toBeTranslatedData === "XmonthsFreePromotion"){
    freeTrialMonths = "2"
  }
  else if(toBeTranslatedData === "ReadyPromotion"){
    freeTrialMonths = "4"
  }
  if(signup === "web" || signup === "promotionBanner"){
    return <Trans i18nKey="CUSTOMER_WEB.SELECT_PLAN.FREE_TRIAL_MONTHS" freeTrialMonths={freeTrialMonths}>
      Free for {{freeTrialMonths}} months
    </Trans>
  }
  else{
    const countryCode = getCountryCode();
    let translatedData;
    if(printerType){
      const formattedPrinterType = printerType
      .replace(/\s+/g, '')
      const printerKey = `ECOPROCAROUSEL_CONTENT.${formattedPrinterType}.${toBeTranslatedData}`;
      translatedData =  i18next.t(printerKey);
    }
    else{
      translatedData = i18next.t(`ECOPROCAROUSEL_CONTENT.${toBeTranslatedData}`);
    }
    // Check if the translation exists or not
    if (translatedData.includes('ECOPROCAROUSEL_CONTENT')) {
      translatedData = toBeTranslatedData;
    }

    // Add double quotes only for sv-SE locale
    if (countryCode === "SE" && source === "dashboard") {
      return `"${translatedData}"`;  // Add double quotes for Swedish
    }
    
    return translatedData; 
  }
};

export const getCarbonSavingTypeTranslation = (carbonSavingType) => {
  if(i18next.t(`CARBON_OFFSETTING.CARBON_SAVING_TYPE.${carbonSavingType?.toUpperCase()?.replace(/ /g, "_")}`)?.includes('CARBON_OFFSETTING.CARBON_SAVING_TYPE')){
    return carbonSavingType
  }
  else{
    return i18next.t(`CARBON_OFFSETTING.CARBON_SAVING_TYPE.${carbonSavingType?.toUpperCase()?.replace(/ /g, "_")}`)
  }
}

export const findPromotionType = (discounts,signup) => {
  let promotion = ""

  if(signup === "promotionBanner"){
    promotion = getPlanTranslation("6MonthsFree",signup)
  }
  else{
    for (let i = 0; i < discounts.length; i++) {
      if(discounts[i].planName === HUNDRED_PAGES_BONUS_DISCOUNT_PLAN_NAME){
        continue;
      }
      if(discounts[i].planName === READY_PROMOTION.discountName){
        promotion = getPlanTranslation(discounts[i].planName,signup)
        break;
      }
      promotion = getPlanTranslation(discounts[i].planName,signup);
    }
  }
  
  return promotion
}

export const getOrderTypeTranslation = (toBeTranslatedData) => {
  if(i18next.t(`DASHBOARD.ORDERS.${toBeTranslatedData}`).includes('DASHBOARD.ORDERS')){
    return toBeTranslatedData
  }
  else{
    return i18next.t(`DASHBOARD.ORDERS.${toBeTranslatedData}`)
  }
}

const postalCodes = require("postal-codes-js");
export const findzipCodeError = (country, postcode) => {
  const countryCode = extractCountryCode(country);
  if (postalCodes.validate(countryCode, postcode) === true) {
    return false;
  } else {
    return true;
  }
};

export const findMobileError = (mobileNumber) => {
  if (mobileNumber !== undefined) {
    if (isValidPhoneNumber(mobileNumber)) {
      return false;
    } else {
      return true;
    }
  }
  else{
    return true
  }
};

export const validateEmail = (emailAddress) => {
  if(validator.isEmail(emailAddress)){
    return false
  }
  else{
    return true
  }
}

export const getDefaultCountry = () => {
  let countryCode = getLangCode().split('-')[1]
  return countryCode
}

const checkPath = (pathArr,currentPath, type= null) => {
  if(pathArr.indexOf(currentPath) !== -1){
    return true
  }
  else{
    return false
  }
}

export const checkHamburgerHeaderLocation = (pageLocation) => {
  const locationPathArr = [routePath.DASHBOARD, routePath.ORDERS, routePath.MY_ACCOUNT, routePath.HELP_SUPPORT,routePath.CARBON_OFFSETTING, routePath.PRINT_ACTIVITY, routePath.ECOPRO_FEEDBACK];
  return checkPath(locationPathArr,pageLocation)
}
export const checkEditForm = (pageLocation) => {
  const locationPathArr = [routePath.EDIT_ACCOUNT];
  return checkPath(locationPathArr,pageLocation)
}
export const emptyObjectCheck = (value) => {
  return Object.keys(value).length === 0
    && value.constructor === Object; 
}
export const getReasonString = (codeArray) => {
  return (codeArray) ? codeArray.join(): null;
}
export const convertToKG = (data) => {
  return (data/1000).toFixed(2);
}
export const getMobileOperatingSystem = () =>{
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}
export const navigateToMobileConnect = () => {
  if(getMobileOperatingSystem() === "iOS"){
    window.location.href = EXTERNAL_LINK_ENDPOINT.iosMobileConnectApp
  }
  else{
    window.location.href = EXTERNAL_LINK_ENDPOINT.androidMobileConnectApp
  }
}

 export const getPersonalOrBusinessTerms = (bothPersonalAndBusiness = false) => {
  let countryCodewithLang = getLangCode();
  const countryCode = getCountryCode();
  let countryCodeWithDefaultLang = languageCodes[countryCode?.toLowerCase()]
  const personalOrBusiness = getUserDataByKey('businessModel');
  const webSignupLangCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE);

  if(webSignupLangCode){
    const ctryCodeExtracted = extractLangcodeFromDomain();
    /**
     * Country BE & CH have two language combinations, so considered only these two countries.
     */
    if((ctryCodeExtracted.toUpperCase() === "BE" || ctryCodeExtracted.toUpperCase() === "CH") && ctrylangCombinations[ctryCodeExtracted.toUpperCase()].indexOf(webSignupLangCode) > -1){
      countryCodewithLang = countryCodeWithDefaultLang = webSignupLangCode ;
    }
  }
  let link ;
  if(bothPersonalAndBusiness){
    link = {'personal':  SITECORE_TERMS_AND_CONDITION.personal[countryCodewithLang], 'business': SITECORE_TERMS_AND_CONDITION.business[countryCodewithLang]}
  } else {
    if(personalOrBusiness === "1"){
      link =  SITECORE_TERMS_AND_CONDITION.business[countryCodewithLang]
    } else {
      link =  SITECORE_TERMS_AND_CONDITION.personal[countryCodewithLang]
    }
  }
  if(link) return link
  else return SITECORE_TERMS_AND_CONDITION.personal[countryCodeWithDefaultLang]
}

export const formatNumberOnLocale = (price, currency, locale, isWholeNumber = false) => {
  let langCode;
  if(locale){
    langCode = locale
  }
  else{    
    langCode = getLangCode() || getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE) || "en-GB";
  }
  const options = {};

  if (isWholeNumber) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  } else {
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  }

  if (langCode === 'fr-CH' && currency) {
      options.style = 'decimal';
  } else if (currency) {
      options.style = 'currency';
      options.currency = currency;
  } else {
      options.style = 'decimal';
  }

  const formattedPrice = new Intl.NumberFormat(langCode, options).format(price);

  if (langCode === 'fr-CH' && currency) {
      return `CHF ${formattedPrice.replace(/\u00A0/g, ' ')}`;
  }

  return formattedPrice;

}

export const getPrinterTypeTranslation = (printerName, type) => {
  const printerTypesObj = printerTypes
  const PrinterTypeKey = Object.keys(printerTypesObj).find(key => printerTypesObj[key] === printerName);
  if(type === "description"){
    if(i18next.t(`CUSTOMER_WEB.SELECT_PRINTER.DESCRIPTION.${PrinterTypeKey}`).includes('CUSTOMER_WEB.SELECT_PRINTER.DESCRIPTION')){
      return printerName
    }
    else{
      return i18next.t(`CUSTOMER_WEB.SELECT_PRINTER.DESCRIPTION.${PrinterTypeKey}`)
    }
  }
  else{
    if(i18next.t(`CUSTOMER_WEB.SELECT_PRINTER.${PrinterTypeKey}`).includes('CUSTOMER_WEB.SELECT_PRINTER')){
      return printerName
    }
    else{
      return i18next.t(`CUSTOMER_WEB.SELECT_PRINTER.${PrinterTypeKey}`)
    }
  }
}
/**
 * Method to authenticate Web signup user through external URL call
 * @return void
 */
export const AuthenticateUserByExternalRedirect = (redirectURL = null) => {
  const redirect_uri = redirectURL || WebCustomerRoutePaths.USER_ACCOUNT_INFO;
  const domain  = (new URL(window.location.href));
  const countryCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE).toLowerCase();
  const langCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE).split("-")[0];
  const finalURL = `${ENVSettings.BOL_API_BASE_REDIRECT_URL}/api/v1/connected_service/login?client_id=ecoproclient&redirect_uri=${domain.origin}${redirect_uri}&state=state&country=${countryCode}&language=${langCode}`;
  window.location.href = finalURL;
}
export const paymentOptionsNameTranslation = (paymentType) => {
  if(i18next.t(`PAYMENT_OPTIONS.DISPLAY_NAME.${paymentType}`).includes('PAYMENT_OPTIONS.DISPLAY_NAME')){
    return paymentType
  }
  else{
    return i18next.t(`PAYMENT_OPTIONS.DISPLAY_NAME.${paymentType}`)
  }
}
export const pushDataLayer = (event,parameterName,parameterValue) => {
  window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event':event,
      [parameterName]:parameterValue
    });
}

export const setClientParameterLocale = (langCode) => {
  const result = langCode?.replace('-','_')
  return result
}

export const checkEcoproSupportRegion = (postCode,response) => {
  let countryRegexResponse = response[0]
  const countryRegex = new RegExp(countryRegexResponse);
  const isPostCodeRestrict= countryRegex.test(postCode?.toUpperCase());
  return isPostCodeRestrict
}

export const getGradient = (ctx, chartArea, start_color, stop_color) => {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, start_color);
    gradient.addColorStop(0.5, stop_color);
    gradient.addColorStop(1, stop_color);
  }
  return gradient;
};

export const chooseMaxOrMinDate = (
  dateType,
  planData,
  dateDisplayOptions,
  langCode,
  source
) => {
  let dateFiltered;
  if (dateType === "max") {
    dateFiltered = new Date(
      Math.max(
        ...planData?.map((element) => {
          if(source === "brother-plus")
            return new Date(element?.date);
          return new Date(element?.startDate);
        })
      )
    );
  } else {
    dateFiltered = new Date(
      Math.min(
        ...planData?.map((element) => {
          if(source === "brother-plus")
            return new Date(element?.date);
          return new Date(element?.startDate);
        })
      )
    );
  }
  return DisplayDate(dateFiltered, dateDisplayOptions, langCode);
};
export const sortDateInDescendingOrder = (arrayOfObject, source, monthCount) => {
  if (!Array.isArray(arrayOfObject)) {
    return []; 
  }
  const sortedArray1 = [...arrayOfObject]?.sort(function (a, b) {
    if(source === "brother-plus")
      return new Date(b.date) - new Date(a.date);

    return new Date(b.startDate) - new Date(a.startDate);
  });
  return sortedArray1.slice(0, monthCount).reverse();
};

export const getChartLabels = (printData, source, monthCount) => {
  const dateDisplayOptions = { month: "short" };
  const endDateDisplayOptions = {month:"short",year:"numeric"}
  const langCode = getLangCode();
  const d = new Date(
    Math.max(
      ...printData?.map((element) => {
        if(source === "brother-plus"){
          return new Date(element?.date) 
        }
        else{
          return new Date(element?.startDate);
        }
      })
    )
  );
  let currentMonth = d.getMonth() + 1;
  let labels = [];
  let endDate = ""
  for (let i = monthCount; i > 0; i--) {
    d.setMonth(currentMonth - i);
    if(i === monthCount){
      endDate = d?.toLocaleDateString(langCode, endDateDisplayOptions)
    }
    labels.push(d?.toLocaleDateString(langCode, dateDisplayOptions));
  }
  return {labels:labels,endDate:endDate};
};



export const getChartValues = (labels, printData, source, monthCount) => {
  const dateDisplayOptions = { month: "short" };
  const langCode = getLangCode();
  let values = [];
  for (let i = 0; i < monthCount; i++) {
    if (labels) {
      if(source === "brother-plus"){
        const filteredChartValues = printData.filter(
          (data) => (
            DisplayDate(data.date, dateDisplayOptions, langCode) === labels[i]
          )
        );
        if(filteredChartValues[0]?.printedPages)
        values.push(filteredChartValues[0]?.printedPages);
        else values.push(0)
      }
      else{
        const filteredChartValues = printData.filter(
          (data) => (
            DisplayDate(data.startDate, dateDisplayOptions, langCode) === labels[i]
          )
        );
        if(filteredChartValues[0]?.printedTotalPages)
        values.push(filteredChartValues[0]?.printedTotalPages);
        else values.push(0)
      }
    }
  }
  return values;
};
export const getYAxesMaxValue = (printData, source) => {
  if(source === "brother-plus"){
    const yAxesMaxValue = Math.max(
      ...printData.map((element) => {
        return element?.printedPages;
      })
    );
    return yAxesMaxValue
  }
  else{
    const yAxesMaxValue = Math.max(
      ...printData.map((element) => {
        return element?.printedTotalPages;
      })
    );
    const planPages = Math.max(
      ...printData.map((element) => {
        return element?.planPages;
      })
    );

    if(planPages > yAxesMaxValue){
      return planPages
    }
    else return yAxesMaxValue
  }

};
export const getAverageValue = (chartValues) => {
  const filter = array => array.filter((last => v => last = last || v)(false));
  const filteredArray = filter(chartValues)
  if(filteredArray?.length>0){
    const sum = filteredArray.reduce((a, b) => a + b);
    const average = sum / filteredArray.length;
    return Math.round(average)
  }
  else {
    return 0
  }
}

export const trimAddressFieldsLength = (txt, trimLength) => {
  return (txt) ? txt.substring(0, trimLength).trim() : null;
}

export const truncateAddressLine1Characters = (string, initialIndex, finalIndex) => {
  const truncatedString = string?.substring(initialIndex, finalIndex)
  return truncatedString
}

export const validateProvinceData = (formData) => {
  return (formData.country && formData.country.toUpperCase() !== 'ITALY') ? "" : formData.province;
}
export const verifyItalyBusinessUser = (country) => {
  const personalOrBusiness = getUserDataByKey('businessModel');
  if(country && COUNTRIES_WITH_SDI.includes(country.toUpperCase()) && personalOrBusiness === "1"){
    return true
  }
  else{
    return false
  }
}

/**
 * Method to avoid page auto scrolling to down in some browser scenarios eg:Firefox 
 * @param string elementClassName 
 * @return void
 */
export const scrollToTop = (elementClassName) => {
  let element = document.querySelector(elementClassName);
  if(element){
      element.scrollIntoView();
  }
}
export const currentUserRolesArr = () => {
  return (getUserSessionData(ECOPROTOKEN_CLAIMS.ROLES)) ? getUserSessionData(ECOPROTOKEN_CLAIMS.ROLES).split(',') : [];
}
export const validateRoleAndPathname_CSAGENT_ADMIN = (roles, pathname) => {
  return (
    ( roles.indexOf(userRoles.ADMIN) > -1 || roles.indexOf(userRoles.CSAGENT_ADMIN) > -1 ) 
    && pathname.indexOf(routePrefix.CSAGENT) > -1 )
    ? true : false;
}

export const currentUserRoleByOrder = () => {
  const roles = currentUserRolesArr();
  let currentUserRole = null;
  if(roles.indexOf(userRoles.ADMIN) > -1){
    currentUserRole = userRoles.ADMIN;
  } else if (roles.indexOf(userRoles.CSAGENT_ADMIN) > -1){
    currentUserRole = userRoles.CSAGENT_ADMIN
  } else if(roles.indexOf(userRoles.CSAGENT) > -1){
    currentUserRole = userRoles.CSAGENT
  } else if(roles.indexOf(userRoles.CSAGENT_VIEWER) > -1){
    currentUserRole = userRoles.CSAGENT_VIEWER
  } else {
    currentUserRole = 'Invalid input'
  }
  return currentUserRole;
}

export const postalCode_ErrorMsg = (errStatus, regionUnSupportedZipError, countryCode) => {
    let errorMsg;
    if (errStatus && !regionUnSupportedZipError) {
        errorMsg = i18next.t("FORM_CONTENT.FORM_ERROR.POSTCODE_VALID")
    } else if (errStatus && regionUnSupportedZipError) {
        if (countryCode === "FR") {
            errorMsg = i18next.t("FORM_CONTENT.FORM_ERROR.FRANCE_POSTCODE_ERROR")
        }
        else {
            errorMsg = i18next.t("FORM_CONTENT.FORM_ERROR.POSTCODE_RESTRICTION")
        }
    }
    else {
        errorMsg = i18next.t("FORM_CONTENT.FORM_ERROR.POSTCODE_EMPTY")
    }
    return errorMsg;
}

export const getPieChartDataWithBonus = (printedTotalPages, printedPlanPages, printedRollover, printedOverage, planPages, givenRolloverPages, purchasedOveragePages, printedBonusPages) => {
  let allInfo = {}
  const bonusPages = 100
  let monthlyAllowance = bonusPages + planPages + givenRolloverPages + purchasedOveragePages
  let totalPagesUsed = printedBonusPages + printedPlanPages + printedRollover + printedOverage
  let printedBonusColor = 'rgba(163, 73, 164, 1)'
  let printedPlanColor = 'rgba(0, 126, 143, 1)'
  let rollOverColour = 'rgba(69, 129, 232, 1)'
  let overageColor = 'rgba(240, 139, 71, 1)'
  let transparentColor = 'rgba(0, 126, 143, 0.1)'
  let unusedBonusColor = 'rgba(204, 204, 255, 1)';
  let data1;
  let lineSegment;
  let backgroundColor;
  let labels;

  let lineSegment1 = bonusPages
  let lineSegment2 = monthlyAllowance === planPages+bonusPages ? undefined : planPages+bonusPages
  let lineSegment3 = ( monthlyAllowance === planPages+givenRolloverPages+bonusPages)? undefined : planPages+bonusPages+givenRolloverPages
  lineSegment = [lineSegment1, lineSegment2, lineSegment3]
  let unusedPagesLabel;

  
  if(printedOverage<purchasedOveragePages) unusedPagesLabel = i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_OVERAGE")
  if(printedRollover<givenRolloverPages) unusedPagesLabel = i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_ROLLOVER")
  if(printedPlanPages<planPages) unusedPagesLabel = i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_PLAN")

  if(printedBonusPages === 0){
    data1=[bonusPages,planPages]
    backgroundColor=[unusedBonusColor, transparentColor]
    labels=[ i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_BONUS"), i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_PLAN") ]
  }
  else if(printedBonusPages !== 0 && printedBonusPages < bonusPages && printedPlanPages === 0){
    data1=[printedBonusPages,bonusPages-printedBonusPages,planPages - printedPlanPages]
    backgroundColor=[printedBonusColor,unusedBonusColor,transparentColor]
    labels=[ i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.BONUS_PAGES_USED"),
      i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_BONUS"), 
             i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_PLAN")]
  }
  else if(printedBonusPages === bonusPages && printedPlanPages<=planPages && planPages<=monthlyAllowance && printedRollover === 0 && givenRolloverPages !== 0){
    data1=[printedBonusPages,printedPlanPages,(planPages+bonusPages)-(printedBonusPages+printedPlanPages), givenRolloverPages]
    backgroundColor=[printedBonusColor,printedPlanColor,transparentColor,transparentColor]
    labels=[ i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.BONUS_PAGES_USED"),
      i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED"), 
             i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_PLAN"),
             i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_ROLLOVER")]
  }

  allInfo = {
    data:data1?data1:[printedBonusPages,printedPlanPages, printedRollover, printedOverage, monthlyAllowance-printedTotalPages],
    extra:lineSegment,
    backgroundColor:backgroundColor?backgroundColor:[printedBonusPages? printedBonusColor : undefined, printedPlanPages?printedPlanColor:undefined,printedRollover? rollOverColour:undefined,printedOverage? overageColor:undefined, monthlyAllowance-printedTotalPages?transparentColor:undefined],
    monthlyAllowance: monthlyAllowance,
    totalPagesUsed: totalPagesUsed,
    labels:labels?labels:[i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.BONUS_PAGES_USED"),
      i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED"),
                          i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.ROLLOVER"),
                          i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.OVERAGE"),
                          unusedPagesLabel]
  }

  return allInfo
}

export const getPieChartData = (printedTotalPages, printedPlanPages, printedRollover, printedOverage, planPages, givenRolloverPages, purchasedOveragePages) => {
  let allInfo = {}
  let monthlyAllowance = planPages + givenRolloverPages + purchasedOveragePages
  let totalPagesUsed = printedPlanPages + printedRollover + printedOverage
  let printedPlanColor = 'rgba(0, 126, 143, 1)'
  let rollOverColour = 'rgba(69, 129, 232, 1)'
  let overageColor = 'rgba(240, 139, 71, 1)'
  let transparentColor = 'rgba(0, 126, 143, 0.1)'
  let data1;
  let lineSegment;
  let backgroundColor;
  let labels;

  let lineSegment1 = monthlyAllowance === planPages ? undefined : planPages
  let lineSegment2 = monthlyAllowance === planPages+givenRolloverPages? undefined : planPages+givenRolloverPages
  lineSegment = [lineSegment1, lineSegment2]

  let unusedPagesLabel;

  if(printedOverage<purchasedOveragePages) unusedPagesLabel = i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_OVERAGE")
  if(printedRollover<givenRolloverPages) unusedPagesLabel = i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_ROLLOVER")
  if(printedPlanPages<planPages) unusedPagesLabel = i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_PLAN")
  if(printedPlanPages<planPages && planPages<monthlyAllowance){
    data1=[printedPlanPages,planPages-printedPlanPages, givenRolloverPages]
    backgroundColor=[printedPlanColor,transparentColor,transparentColor]
    labels=[ i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED"), 
             i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_PLAN"),
             i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_ROLLOVER")]
  }

  allInfo = {
    data:data1?data1:[printedPlanPages, printedRollover, printedOverage, monthlyAllowance-printedTotalPages],
    extra:lineSegment,
    backgroundColor:backgroundColor?backgroundColor:[printedPlanPages?printedPlanColor:undefined,printedRollover? rollOverColour:undefined,printedOverage? overageColor:undefined, monthlyAllowance-printedTotalPages?transparentColor:undefined],
    monthlyAllowance: monthlyAllowance,
    totalPagesUsed: totalPagesUsed,
    labels:labels?labels:[i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED"),
                          i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.ROLLOVER"),
                          i18next.t("PRINT_ACTIVITY.PAGES_USED_LABELS.OVERAGE"),
                          unusedPagesLabel]
  }
  return allInfo
}

export const convertToYYYYMMDD = (originalDateString) => {
  //Parse the original date string
  let originalDate = new Date(originalDateString);
  // Format the Date object into "YYYY-MM-DD"
  let year = originalDate.getFullYear();
  let month = String(originalDate.getMonth() + 1).padStart(2, '0');
  let day = String(originalDate.getDate()).padStart(2, '0');
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const calculateDaysBetweenDates = (startDate, endDate) => {
  let formattedStartDate = new Date(startDate); 
  let formattedEndDate = new Date(endDate); 
  // Convert both dates to milliseconds  
  let startDateMS = formattedStartDate.getTime();
  let endDateMS = formattedEndDate.getTime();
  // Calculate the difference in milliseconds
  let differenceMS = endDateMS - startDateMS;
  // Convert milliseconds to days
  let daysDifference = Math.ceil(differenceMS / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const handleNavigateToAppStore = (e) => {
  const appStoreType = e.currentTarget.querySelector('img').alt;
  let navigationURL = null;
  navigationURL = (appStoreType === 'andriod-app') ? EXTERNAL_LINK_ENDPOINT.googlePlayStoreAppLink : EXTERNAL_LINK_ENDPOINT.appleAppStoreLink;
  if(appStoreType === 'android-app'){
      pushDataLayer("web_signup_download_app", "app_type", "android");
  } else {
      pushDataLayer("web_signup_download_app", "app_type", "ios");
  }
  window.open(navigationURL, '_blank');
}

export const handleSubscriptionOrderPayload = async (payloadInfo) => {
  return await SubscriptionOrderPayload(null, payloadInfo);
} 

//Compares only keys which are common in both the object
export const compareObjects = (obj1, obj2) => {
  if(obj1 && obj2){
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  
  // Check if all keys in obj1 exist in obj2
  for (const key of keys1) {
      if (!keys2.includes(key)) {
          continue;
      }
      if (obj1[key] !== obj2[key]) {
          return false; // Values are different
      }
  }
  return true; // All common values are the same
  }
  else{
    return true
  }
}

export const getPrinterDescriptionKey = (printerTypeId) => {
  switch (printerTypeId) {
      case 1:
          return i18next.t('HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.INKJET_DESC');
      case 2:
          return i18next.t('HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.MONO_LASER_DESC');
      default:
          return '';
  }
};

export const Is_HWInclusive_Signup = () =>{
  const SIGNUPTYPE_FLOW = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);
  return (SIGNUPTYPE_FLOW === SIGNUPTYPE.HARDWARE_INCLUSIVE) ? true : false;
}

export const Is_BrotherPlus_Signup = () => {
  let status = false;
  const SIGNUPTYPE_FLOW = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);
  if(Is_EcoPro_RegistrationType() 
    || SIGNUPTYPE_FLOW === SIGNUPTYPE.WEB 
    || SIGNUPTYPE === SIGNUPTYPE.HARDWARE_INCLUSIVE) {
    status =  false;
  } else if (Is_BrotherPlus_RegistrationType()) {
    status =  true;
  } else if (SIGNUPTYPE_FLOW === SIGNUPTYPE.BROTHER_PLUS){
    status =  true
  }
 return status;
}

export const Is_BrotherPlus_RegistrationType = () => {
  const userRegType = retreiveUserRegistrationType();
  return (userRegType === USER_REGISTRATION_TYPE_KEYS.IS_BROTHER_PLUS) ? true : false
}

export const Is_EcoPro_RegistrationType = () => {
  const userRegType = retreiveUserRegistrationType();
  return (userRegType === USER_REGISTRATION_TYPE_KEYS.IS_ECOPRO) ? true : false
}

export const Is_Both_EcoPro_And_BrotherPlus_RegistrationType = () => {
  return retreiveUserRegistrationType('isBoth');
}

export const Is_BrotherPlusFreePlanSelectedFromSitecore = () => {
  const BROTHER_PLUS_FREE_PLAN = getLocalSessionData(LOCALSESSIONDATAKEYS.SITECORE_BROTHER_PLUS_PLAN);
  return (BROTHER_PLUS_FREE_PLAN) ? true : false;
}
export const getSubscriptionkindBy_SignupType = (SignupType) => {
  switch (SignupType) {
    case SIGNUPTYPE.HARDWARE_INCLUSIVE:
        return SUBSCRIPTIONKIND.HARDWARE_INCLUSIVE;
    case SIGNUPTYPE.WEB:
        return SUBSCRIPTIONKIND.WEB
    case SIGNUPTYPE.BROTHER_PLUS:
      return SUBSCRIPTIONKIND.BROTHER_PLUS
    default:
        return null;
  }
}

export const getSignupTypeBy_SubscriptionKind = (subscriptionkind) => {
  switch (subscriptionkind) {
    case SUBSCRIPTIONKIND.HARDWARE_INCLUSIVE:
        return SIGNUPTYPE.HARDWARE_INCLUSIVE;
    case SUBSCRIPTIONKIND.WEB:
        return SIGNUPTYPE.WEB
    case SUBSCRIPTIONKIND.BROTHER_PLUS:
        return SIGNUPTYPE.BROTHER_PLUS
    default:
        return null;
  }
}

/**
 * Determines the appropriate language to send in the API payload
 * contains settings for Belgium and Switzerland.
 *
 * Logic:
 * - For Belgium:
 *   - If the browser language is set to either 'nl' (Dutch) or 'fr' (French), the same language will be used.
 *   - If the browser language is set to any other language, 'nl' (Dutch) will be sent by default.
 * - For Switzerland:
 *   - If the browser language is set to either 'de' (German) or 'fr' (French), the same language will be used.
 *   - If the browser language is set to any other language, 'de' (German) will be sent by default.
 * - For others:
 *    - If the browser language is set to any language, the respective language of the country will be sent by default.
 * 
 * This method ensures that users in Belgium and Switzerland are served content in their official languages,
 * with defaults set to 'nl' for Belgium and 'de' for Switzerland if a different language is detected.
 *
 * @returns {string} - The language code ('nl', 'fr', or 'de') to be sent in the payload.
 *
 * Example:
 * - country = 'Belgium', browserLang = 'en' -> returns 'nl'
 * - country = 'Belgium', browserLang = 'nl' -> returns 'nl'
 * - country = 'Switzerland', browserLang = 'it' -> returns 'de'
 * - country = 'Switzerland', browserLang = 'fr' -> returns 'fr'
 */
export const determineLanguageCodeForPayload = () => {
  let langCode = "";
  const currentAppLangCode = localStorage.getItem('i18nextLng').split("-")[0];
  const ctryCodeExtracted = extractLangcodeFromDomain().toUpperCase();
  if(ctryCodeExtracted === 'BE' || ctryCodeExtracted === 'CH'){
    const validateLangCombination = multiLingualCountries[ctryCodeExtracted.toUpperCase()].indexOf(currentAppLangCode);
    langCode = (validateLangCombination > -1) ?  currentAppLangCode : multiLingualCountries[ctryCodeExtracted.toUpperCase()][0];
  } else {
    langCode = (getLangCode()) ? getLangCode().split("-")[0] :  "";
  }
  return langCode;
}

export const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const extractKeysAsArray = (data, printerType) => {
  if (!data || typeof data !== 'object') {
    return []; 
  }
  
  if (printerType === printerTypes.MONO_LASER) {
    return Object.keys(data).filter(key => key.toLowerCase() === 'black');
  }
  return Object.keys(data);
}
export const extractValuesAsArray = (data, printerType) => {
  if (!data || typeof data !== 'object') {
    return []; 
  }
  if (printerType === printerTypes.MONO_LASER) {
    return Object.keys(data).filter(key => key.toLowerCase() === 'black').map(key => data[key]);
  }
  return Object.values(data);
}


export const getChartColors = (chartLabels) => {
  const colorMap = {
    cyan: '#A0F9FF',
    magenta: '#F05FEF',
    yellow: '#F5F44E',
    black: '#171204'
  };

  return chartLabels?.map(label => colorMap[label]);
}
/**
 * Method to handle Signup type for B+ customer
 * For Brother plus customers the signtype should be null
 * @param {string} SignupType 
 * @returns string
 */
export const handleSignUpType = (SignupType) => {
  return (SignupType !== SIGNUPTYPE.BROTHER_PLUS) ? SignupType : null
}

export const isDelayedCancellationEnabled = (subcActivatedDate) => {
  const delayCancellation_FeatureSwitch = (getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION)) ? true : false;
  return (subcActivatedDate && delayCancellation_FeatureSwitch) ? true : false;
}

export const isBonusPagesAvailableInDiscountArray = (deviceInfoResponse) => {
  const discountInfo = deviceInfoResponse?.response?.service?.currentPlan?.discount;
  return Array.isArray(discountInfo)
    ? discountInfo?.some(obj => obj.name === ONE_TIME_BONUS)
    : false;
}

/**
 * Finds and returns a specific property value from an object in an array 
 * based on a key-value match.
 * 
 * @param {Array} array - The array of objects to search through.
 * @param {string} searchKey - The key to match in the objects.
 * @param {*} searchValue - The value to match for the given key.
 * @param {string} returnKey - The key whose value needs to be retrieved from the matching object.
 * @returns {*} - The value of the `returnKey` from the first matching object, or `null` if no match is found.
 */
export const findValueByKey_From_ArrayOfObj = (array, searchKey, searchValue, returnKey) => {
   // Validate that the input is an array
  if (!Array.isArray(array)) {
    return null;
  }
  for (const obj of array) {
    if (obj[searchKey] === searchValue) {
        return obj[returnKey];
    }
  }
  return null;
}