import { createSlice } from '@reduxjs/toolkit';

const cancelServiceSlice = createSlice({
  name: 'cancelService',
  initialState: {
    success: false,
    loading: false,
    cancelService: null,
    error: null,
  },
  reducers: {
    fetchCancelServiceRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCancelServiceSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.cancelService = action.payload;
    },
    fetchCancelServiceFailure(state, action) {
      state.loading = false;
      state.error = action.payload.message;
      state.success = false
    },
  },
});

export const { fetchCancelServiceRequest, fetchCancelServiceSuccess, fetchCancelServiceFailure } = cancelServiceSlice.actions;
export default cancelServiceSlice.reducer;
