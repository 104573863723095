import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignIn';

/**
 * SAMPLE RESPONSE:
 * {
    "id": "EM-00003504",
    "userId": "9aa8f422-0592-4d52-adcf-7ae54946854c",
    "date": "2024-10-18T11:53:52+02:00",
    "kind": "web_signup",
    "deviceOption": null,
    "orderNumber": null,
    "parameter": {
        "planId": "8adcc1d186255e7e01862f9fadc635df",
        "discountPlanIds": [
            "8adcddea7d4c63ae017d4ef070cc1d13"
        ],
        "shippingAddress": {
            "addressNumber": null,
            "active": null,
            "firstName": null,
            "lastName": null,
            "street1": "a1",
            "street2": "a2",
            "city": "c1",
            "state": null,
            "stateName": null,
            "postalCode": "WC2N5DU",
            "country": "United Kingdom",
            "currency": null,
            "companyName": null,
            "languageCode": null,
            "businessModel": 0,
            "vatId": null,
            "taxId": null,
            "sapBpId": null,
            "eInvoiceId": null,
            "homePhone": null,
            "mobilePhone": null,
            "nameTag": "",
            "actionStatus": "",
            "actionMessage": "",
            "customCode": ""
        }
    },
    "nameTag": "",
    "actionStatus": "Success",
    "actionMessage": "Data fetched successfully.",
    "customCode": ""
}
 */
const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const GetPreEnrollmentInfo = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_PRE_ENROLLMENT_INFO_INIT:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.GET_PRE_ENROLLMENT_INFO_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.enrollmentResponse,
                'error': false
            }
        case actionTypes.GET_PRE_ENROLLMENT_INFO_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                'data': {}
            }
        case actionTypes.RESET_STATE:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'data': {}
            }
        default:
            return {...state};
    }

}

export default GetPreEnrollmentInfo;