import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLocalSessionData, getUserSessionData } from "../../utils/Authentication/handleUserSession";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn } from "../../constants/AppConstants";
import Loader from "../../components/shared/loader/Loader";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import PagesUsed from "../../components/PrintActivity/PagesUsed/PagesUsed";
import "./PrintActivity.css";
import EcoproProgressBar from "../../components/shared/EcoproProgressBar/EcoproProgressBar";
import currencies from "../../constants/CurrencyTypes";
import { formatNumberOnLocale, Is_BrotherPlus_Signup } from "../../constants/AppMethod";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { routePrefix } from "../../constants/Routes";
import Skeleton from "react-loading-skeleton";
import AveragePagesUsed from "../../components/CustomerDashboard/AveragePagesUsed/AveragePagesUsed";
import { GetPrintActivity } from "../../actions/PrintActivity";

export const AvgPagesUsedSkeletonDashboard = () => {
    return (
        <>
            <div className="content">
                <div className="row">
                <div className="col-8 order-status-wrapper">
                    <Skeleton count={2} />
                </div>
                </div>
            </div>
            <div className="content mt-30">
                <div className="row">
                <div className="col-8 order-status-wrapper">
                    <Skeleton count={2} />
                </div>
                </div>
            </div>
        </>
    )
}

const PrintActivity = (props) => {
    const currentURL = useLocation();
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    /**
     * This component is used to display print activity in CSAgent Dashboard also.
     */
    /**
     * Method to check whether current page is csagent page
     * to display text with proper translations
     * @returns boolean
     */
    const checkISCSAgentByPath = () => {
        return (currentURL?.pathname.indexOf(routePrefix.CSAGENT) > -1 || currentURL?.pathname.indexOf(routePrefix.ADMIN) > -1) ? true : false;
    }
    const { 
        CustomerUID = null, 
        customerDeviceID = null, 
        customerCountryCode = null, 
        showPagesUsed = null, 
        showAvgPagesUsed = null, 
        isChildComp = null,
        isBlockActive = true,
    } = props;

    const IS_CSAGENT = checkISCSAgentByPath();
    const dispatch = useDispatch();
    const pageTranslation = (IS_CSAGENT) ? "CSAgenttranslation" : "translation"
    const { t } = useTranslation([pageTranslation]);
    const [isInitialLoading, setIsinitialLoading] = useState(false);
    const [pagesUsedData, setPagesUsedData] = useState({
        planName : null,
        planPages :0,
        printedPlanPages : 0,
        printedTotalPages: 0,
        printedRolloverPages: 0,
        givenRolloverPages: 0,
        printedOveragePages: 0,
        purchasedOveragePages: 0,
        printerType: null,
        dateDropdownArr : [],
        rollOverPages:0,
        overageBlockPages:0,
        planCurrency:null,
        planPrice:0,
        printedBonusPages:0
    })
    const [printHistoryAPIFlag, setPrintHistoryAPIFlag] = useState(false);
    const printActivityResult = useSelector((state) => state.PrintActivity);
    const deviceID = (IS_CSAGENT) ? customerDeviceID : getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const UID = (IS_CSAGENT) ? CustomerUID : getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);

    useEffect(() => {
            dispatch(GetPrintActivity(UID, deviceID));
    }, [dispatch, UID, deviceID]);

    useEffect(() => {
        setIsinitialLoading(true);
        setPrintHistoryAPIFlag(true)
        return () => {
            localStorage.removeItem("PRINT_ACTIVITY_SUBSC_DATES")
          };
    }, [dispatch, UID, deviceID, IS_CSAGENT, customerCountryCode, IsBrotherPlus_Signup]);

    const onSUBSCDateChange = (date) => {
        setIsinitialLoading(false);
        const filtereData = Object.values(printActivityResult?.printActivity?.printHistory).filter((val) => {
            return (val.startDate === date) ? val : "" ;
        });
        setPagesUsedData({
            planName : filtereData[0].planName,
            planPages : filtereData[0].planPages,
            printedPlanPages : filtereData[0].printedPlanPages,
            printedTotalPages: filtereData[0].printedTotalPages,
            printedRolloverPages: filtereData[0].printedRolloverPages,
            givenRolloverPages: filtereData[0].givenRolloverPages,
            printedOveragePages: filtereData[0].printedOveragePages,
            purchasedOveragePages: filtereData[0].purchasedOveragePages,
            printerType: printActivityResult?.printActivity?.printerType,
            rollOverPages:filtereData[0].plan.rollOverPages,
            overageBlockPages:filtereData[0].plan.overageBlockPages,
            planCurrency:filtereData[0].plan.currency,
            planPrice:filtereData[0].plan.overagePrice,
            printedBonusPages:filtereData[0].printedBonusPages,
            dateDropdownArr : Object.values(printActivityResult.printActivity.printHistory).map((val) => {
                return val.startDate;
            })
         });
    }

    if(printActivityResult.loading && isInitialLoading && !isChildComp) {
        return <Loader></Loader>
    }

    if(printActivityResult.error && isInitialLoading && !IS_CSAGENT && !isChildComp) {
        return <ErrorPage errorInfo={printActivityResult.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
    }

    if(printActivityResult.error && isInitialLoading && IS_CSAGENT && !isChildComp) {
        return <div className="email-log-error">{t("ERROR_MESSAGES.DEVICE_NOT_FOUND")}</div>
    }

    if(printHistoryAPIFlag && printActivityResult.success && printActivityResult.printActivity.printHistory.length > 0){
        setPagesUsedData({
            planName : printActivityResult?.printActivity?.printHistory[0].planName,
            planPages : printActivityResult?.printActivity?.printHistory[0].planPages,
            printedPlanPages : printActivityResult?.printActivity?.printHistory[0].printedPlanPages,
            printedTotalPages: printActivityResult?.printActivity?.printHistory[0].printedTotalPages,
            printedRolloverPages: printActivityResult?.printActivity?.printHistory[0].printedRolloverPages,
            givenRolloverPages: printActivityResult?.printActivity?.printHistory[0].givenRolloverPages,
            printedOveragePages: printActivityResult?.printActivity?.printHistory[0].printedOveragePages,
            purchasedOveragePages: printActivityResult?.printActivity?.printHistory[0].purchasedOveragePages,
            printerType: printActivityResult?.printActivity?.printerType,
            rollOverPages:printActivityResult?.printActivity?.printHistory[0].plan.rollOverPages,
            overageBlockPages:printActivityResult?.printActivity?.printHistory[0].plan.overageBlockPages,
            planCurrency:printActivityResult?.printActivity?.printHistory[0].plan.currency,
            planPrice:printActivityResult?.printActivity?.printHistory[0].plan.overagePrice,
            printedBonusPages:printActivityResult?.printActivity?.printHistory[0]?.printedBonusPages,
            dateDropdownArr : Object.values(printActivityResult.printActivity.printHistory).map((val) => {
                     return val.startDate;
                 })
            });
        setPrintHistoryAPIFlag(false);
    }
    return(   
        <div className="print-activity-wrapper displayed-as-child">
            {
                /**
                 * This section is displayed as widget in the Dashboard page
                 */
            }
            {
                (showPagesUsed) ?
                        <div className={`print-activity-pages-used-wrapper displayed-as-child ${(isBlockActive) ? 'active-dashboard-blocks':'disabled-dashboard-blocks'}`}>
                            <h2 className="h2">{t("PRINT_ACTIVITY.PRINT_ACTIVITY_HEADER")}</h2>
                            {
                               ( printActivityResult.loading )
                               ? <div className="pages-used-loader-wrapper">
                                    <Skeleton className="para-skeleton1" />
                                    <Skeleton className="para-skeleton2" />

                                    <div className="circular-skeleton-flex">
                                        <Skeleton
                                        circle
                                        className="circular-skeleton"
                                        height="108px"
                                        width="108px"
                                        duration={0.9}
                                        />
                                    </div>
                                </div>
                               : null
                            }
                            {
                                 ( printActivityResult.error ) ? <div className="pages-used-error-wrapper"><p>{t('ECOPRO_NEW_DASHBOARD.NO_DATA_DISPLAYED')}</p></div> : null
                            }
                            {
                                ( printActivityResult.success && !printActivityResult?.loading ) ?
                                <>
                                    <p className="body-medium pages-used-caption">{t("PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED")}</p>
                                    <PagesUsed 
                                        planName = {pagesUsedData.planName}
                                        planPages = {pagesUsedData.planPages}
                                        printedPlanPages = {pagesUsedData.printedPlanPages}
                                        printedTotalPages= {pagesUsedData.printedTotalPages}
                                        printedRolloverPages= {pagesUsedData.printedRolloverPages}
                                        givenRolloverPages= {pagesUsedData.givenRolloverPages}
                                        printedOveragePages= {pagesUsedData.printedOveragePages}
                                        purchasedOveragePages= {pagesUsedData.purchasedOveragePages}
                                        printerType= {pagesUsedData.printerType}
                                        printedBonusPages= {pagesUsedData.printedBonusPages}
                                        dateDropdownArr = {pagesUsedData.dateDropdownArr}
                                        dateFilter =  {onSUBSCDateChange}
                                        IS_CSAGENT = {IS_CSAGENT}
                                        isChildComp = {isChildComp}
                                    />
                                </>
                                 : null
                            }
                        </div>
                    : null
            }
            {
                /**
                 * This section is displayed as widget in the Dashboard page
                 */
            }
            { (showAvgPagesUsed) ? (
                    <>
                        {
                            <div className={`print-activity-avg-pages-used-wrapper displayed-as-child ${(isBlockActive) ? 'active-dashboard-blocks':'disabled-dashboard-blocks'}`}>
                                {
                                    ( printActivityResult?.loading)
                                    ? <>
                                            <div className="average-pages-used-wrapper loader-wrapper">
                                                <div className="average-page-trends h2">{t("PRINT_ACTIVITY.TRENDS")}</div>
                                                <div className="average-pages-used-content">
                                                    <AvgPagesUsedSkeletonDashboard />
                                                </div>
                                            </div>
                                        </>
                                    : null
                                }
                                {
                                 ( printActivityResult?.error ) 
                                    ?   <> 
                                            <div className="average-pages-used-wrapper error-wrapper">
                                                <div className="average-pages-used-content">
                                                    <div className="average-page-trends h2">{t("PRINT_ACTIVITY.TRENDS")}</div>
                                                    <p>{t('ECOPRO_NEW_DASHBOARD.NO_DATA_DISPLAYED')}</p>
                                                </div>
                                            </div>
                                        </> 
                                    : null
                                }
                                {
                                   
                                    !IsBrotherPlus_Signup && printActivityResult.success && !printActivityResult?.loading
                                    && printActivityResult?.printActivity?.printHistory?.length > 0
                                    &&
                                     <AveragePagesUsed printHistoryResult={printActivityResult?.printActivity?.printHistory}/>
                                    
                                }
                            </div>
                        }
                    </>
                ) : null
            }
            {
                /**
                 * This code block is to display Print activities in a page
                 */
            }
            {
                (!isChildComp) 
                ? <div>
                    <div className='print-activity-pages-used-wrapper-not-child-comp displayed-as-child'>
                    <h1 className="">{t("PRINT_ACTIVITY.PRINT_ACTIVITY_HEADER")}</h1>
                    <PagesUsed 
                        planName = {pagesUsedData.planName}
                        planPages = {pagesUsedData.planPages}
                        printedPlanPages = {pagesUsedData.printedPlanPages}
                        printedTotalPages= {pagesUsedData.printedTotalPages}
                        printedRolloverPages= {pagesUsedData.printedRolloverPages}
                        givenRolloverPages= {pagesUsedData.givenRolloverPages}
                        printedOveragePages= {pagesUsedData.printedOveragePages}
                        purchasedOveragePages= {pagesUsedData.purchasedOveragePages}
                        printerType= {pagesUsedData.printerType}
                        printedBonusPages= {pagesUsedData.printedBonusPages}
                        dateDropdownArr = {pagesUsedData.dateDropdownArr}
                        dateFilter =  {onSUBSCDateChange}
                        IS_CSAGENT = {IS_CSAGENT}
                    />
                    </div>
                    {
                        (!IS_CSAGENT) ?
                        <>
                            {
                                printActivityResult.success 
                                && printActivityResult?.printActivity?.printHistory?.length > 0 
                                &&
                                <>
                                    <div className='print-activity-pages-used-wrapper-not-child-comp displayed-as-child'>
                                        <div className="pa-progress-bar-wrapper">
                                            <EcoproProgressBar 
                                                value={(pagesUsedData.givenRolloverPages*100/pagesUsedData.rollOverPages) || 0}
                                                header={t("PRINT_ACTIVITY.ROLLOVER.HEADER")}
                                                info={t("PRINT_ACTIVITY.ROLLOVER.INFO")}
                                                initialLabel={t("PRINT_ACTIVITY.ROLLOVER.INITIAL")}
                                                initialValue={`${pagesUsedData.givenRolloverPages} ${t("PRINT_ACTIVITY.PAGES")}`}
                                                finalLabel={t("PRINT_ACTIVITY.ROLLOVER.LIMIT")}
                                                finalValue={`${pagesUsedData.rollOverPages} ${t("PRINT_ACTIVITY.PAGES")}`}
                                            ></EcoproProgressBar>
                                        </div>
                                    </div>
                                
                                    {pagesUsedData?.planCurrency && pagesUsedData?.purchasedOveragePages ?
                                    <div className='print-activity-pages-used-wrapper-not-child-comp displayed-as-child'>
                                        <div className="pa-progress-bar-wrapper">
                                            <EcoproProgressBar 
                                                value={(pagesUsedData.printedOveragePages*100/pagesUsedData.purchasedOveragePages) || 0}
                                                header={t("DASHBOARD.OVERAGES")}
                                                info={t("DASHBOARD.PAGES_PRICE", {
                                                    plan_pages: pagesUsedData.overageBlockPages,
                                                    plan_price: formatNumberOnLocale(pagesUsedData.planPrice, currencies[pagesUsedData?.planCurrency]?.code),
                                                })}
                                                initialLabel={`${pagesUsedData.printedOveragePages}/${pagesUsedData.purchasedOveragePages}`}
                                            ></EcoproProgressBar>
                                        </div>
                                    </div>
                                        :null    
                                    }
                                </>
                            }
                            {
                                <div className='print-activity-pages-used-wrapper-not-child-comp displayed-as-child'>
                                {printActivityResult.success 
                                && printActivityResult?.printActivity?.printHistory?.length > 0 
                                && <AveragePagesUsed printHistoryResult={printActivityResult?.printActivity?.printHistory}/>
                                }
                                </div>
                            }
                        </>
                        : null
                    }
                </div>
                
                : null
            }
        </div>
    );
};

export default PrintActivity;
