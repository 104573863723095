import React, { useEffect } from "react";
import { Is_BrotherPlus_Signup } from "../../../constants/AppMethod";
import BrotherPlusDashboard from "../BrotherPlus/Dashboard";
import EcoProDashboard from "../EcoPro/Dashboard";
import './CustomerDashboard.css';
import { useDispatch, useSelector } from "react-redux";
import { GetUserRegistrationServiceInit } from "../../../store/UserRegistration/UserRegistrationSlice";
import Loader from "../../../components/shared/loader/Loader";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import { ResetUserFeedback } from "../../../actions/Dashboard";

const CustomerDashboard = () => {
  const dispatch = useDispatch();
  const userRegistrationType = useSelector((state => state.ROOTREDUCER_RTK.GetUserRegistrationService));
  const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

  useEffect(() => {
    dispatch(GetUserRegistrationServiceInit());
    dispatch(ResetUserFeedback());
  }, [dispatch]);

    if(userRegistrationType.loading){
      return <Loader />;
    }
    if(userRegistrationType.error){
      return <ErrorPage errorInfo={userRegistrationType.error}></ErrorPage>
    }
    return (
      <>
        {
          (userRegistrationType.success === true)
          ? (IsBrotherPlus_Signup) ? <BrotherPlusDashboard /> : <EcoProDashboard />
          : <Loader />
        }
      </>
    )
}

export default CustomerDashboard;