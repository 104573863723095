import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';
import { getDevicesFailure, getDevicesRequest, getDevicesSuccess } from './getDevicesSlice';
import i18next from 'i18next';

function _GETDEVICES(userId){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.GET_DEVICES;
    const payload = {
        params:{
            userId:userId
        }
    }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getDevicesSaga(action) {
  try {
    const userId = action.payload.userId
    const devices = yield call(_GETDEVICES, userId);
    if(devices?.actionStatus.toUpperCase() === SUCCESS){
      yield put(getDevicesSuccess(devices));
    }
    else{
      yield put(getDevicesFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(getDevicesFailure(error.message));
  }
}

export default function* watchGetDevicesService() {
  yield takeEvery(getDevicesRequest.type, getDevicesSaga);
}
