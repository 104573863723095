import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncEnrollmentRetry, EnrollmentStatus, GetCancelSubscriptionStatus, GetUserSubscriptionAccountSummary, ReactivateSubscription, ResetCancelSubscriptionResult } from '../../../../actions/Dashboard';
import { alertSubscriptionId, ECOPROTOKEN_CLAIMS, FAILURE, featureSwitchName, LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';
import { Trans, useTranslation } from 'react-i18next';
import EcoproAlert from '../../../shared/alert/alert';
import ErrorAlert from "../../../../assets/images/error-alert.svg";
import WarningAlert from "../../../../assets/images/warning-alert.svg";
import DeviceStatus from "../../../../assets/images/dashboard-device-status.svg";
import BrotherPlusHorn from "../../../../assets/images/BrotherPlus/party-horn.svg";
import CancelSubscription from "../../../../assets/images/cancel-subscription-alert.svg";
import AlertPrinterIcon from "../../../../assets/images/alert-printer-icon.svg";
import TrialDaysLeft from "../../../../assets/images/dashboard-days-left-trial.svg"
import { calculateDaysBetweenDates, convertToYYYYMMDD, Is_BrotherPlus_Signup } from '../../../../constants/AppMethod';
import { DisplayDate } from '../../../../utils/DisplayDate';
import EcoProButton from '../../../shared/button/button';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import "./EcoproDashboardAlert.css"
import { getFeatureSwitchByKey, getLocalSessionData, getUserSessionData, setLocalSessionData } from '../../../../utils/Authentication/handleUserSession';

const EcoproDashboardAlert = (props) => {
    const { deviceInfoResponse } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    
    const userSubscriptionAccountSummaryResult = useSelector(state =>state.UserSubscriptionAccountSummary)
    const canelSubscriptionStatusResult = useSelector(state => state.GetCancelSubscriptionStatus)
    const reactivatedSubscriptionResult = useSelector(state => state.ReactivateSubscription)
    const isdelayCancellationFeatureSwitch = getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION) ? true : false

    const [userSubscriptionAlertStatus, setUserSubscriptionAlertStatus] = useState(true);
    const [modal, setModal] = useState(false);

    

    const retryAPIEventCount = useRef(0);
    const deviceInfoAPIEventCount = useRef(0);

    const dismissAlert = useCallback((key) => {
        reducer_dispatch({
            type: 'deviceAlert',
            alertType: key,
            displayAlert: false
        });
    }, []);

    const toggle = () => {
        setModal(!modal);
    }

    const setDeviceInfoAlert = useCallback((data) => {
        const deviceAlert = data?.service?.deviceAlert || [];
        const checkAndDispatchAlert = (condition, alertType, headerKey, subHeaderKey, subHeaderData = {}) => {
            if (condition) {
                reducer_dispatch({
                    type: 'deviceAlert',
                    alertType,
                    displayAlert: true,
                    header: t(headerKey),
                    subHeader: t(subHeaderKey, subHeaderData)
                });
            } else {
                dismissAlert(alertType);
            }
        };

        checkAndDispatchAlert(
            deviceAlert.some(alert => alert.descriptionId === alertSubscriptionId.DEVICE_OFFLINE),
            "deviceOffline",
            'DASHBOARD.ALERT.DEVICE_OFFLINE_HEADER',
            'DASHBOARD.ALERT.DEVICE_OFFLINE_SUBHEADER',
            { model: data?.model, serialNumber: data?.serialNumber }
        );

        checkAndDispatchAlert(
            deviceAlert.some(alert => alert.descriptionId === alertSubscriptionId.CLOSE_TO_OVERAGE),
            "closeToOverage",
            'DASHBOARD.ALERT.CLOSE_TO_OVERAGE_HEADER',
            'DASHBOARD.ALERT.CLOSE_TO_OVERAGE_SUBHEADER'
        );

        checkAndDispatchAlert(
            deviceAlert.some(alert => alert.descriptionId === alertSubscriptionId.OVERAGE),
            "overage",
            'DASHBOARD.ALERT.OVERAGE_HEADER',
            'DASHBOARD.ALERT.OVERAGE_SUBHEADER'
        );

        checkAndDispatchAlert(
            deviceAlert.some(alert => alert.descriptionId === alertSubscriptionId.PAYMENT_FAILED),
            "paymentFailed",
            'DASHBOARD.ALERT.PAYMENT_FAILED_HEADER',
            'DASHBOARD.ALERT.PAYMENT_FAILED_SUBHEADER'
        );

        checkAndDispatchAlert(
            !data?.isEnrollmentAsyncCompleted && !IsBrotherPlus_Signup,
            "enrollmentFailed",
            null,
            'DASHBOARD.ALERT.ENROLLMENT_FAILED',
            null,
            { alertImg: AlertPrinterIcon }
        );
    }, [dismissAlert, t, IsBrotherPlus_Signup]);

    const onReactivateSubscription = () => {
        dispatch(ReactivateSubscription())
    }

    useEffect(()=>{
        if(reactivatedSubscriptionResult?.success && isdelayCancellationFeatureSwitch){
            dismissAlert("cancelSubscription")
            setModal(true)
            dispatch(ResetCancelSubscriptionResult())
        }
    },[reactivatedSubscriptionResult?.success, isdelayCancellationFeatureSwitch, dispatch, dismissAlert])

    useEffect(() => {
        isdelayCancellationFeatureSwitch && dispatch(GetCancelSubscriptionStatus(UID, deviceID))
        dispatch(GetUserSubscriptionAccountSummary(UID));
        dispatch(EnrollmentStatus(false));
        setLocalSessionData(LOCALSESSIONDATAKEYS.ENROLLMENT_STATUS_COMPLETED, false)
    }, [dispatch, UID, deviceID, isdelayCancellationFeatureSwitch]);

    useEffect(()=>{
        displaySubscriptionNotActivatedAlert();
        displayCancelSubscriptionAlert()
        displaySubscriptionTrialAlert()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deviceInfoResponse?.response])

    useEffect(() => {
        if (deviceInfoResponse?.response?.actionStatus.toUpperCase() !== FAILURE) {
            if (deviceInfoResponse?.response?.isEnrollmentAsyncCompleted) {
                dispatch(EnrollmentStatus(true));
                setLocalSessionData(LOCALSESSIONDATAKEYS.ENROLLMENT_STATUS_COMPLETED, true)
            } else {
                if (retryAPIEventCount.current < 3 && deviceInfoAPIEventCount.current > 1) {
                    dispatch(AsyncEnrollmentRetry(deviceInfoResponse?.response?.service));
                    retryAPIEventCount.current++;
                }
                dispatch(EnrollmentStatus(false));
                setLocalSessionData(LOCALSESSIONDATAKEYS.ENROLLMENT_STATUS_COMPLETED, false)
            }
            deviceInfoAPIEventCount.current++;
            setDeviceInfoAlert(deviceInfoResponse?.response);
        }
    }, [deviceInfoResponse, dispatch, setDeviceInfoAlert]);

    useEffect(() => {
        if (userSubscriptionAccountSummaryResult.success && userSubscriptionAlertStatus) {
            userSubscriptionAccountSummaryResult?.userSubscriptionAccountSummary?.alert?.forEach(alertType => {
                const checkAlertType = (condition, alertKey, header, subHeader) => {
                    if (condition) {
                        reducer_dispatch({
                            type: 'deviceAlert',
                            alertType: alertKey,
                            displayAlert: true,
                            header: t(header),
                            subHeader: t(subHeader)
                        });
                    }
                };
                checkAlertType(
                    alertType.descriptionId === alertSubscriptionId.CREDIT_CARD_CLOSE_TO_EXPIRY,
                    "creditCardCloseToExpiry",
                    'DASHBOARD.ALERT.CLOSE_TO_EXPIRY_DATE_HEADER',
                    'DASHBOARD.ALERT.CLOSE_TO_EXPIRY_DATE_SUBHEADER'
                );
                checkAlertType(
                    alertType.descriptionId === alertSubscriptionId.CREDIT_CARD_EXPIRY,
                    "creditCardExpiry",
                    'DASHBOARD.ALERT.EXPIRY_DATE_HEADER',
                    'DASHBOARD.ALERT.EXPIRY_DATE_SUBHEADER'
                );
            });
            setUserSubscriptionAlertStatus(false);
        }
    }, [userSubscriptionAccountSummaryResult, userSubscriptionAlertStatus, t]);

    const alertInitialState = {
        closeToOverage:{
            alertImg:WarningAlert,
            alertClass:"dashboard-warning",
            displayAlert:false,
        },
        overage:{
            alertImg:WarningAlert,
            displayAlert:false,
            alertClass:"dashboard-warning",
        },
        cancelSubscription: {
            alertImg:CancelSubscription,
            displayAlert:false,
            alertClass:"dashboard-subscription-status"
        },
        deviceOffline:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        paymentFailed:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        creditCardCloseToExpiry:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        creditCardExpiry:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        activatedSubscriptionStatus:{
            alertImg:DeviceStatus,
            displayAlert:false,
            alertClass:"dashboard-subscription-status"
        },
        daysLeftForFreeTrial:{
            alertImg:TrialDaysLeft,
            displayAlert:false,
            alertClass:"dashboard-success"
        },
        enrollmentFailed:{
            alertImg:AlertPrinterIcon,
            displayAlert:false,
            alertClass:"dashboard-error"
        },
        brotherPlusWelcome:{
            alertImg:BrotherPlusHorn,
            displayAlert:false,
            alertClass:"brother-plus-welcome-alert"
        }
    }
    const [state, reducer_dispatch] = useReducer(loginReducer, alertInitialState);

    function loginReducer(state, action) {
        switch (action.type) {
            case 'deviceAlert':
                return {
                    ...state,
                    [action.alertType]: {
                        ...state[action.alertType],
                        displayAlert: action.displayAlert,
                        header: action.header,
                        subHeader: action.subHeader,
                        isSubheaderButton: action.isSubheaderButton,
                        buttonInAlert: action.buttonInAlert
                    }
                };
            default:
                return state;
        }
    }

    const displaySubscriptionNotActivatedAlert = () => {
        if(deviceInfoResponse?.response?.isEnrollmentAsyncCompleted && !(deviceInfoResponse?.response?.service?.activatedDate)){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "activatedSubscriptionStatus",
                displayAlert:true,
                subHeader:t('DASHBOARD.ALERT.SUBSCRIPTION_INFO'),        
            })
        }
        else if(deviceInfoResponse?.response?.service?.activatedDate){
            dismissAlert("activatedSubscriptionStatus")
        }
    }

    const displaySubscriptionTrialAlert = () => {
            const discount = deviceInfoResponse?.response?.service?.currentPlan?.discount
        if(deviceInfoResponse?.response?.isEnrollmentAsyncCompleted 
            && deviceInfoResponse?.response?.service?.activatedDate && 
            discount && discount[0]?.planId && discount[0]?.promotionEndDate){
            const furthestPromotionEndDate =  displayFurthestPromotionEndDate(discount);
            const formattedPromotionEndDate = convertToYYYYMMDD(furthestPromotionEndDate)
            const formattedTodaysDate = convertToYYYYMMDD(new Date())
            const calculateDaysBetweenDatesResult = formattedPromotionEndDate && formattedTodaysDate? calculateDaysBetweenDates(formattedTodaysDate,formattedPromotionEndDate) : null
             if(calculateDaysBetweenDatesResult!==0){
                reducer_dispatch({
                    type: 'deviceAlert',
                    alertType: "daysLeftForFreeTrial",
                    displayAlert:true,
                    subHeader:t('DASHBOARD.ALERT.TRIAL_DAYS_LEFT',{daysLeft:calculateDaysBetweenDatesResult}),        
            })
            }
            else{
                dismissAlert("daysLeftForFreeTrial")
            }
        }
        else{
            dismissAlert("daysLeftForFreeTrial")
        }
    }

    const displayFurthestPromotionEndDate = (discount) => {
        const maxDate = new Date(
            Math.max(
              ...discount.map(element => {
                return new Date(element?.promotionEndDate);
              }),
            ),
        );
        return maxDate

    }

    const displayCancelSubscriptionAlert = () => {
        const billingCycleEndDate = canelSubscriptionStatusResult?.success && canelSubscriptionStatusResult?.cancelSubscriptionStatus?.billingCycleEndDate
        const dateDisplayOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedBillingEndDate = DisplayDate(billingCycleEndDate,  dateDisplayOptions)
        if(canelSubscriptionStatusResult?.success && billingCycleEndDate && isdelayCancellationFeatureSwitch ){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "cancelSubscription",
                displayAlert:true,
                subHeader:<Trans i18nKey="DELAY_CANCELLATION.CANCEL_SUBSCRIPTION_ALERT" formattedBillingEndDate={formattedBillingEndDate}>
                You have cancelled your subscription. Your plan will get deactivated by the end of you last billing month, on <strong>{{formattedBillingEndDate}}</strong>. If you change your mind and wish to continue receiving the benefits of EcoPro, you can reactivate it below.
                </Trans>,
                isSubheaderButton: true,
                buttonInAlert:<EcoProButton cname="web-signup, cancel-subsc f-14" onBtnClick={()=>onReactivateSubscription()}>{t('DELAY_CANCELLATION.REACTIVATE_SUBSCRIPTION')}</EcoProButton>   
              })    
        }
        else{
            dismissAlert("cancelSubscription")
        }

    }

    return (
        <div className='ecopro-alert-wrapper'>
            {reactivatedSubscriptionResult.loading?
                <div className="spinner-overlay full-screen-loader">
                    <div className="web-form-input-spinner-wrapper">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>
                </div>
                 :
                 null}
                 {
                    reactivatedSubscriptionResult.success &&
                    <div>
                        <Modal isOpen={modal} toggle={toggle} >
                            <ModalHeader>Your subscription is reactivated successfully</ModalHeader>
                            <ModalFooter>
                            <Button color="secondary" onClick={toggle}>
                                Close
                            </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                 }
            {!deviceInfoResponse?.loader &&
            Object.keys(state).map(key => (
                state[key].displayAlert ? (
                    <EcoproAlert
                        key={key}
                        alertImg={state[key].alertImg}
                        dismissAlert={() => dismissAlert(key)}
                        alertHeader={state[key].header}
                        alertSubHeader={state[key].subHeader}
                        alertClass={state[key].alertClass}
                        isSubheaderButton={state[key].isSubheaderButton}
                        buttonInAlert={state[key].buttonInAlert}
                    />
                ) : null
            ))}
        </div>
    );
};

export default EcoproDashboardAlert;
