import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  _BROTHER_PLUS_API_ENDPOINTS, 
  ECOPROTOKEN_CLAIMS, 
  LOCALSESSIONDATAKEYS, 
  SUCCESS
} from '../../../../constants/AppConstants';
import { _CALLPOSTAPI } from '../../../../api/apiHandler';
import { getLocalSessionData, getUserDataByKey, getUserSessionData } from '../../../../utils/Authentication/handleUserSession';
import { 
  EnrollmentRegisterServiceFailure, 
  EnrollmentRegisterServiceInit, 
  EnrollmentRegisterServiceSuccess 
} from './RegisterServiceSlice';
import i18next from 'i18next';
import { getSubscriptionkindBy_SignupType } from '../../../../constants/AppMethod';


function _REGISTERSERVICE(){
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const bpID = getUserDataByKey('sapBpId');
  const serialNumber = getLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER)
  const model = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
  const displayName = getUserDataByKey('displayName', 'personal_details');
  const signupType = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);
  const endpoint = _BROTHER_PLUS_API_ENDPOINTS.REGISTER_SERVICE;

    const payload = {
      'userId':UID,
      'deviceId':deviceID,
      'bpId':bpID,
      'serialNumber': serialNumber,
      'model': model,
      'displayName': displayName,
      'subscriptionKind':  getSubscriptionkindBy_SignupType(signupType)
    }
    
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleRegisterServiceSaga(action) {
  try {
    const registerService = yield call(_REGISTERSERVICE);
    (registerService.actionStatus.toUpperCase() === SUCCESS)
    ? yield put(EnrollmentRegisterServiceSuccess(registerService))
    : yield put(EnrollmentRegisterServiceFailure(i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR")));
  } catch (error) {
    yield put(EnrollmentRegisterServiceFailure(error.message));
  }
}

export default function* watchRegisterService() {
  yield takeLatest(EnrollmentRegisterServiceInit.type, handleRegisterServiceSaga);
}
