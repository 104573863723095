import { createSlice } from '@reduxjs/toolkit';

const GetUserDeviceStatusSlice = createSlice({
  name: 'fetchUserDeviceStatus',
  initialState: {
    loading: false,
    deviceStatus: null,
    error: null,
    success: false,
  },
  reducers: {
    fetchUserDeviceStatusRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserDeviceStatusSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.deviceStatus = action.payload;
    },
    fetchUserDeviceStatusFailure(state, action) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchUserDeviceStatusRequest,
  fetchUserDeviceStatusSuccess,
  fetchUserDeviceStatusFailure,
} = GetUserDeviceStatusSlice.actions;

export default GetUserDeviceStatusSlice.reducer;
