import { call, put, takeEvery } from 'redux-saga/effects';
import { _CALLPOSTAPI } from '../../../api/apiHandler';
import {  addUserActivityLogRequest, addUserActivityLogSuccess, addUserActivityLogFailure } from './addUserActivityLogServiceSlice';
import { getLocalSessionData,getUserDataByKey } from '../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS, _BROTHER_PLUS_API_ENDPOINTS } from '../../../constants/AppConstants';

function _ADDUSERACTIVITYLOGSERVICE(payload){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.USER_ACTIVITY_LOG;
    return _CALLPOSTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* addUserActivityLogSaga() {
    try {
      const country =  getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE);
      const userId = getLocalSessionData(LOCALSESSIONDATAKEYS.UID);
      const bpId = getUserDataByKey('sapBpId');
      const model = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
      const requestPayload = {
        userId,
        bpId,
        model,
        country,
      };
      const response = yield call(_ADDUSERACTIVITYLOGSERVICE, requestPayload);
      
      yield put(addUserActivityLogSuccess(response));
    } catch (error) {
      yield put(addUserActivityLogFailure(error.message));
    }
  }
  

export default function* watchAddUserActivityLogService() {
  yield takeEvery(addUserActivityLogRequest.type, addUserActivityLogSaga);
}
