import { combineReducers } from "redux";
import CancelServiceSlice from "./brotherPlus/cancelService/cancelServiceSlice";
import RegisterServiceSlice from "./brotherPlus/EnrollmentService/RegisterService/RegisterServiceSlice";
import BOCDeviceConnectionServiceSlice from "./CSAgentPortal/BOCDeviceConnectionServiceSlice";
import getDevicesSlice from "./brotherPlus/getDevices/getDevicesSlice";
import getDeviceStatusSlice from "./brotherPlus/getDeviceStatus/getDeviceStatusSlice";
import getPrintHistorySlice from "./brotherPlus/getPrintHistory/getPrintHistorySlice";
import getUserPromoCodeSlice from "./brotherPlus/getUserPromoCode/getUserPromoCodeSlice";
import GetUserDeviceStatusSlice from "./CSAgentPortal/BrotherPlus/GetUserDeviceStatusSlice";
import GetUserPrintHistorySlice from "./CSAgentPortal/BrotherPlus/GetUserPrintHistorySlice";
import GetUserSubscriptionDetailsSlice from "./CSAgentPortal/BrotherPlus/GetUserSubscriptionDetailsSlice";
import validateUserPromoCodeSlice from "./brotherPlus/ValidateUserPromoCode/validateUserPromoCodeSlice";

export default combineReducers({
    cancelService: CancelServiceSlice,
    RegisterService: RegisterServiceSlice,
    BOCDeviceConnectionService:BOCDeviceConnectionServiceSlice,
    getDevices: getDevicesSlice,
    getDeviceStatus: getDeviceStatusSlice,
    getPrintHistory: getPrintHistorySlice,
    getUserPromoCode: getUserPromoCodeSlice,
    getUserDeviceStatus:GetUserDeviceStatusSlice,
    getUserPrintHistory:GetUserPrintHistorySlice,
    getUserSubscriptionDetails: GetUserSubscriptionDetailsSlice,
    validateUserPromoCode: validateUserPromoCodeSlice
})