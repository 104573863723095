import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CSAGENT_API_ENDPOINTS, FAILURE, USER_ACTIVITY_LOGS } from "../../../constants/AppConstants";
import { _CALLPOSTAPI } from "../../../api/apiHandler";
import i18next from "i18next";
import UserActivityLogsPayload from "../../../utils/AdminPortal/UserActivityLogsPayload";

async function _CALLUSERACTIVITYAPI(customerId) {
    const endpoint = _CSAGENT_API_ENDPOINTS.FETCH_USER_ACTIVITY_HISTORY;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_USER_ACTIVITY_HISTORY,
        description: `Fetched activity history for the customer ${customerId}`
    }
    const payload = {
        userId: customerId,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
    }
    return _CALLPOSTAPI(endpoint, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
}

function* handleUserActivityHistory(action) {
    try {
        const customerId = action.customerID;
        const activityHistory = yield call(_CALLUSERACTIVITYAPI, customerId);
        if (activityHistory.actionStatus.toUpperCase() !== FAILURE) {
            yield put({ type: actionTypes.FETCH_USER_ACTIVITY_HISTORY_SUCCESS, activityHistory: activityHistory });
        } else {
            yield put({ type: actionTypes.FETCH_USER_ACTIVITY_HISTORY_ERROR, message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR') });
        }
    } catch (e) {
        yield put({ type: actionTypes.FETCH_USER_ACTIVITY_HISTORY_ERROR, message: e.message });
    }
}

function* UserActivityHistory() {
    yield takeLatest(actionTypes.FETCH_USER_ACTIVITY_HISTORY_INIT, handleUserActivityHistory);
}

export default UserActivityHistory;