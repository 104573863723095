import React from "react";
import "./MaintenancePage.css";
import { useTranslation } from "react-i18next";

const MaintenancePage = () => {
  const {t} = useTranslation(["translation"])
  return (
    <>
      <div className="ecopro-maintainance-wrapper">
        <h1 className="ecopro-maintainance">
          {t("MAINTENANCE.HEADER")}
        </h1>
        <div className="maintenance-info">
          <p>
          {t("MAINTENANCE.SUB_HEADER")}
          </p>
        </div>
        {/* @@ to be enable once functional */}
        {/* <div className="more-info">
          <p>Please click on the below links for more details:</p>
          <div>
            <a
              href="https://www.brother.at/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              Österreich
            </a>
          </div>
          <div>
            <a
              href="https://www.brother.be/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              Belgique
            </a>
          </div>
          <div>
            <a
              href="https://www.brother.de/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              Deutschland
            </a>
          </div>
          <div>
            <a
              href="https://www.brother.fr/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              France
            </a>
          </div>
          <div>
            <a
              href="https://www.brother.ie/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              Ireland
            </a>
          </div>
          <div>
            <a
              href="https://www.brother.co.uk/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              United Kingdom
            </a>
          </div>
          <div>
            <a
              href="https://www.brother.nl/brother-connection-service"
              target="_blank"
              rel="noreferrer"
            >
              Nederland
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MaintenancePage;
