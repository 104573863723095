import React, { useEffect, useState } from 'react';
import { GetUserSubscription } from '../../../../actions/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalSessionData, getUserSessionData, setLocalSessionData } from '../../../../utils/Authentication/handleUserSession';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';
import Skeleton from "react-loading-skeleton";
import EnrolledDevice from '../../EnrolledDevice/EnrolledDevice';
import { useTranslation } from 'react-i18next';


const EcoproEnrolledDevice = (props) => {
    const {deviceInfoResponse} = props
    const subscriptionInfo = deviceInfoResponse?.response

    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);


    const [isWithinTermEndDate, setIsWithinTermEndDate] = useState(false)
    const [hasFetchedSubscription, setHasFetchedSubscription] = useState(false);
    const [minimumTermEndDate, setMinimumTermEndDate] = useState(null)


    const userSubscriptionResult = useSelector(state =>state.GetUserSubscription)
    const EnrollmentStatusResult = useSelector(state => state.EnrollmentStatus);
   

    let planName= 'N/A', printerType = "", planPages= 0, activatedDate= "", networkStatus="";

    const checkDeviceInfoProps = (key, dataObject) => {
      const defaultVal = (key === "planName") ? 'N/A' : (key === "currency") ? '' : 0;
      return (dataObject.hasOwnProperty(key) && dataObject[key]) ? dataObject[key] : defaultVal;
    }

    if(deviceInfoResponse?.success && Object.keys(subscriptionInfo).length > 0 && subscriptionInfo?.service !== null){
      activatedDate = checkDeviceInfoProps("activatedDate",subscriptionInfo.service);
      networkStatus = checkDeviceInfoProps("networkStatus", subscriptionInfo)
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentPlan')
          && subscriptionInfo?.service?.currentPlan !== null) {
          planName = checkDeviceInfoProps("planName", subscriptionInfo.service.currentPlan);
          printerType = checkDeviceInfoProps("printerType", subscriptionInfo);
          planPages = checkDeviceInfoProps("planPages", subscriptionInfo.service.currentPlan);
      }
  }
   
    useEffect(() => {
        if (!userSubscriptionResult?.success && subscriptionInfo?.isEnrollmentAsyncCompleted && !hasFetchedSubscription) {
          dispatch(GetUserSubscription(UID, deviceID));
          setHasFetchedSubscription(true);
        }
      }, [dispatch, UID, deviceID, userSubscriptionResult, hasFetchedSubscription,deviceInfoResponse?.response, subscriptionInfo?.isEnrollmentAsyncCompleted]);

    useEffect(() => {
        if(userSubscriptionResult?.success){
            const userSubscriptionViewModel = userSubscriptionResult?.userSubscription?.userSubscriptionViewModels[0]
            if (userSubscriptionViewModel?.deviceOption === "purchase") {
                const today = new Date();
                const endDate = new Date(userSubscriptionViewModel?.termEndDate);
                setIsWithinTermEndDate(today <= endDate)
                if(today <= endDate){
                    setMinimumTermEndDate(userSubscriptionViewModel?.termEndDate)
                    setLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE, userSubscriptionViewModel?.termEndDate)
                }
                //return today <= endDate;
            } else {
                setIsWithinTermEndDate(false)
            }
        }
    },[userSubscriptionResult])
    

    return (
        <div>
          {
                deviceInfoResponse.loader ? 
                (
                    <div className="enrolled-device-outer-wrapper">
                        <div className= "enrolled-device-wrapper skeleton-loader-wrapper">
                            <div className="content">
                                <div className="row">
                                    <div className="col-2">
                                        <Skeleton className="square-skeleton" />
                                    </div>
                                    <div className="col-8 order-status-wrapper">
                                        <Skeleton count={2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <EnrolledDevice
                    deviceImagePath = {subscriptionInfo?.imagePath}
                    deviceModel = {subscriptionInfo?.model || 'NA'}
                    deviceSerialNumber = {subscriptionInfo?.serialNumber || 'NA'}
                    subscriptionDateText = { isWithinTermEndDate ? t('MINIMUM_TERM_PERIOD.MINIMUM_TERM_END_DATE_DASHBOARD'):t('DASHBOARD.ENROLLED_DEVICE_SECTION.START_DATE')}
                    subscriptionDate = {isWithinTermEndDate ? minimumTermEndDate : activatedDate}
                    deviceStatus= {EnrollmentStatusResult.enrollment_completed}
                    networkStatus={networkStatus}
                    planName = {planName}
                    printerType = {printerType}
                    planPages = {planPages}
                    />
                )
            }  
        </div>
    );
};

export default EcoproEnrolledDevice;