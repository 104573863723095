import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger'
import reducer from '../reducers';
import saga from '../sagas'

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) => {
  if (process.env.NODE_ENV === 'development') {
    return getDefaultMiddleware({thunk: false}).concat(sagaMiddleware, logger)
  } else{
    return getDefaultMiddleware({thunk: false}).concat(sagaMiddleware)
  }
}

const store = configureStore({
  reducer,
  middleware
});

sagaMiddleware.run(saga);

export default store;