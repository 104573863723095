import React, { useEffect } from 'react';
import EcoProButton from '../../shared/button/button';
import './DisplayUpdateAccountConfirmation.css'
import { scrollToTop } from '../../../constants/AppMethod';

const DisplayUpdateAccountConfirmation = (props) => {
    const {
        updateAddressHeader,
        updateAddressInfo,
        subInfo,
        updateAddressBtn,
        onUpdateClick,
        onRejectClick,
        noThanksStatus,
        btnStatus,
        image,
        onUpdateBtnID,
        onRejectBtnID,
        ecoproCancellation_with_brotherplus_template,
        cname = 'comp-class-none'
    } = props

    useEffect(() => {
        /** This was implemented to avoid page auto scrolling down in some browser scenarios eg:Firefox*/
        scrollToTop('.updateAddress');
    }, [])
    return (
        /**
         * Modifying the classname 'updateAddress' should be updated in 'scrollToTop' method in useEffect
         */
        <div className={`updateAddress ${cname}`}>
            <div className='updateAddress-content'>
                {image?
                    <div className='updateAddress-img'>
                        <img src={image} alt="update-account"/>
                    </div>
                    :
                    <div className='updateAddress-no-img'></div>
                }
                <h1 className='f-18 fc-e fw-b mt-30'>{updateAddressHeader}</h1>
                <div className='f-12 fc-g mt-10'>{updateAddressInfo}</div>
            </div>
            {
                (ecoproCancellation_with_brotherplus_template)
                ? <>{ecoproCancellation_with_brotherplus_template}</>
                : null
            }
            {subInfo?
               <div className="f-12 fc-g mt-10">{subInfo}</div>
               :null
            } 
            <div className={`updateAddress-btn ${noThanksStatus?null:'updateAddress-btn-margin'}`}>
                <EcoProButton cname='default btn-f-14' btnID={onUpdateBtnID} onBtnClick={onUpdateClick} disabled={btnStatus === 'disabled'? true:false}>{updateAddressBtn}</EcoProButton>
            </div>
            {noThanksStatus?
                <div className='updateAddress-nothanks fc-e' onClick={onRejectClick} id={onRejectBtnID}>
                    <span className='pointer-cursor'>{noThanksStatus}</span>
                </div>
                :null
            }
        </div>
    );
};

export default DisplayUpdateAccountConfirmation;