import { LOCALSESSIONDATAKEYS } from "./AppConstants";

const countryCode = localStorage.getItem(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE);
let baseURL = (window.env.REACT_APP_API_BASE_URL) ? window.env.REACT_APP_API_BASE_URL : "https://ecopro.brother.eu.local:8001/api/v1/";
let environment = ( window.env.REACT_APP_ENVIRONMENT_NAME) ?  window.env.REACT_APP_ENVIRONMENT_NAME : "Production";
let bolBaseUrl = window.env[`BOL_BASEURL_${countryCode}`];
let mainBaseURL = window.env[`MAIN_BASEURL_${countryCode}`];
let brotherStoreBaseURL = window.env[`BROTHER_STORE_EASYBUY_BASKET_BASEURL_${countryCode}`];

export const ENVSettings = {
    API_BASE_URL: baseURL,
    APP_ENVIRONMENT: environment,
    AZURE_MSAL_B2C_POLICIES: window.env.AZURE_MSAL_CONFIG.B2CPOLICIES,
    AZURE_MSAL_CLIENT_ID: window.env.AZURE_MSAL_CONFIG.CLIENT_ID,
    AZURE_MSAL_REDIRECT_URI: window.env.AZURE_MSAL_CONFIG.REDIRECT_URI,
    AZURE_MSAL_SCOPE: window.env.AZURE_MSAL_CONFIG.LOGIN_REQUEST_SCOPE,
    BOL:bolBaseUrl,
    MAIN_BASE_URL: mainBaseURL,
    GTM_TRACKING_ID: window.env.GTM_TRACKING_ID,
    COUNTRIES:window.env.COUNTRIES,
    AZURE_TENANT_NAME: window.env.AZURE_MSAL_CONFIG.AZURE_ADB2C_TENANT_NAME,
    BOL_API_BASE_REDIRECT_URL: window.env.BOL_API_BASE_REDIRECT_URL,
    ADMIN_PORTAL_ADMIN_ROLE_VALID_EMAIL_DOMAINS: window.env.ADMIN_PORTAL_ADMIN_ROLE_VALID_EMAIL_DOMAINS,
    BROTHER_STORE_BASE_URL : brotherStoreBaseURL
  };


export const EXTERNAL_LINK_ENDPOINT = {
    footer_privacyPolicy : `about-brother/privacy-policy`,
    footer_termsOfUse : `about-brother/terms-and-conditions`,
    footer_de_at_privacyPolicy:`unternehmen/datenschutzrichtlinie`,
    footer_de_at_termsOfUse:`unternehmen/nutzungsbedingungen`,
    footer_it_privacyPolicy: "privacy-policy",
    footer_it_termsOfUse : "terms-and-conditions",
    signup_privacyPolicy: `privacy-policy`,
    signin_updatePersonalDetails: `my-account`,
    mobileConnectApp:"brother.mobileconnect://login",
    androidMobileConnectApp:"brother.mobileconnect://login/ews/fwupdate",
    iosMobileConnectApp:"brother.shp.v1://open/ews/fwupdate",
    marketingPage:"ecopro",
    FrenchMarketingPage:"fr-BE/ecopro",
    googlePlayStoreAppLink:"https://play.google.com/store/apps/details?id=com.brother.mfc.mobileconnect",
    appleAppStoreLink:"https://apps.apple.com/gb/app/brother-mobile-connect/id1516235668"
}