import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { getPlanTranslation, getPrinterTypeTranslation } from "../../../constants/AppMethod";
import { capitalizeFirstLetter, DisplayDate } from "../../../utils/DisplayDate";
import BonusPagesUsedLabelIcon from "../../../assets/images/print-activity-bonus-color-label-icon.svg";
import UnunsedBonusPagesLabelIcon from "../../../assets/images/print-activity-unused-bonus-color-label-icon.svg"
import PagesUsedLableIcon from "../../../assets/images/print-activity-pages-used-label-icon.svg";
import MonthlyAllowanceLableIcon from "../../../assets/images/print-activity-monthly-allowance-icon.svg";
import RolloverLabelIcon from "../../../assets/images/print-activity-rollover.svg";
import OverageLabelIcon from "../../../assets/images/print-activity-overage.svg"
import { getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";
import "./PagesUsed.css";
import HalfDoughnutChart from "../HalfDoughnut";
import { featureSwitchName } from "../../../constants/AppConstants";
import { getFeatureSwitchByKey } from "../../../utils/Authentication/handleUserSession";

const PagesUsed = (props) => {
    const {
        planName = null,
        planPages = 0,
        printedPlanPages = 0,
        printedTotalPages= 0,
        printedRolloverPages= 0,
        givenRolloverPages= 0,
        printedOveragePages= 0,
        purchasedOveragePages= 0,
        printerType=null,
        printedBonusPages = 0,
        dateDropdownArr = [],
        dateFilter,
        IS_CSAGENT,
        isChildComp
    } = props;
    const pageTranslation = (IS_CSAGENT) ? "CSAgenttranslation" : "translation";
    const { t } = useTranslation([pageTranslation]);
    const langcode = (IS_CSAGENT) ? 'en' : getLangCode();
    const dateDisplayOptions = { month: "long", year: "numeric"};
    const isBonusPageFeatureSwitchEnabled = getFeatureSwitchByKey(featureSwitchName.BONUS_PAGES)
    return (
        <Container className="pages-used-outer-wrapper mt-15">
            <div className="pages-used-inner-wrapper">
                {
                    (!isChildComp) ? 
                        <div className="subscription-month-dropdown-wrapper">
                            <select className="subscription-month-dropdown" onChange={({ target: { value } }) => dateFilter(value)}>
                                {
                                    dateDropdownArr && dateDropdownArr.length > 0 && dateDropdownArr.map((date) => (
                                        <option value={date} key={date}>{capitalizeFirstLetter(DisplayDate(date, dateDisplayOptions, langcode))}</option>
                                    ))
                                }
                            </select>
                        </div> 
                    : null
                }
                <div>
                    <div className="pages-used-data-representation-wrapper">
                            <HalfDoughnutChart
                                printedTotalPages = {printedTotalPages}
                                pagesUsed = {printedPlanPages}
                                rolloverAllowance = {printedRolloverPages}
                                overage = {printedOveragePages}
                                planPages = {planPages}
                                givenRolloverPages = {givenRolloverPages}
                                purchasedOveragePages = {purchasedOveragePages}
                                printedBonusPages={printedBonusPages}
                                IS_CSAGENT = {IS_CSAGENT}
                                isBonusPageFeatureSwitchEnabled = {isBonusPageFeatureSwitchEnabled}
                            ></HalfDoughnutChart>
                    </div>
                    <div className="pages-used-progressbar-labels-wrapper caption">
                        {isBonusPageFeatureSwitchEnabled ? 
                        <>
                        <div className="pages-used-label label">
                            <img src={BonusPagesUsedLabelIcon} alt="pages-used" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.BONUS_PAGES_USED')}</p>
                        </div>
                        <div className="pages-used-label label">
                            <img src={UnunsedBonusPagesLabelIcon} alt="pages-used" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.UNUSED_BONUS')}</p>
                        </div>
                        </>
                        :
                        null}
                        <div className="pages-used-label label">
                            <img src={PagesUsedLableIcon} alt="pages-used" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED')}</p>
                        </div>
                        <div className="monthly-allowance-label label">
                            <img src={MonthlyAllowanceLableIcon} alt="monthly-allowance" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.MONTHLY_ALLOWANCE')}</p>
                        </div>
                        <div className="pages-used-label label">
                            <img src={RolloverLabelIcon} alt="roll-over" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.ROLLOVER')}</p>
                        </div>
                        <div className="monthly-allowance-label label">
                            <img src={OverageLabelIcon} alt="monthly-allowance" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.OVERAGE')}</p>
                        </div>
                    </div>
                    <div className="pages-used-plan-details-wrapper body-medium">
                        <p>{t('PRINT_ACTIVITY.PLAN_TYPE')}</p>
                        {
                            (IS_CSAGENT) 
                            ? <p className="print-activity-plan-name">{t('PRINT_ACTIVITY.PLAN_NAME', { plan_name: t(`PLAN_NAMES.${planName}`), printer_type: t(`PRINTER_TYPES.${printerType?.toUpperCase().split(" ").join("_")}`), plan_pages: planPages })}</p> 
                            : <p className="print-activiyt-plan-name">{t('PRINT_ACTIVITY.PLAN_NAME', { plan_name: getPlanTranslation(planName), printer_type: getPrinterTypeTranslation(printerType), plan_pages: planPages })}</p> 
                        }
                    </div>
                </div>
            </div>
        
        </Container>
    )
}

export default PagesUsed;