import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    enrollment_completed: false,
    enrollment_completed_BrotherPlus: false
  }

const EnrollmentStatus = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENROLLMENT_STATUS_CHECK:
            return {
                ...state,
                enrollment_completed: action.enrollmentStatus
            }
        case actionTypes.ENROLLMENT_STATUS_CHECK_BROTHER_PLUS:
            return {
                ...state,
                enrollment_completed_BrotherPlus: action.enrollmentStatus
            }
            default:
            return {...state}
    }
}

export default EnrollmentStatus