/**
 * This file contributes to maintain the user roles of the application
 * New roles added into the app should be configured here
 */
import i18next from "i18next";

const ACTIVE = 'ACTIVE'; 
const ENROLLMENT_SUCCESSFUL = 'ENROLLMENTSUCCESSFUL';

/**
 * File that maps user roles
 * Redirection is proceeded  in this order
 */
export const userRoles = {
    ADMIN: "Admin", //priority I
    CSAGENT_ADMIN: "CSAgentAdmin", //priority II
    CSAGENT: "CSAgent", //priority III
    CSAGENT_VIEWER: "CSAgentViewer", //priority IV
    CUSTOMER: "Customer" //priority V
}
const ALL_ACCESS_ROLES = [userRoles.ADMIN];

const ADMINISTRATOR_ROLES = [
    ...ALL_ACCESS_ROLES, 
    userRoles.CSAGENT_ADMIN, 
    userRoles.CSAGENT, 
    userRoles.CSAGENT_VIEWER
];

const SDTICKET_ACCESS_ROLES = [
    ...ALL_ACCESS_ROLES, 
    userRoles.CSAGENT_ADMIN
];

const SWAPDEVICE_ORDERSUPPLIES_ACCESS_ROLES = [
    ...ALL_ACCESS_ROLES, 
    userRoles.CSAGENT_ADMIN, 
    userRoles.CSAGENT
];

const CANCELSUBSCRIPTION_ACCESS_ROLES = [
    ...ALL_ACCESS_ROLES, 
    userRoles.CSAGENT_ADMIN
];
/**
 * This mapping maintains administartor roles other than customer
 */
export const AdminUsersRoleMapping = {
    Admin: "Admin",
    CSAgentAdmin: "CSAgentAdmin",
    CSAgent: "CSAgent",
    CSAgentViewer: "CSAgentViewer",
}

/**
 * This method validates whether all the roles are selected
 * @param {object} roleFormValues 
 * @returns boolean
 */
export const noUserRolesSelected = (roleFormValues) => {
    return Object.values(AdminUsersRoleMapping).every(key => roleFormValues[key] === false);
}

/**
 * This method maintains the administrator roles config
 * @returns array
 */
export const adminUserRoles_CONFIG = () => {
    return [
        {
            name: userRoles.ADMIN,
            label: i18next.t('Admintranslation:MANAGE_ADMIN_USERS.FORM_LABELS.ADMIN'),
            isChecked: false
        },
        {
            name: userRoles.CSAGENT,
            label: i18next.t('Admintranslation:MANAGE_ADMIN_USERS.FORM_LABELS.CSAGENT'),
            isChecked: false
        },
        {
            name: userRoles.CSAGENT_ADMIN,
            label: i18next.t('Admintranslation:MANAGE_ADMIN_USERS.FORM_LABELS.CSAGENT_ADMIN'),
            isChecked: false
        },
        {
            name: userRoles.CSAGENT_VIEWER,
            label: i18next.t('Admintranslation:MANAGE_ADMIN_USERS.FORM_LABELS.CSAGENT_VIEWER'),
            isChecked: false
        },
    ];
}
const validateAccess = (accessRoles, loggedInUserRoles) => {
    return accessRoles.some(role => loggedInUserRoles.includes(role));
}
/**
 * Validate current logged in userRole
 * @returns object
 */
export const ValidateRoles = (loggedInUserRoles) => {
    const isCSAgentRoles = isAdministratorRole(loggedInUserRoles);
    const loggedInUserRole = {
        'isAdmin': loggedInUserRoles.includes(userRoles.ADMIN) || false, 
        'isCSAgent': isCSAgentRoles || false, 
        'isCustomer': loggedInUserRoles.includes(userRoles.CUSTOMER) || false
    };
    return loggedInUserRole;
}

export const isAdministratorRole = (loggedInUserRoles) => {
    return validateAccess(ADMINISTRATOR_ROLES, loggedInUserRoles);
}

/**
 * Method to handle the access for creating SD ticket from CSAgent Dashboard.
 * @returns boolean
 */
export const canAccess_SDTicketButton = (loggedInUserRoles) => {
    return validateAccess(SDTICKET_ACCESS_ROLES, loggedInUserRoles);
}
/**
 * Method to validate the access for enable swap and order supplies button
 * @returns boolean
 */
export const canAccess_SwapDevice_OrderSupplies = (CustomerSubscriptionStatus, loggedInUserRoles) => {
    const isAccessValid = validateAccess(SWAPDEVICE_ORDERSUPPLIES_ACCESS_ROLES, loggedInUserRoles);
    return ( CustomerSubscriptionStatus === ACTIVE && isAccessValid ) ? true : false;
}

/**
 * Method to handle the access for cancel subscription from CSAgent dashbaord.
 * @param {string} enrollmentStatus 
 * @returns boolean
 */
export const canAccess_CancelSubscription = (enrollmentStatus, loggedInUserRoles) => {
    const isAccessValid = validateAccess(CANCELSUBSCRIPTION_ACCESS_ROLES, loggedInUserRoles);
    return ( enrollmentStatus?.toUpperCase() === ENROLLMENT_SUCCESSFUL && isAccessValid ) ? true : false;
}