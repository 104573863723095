import React, { useEffect, useState } from 'react';
import printerIcon from "../../../../assets/images/connectdevice-printericon.svg";
import EcoProButton from '../../../shared/button/button';
import { 
        GetBOCWebDeviceCapability, 
        GetDeviceRegistration, 
        ResetCodeSubmissionState, 
        ResetOneTimeCodeError 
    } from '../../../../actions/Web/Customer/SignUp';
import { useDispatch, useSelector } from 'react-redux';
import { 
        WEB_handleLocalData, 
        WEB_handleSelectedPlanLocalData, 
        getLocalSessionData, 
        getUserData, 
        getUserDataByKey, 
        getUserSessionData 
    } from '../../../../utils/Authentication/handleUserSession';
import { 
        BOC_WEB_DEVICE_CAPABILITY_CODES, 
        CONTACT_SUPPORT, 
        ECOPROTOKEN_CLAIMS, 
        LOCALSESSIONDATAKEYS, 
        UNIQUE_ID_GA, 
        alphaNumericRegex 
    } from '../../../../constants/AppConstants';
import Loader from '../../../shared/loader/Loader';
import ErrorPage from '../../../shared/errorPage/ErrorPage';
import { FetchUserAccount } from '../../../../actions/UserAccount';
import { Enrollment } from '../../../../actions/MyAccount';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routePath } from '../../../../constants/Routes';
import { Trans, useTranslation } from 'react-i18next';
import { 
        handleNavigateToAppStore, 
        handleSignUpType, 
        handleSubscriptionOrderPayload, 
        Is_BrotherPlus_Signup, 
        pushDataLayer 
        } from '../../../../constants/AppMethod';
import { extractLangcodeFromDomain, getCountryCode, getLangCode } from '../../../../utils/Internationalization/handleAppInternationalization';
import { ctrylangCombinations, languageCodes } from '../../../../constants/Country';
import DeviceConnectedSuccessfullyImage from "../../../../assets/images/account-steps-completed-tick.svg"
import { DeviceNotConnected } from '../../../../pages/Web/Customer/Account/SignUp/Dashboard/ConnectDeviceDashboard';
import deviceOffline from '../../../../assets/images/printer-offline.svg';
import DisplayUpdateAccountConfirmation from '../../../EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import UseAndroidIosAppIcons from '../../../../utils/CustomHooks/UseAndroidIosAppIcons';
import { EnrollmentRegisterServiceInit } from '../../../../store/brotherPlus/EnrollmentService/RegisterService/RegisterServiceSlice';

const WebConnection = (props) => {
    const {header, subcontent, instruction1, instruction2,
         instruction3, enterCodeLabel, codeSubmitText, cname} = props
    
    const dispatch = useDispatch()
    const {t} = useTranslation(["translation"]);
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const AZURE_AD_B2C_UID = getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID);

    const [oneTimeCode, setOneTimeCode] = useState("")
    const [showSuccessContent, setShowSuccessContent] = useState(false);
    const [showUnsupportedFirmware, setShowUnsupportedFirmware] = useState(false)
    const [showDeviceOffline, setShowDeviceOffline] = useState(false)
    const DeviceRegistrationResult = useSelector(state =>state.GetDeviceRegistration)
    const BOCDeviceCapabilityResult = useSelector(state => state.GetBOCWebDeviceCapability)
    const zrenderParams =  useSelector(state => state.ZuoraPaymentInfoRendering);
    const ScmsTokenResult = useSelector(state => state.SCMSTokenAuthentication)
    const UserAccount = useSelector((state) => state.UserAccount);
    const EnrollmentResponse =  useSelector(state => state.Enrollment);
    const BrotherPlus_RegisterServiceResp = useSelector(state => state.BROTHERPLUS.RegisterService);
    const history = useHistory();
    const langCode = getLangCode();
    const appIconsImgSrc = UseAndroidIosAppIcons(langCode);
    const SignupType = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();


    const handleCodeSubmit = () =>{
        pushDataLayer("web_BOC_enrollment", "printer_code_button", "submit");
        dispatch(ResetCodeSubmissionState())
        dispatch(GetDeviceRegistration(UID, oneTimeCode))
    }

    const handleContactUs = () => {
        let countryCodewithLang = getLangCode();
        let countryCode = getCountryCode();
        countryCode = countryCode === "GB" ? "uk" : countryCode
        let countryCodeWithDefaultLang = languageCodes[countryCode?.toLowerCase()]
        const webSignupLangCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE);

        if(webSignupLangCode){
            const ctryCodeExtracted = extractLangcodeFromDomain();
            /**
             * Country BE & CH have two language combinations, so considered only these two countries.
             */
            if((ctryCodeExtracted.toUpperCase() === "BE" || ctryCodeExtracted.toUpperCase() === "CH") && ctrylangCombinations[ctryCodeExtracted.toUpperCase()].indexOf(webSignupLangCode) > -1){
                countryCodewithLang = countryCodeWithDefaultLang = webSignupLangCode ;
            }
        }
        let link ;

        if(countryCodewithLang){link = CONTACT_SUPPORT[countryCodewithLang]}
        else{link =  CONTACT_SUPPORT[countryCodeWithDefaultLang]}

        return link
    }
    const contactUsLink = handleContactUs()

    const handleOneTimeCode = (e) => {
        if(DeviceRegistrationResult.error || BOCDeviceCapabilityResult.error){
            dispatch(ResetOneTimeCodeError())
        }
        const {value} = e.target
        if (value === "" || alphaNumericRegex.test(value)) {
            setOneTimeCode(e.target.value)
        }
    }
    const recheckNetworkStatus = () => {
        setShowDeviceOffline(false)
        const deviceId = DeviceRegistrationResult?.data?.deviceId
        const modelName = DeviceRegistrationResult?.data?.modelName
        dispatch(GetBOCWebDeviceCapability(UID, deviceId, modelName));
    }

    useEffect(()=>{
        if(BOCDeviceCapabilityResult.success && DeviceRegistrationResult.success){

            if(BOCDeviceCapabilityResult.bocDeviceCapability.customCode === BOC_WEB_DEVICE_CAPABILITY_CODES.UNSUPPORTED_FIRMWARE_VERSION.customeCode){
                setShowUnsupportedFirmware(true)
            }
            else if(BOCDeviceCapabilityResult.bocDeviceCapability.customCode === BOC_WEB_DEVICE_CAPABILITY_CODES.DEVICE_OFFLINE.customeCode){
                setShowDeviceOffline(true)
            }
            else{
                const deviceId = DeviceRegistrationResult?.data?.deviceId
                dispatch(FetchUserAccount(AZURE_AD_B2C_UID, UID, deviceId, handleSignUpType(SignupType)));    
            }
        }
    },[dispatch, BOCDeviceCapabilityResult.success, DeviceRegistrationResult.success, DeviceRegistrationResult?.data?.deviceId, UID, AZURE_AD_B2C_UID, BOCDeviceCapabilityResult?.bocDeviceCapability?.customCode, SignupType])

    useEffect(()=>{
        if(UserAccount.success && BOCDeviceCapabilityResult.success){
            if(IsBrotherPlus_Signup){
                dispatch(EnrollmentRegisterServiceInit());
            } else {
                let offers = getUserDataByKey("marketingEmails");
                const userData = getUserData()
                const shipping_address_from_managesubscription = WEB_handleLocalData('Get', LOCALSESSIONDATAKEYS.WEB_SHIPPING_ADDRESS)
                let shipping_address;
                if(userData?.shipping_address?.addressLine1){
                    shipping_address = {
                        address: getUserDataByKey("addressLine1", 'shipping_address'),
                        addressLine2: getUserDataByKey("addressLine2", 'shipping_address'),
                        city: getUserDataByKey("cityTown", 'shipping_address'),
                        postcode:getUserDataByKey("postCode", 'shipping_address'),
                        country: getUserDataByKey("country", 'shipping_address'),
                        company: getUserDataByKey("companyName", 'shipping_address'),
                        province: getUserDataByKey("state", 'shipping_address')
                    }
                }
                else if(shipping_address_from_managesubscription?.street1){
                    shipping_address = {
                        address:    shipping_address_from_managesubscription?.street1,
                        addressLine2: shipping_address_from_managesubscription?.street2,
                        city: shipping_address_from_managesubscription?.city,
                        postcode:shipping_address_from_managesubscription?.postalCode,
                        country: shipping_address_from_managesubscription?.country,
                        company: shipping_address_from_managesubscription?.companyName,
                        province: shipping_address_from_managesubscription?.state
                    }
                }
                else{
                    shipping_address = {
                        address: getUserDataByKey("addressLine1", 'billing_address'),
                        addressLine2: getUserDataByKey("addressLine2", 'billing_address'),
                        city: getUserDataByKey("cityTown", 'billing_address'),
                        postcode: getUserDataByKey("postCode", 'billing_address'),
                        country: getUserDataByKey("country", 'billing_address'),
                        company: getUserDataByKey("companyName", 'billing_address'),
                        province: getUserDataByKey("state", 'billing_address')
                    }
                }

                offers = (offers === "true") ? true : false;
                const  payloadInfo = {
                    accountDetails: {
                        personalDetails: {
                            firstName: getUserDataByKey("firstName", 'personal_details'),
                            lastName: getUserDataByKey("lastName", 'personal_details'),
                            emailAddress: getUserDataByKey("emailAddress", 'personal_details'),
                            offers: offers,
                            telephoneNumber: getUserDataByKey("telephoneNumber", 'personal_details'),
                            mobileNumber: getUserDataByKey("mobileNumber", 'personal_details')
                        }, 
                        shippingAddress: { ...shipping_address },
                        billingAddress:{
                            address: getUserDataByKey("addressLine1", 'billing_address'),
                            addressLine2: getUserDataByKey("addressLine2", 'billing_address'),
                            city: getUserDataByKey("cityTown", 'billing_address'),
                            postcode: getUserDataByKey("postCode", 'billing_address'),
                            country: getUserDataByKey("country", 'billing_address'),
                            company: getUserDataByKey("companyName", 'billing_address'),
                            province: getUserDataByKey("state", 'billing_address')
                        }
                    },
                    billingInfo: zrenderParams.zrenderParams,
                    selectedplan: WEB_handleSelectedPlanLocalData("Get")
                }
                const handlePayload = async (payloadInfo) => {
                const payload = await handleSubscriptionOrderPayload(payloadInfo);
                    dispatch(Enrollment(payload));
                }
                handlePayload(payloadInfo);
            }
        }
    },[dispatch, UserAccount.success, zrenderParams.zrenderParams, BOCDeviceCapabilityResult.success, IsBrotherPlus_Signup])

    useEffect(() => {
        if (EnrollmentResponse.success || BrotherPlus_RegisterServiceResp.success) {
            pushDataLayer(`${(IsBrotherPlus_Signup) ? 'brother-plus': 'web-signup'}`, 'status', 'success');
            
            // Show success content for 3 seconds
            setShowSuccessContent(true);
            
            // Set a timer to hide the success content after 3 seconds
            const timer = setTimeout(() => {
              setShowSuccessContent(false);
              history.push(routePath.DASHBOARD);
            }, 3000);
        
            // Clear the timer if the component unmounts or if the dependency array changes
            return () => clearTimeout(timer);
          }
    },[dispatch, EnrollmentResponse.success, history, BrotherPlus_RegisterServiceResp.success, IsBrotherPlus_Signup])


    if(ScmsTokenResult.error ) {
        return <ErrorPage errorInfo={ScmsTokenResult.error} />
    }
          return (
       
            UserAccount.error ?  
                <div className={`device-not-connected-wrapper ${cname}`}>
                    <ErrorPage errorInfo={UserAccount.error} webConnectionDashboardError={true}/>
                </div>
            :
            EnrollmentResponse.error ? 
                <div className={`device-not-connected-wrapper ${cname}`}>
                    <ErrorPage errorInfo={EnrollmentResponse.error} webConnectionDashboardError={true} />
                </div>
            :
            BrotherPlus_RegisterServiceResp.error ? 
            <div className={`device-not-connected-wrapper ${cname}`}>
                <ErrorPage errorInfo={BrotherPlus_RegisterServiceResp.error} webConnectionDashboardError={true} />
            </div>
            :
            showUnsupportedFirmware ?
                <DeviceNotConnected
                    header={t("DEVICE_STATUS_CHECK.FIRMWARE_STATUS_INFO")}
                    content={t('DASHBOARD_CONNECT_DEVICE.DEVICE_NOT_CONNECTED_CONTENT')}
                    btnText1={<img alt="andriod-app" src={appIconsImgSrc.androidAppIcon} role="button" height="50px"/>}
                    btnText2={<img alt="ios-app" src={appIconsImgSrc.IOSAppIcon} role="button" height="40px"/>}
                    handleConnectDevice={(e)=>handleNavigateToAppStore(e)}
                    cname="download-app-section web-connect"
                ></DeviceNotConnected>
                :
            showDeviceOffline ? 
                <div className='web-connection-device-offline'>
                    <DisplayUpdateAccountConfirmation
                        updateAddressHeader={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_HEADER")}
                        updateAddressInfo={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_INFO")}
                        updateAddressBtn={t("ECOPRO_BTNS.RETRY")}
                        onUpdateClick = {()=>recheckNetworkStatus()}
                        image={deviceOffline}
                    ></DisplayUpdateAccountConfirmation>
                </div>
            :
            <>
            <div className={`device-not-connected-wrapper show-xs ${cname}`}>
                {(
                    ScmsTokenResult.loading 
                    || DeviceRegistrationResult.loading 
                    || BOCDeviceCapabilityResult.loading 
                    || UserAccount.loading 
                    || EnrollmentResponse.loading
                    || BrotherPlus_RegisterServiceResp.loading
                ) &&
                <div className={`loader-overlay-wrapper`}>
                    <Loader slowResponse={true} webConnection={true} setOverlay={true}></Loader>
                 </div>
                }
                {
                    showSuccessContent && 
                    <div className='spinner-wrapper apply-overlay-bg device-successfully-connected'>
                        <img src={DeviceConnectedSuccessfullyImage} alt="device-successfully-connected"></img>
                        <div>{t("WEB_CONNECTION.CONNECTION_SUCCESS")}</div>
                    </div>
                }
                        <div className="printer-img-wrapper">
                            <div className="printer-icon">
                                <img
                                    className="printer-icon-img"
                                    src={printerIcon}
                                    alt="device"
                                />
                            </div>
                        </div>
                        <div className="content">
                        <h5>{header}</h5>
                        <p>{subcontent}</p>
                        <div className="web-connection-instruction">
                            <div>{instruction1}</div>
                            <div>{instruction2}</div>
                            <div>{instruction3}</div>
                        </div>
                        
                        <div className="enter-code-wrapper">
                            <div className="enter-code-container">
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => {handleOneTimeCode(e)}} value={oneTimeCode} placeholder={enterCodeLabel}/>
                                <div className="device-not-connected-btn">
                                    <EcoProButton cname="default" onBtnClick={(e) => handleCodeSubmit(e)} disabled={!oneTimeCode}>{codeSubmitText}</EcoProButton>
                                </div>
                            </div>
                        </div>
                        <div className={`invalid-feedback ${(DeviceRegistrationResult.error || BOCDeviceCapabilityResult.error) ? "display-error"  : null }`}>{t("WEB_CONNECTION.ONE_TIME_CODE_ERROR")}</div>
                        <p className="support-content">
                            <Trans
                                i18nKey="WEB_CONNECTION.CONTACT_SUPPORT_TEAM"
                            >
                                If you still can't connect your device, get in touch with our 
                                <a href={contactUsLink} target="_blank"
                                    rel="noreferrer"
                                    id={UNIQUE_ID_GA.signup + "-contact-support-team"}>support team</a>.
                            </Trans>                    
                        </p>
                    </div>
            </div>
            <div className="device-not-connected-outer-wrapper show-lg">
                <div className={`device-not-connected-wrapper ${cname}`}>
                    <div className="printer-img-wrapper">
                    <div className="printer-icon">
                        <img
                            className="printer-icon-img"
                            src={printerIcon}
                            alt="device"
                        />
                    </div>
                    </div>
                    <div className="content">
                        {(
                            ScmsTokenResult.loading 
                            || DeviceRegistrationResult.loading 
                            || BOCDeviceCapabilityResult.loading 
                            || UserAccount.loading 
                            || EnrollmentResponse.loading
                            || BrotherPlus_RegisterServiceResp.loading
                        ) &&
                        <div className={`loader-overlay-wrapper`}>
                            <Loader slowResponse={true} webConnection={true} setOverlay={true}></Loader>
                        </div>
                        }
                        {
                            showSuccessContent && 
                            <div className='spinner-wrapper apply-overlay-bg device-successfully-connected'>
                                <img src={DeviceConnectedSuccessfullyImage} alt="device-successfully-connected"></img>
                                <div>{t("WEB_CONNECTION.CONNECTION_SUCCESS")}</div>
                            </div>
                        }
                        <h5>{header}</h5>
                        <p>{subcontent}</p>
                        <div className="web-connection-instruction">
                            <div>{instruction1}</div>
                            <div>{instruction2}</div>
                            <div>{instruction3}</div>
                        </div>
                        <div className="enter-code-wrapper">
                            <div className="enter-code-container">
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => {handleOneTimeCode(e)}} value={oneTimeCode} placeholder={enterCodeLabel}/>
                                <div className="device-not-connected-btn">
                                    <EcoProButton cname="default" onBtnClick={(e) => handleCodeSubmit(e)} disabled={!oneTimeCode}>{codeSubmitText}</EcoProButton>
                                </div>
                            </div>
                        </div>
                        <div className={`invalid-feedback ${(DeviceRegistrationResult.error || BOCDeviceCapabilityResult.error) ? "display-error"  : null }`}>{t("WEB_CONNECTION.ONE_TIME_CODE_ERROR")}</div>
                        <p className="support-content">
                            <Trans
                                i18nKey="WEB_CONNECTION.CONTACT_SUPPORT_TEAM"
                            >
                                If you still can't connect your device, get in touch with our 
                                <a href={contactUsLink} target="_blank"
                                rel="noreferrer"
                                id={UNIQUE_ID_GA.signup + "-contact-support-team"}>support team</a>.
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
            </>
        
    );
};

export default WebConnection;