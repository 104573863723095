import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../../../../shared/loader/Loader";
import {Nav, NavItem, NavLink, TabContent,TabPane} from 'reactstrap';
import Overview from "../Overview/Overview";
import EcoproAlert from "../../../../shared/alert/alert";
import AlertDanger from '../../../../../assets/images/alert-danger.svg';
const DeviceAndDeviceUsageDetails = (props) => {
    const { t } = useTranslation(["CSAgenttranslation"]);
    const TABS = {
        'ACTIVE_DEVICES': 'ACTIVE_DEVICES',
        "INACTIVE_DEVICES": 'INACTIVE_DEVICES'
    }
    const [activeTab, setActiveTab] = useState(TABS.ACTIVE_DEVICES);

 
    const DeviceAndDeviceUsageDetails = useSelector((state) => state.CSAGENT.DeviceAndDeviceUsageDetails);
    const SubscriptionInfo = useSelector(state => state.CSAGENT.SubscriptionDetails);

    if (DeviceAndDeviceUsageDetails.loading || SubscriptionInfo.loading) {
        return <Loader />;
    }
    if (DeviceAndDeviceUsageDetails.error) {
        return <div className="email-log-error">{t("ERROR_MESSAGES.DEVICE_NOT_FOUND")}</div>;
    }
    return (
        <div className="device-info-wrapper mt-40">
            {(SubscriptionInfo.error) 
                ? <EcoproAlert 
                alertImg = {AlertDanger} 
                alertSubHeader={t("ERROR_MESSAGES.SUBSCRIPTION_INFO_NOT_FOUND")}
                alertClass = 'mt-20'
                /> 
                : null
            }
            <div className="tab-btn-wrapper">
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink active={activeTab === TABS.ACTIVE_DEVICES} onClick={() => setActiveTab(TABS.ACTIVE_DEVICES)}>
                                {t('CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.ACTIVE_DEVICES')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === TABS.INACTIVE_DEVICES} onClick={() => setActiveTab(TABS.INACTIVE_DEVICES)}>
                                {t('CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.INACTIVE_DEVICES')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
            <div className="device-info-tab-contens">
                <TabContent activeTab={activeTab}>
                     <TabPane tabId={TABS.ACTIVE_DEVICES}>
                       { (DeviceAndDeviceUsageDetails?.deviceInfo?.activeDevice) 
                            ? <Overview {...{...props?.customerInfo, ...DeviceAndDeviceUsageDetails, ...SubscriptionInfo}}/>
                            : <div className="email-log-error">{t("CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.NO_ACTIVE_DEVICES")}</div>
                       }
                     </TabPane>
                     <TabPane tabId={TABS.INACTIVE_DEVICES}>
                        <div className="device-usage-details">
                            {(DeviceAndDeviceUsageDetails?.deviceInfo?.inactiveDevices?.length > 0) ? (
                            <div className="inactive-devices">
                                {DeviceAndDeviceUsageDetails?.deviceInfo?.inactiveDevices.map((device, index) => (
                                    <div key={index}>
                                        <p>{t("CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.DEVICE_ID")}: <span>{device.deviceId}</span></p>
                                        <p>{t("CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.SERIAL_NUMBER")}: <span>{device.serialNumber}</span></p>
                                        <p>{t("CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.MODEL_NUMBER")}: <span>{device.modelNumber}</span></p>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                            ) : (
                                <div className="email-log-error">{t("CUSTOMER_DASHBOARD.DEVICE_AND_USAGE_DETAILS.NO_INACTIVE_DEVICES")}</div>
                            )}
                        </div>
                     </TabPane>
                </TabContent>
            </div>
        </div>
    );
};

export default DeviceAndDeviceUsageDetails;
