import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ENVSettings } from "../../../../constants/ENVSettings";
import DisplayUpdateAccountConfirmation from "../../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation";
import checkWithBackground from '../../../../assets/images/complete-confirmation-icon.svg'
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { getCountryCode, getLangCode } from "../../../../utils/Internationalization/handleAppInternationalization";
import { Is_Both_EcoPro_And_BrotherPlus_RegistrationType, Is_BrotherPlus_Signup, isDelayedCancellationEnabled } from "../../../../constants/AppMethod";
import { routePath } from "../../../../constants/Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { getFeatureSwitchByKey } from "../../../../utils/Authentication/handleUserSession";
import { featureSwitchName, SUCCESS } from "../../../../constants/AppConstants";
import { DisplayDate } from "../../../../utils/DisplayDate";


const CancellationConfirmed = (props) => {
    const { t } = useTranslation(["translation"]);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    const isBothRegType = Is_Both_EcoPro_And_BrotherPlus_RegistrationType();
    const DeviceInfo = useSelector((state) => state.DashboardDeviceInfo)
    const history = useHistory()
    const dateDisplayOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const langCode = getLangCode()
    const activatedDate = DeviceInfo?.deviceInfo?.service?.activatedDate
    const isdelayCancellationFeatureSwitch = getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION) ? true : false
    const billingEndDate = DeviceInfo?.deviceInfo?.service?.currentUsage?.usageCycleEndDate
    const formattedBillingEndDate = DisplayDate(billingEndDate,  dateDisplayOptions, langCode);
    const {cancellationsuccess, isBrotherPlusCancellationEnabled, cancelOrderResponse} = props?.location?.state;
   
    const cancellationConfirmed = (event) => {
      if((activatedDate && isdelayCancellationFeatureSwitch) 
          || (cancelOrderResponse?.isBothRegistrationType && !isBrotherPlusCancellationEnabled)) { 
        history.push(routePath.DASHBOARD)
      }
      else{
        const countryCode = getCountryCode();
        const redirectUrl = (!ENVSettings.MAIN_BASE_URL) ? window.env[`MAIN_BASEURL_${countryCode}`] : ENVSettings.MAIN_BASE_URL;
        event.preventDefault();
        window.location.href = redirectUrl;
        return false;
      }
    }

    const getDisplayTxt_For_Cancellation = () => {
      let text = t("CONFIRMED_CANCELLATION.INFO");
      if(isDelayedCancellationEnabled(activatedDate)){
        text = <Trans i18nKey="DELAY_CANCELLATION.DELAY_CANCEL_INFO" formattedBillingEndDate={formattedBillingEndDate}>
        Your EcoPro subscription has now been cancelled. Your subscription will finish at the end of your last billing month on <strong>{{formattedBillingEndDate}}</strong>.
        </Trans>;
      } else {
        if(!isBrotherPlusCancellationEnabled && cancelOrderResponse?.isBothRegistrationType){
          text = t('BROTHER_PLUS.CANCEL_PLAN.ECOPRO_CANCELLATION_TEXT_FOR_BOTH_THE_MEMBERSHIP.CANCELLATION_CONFIRMED_BROTHER_PLUS_NOT_CANCELLED');
        } else if(isBothRegType && isBrotherPlusCancellationEnabled === true){
          text = (cancelOrderResponse?.isEcoProCancelled.toUpperCase() === SUCCESS && cancelOrderResponse?.isBrotherPlusCancelled.toUpperCase() !== SUCCESS) ? t('BROTHER_PLUS.CANCEL_PLAN.ECOPRO_CANCELLATION_TEXT_FOR_BOTH_THE_MEMBERSHIP.CANCELLATION_FAILED_ECOPRO_CANCELLED_BROTHER_PLUS_NOT_CANCELLED') : t('BROTHER_PLUS.CANCEL_PLAN.CONFIRMED_CANCELLATION') ;
        } else if (IsBrotherPlus_Signup) {
          text = t("BROTHER_PLUS.CANCEL_PLAN.CONFIRMED_CANCELLATION");
        } else {
          text =  t("CONFIRMED_CANCELLATION.INFO");
        }
      }
      return text;
    }
   
  return (
    cancellationsuccess === true ?
    <div>
      <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("CONFIRMED_CANCELLATION.HEADER")}
        updateAddressInfo={getDisplayTxt_For_Cancellation()}
        updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
        onUpdateClick={(event) => cancellationConfirmed(event)}
        image={checkWithBackground}
        cname = 'mt-40'
      ></DisplayUpdateAccountConfirmation>
    </div>
     :
     <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default CancellationConfirmed;
