export const refreshBtn = {
    "SHOW": true,
    "HIDE": false
}

export const tryAgainBtn = {
    "SHOW": true,
    "HIDE": false
}

export const alphaNumericRegex = /^[A-Za-z0-9 ]+$/i
export const alphaRegex = /^[a-zA-Z ]+$/i
export const nonNumericRegex = /^\D+$/i
export const numericRegex = /^[0-9]+$/i
export const blockSpecialCharacterRegex = /^[^<>'";%&]+$/i
export const eInvoiceIdRegex = /^[a-zA-Z0-9]{7}$/i

export const alphaNumericForms = ["firstName", "lastName"];
export const alphaNumericAndSpaceless = ["eInvoiceId"];
export const onlyNumericForms = ["telephoneNumber"];

export const _API_ENDPOINT = {
    "FETCH_PLAN": "plan/Getserviceplan",
    "SETUP_BILLINGPAGE": "billing/setupbillingpage",
    "SUBSCRIPTION_MANAGE": "Subscription/manage",
    "ENROLLMENT_PLACEORDER": "Enrollment/placeorder",
    "ASYNC_ENROLLMENT_PLACEORDER": "Enrollment/placeorderasync",
    "ASYNC_ENROLLMENT_PLACEORDER_RETRY": "Enrollment/RetryPlaceOrderAsync",
    "ASYNC_ENROLLMENT_PLACEORDER_RETRY_GET_CONTENT": "Enrollment/GetContentForRetryPlaceOrderAsync",
    "DEVICE_INFO": "Device/GetDeviceInfo",
    "ORDER_HISTORY": "History/GetOrderHistory",
    "UPDATE_SHIPPING":"Shipping/UpdateShippingAddress",
    "FETCH_DEVICE_LIST":"Device/GetDeviceListInfo",
    "BOL_API": "BolUser/GetBolAccountInfo",
    "GET_USER_ACCOUNT":"User/GetUserAccount",
    "GET_MY_ACCOUNT":"User/GetMyAccount",
    "CANCEL_ORDER":"Cancellation/cancelorder",
    "CANCEL_SERVICE":"Cancellation/addcancelservicerequest",
    "GET_CARBON_SAVING":"UserCarbonSaving/GetCarbonSavings",
    "FEATURE_SWITCH":"admin/FeatureSwitch/GetFeatureSwitch",
    "SWAP_DEVICE":"Device/Swap",
    "DEVICE_CAPABILITY":"Device/GetDeviceCapability",
    "DOWNLOAD_ORDER_INVOICE":"History/DownloadOrderInvoice",
    "UPDATE_PLAN":"plan/UpdateSubscriptionPlan",
    "SIGNUP_USER_FEEDBACK":"Feedback/AddUserFeedback",
    "PAYMENT_OPTIONS": "admin/Country/GetCountryPaymentMethods",
    "SEPA_BANk_TRANSFER_SETUP_BILLINGPAGE": "Billing/SetupSBTBillingPage",
    "USER_SUBSCRIPTION_ACCOUNT_SUMMARY":"User/GetUserSubscriptionAccount",
    "GET_PRINT_HISTORY":"History/GetPrintHistory",
    "COUNTRY_POST_CODE":"admin/Country/GetCountryPostCodeRestriction",
    "FETCH_PLAN_LANDING":"Plan/GetServicePlanDetails",
    "CUSTOMER_UPDATE_PROFILE": "Subscription/UpdateSubscriptionAccount",
    "DEVICE_REGISTRATION": "Device/DeviceRegistration",
    "GET_BENEFIT_OF_SUPPLIES": "PromotionPage/GetBenefitOfSupplies",
    "GET_CANCEL_SUBSCRIPTION_STATUS": "Cancellation/getcancelservicerequest",
    "REACTIVATE_SUBSCRIPTION": "Cancellation/deletecancelservicerequest",
    "GET_USER_REGISTRATION_TYPE": "shared/UserRegistration/GetUserRegistration"
}
export const WEB_CUSTOMER_API_ENDPOINT = {
    "GET_PRINTERS_TYPE_LIST": "WebSignUp/GetPrinterTypes",
    "GET_PRINTERS_MODEL_LIST":"WebSignUp/GetPrintersByPrinterType",
    "FETCH_PLAN":"WebSignUp/GetServicePlan",
    "CREATE_BOC_ACCOUNT": "BocUser/CreateBocUser",
    "PRE_ENROLLMENT": "Enrollment/WebSignUp/CreatePreEnrollInfo",
    "GET_PRE_ENROLL_INFO": "Enrollment/WebSignUp/GetPreEnrollInfo",
    "VERIFY_USER_PRE_ENROLL_INFO": "Enrollment/WebSignUp/VerifyUserPreEnrollInfo",
    "UPDATE_ADB2C_NEW_USER": "User/UpdateNewUserADB2CData",
    "GET_USER_BOC_ID_BY_ADB2CID": "User/GetUserBocIdByADB2CId",
    "GET_PAYMENT_DETAILS": "User/GetUserPaymentDetails",
    "GET_USER_SUBSCRIPTION_ACCOUNT": "User/GetUserSubscriptionDevice",
    "GET_DASHBOARD_PRE_ENROLL_INFO": "WebSignUpDashboard/GetDashboardPreEnrollInfo",
    "GET_PROMOTION_BANNER_STATUS": "PromotionPage/GetPromotionBannerStatus",
    "GET_REGISTRATION_TYPE": "BOCDeviceRegistration/GetBOCDeviceRegistrationTypes",
    "GET_WEB_DEVICE_REGISTRATION":"BOCDeviceRegistration/BOCWebDeviceRegistration",
    "GET_BOC_WEB_DEVICE_CAPABILITY":"BOCDeviceRegistration/GetBOCWebDeviceCapability",
    "GET_USER_SCMS_TOKEN":"SCMSToken/GetUserSCMSToken",
    "GET_USER_SUBSCRIPTION":"Subscription/GetUserSubscriptions"
}

export const _CSAGENT_API_ENDPOINTS = {
    "FETCH_CUSTOMER_DATA": "csagent/CSAgentDashboard/GetUserDetailsByParams",
    "ENABLE_DEVICE_SWAP": "csagent/CSAgentDashboard/UpdateSwapStatus",
    "UPDATE_ORDER_CONSUMABLES": "csagent/CSAgentDashboard/CreateManualOrderForConsumables",
    "EMAIL_LOGS": "csagent/CSAgentDashboard/GetUserEmailLogs",
    "CANCEL_SUBSCRIPTION": "csagent/CSAgentDashboard/CancelUserSubscription",
    "SUPPLY_ORDER_LOGS": "csagent/CSAgentDashboard/GetUserSupplyOrderLogs",
    "GET_PRINT_HISTORY":"csagent/CSAgentDashboard/GetUserPrintHistory",
    "BOC_DEVICE_CONNECTION":"csagent/CSAgentDashboard/GetBOCDeviceDetails",
    "FETCH_SUBSCRIPTION_DETAILS":"csagent/CSAgentDashboard/GetUserSubscriptionDetails",
    "FETCH_DEVICE_AND_USAGE_DETAILS": "csagent/CSAgentDashboard/GetUserDeviceAndUsageDetails",
    "FETCH_USER_ACTIVITY_HISTORY":"csagent/CSAgentDashboard/GetUserActivityHistory",
    "GET_USER_PRE_ENROLLMENT_INFO":"csagent/CSAgentDashboard/GetUserPreEnrollmentInfo"
}

export const _ADMINISTRATOR_API_ENDPOINTS = {
    FEATURE_SWITCH_BY_COUNTRY: "admin/FeatureSwitch/GetFeatureSwitchByCountry",
    UPDATE_FEATURE_SWITCH: "admin/FeatureSwitch/UpdateFeatureSwitch",
    FEATURE_SWITCH_BY_FEATURE: "admin/FeatureSwitch/GetFeatureSwitchByFeature",
    GET_FEATURE_SWITCH_ITEMS_VALUE: "admin/FeatureSwitch/GetFeatureSwitchValue",
    GET_FEATURE_SWITCH_ITEMS_VALUES:"admin/FeatureSwitch/GetFeatureSwitchValues",
    MANAGE_ADMIN_USER: {
        "ADD_USER": "admin/AdminUser/CreateServicePortalUser",
        "EDIT_USER_ACCESS": "admin/AdminUser/EditServicePortalUserAccess",
        "VIEW_USER": "admin/AdminUser/GetServicePortalUsers",
        "VIEW_USER_BY_EMAIL": "admin/AdminUser/GetServicePortalUserByEmail"
    },
    TESI_INVOICE_POSTING:{
        'VIEW_INVOICES': "admin/ItalianTaxData/GetPendingItalianTaxData",
        "RETRY_INVOICE": "admin/ItalianTaxData/SendPendingItalianTaxData"
    },
    SETTINGS:{
        "MAINTENENCE_SERVICE":{
            "FETCH": "admin/ServiceMaintenance/GetServiceMaintenanceStatus",
            "UPDATE": "admin/AdminUser/UpdateServiceMaintenanceDetails"
        }
    },
    MANAGE_PRINTER_MODELS:{
        'BY_COUNTRY': "admin/Country/GetCountryPrinterModels",
        "BY_MODELS": "admin/Country/GetCountriesByPrinterModel",
        "UPDATE_MODEL_STATUS": "admin/Printer/UpdatePrinterModelSupportByCountry"
    },
    USER_ACTIVITY_LOGS: {
        'USER_SESSION_LOGS': 'admin/AdminUser/AddServicePortalUserLog'
    }
}

export const _HARDWARE_API_ENDPOINTS = {
    "GET_HW_PRINTER_FEATURES_BY_PRINTER_TYPE":"HardwareInclusive/GetHardwareInclusivePrinterFeaturesByPrinterType",
    "GET_HW_SERVICE_PLAN":"HardwareInclusive/GetHardwareInclusiveServicePlan",
    "GET_HW_PRINTER_BY_FEATURES":"HardwareInclusive/GetHardwareInclusivePrinterByFeatures",
    'CREATE_INSTANT_PAYMENT': 'HardwareInclusive/CreateHardwareInclusiveInstantPayment',
    'CREATE_PREENROLL_INFO': 'HardwareInclusive/CreateHardwareInclusivePreEnrollInfo',
    "GET_PREENROLL_INFO": 'HardwareInclusive/GetHardwareInclusivePreEnrollInfo',
    "GET_DASHBOARD_PREENROLL_INFO": 'HardwareInclusiveDashboard/GetDashboardPreEnrollInfo',
    "GET_CANCELLATION_FEE":"Cancellation/GetCancellationFee",
    "VERIFY_HW_USER_PRE_ENROLL_INFO":"HardwareInclusive/VerifyHardwareInclusiveUserPreEnrollInfo"
}

export const _BROTHER_PLUS_API_ENDPOINTS = {
    "CANCEL_SERVICE":"BrotherPlus/Cancellation/CancelService",
    "REGISTER_SERVICE":"BrotherPlus/Enrollment/RegisterService",
    "GET_USER_ACCOUNT": "BrotherPlus/User/GetUserAccount",
    "GET_MY_ACCOUNT": "BrotherPlus/User/GetMyAccount",
    "GET_DEVICES":"BrotherPlus/Device/GetDevices",
    "GET_DEVICE_STATUS":"BrotherPlus/Device/GetDeviceStatus",
    "GET_PRINT_HISTORY":"BrotherPlus/History/GetPrintHistory",
    "GET_USER_PROMO_CODE":"BrotherPlus/User/GetUserPromoCode",
    "USER_ACTIVITY_LOG":"BrotherPlus/User/AddUserActivityLog",
    "FETCH_USER_DEVICE_STATUS":"BrotherPlus/CSAgentDashboard/GetUserDeviceStatus",
    "FETCH_USER_SUBSCRIPTION_DETAILS":"BrotherPlus/CSAgentDashboard/GetUserSubscriptionDetails",
    "FETCH_USER_PRINT_HISTORY":"BrotherPlus/CSAgentDashboard/GetUserPrintHistory",
    "VALIDATE_USER_PROMO_CODE":"BrotherPlus/User/ValidateUserPromoCodeEligibility",
    "ADD_BROTHERPLUS_USER_FEEDBACK":"BrotherPlus/Feedback/AddBrotherPlusUserFeedback"
}

export const CCInfoOption = {
    "EXISTS" : 1,
    "NEW" : 2
}
export const USER_LANGUAGE_CODE_TYPE = "user_lang";
export const PAYMENT_TYPE = {
    "NEW" : "new_payment",
    "UPDATE": "update_payment"
}
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const ONLINE = "ONLINE";
export const OFFLINE = "OFFLINE";

export const SUBSCRIPTION_TYPE = {
    "NEW" : "new_subscription",
    "UPDATE_BILLING": "update_billing_address",
    "UPDATE_PAYMENT": "update_payment_details"
}
export const DISCOUNT_PRICE = "0.00"

export const featureSwitchName = {
    CARBON_SAVING: "CarbonSavings",
    CHANGE_PLAN: "ChangePlan",
    AUTOMATED_ORDER_PLACEMENT: "AutomatedOrderPlacement",
    COLOUR_LASER: "ColourLaser",
    AUTOMATED_DEVICE_WARRANTY_REGISTRATION: "AutomatedDeviceWarrantyRegistration",
    PROMOTION_BANNERS: "PromotionBanners",
    BOC_WEB_ENROLLMENT: "BOCWebEnrollment",
    DELAY_CANCELLATION: "DelayCancelService",
    HARDWARE_INCLUSIVE: "HardwareInclusive",
    BROTHER_PLUS: "BrotherPlusProgram",
    BROTHER_PLUS_PROMO_CODE: "BrotherPlusPromoCode",
    BONUS_PAGES: "BonusPages"
};

export const FALLBACK_LNG = 'en-GB'
export const UK_COUNTRY = "UK"
export const GB_COUNTRY = "GB"

export const LOCALSESSIONDATAKEYS = {
    "UID" : "UID",
    "DEVICE_ID": "DEVICE_ID",
    "AZURE_AD_B2C_UID": "AZURE_AD_B2C_UID",
    "DEVICE_MODEL": "DEVICE_MODEL",
    "SERIAL_NUMBER": "SERIAL_NUMBER",
    "USERROLES": "USERROLES",
    "ECOPROCTRYCODE": 'ECOPROCTRYCODE',
    "CURRENCY":'CURRENCY',
    "ECOPROLANGUAGECODE": "ECOPROLANGUAGECODE",
    "ECOPROLNGCODESTATUS": "ECOPROLNGCODESTATUS",
    "LOGOUT_LNG_CODE":'LOGOUT_LNG_CODE',
    "CARTRIDGE_TYPE": 'CARTRIDGE_TYPE',
    "WEB_SELECTED_PLAN": "WEB_SELECTED_PLAN",
    "PRE_ENROLLMENT_ID": "PRE_ENROLLMENT_ID",
    "ECOPROWEBSIGNUPLANGCODE": "ECOPROWEBSIGNUPLANGCODE",
    "NETWORK_STATUS":"NETWORK_STATUS",
    "ECOPROID": "ECOPROID",
    "USER_SESSION_ACTIVITY_LOGS": "USER_SESSION_ACTIVITY_LOGS",
    "WEB_SHIPPING_ADDRESS":"WEB_SHIPPING_ADDRESS",
    "SIGNUP_TYPE": "SIGNUP_TYPE",
    "TERM_END_DATE":"TERM_END_DATE",
    "PRINTER_TYPE":"PRINTER_TYPE",
    "USER_REGISTRATION_TYPE": "USER_REGISTRATION_TYPE",
    "SITECORE_BROTHER_PLUS_PLAN":"SITECORE_BROTHER_PLUS_PLAN",
    "ENROLLMENT_STATUS_COMPLETED":"ENROLLMENT_STATUS_COMPLETED"
}
export const ORDER_SUPPLIES_CONSUMABLES = [
    {
        key:"black",
        content:"Black Cartridge"
    },
    {
        key:"cyan",
        content:"Cyan Cartridge"
    },
    {
        key:"yellow",
        content:"Yellow Cartridge"
    },
    {
        key:"magenta",
        content:"Magenta Cartridge"
    },
]
export const ORDER_PARTS_CONSUMABLES = [
    {
        key:"drum",
        content:"Drum"
    },
    {
        key:"belt",
        content:"Belt"
    }
]
export const UNSUPPORTED_FIRMWARE_VERSION = "UNSUPPORTED_FIRMWARE_VERSION"
export const DEVICE_OFFLINE = "DEVICE_OFFLINE"
export const UNSUPPORTED_MODEL = "UNSUPPORTED_MODEL"

export const DEVICE_CARTRIDGE_TYPE = {
    "STANDARD": "STANDARD",
    "HYBRID": "HYBRID",
    "SUBSC": "SUBSC"
}
export const ECOPROTOKEN_CLAIMS = {
    "USERID": "UserId",
    "ADB2CUSERID": "ADB2CUserId",
    "ROLES": "Roles",
    "FEATURESWITCHITEMS": "FeatureSwitchItems"
}
export const UNIQUE_ID_GA = {
    dashboard:"dashboard",
    ecoSaving:"eco-saving",
    orderHistory:"order-history",
    printActivity:"print-activity",
    myAccount:"my-account",
    helpAndSupport:"help-and-support",
    feedback:"feedback",
    customerLogout:"customer-logout",
    addNewDeviceMobileConnect:"add-new-device-mobile-connect",
    cancelPlanFeedback:'cancel-plan-feedback',
    signup:"sign-up",
    signupPlans:"sign-up-plans",
    signupConfirmDevice:"sign-up-confirm-device",
    signupSelectDifferentDevice:"sign-up-select-different-device",
    logout:"logout"
}
export const READY_PROMOTION = {
    promotion : "Ready",
    discountName:"ReadyPromotion"
}
export const BONUSPAGE_PROMOTION = {
    promotion : "BonusPages",
    discountName:"BonusPagesPromotion"
}

export const USER_PROFILE_EDIT_TYPE = {
    password: "PASSWORD",
    profile: "PROFILE"
}

export const SITECORE_TERMS_AND_CONDITION = {
  personal: {
    "en-GB": "https://www.brother.co.uk/ecopro/ecopro-terms-and-conditions",
    "nl-BE": "https://www.brother.be/nl-be/ecopro/ecopro-voorwaarden",
    "nl-NL": "https://www.brother.nl/ecopro/ecopro-voorwaarden",
    "fr-BE": "https://www.brother.be/fr-be/ecopro/ecopro-conditions",
    "fr-FR": "https://www.brother.fr/ecopro/ecopro-conditions-generales",
    "de-DE": "https://www.brother.de/ecopro/ecopro-bedingungen-privat",
    "de-AT": "https://www.brother.at/ecopro/ecopro-bedingungen-privat",
    "de-CH": "https://www.brother.ch/de-ch/ecopro/",
    "fr-CH": "https://www.brother.ch/fr-ch/ecopro",
    "en-IE": "https://www.brother.ie/ecopro/ecopro-terms-and-conditions",
    "it-IT": "https://www.brother.it/ecopro/termini-e-condizioni",
    "es-ES": "https://www.brother.es/ecopro/terminos-y-condiciones",
    "pt-PT": "https://www.brother.pt/ecopro/termos-e-condicoes",
    "da-DK": "https://www.brother.dk/ecopro/ecopro-terms-and-conditions",
    "fi-FI": "https://www.brother.fi/ecopro/ecopro-terms-and-conditions",
    "sv-SE": "https://www.brother.se/ecopro/ecopro-terms-and-conditions"
  },
  business: {
    "en-GB": "https://www.brother.co.uk/ecopro/ecopro-terms-and-conditions",
    "nl-BE": "https://www.brother.be/nl-be/ecopro/ecopro-voorwaarden",
    "nl-NL": "https://www.brother.nl/ecopro/ecopro-voorwaarden",
    "fr-BE": "https://www.brother.be/fr-be/ecopro/ecopro-conditions",
    "fr-FR": "https://www.brother.fr/ecopro/ecopro-conditions-generales",
    "de-DE": "https://www.brother.de/ecopro/ecopro-bedingungen-geschaeftlich",
    "de-AT": "https://www.brother.at/ecopro/ecopro-bedingungen-geschaeftlich",
    "de-CH": "https://www.brother.ch/de-ch/ecopro/",
    "fr-CH": "https://www.brother.ch/fr-ch/ecopro",
    "en-IE": "https://www.brother.ie/ecopro/ecopro-terms-and-conditions",
    "it-IT": "https://www.brother.it/ecopro/termini-e-condizioni",
    "es-ES": "https://www.brother.es/ecopro/terminos-y-condiciones",
    "pt-PT": "https://www.brother.pt/ecopro/termos-e-condicoes",
    "da-DK": "https://www.brother.dk/ecopro/ecopro-terms-and-conditions",
    "fi-FI": "https://www.brother.fi/ecopro/ecopro-terms-and-conditions",
    "sv-SE": "https://www.brother.se/ecopro/ecopro-terms-and-conditions"
  },
};
export const WEB_EXTERNAL_LINK_ENDPOINT = {
    play_store:"https://play.google.com/store/apps/details?id=com.brother.mfc.mobileconnect",
    app_store:"https://apps.apple.com/us/app/brother-mobile-connect/id1516235668"
}

export const SIGNUPTYPE = {
    "WEB": "WEB",
    "MOBILE": "MOBILE",
    "HARDWARE_INCLUSIVE": 'HW_INCLUSIVE',
    "BROTHER_PLUS": "BROTHER_PLUS"
}

export const SUBSCRIPTIONKIND = {
    "WEB": 'web_signup',
    "HARDWARE_INCLUSIVE": 'hw_inclusive',
    "BROTHER_PLUS": "brother_plus"
}

export const FEEDBACK_TYPES = {
    SIGNUP_USER_FEEDBACK: "SIGNUP_USER_FEEDBACK",
    DASHBOARD_USER_FEEDBACK:"DASHBOARD_USER_FEEDBACK",
    BROTHERPLUS_SIGNUP_USER_FEEDBACK:"BROTHERPLUS_SIGNUP_USER_FEEDBACK"
}

export const alertSubscriptionId = {
    "CREDIT_CARD_CLOSE_TO_EXPIRY":"ID5-3",
    "CREDIT_CARD_EXPIRY":"ID5-4",
    "DEVICE_OFFLINE":"ID3-1",
    "NON_BARRACUDA_CARTRIDGE":"ID3-2",
    "CLOSE_TO_OVERAGE":"ID2-1",
    "OVERAGE":"ID2-2",
    "PAYMENT_FAILED":"ID5-2"
}

export const PAYMENT_OPTIONS = {
    "CREDIT_CARD": "Credit Card",
    "DIRECT_BANK_TRANSFER": "Bank Transfer-SEPA"
}

export const DASHBOARD_REFRESH_INTERVAL = 50000

export const BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH = 15;
export const ADDRESS_FIELDS_INPUT_MAX_LENGTH = 35;

export const USER_ACTIVITY_LOGS = {
    "LOGIN": "LOGIN",
    "DEVICE_SWAP": {
        "ENABLED": "DEVICE_SWAP_ENABLED",
        "CANCELED":  "DEVICE_SWAP_CANCELED"
    },
    "CUSTOMER_SEARCH": "CUSTOMER_SEARCH",
    "BOC_DEVICE_SEARCH" : "BOC_DEVICE_CONNECTION_INFO",
    "UPDATE_SUPPLY_ORDER_CONSUMABLES": "UPDATE_SUPPLY_ORDER_CONSUMABLES",
    "ADMIN_UPDATE_FEATURE_SWITCH":"UPDATE_FEATURE_SWITCH",
    "MAINTENANCE_PAGE_SETTINGS": "UPDATE_MAINTENANCE_PAGE_SETTINGS",
    "ADD_ADMIN_USER": "ADD_ADMIN_USER",
    "UPDATE_ADMIN_USER_ROLES":"UPDATE_ADMIN_USER_ROLES",
    "FETCH_SUPPLY_ORDER_CONSUMABLES": "FETCH_SUPPLY_ORDER_CONSUMABLES",
    "FETCH_EMAIL_LOGS": "FETCH_EMAIL_LOGS",
    "CANCEL_SUBSCRIPTION": "CANCEL_SUBSCRIPTION",
    "FETCH_PRINT_HISTORY": "FETCH_PRINT_HISTORY",
    "FETCH_DEVICE_AND_USAGE_DETAILS": "FETCH_DEVICE_AND_ITS_USAGE_DETAILS",
    "FETCH_SUBSCRIPTION_DETAILS": "FETCH_SUBSCRIPTION_DETAILS",
    "FETCH_USER_ACTIVITY_HISTORY": "FETCH_USER_ACTIVITY_HISTORY",
    "FETCH_USER_PRE_ENROLLMENT_INFO": "FETCH_USER_PREENROLLMENT_INFO",
    "BROTHERPLUS_FETCH_SUBSCRIPTION_DETAILS":"BROTHERPLUS_FETCH_SUBSCRIPTION_DETAILS",
    "BROTHERPLUS_FETCH_PRINT_HISTORY":"BROTHERPLUS_FETCH_PRINT_HISTORY",
    "BROTHERPLUS_FETCH_DEVICE_STATUS":"BROTHERPLUS_FETCH_DEVICE_STATUS"

}
export const COUNTRIES_WITH_SDI = ["ITALY"]
export const COUNTRIES_WITH_LOCAL_TNC = ["IT"]
export const ITALY_COUNTRY = "ITALY"
export const ENROLLMENT_ASYNC_FLOW_TYPE = true

export const PRINT_CODES = {
    PRINTCODE_1: 'print-code1',
    PRINTCODE_2: 'print-code2',
    PRINTCODE_3: 'print-code3',
};

export const CONTACT_SUPPORT = {
    "en-GB": "https://www.brother.co.uk/support/contact-us",
    "nl-BE": "https://www.brother.be/nl-be/contact",
    "nl-NL": "https://www.brother.nl/contact",
    "fr-BE": "https://www.brother.be/fr-be/contact",
    "fr-FR": "https://www.brother.fr/contactez-nous",
    "de-DE": "https://www.brother.de/kontakt",
    "de-AT": "https://www.brother.at/kontakt",
    "en-IE": "https://www.brother.ie/contact-us",
    "it-IT": "https://www.brother.it/support",
    "es-ES": "https://www.brother.es/soporte/contactanos",
    "pt-PT": "https://www.brother.pt/suporte/contacte-nos",
    "da-DK": "https://www.brother.dk/contact-us",
    "fi-FI": "https://www.brother.fi/contact-us",
    "sv-SE": "https://www.brother.se/contact-us",
    "de-CH": "https://www.brother.ch/de-ch/support/kontakt",
    "fr-CH": "https://www.brother.ch/fr-ch/support/contactez-nous"
}

export const ORDER_TYPES = {
    SUPPLIES_SHIPPED: 'SupplyShipped',
    INVOICE_RAISED: 'InvoiceRaised'
  } 
export const BOC_WEB_DEVICE_CAPABILITY_CODES = {
    "UNSUPPORTED_FIRMWARE_VERSION":{
        "customeCode": "904",
        "actionMessage": "Unsupported firmware version"
    },
    "DEVICE_OFFLINE": {
        "customeCode": "510",
        "actionMessage": "Device is Offline"
    }
}

export const BROTHER_SERVICE_DESK_BASE_URL = 'https://brother-bie.atlassian.net/servicedesk/customer/portal/1/group/1/create/14'

export const SD_TICKET_CATEGORY_OPTION = [
        {
            "name": "Device Connection",
            "id": 1
        },
        {
            "name": "Billing",
            "id": 2
        },
        {
            "name": "Data dispute (pages printed)",
            "id": 3
        },
        {
            "name": "Other",
            "id": 4
        },
    ]
export const CSAGENT_SUBSCRIPTION_TYPES = {
    "ACTIVE": "Active",
    "CANCELLED": "Cancelled",
    "INACTIVE": "In Active",
    "PENDINGENROLLMENT": "Pending Enrollment",
    "PRE_ENROLLMENT_COMPLETED": "Pre Enrollment Completed",
    "PENDINGACTIVATION": "Pending Activation"
}

export const USER_REGISTRATION_TYPE_KEYS = {
    'IS_ECOPRO': 'IS_ECOPRO',
    'IS_BROTHER_PLUS': 'IS_BROTHER_PLUS',
    'IS_BOTH': 'IS_BOTH'
}

export const UPGRADE_TO_ECOPRO = 'UPGRADE_TO_ECOPRO'

export const ONE_TIME_BONUS='OnetimeBonus100Page_k0'
export const HUNDRED_PAGES_BONUS_DISCOUNT_PLAN_NAME='OnetimeBonus100Page'

