import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CSAGENT_API_ENDPOINTS, FAILURE, USER_ACTIVITY_LOGS } from "../../../constants/AppConstants";
import i18next from "i18next";
import UserActivityLogsPayload from "../../../utils/AdminPortal/UserActivityLogsPayload";
import { _CALLPOSTAPI } from "../../../api/apiHandler";

async function _CALLDEVICEDETAILSAPI(customerId) {
    const endpoint = _CSAGENT_API_ENDPOINTS.FETCH_DEVICE_AND_USAGE_DETAILS;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_DEVICE_AND_USAGE_DETAILS,
        description: `Fetched device and its usage info for the customer ${customerId}`
    }
    const payload = {
        userId: customerId,
        servicePortalUser:await UserActivityLogsPayload(logDetails)
      }
    return _CALLPOSTAPI(endpoint,payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleDeviceAndUsageInfo(action){
    const loadedTab = action.loadedTab;
    try{
        const customerId = action.customerID;
        const deviceInfo = yield call(_CALLDEVICEDETAILSAPI, customerId);
        if(deviceInfo.actionStatus.toUpperCase() !== FAILURE){
                yield put({type: actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_SUCCESS, deviceInfo: deviceInfo, loadedTab: loadedTab });
        } else {
            yield put({type: actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_ERROR, message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'), loadedTab: loadedTab });
        }
    } catch(e){
        yield put({type: actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_ERROR, message: e.message, loadedTab: loadedTab});
    }
}
function* CustomerDeviceAndDeviceUsageDetails(){
    yield takeLatest(actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_INIT, handleDeviceAndUsageInfo);
}

export default CustomerDeviceAndDeviceUsageDetails;