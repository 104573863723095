import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLPOSTAPI,_CALLPUTAPI } from "../../api/apiHandler";
import { _API_ENDPOINT, _CSAGENT_API_ENDPOINTS, featureSwitchName, LOCALSESSIONDATAKEYS, SIGNUPTYPE, SUCCESS, USER_ACTIVITY_LOGS } from "../../constants/AppConstants";
import i18next from "i18next";
import CancelOrderPayload from "../../utils/MyAccount/CancelOrderPayload";
import { userRoles } from "../../constants/UserRoles";
import { getFeatureSwitchByKey, setLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { Is_Both_EcoPro_And_BrotherPlus_RegistrationType } from "../../constants/AppMethod";
import { call_GetUserRegisterationType } from "../Authentication/SignIn_Auth";
import UserActivityLogsPayload from "../../utils/AdminPortal/UserActivityLogsPayload";

function _CANCELORDER(payload, role) {
  if(role && role === userRoles.CSAGENT_ADMIN){
    return handleSubscriptionCancellation_CSAGENT(payload);
  } else {
    return handleSubscriptionCancellation_CUSTOMER(payload);
  }
}
/**
 * This method handles the subcription cancellation attempted by CS Agent user
 * @param {Object} payload 
 * @returns {Promise}
 */
async function handleSubscriptionCancellation_CSAGENT(payloadInfo){
  const endpoint = _CSAGENT_API_ENDPOINTS.CANCEL_SUBSCRIPTION;
  const logDetails = {
    action: USER_ACTIVITY_LOGS.CANCEL_SUBSCRIPTION,
    description: `Cancelled subscription for the user ${payloadInfo.UserId} for the device ${payloadInfo.DeviceId}`
  }
  const payload = {
    "userId": payloadInfo.UserId,
    "deviceId": payloadInfo.DeviceId,
    servicePortalUser:await UserActivityLogsPayload(logDetails)
  }
  return _CALLPUTAPI(endpoint, payload)
  .then(response => {
      return response;
  })
  .catch((error) => {
      throw error;
  });
}
/**
 * This method handles the subcription cancellation attempted by Customer
 * @param {Object} payload 
 * @returns {Promise}
 */
function handleSubscriptionCancellation_CUSTOMER(payload){
  const isdelayCancellationFeatureSwitch = getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION) ? true : false

  if(isdelayCancellationFeatureSwitch){
    const endpoint = _API_ENDPOINT.CANCEL_SERVICE
    return _CALLPOSTAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  }
  else{
    const endpoint = _API_ENDPOINT.CANCEL_ORDER
    return _CALLPUTAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  }
 
}
/**
 * Method to handle the customer subscription cancellation
 * 
 * Scenario 1: If the customer opt to stay with B+ membership, below actions happen
 * Actions:
 *  - GetUserRegistrationType API is tirggered
 *  - GetUserRegistrationType API updates the user membership to B+, hence in the state the flag 'isBothRegistrationType' is set to `true` to show appropriate message to the user
 *  - User is redirected to B+ dashboard
 * 
 * Scenario 2: If the customer opt to cancel from B+ and EcoPro membership, below actions happen
 *  - Scenario 2.1: If both the memberships are cancelled successfully, cancellation confirmation message will be displayed
 *  - Scenario 2.2: If Ecopro is cancelled sucessfully but B+ cancellation failed, then a message will be displayed to customer stating the issue and option to redirect to daashboard to cancel the B+ again.
 * 
 * Note: B+ will be cancelled only if EcoPro is cancelled sucessfully, hence there is no scenario of EcoPro failing and B+ cancellation being successful
 */
function* handle_Customer_CancelSubscriptionResponse(cancelOrderResp, isCancelBrotherPlusService, UID) {
  let isBothRegistrationType = false;
  const isBothRegType = Is_Both_EcoPro_And_BrotherPlus_RegistrationType();
  if(isBothRegType && !isCancelBrotherPlusService){
    isBothRegistrationType = true;
    setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, SIGNUPTYPE.BROTHER_PLUS);
    yield* call_GetUserRegisterationType(UID, 'CANCELLATION', null);
  }
  yield put({
    type: actionTypes.CANCEL_ORDER_SUCCESS,
    cancelOrder: {...cancelOrderResp, 'isBothRegistrationType': isBothRegistrationType},
  });
}
function* handleCancelOrder(action) {
  try {
    const uid = action.uid;
    const deviceId = action.deviceId;
    const role = action.role;
    const customerCountryCode = action.customerCountryCode
    const isCancelBrotherPlusService = action.isBrotherPlusCancellationEnabled
    if (uid && deviceId) {
      const cancelOrderPayload = CancelOrderPayload({
        'reasonSelection': action.feedbackSelection,
        'otherReason': action.otherReason,
        'deviceID': deviceId,
        'customerCountryCode': customerCountryCode,
        'uid': uid,
        'displayName': null,
        'serialNumber': null,
        'Model': null,
        'isCancelBrotherPlusService': isCancelBrotherPlusService
      });
      let cancelOrdersuccess = yield call(_CANCELORDER, cancelOrderPayload, role);

      if (cancelOrdersuccess.actionStatus.toUpperCase() === SUCCESS) {
        if(role !== userRoles.CSAGENT_ADMIN){
          yield* handle_Customer_CancelSubscriptionResponse(cancelOrdersuccess, isCancelBrotherPlusService, uid);
        } else {
          yield put({
            type: actionTypes.CANCEL_ORDER_SUCCESS,
            cancelOrder: cancelOrdersuccess,
          });
        }
      } else {
        yield put({
          type: actionTypes.CANCEL_ORDER_ERROR,
          message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"),
        });
      }
    } else {
      yield put({
        type: actionTypes.CANCEL_ORDER_ERROR,
        message: i18next.t("ERROR_CONTENT.INFO_GENERAL"),
      });
    }
  } catch (e) {
    const msg = (e.response && e.response.status === 400) ? i18next.t("ERROR_CONTENT.ERR_INFO.CANCEL_SUBSCRIPTION_ERROR") : e.message;
    yield put({ type: actionTypes.CANCEL_ORDER_ERROR, message: msg });
  }
}

function* CancelOrder() {
  yield takeEvery(actionTypes.CANCEL_ORDER, handleCancelOrder);
}

export default CancelOrder;
