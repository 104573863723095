import React from 'react';
import { useTranslation } from 'react-i18next';
import { AvgPagesUsedSkeletonDashboard } from '../../../../pages/PrintActivity/PrintActivity';
import { Is_Both_EcoPro_And_BrotherPlus_RegistrationType, Is_BrotherPlus_Signup } from '../../../../constants/AppMethod';
import { getFeatureSwitchByKey } from '../../../../utils/Authentication/handleUserSession';
import { featureSwitchName } from '../../../../constants/AppConstants';
import AveragePagesUsed from '../../AveragePagesUsed/AveragePagesUsed';

const BrotherPlusPrintActivity = ({BrotherPlus_printHistoryResult}) => {
    const { t } = useTranslation(["translation"]);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    const IsBrotherPlusFeatureSwitchEnabled = getFeatureSwitchByKey(featureSwitchName.BROTHER_PLUS);
    const isBothRegType = (Is_Both_EcoPro_And_BrotherPlus_RegistrationType() && IsBrotherPlusFeatureSwitchEnabled) ? true : false;

    return (
        <div className="print-activity-wrapper displayed-as-child">
            {
                <div className='print-activity-avg-pages-used-wrapper displayed-as-child'>
                    {
                        ( BrotherPlus_printHistoryResult?.loading )
                        ? <>
                                <div className="average-pages-used-wrapper loader-wrapper">
                                    <div className="average-page-trends h2">{t("BROTHER_PLUS.DASHBOARD.PRINT_HISTORY.PRINT_HISTORY")}</div>
                                    <div className="average-pages-used-content">
                                        <AvgPagesUsedSkeletonDashboard />
                                    </div>
                                </div>
                            </>
                        : null
                    }
                    {
                        ( BrotherPlus_printHistoryResult?.error || 
                        ((IsBrotherPlus_Signup || isBothRegType)  && BrotherPlus_printHistoryResult?.success && !BrotherPlus_printHistoryResult?.printHistory?.history?.length)) 
                        ?   <> 
                                <div className="average-pages-used-wrapper error-wrapper">
                                    <div className="average-pages-used-content">
                                        <div className="average-page-trends h2">{t("BROTHER_PLUS.DASHBOARD.PRINT_HISTORY.PRINT_HISTORY")}</div>
                                        <p>{t('ECOPRO_NEW_DASHBOARD.NO_DATA_DISPLAYED')}</p>
                                    </div>
                                </div>
                            </> 
                        : null
                    }
                    {
                        (IsBrotherPlus_Signup || isBothRegType)  && !BrotherPlus_printHistoryResult?.loading && BrotherPlus_printHistoryResult?.success && BrotherPlus_printHistoryResult?.printHistory?.history
                        && BrotherPlus_printHistoryResult?.printHistory?.history?.length > 0 &&
                        <AveragePagesUsed printHistoryResult={BrotherPlus_printHistoryResult?.printHistory?.history} source="brother-plus"/>
                    }
                </div>
            }
        </div>
    );
};

export default BrotherPlusPrintActivity;