import React from 'react';
import deviceStatus from '../../../../assets/images/BrotherPlus/device-stats.svg'
import { useTranslation } from 'react-i18next';
import './BrotherPlusDeviceStats.css'

const BrotherPlusDeviceStats = () => {
    const { t } = useTranslation(["translation"]);
    return (
        <div className='device-stats-wrapper'>
            <div><img src={deviceStatus} alt="device-stats"/></div>
            <div className='device-stats-info'>
                <div className='title'>{t("ECOPRO_NEW_DASHBOARD.DEVICE_STATS.TITLE")}</div>
                <div className='sub-title'>{t("ECOPRO_NEW_DASHBOARD.DEVICE_STATS.SUB_TITLE")}</div>
            </div>
        </div>
    );
};

export default BrotherPlusDeviceStats;