import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FetchUserSubscriptionInfoInit } from "../../../../../actions/Web/Customer/SignUp";
import { FetchUserAccount } from "../../../../../actions/UserAccount";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../components/shared/loader/Loader";
import { LOCALSESSIONDATAKEYS } from "../../../../../constants/AppConstants";
import { WebCustomerRoutePaths, routePath } from "../../../../../constants/Routes";
import { getLocalSessionData, setLocalSessionData } from "../../../../../utils/Authentication/handleUserSession";
import { handleSignUpType, Is_BrotherPlus_RegistrationType, Is_BrotherPlus_Signup } from "../../../../../constants/AppMethod";

const ProcessUserAccount = (props) => {
    const BOCUserId  = props.location?.state?.BOCUserId;
    const dispatch = useDispatch();
    const [userSubscriptionInfoFlag, setUserSubscriptionInfoFlag] = useState(true);
    const UserAccountResult = useSelector((state) => state.UserAccount);
    const UserSubscriptionInfo = useSelector((state) => state.UserSubscriptionInfo);
    const AZURE_AD_B2C_UID = getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID);
    const SignupType = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    useEffect(() => {
        setLocalSessionData(LOCALSESSIONDATAKEYS.UID, BOCUserId);
        /**
         * For Brother+ users GetUserSubscriptionDevice and WebSignUp/GetPreEnrollInfo are skipped
         * Instead BrotherPlus/User/GetUserAccount API is called
         */
        if(IsBrotherPlus_Signup){
            dispatch(FetchUserAccount(AZURE_AD_B2C_UID, BOCUserId, null, handleSignUpType(SignupType)));
        } else {
            dispatch(FetchUserSubscriptionInfoInit(BOCUserId));
        }
    }, [dispatch, AZURE_AD_B2C_UID, BOCUserId, SignupType, IsBrotherPlus_Signup]);

    /**
     * Method to handle BrotherPlus redirection for dashboard
     * 
     * Logic
     *  - If the user singup type is Brotherplus and enrollment for Brotherplus is completed, user is redirected to Dashboard page.
     *  - If the user singup type is Brotherplus but enrollment BrotherPlus is not completed, then redirected to connect device dashboard.
     * 
     * @returns {JSX.Element}
     */
    const handleRedirection = () => {
            return <Redirect to={(Is_BrotherPlus_RegistrationType()) ? routePath.DASHBOARD : routePath.CONNECT_DEVICE_DASHBOARD} />
    }
    
    //***************
    
    //*********Commenting GetPreEnrollInfo related code to allow
    //*users to pre-enroll multiple times until they have an active ecopro susbsc ********/ 
    //  if(GetPreEnrollmentInfoResp.error){
    //     return <Redirect to={WebCustomerRoutePaths.ACCOUNT} />
    // }
    // if(GetPreEnrollmentInfoResp.success && GetPreEnrollmentInfoResp.data.id){   
    //     return <Redirect to={routePath.CONNECT_DEVICE_DASHBOARD} />
    // }
    if(UserAccountResult.error){
        return <ErrorPage errorInfo={UserAccountResult.error}></ErrorPage>
    }
    if(UserAccountResult.success){
        
        if(IsBrotherPlus_Signup){
            return handleRedirection();
               
        } else {
            /**
             * Action to check whether the customer has completed Prenrollment
             * If Preenrollment completed, customer redirected to web signup dashbaord page
             * If Preenrollment not completed, customer redirected to Account page.
             */
            // commenting GetPreEnrollInfo related code to allow Users to pre-enroll multiple times until they have an active ecopro susbsc
            //dispatch(GetPreEnrollmentInfo(UID));

            return <Redirect to={WebCustomerRoutePaths.ACCOUNT} />
        }        
    }
    if(UserSubscriptionInfo.error){
       return <ErrorPage errorInfo={UserSubscriptionInfo.error}></ErrorPage>
    }
    if (UserSubscriptionInfo.success === true && userSubscriptionInfoFlag) {
        dispatch(FetchUserAccount(AZURE_AD_B2C_UID, BOCUserId, null,  handleSignUpType(SignupType)));
        setUserSubscriptionInfoFlag(false);
    } 
    return (
        <Loader></Loader>
    )
}

export default ProcessUserAccount;