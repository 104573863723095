import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import './HalfDoughnut.css'
import { getPieChartData, getPieChartDataWithBonus } from '../../constants/AppMethod';
import { useTranslation } from 'react-i18next';
Chart.register(ChartDataLabels);

const HalfDoughnutChart = (props) => {
  const {
    printedTotalPages, 
    pagesUsed, 
    rolloverAllowance, 
    overage, 
    planPages, 
    givenRolloverPages, 
    purchasedOveragePages, 
    printedBonusPages, 
    IS_CSAGENT, 
    isBonusPageFeatureSwitchEnabled
  } = props
  const pageTranslation = (IS_CSAGENT) ? "CSAgenttranslation" : "translation";
  const {t} = useTranslation([pageTranslation])
  let result;
  isBonusPageFeatureSwitchEnabled ? 
    result = getPieChartDataWithBonus( 
      printedTotalPages,
      pagesUsed, 
      rolloverAllowance, 
      overage, 
      planPages, 
      givenRolloverPages, 
      purchasedOveragePages, 
      printedBonusPages)
    :
    result = getPieChartData(
      printedTotalPages,
      pagesUsed, 
      rolloverAllowance, 
      overage, 
      planPages, 
      givenRolloverPages, 
      purchasedOveragePages)
    
  let extractedData = result?.data;
  let extraData = result?.extra;
  let segmentBackgroundColor = result?.backgroundColor
  let monthlyAllowance = result?.monthlyAllowance
  let totalPagesUsed = result?.totalPagesUsed
  let labels = result?.labels
  const data = {
    labels: labels,
    datasets: [
      {
        data:extractedData ,
        extra:extraData,
        backgroundColor: segmentBackgroundColor,
        borderWidth: 0,
        totalPagesUsed: totalPagesUsed,
        monthlyAllowance: monthlyAllowance
      },
    ],
    
    
  };

  const doughnutLabelsLine = {
    id: 'doughnutLabelsLine',
    afterDraw(chart,args,pluginOptions) {
        const { ctx } = chart;
        ctx.save();
        chart.data.datasets.forEach((dataset,i)=>{
          chart.getDatasetMeta(i).data.forEach((datapoint,index) => {
              const eachData = dataset.extra[index]
              const radius = datapoint.innerRadius + (datapoint.outerRadius - datapoint.innerRadius) / 2;

              const centerX = datapoint.x;
              const centerY = datapoint.y;

              const angle = Math.PI * (eachData / chart.data.datasets[0].monthlyAllowance) + Math.PI;

              const x = centerX + radius * Math.cos(angle);
              const y = centerY + radius * Math.sin(angle);

              const length_of_line = 20
              const dx = length_of_line * Math.cos(angle);
              const dy = length_of_line * Math.sin(angle);
              const x1 = x + dx;
              const y1 = y + dy;

              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x1, y1);
              ctx.strokeStyle = '#000';
              ctx.lineWidth = 1;
              ctx.stroke();


              //DISPLAY LABELS OUTSIDE CHART----------------------------------------------------------
              const labelDistance = 10
              const labelOffsetX = Math.cos(angle) * labelDistance; // Adjust labelDistance as needed
              const labelOffsetY = Math.sin(angle) * labelDistance; // Adjust labelDistance as needed
              const labelX = x1 + labelOffsetX;
              const labelY = y1 + labelOffsetY;
              ctx.textAlign = "center";
              ctx.fillStyle = "#545454";
              ctx.font = `normal 14px Arial`;
              ctx.fillText(dataset.extra[index], labelX, labelY);

          })
        })
    }
  }

  const doughnutSideLable = {
    id: 'guageLabels',
    beforeDatasetsDraw(chart, args, pluginOptions){
      const {ctx, chartArea:{left, right}} = chart;
      ctx.save();

      function labels(text, alignment, position){
        ctx.fillStyle = "#545454";
        ctx.font = `normal 14px Arial`;
        ctx.textBaseLine = 'top'
        ctx.textAlign =alignment;
        ctx.fillText(text, position, chart.getDatasetMeta(0).data[0].y+20);
      }

      labels('0', 'left', left)
      labels(chart.data.datasets[0].monthlyAllowance, 'right', right)
    }
  }

  const doughnutCenterText = {
    id: 'centerText',
    
    beforeDraw(chart,args,pluginOptions) {
      const { ctx, chartArea: {left, right, height}} = chart;
      ctx.save();
      const fontSize = (height / 64).toFixed(2);
      ctx.font = fontSize + "em Arial";
      ctx.fillStyle = "#212121";
      ctx.fontWeight = "bolder"
      ctx.textBaseline = "middle";
      const text = chart.data.datasets[0].totalPagesUsed;
      ctx.textAlign = 'center'
          
      const textX = ((left + right) / 2);
      const textY = chart.getDatasetMeta(0).data[0].y - fontSize*14
      ctx.fillText(text, textX, textY);
      ctx.restore();

      const halfFontSize = ((height / 64)/3).toFixed(2);
      
      ctx.font = halfFontSize + "em Arial";
      ctx.fontWeight = "normal"
      ctx.textBaseline = "middle";
      const textInfo = t("PRINT_ACTIVITY.PAGES_USED_PROGRESS_BAR_TXT");
      ctx.textAlign = 'center'
      ctx.fillStyle = "#545454";

      const stringWidth = ctx.measureText(textInfo).width;
      const sidePadding = 20;
      const sidePaddingCalculated = (sidePadding / 100) * (chart.getDatasetMeta(0).data[0].innerRadius *2)
      const elementWidth = (chart.getDatasetMeta(0).data[0].innerRadius * 2) - sidePaddingCalculated;
    
      if(stringWidth>elementWidth){
        const words = textInfo.split(' ');
          let line = '';
          let lines = [];
          let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          let centerY = chart.getDatasetMeta(0).data[0].y - fontSize*4 + height/32
          let lineHeight = height/12
          for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + ' ';
            let metrics = ctx.measureText(testLine);
            let testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }
          centerY -= (lines.length / 2) * lineHeight;
          for (let n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          ctx.fillText(line, centerX, centerY);
          return;
      }

      const textInfoX = ((left + right) / 2);
      const textInfoY = chart.getDatasetMeta(0).data[0].y - fontSize*4 + height/64 
      ctx.fillText(textInfo, textInfoX, textInfoY);
      ctx.restore();
    }
  }

  const options = {
    layout:{
      padding:40,
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio:1.5,
    circumference: 180,
    cutout: '90%',
    rotation: -90,
    offset:0,
    borderRadius:0,
    borderWidth:0,
    spacing:-2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels:{ 
        display:false
      }
    }
  };

  return(
    <div className='half-doughnut-chart-wrapper'>
      <Doughnut 
      data={data} 
      options={options} 
      plugins={[doughnutLabelsLine,doughnutSideLable, doughnutCenterText]}
      style={{ width: '100%' }} />
    </div>
  )
   
};

export default HalfDoughnutChart;
