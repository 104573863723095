import React from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../shared/loader/Loader";
import './SubscriptionDetails.css'
import { DisplayDate } from "../../../../../../utils/DisplayDate";

const BrotherPlusSubscriptionDetails = ({ getUserSubscriptionDetails }) => {
    const { t } = useTranslation("CSAgenttranslation");

    const { subscriptionDetails: detailsWrapper, error, loading } = getUserSubscriptionDetails || {};
    const subscriptionDetails = detailsWrapper?.subscriptionDetails || [];

    if (loading) {
        return <div><Loader/></div>;
    }

    if (error) {
       return <div className="email-log-error">{t("ERROR_MESSAGES.NO_SUBSCRIPTION_INFO_AVAILABLE")}</div>;
    }
    return (
        <div>
    {subscriptionDetails.map((detail, index) => {
        const { enrollmentStatus, enrollmentDate, cancelledDate, serialNumber } = detail;

        return (
            <div key={index} className="subscription-card">
               {(enrollmentStatus === "EnrollmentSuccessful" && enrollmentStatus === "EnrollmentCancelled" && enrollmentStatus === "InActive") &&
                <div className="subscription-card-row">
                    <span className="subscription-card-label">
                        {t("CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_SUBSCRIPTION_DETAILS.SERIAL_NUMBER")}:
                    </span>
                    <span className="subscription-card-value">{serialNumber}</span>
                </div>}
                <div className="subscription-card-row">
                    <span className="subscription-card-label">
                        {t("CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_SUBSCRIPTION_DETAILS.ENROLLMENT_STATUS")}:
                    </span>
                    <span className="subscription-card-value">
                        {t(`CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_SUBSCRIPTION_DETAILS.STATUS.${enrollmentStatus}`)}
                    </span>
                </div>
                {enrollmentStatus === "EnrollmentSuccessful" && (
                    <div className="subscription-card-row">
                        <span className="subscription-card-label">
                            {t("CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_SUBSCRIPTION_DETAILS.ENROLLMENT_DATE")}:
                        </span>
                        <span className="subscription-card-value">
                        {DisplayDate(enrollmentDate, { day: '2-digit', month: 'long', year: 'numeric' }) || "N/A"}
                        </span>
                    </div>
                )}
                {enrollmentStatus === "EnrollmentCancelled" && (
                    <div className="subscription-card-row">
                        <span className="subscription-card-label">
                            {t("CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_SUBSCRIPTION_DETAILS.CANCELLED_DATE")}:
                        </span>
                        <span className="subscription-card-value">
                        {DisplayDate(cancelledDate, { day: '2-digit', month: 'long', year: 'numeric' }) || "N/A"}
                        </span>
                    </div>
                )}
                {enrollmentStatus === "InActive" && (
                    <div className="subscription-card-row">
                        <span className="subscription-card-label">
                            {t("CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_SUBSCRIPTION_DETAILS.INACTIVE_STATUS")}
                        </span>
                    </div>
                )}
                {index < subscriptionDetails.length - 1 && <div className="subscription-card-divider"></div>}
            </div>
        );
    })}
</div>

    );
};

export default BrotherPlusSubscriptionDetails;
