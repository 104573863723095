import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    subscriptionInfo: {},
    loading: false,
    error: null,
}

const SubscriptionDetails = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SUBSCRIPTION_DETAILS_INIT:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                subscriptionInfo: action.subscriptionInfo,
                loading: false,
                error: null
            }
        
        case actionTypes.FETCH_SUBSCRIPTION_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                subscriptionInfo: {}
            }
        case actionTypes.RESET_STATE:
            return { ...state, 
                loading: false,
                error:null,
                subscriptionInfo: {}
              };
        default:
            return {...state}
    }
}

export default SubscriptionDetails;