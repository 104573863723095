import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { PAYMENT_OPTIONS } from "../../../../constants/AppConstants";
import { useDispatch } from "react-redux";
import { ZuoraPaymentInfoRendering } from "../../../../actions/MyAccount";
import { paymentOptionsNameTranslation } from "../../../../constants/AppMethod";
import ZuoraPaymentForm from "../ZuoraPaymentForm";
import { useTranslation } from "react-i18next";

const PaymentOptionsTab = (props) => {
    const { t } = useTranslation(['translation']);
    const { cname='none', paymentOptions, zrenderParams, signUpType, paymentMethod } = props;
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState((paymentMethod) ? paymentMethod : PAYMENT_OPTIONS.CREDIT_CARD);
    const [tabContents, setTabContents] = useState({[PAYMENT_OPTIONS.CREDIT_CARD]: (paymentMethod && paymentMethod === PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER) ? false : true, [PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER]: (paymentMethod && paymentMethod === PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER) ? true :false});

    const handlePaymentOptions = (paymentType) => {
        if(paymentType === activeTab){
            setActiveTab(paymentType);
        } else {
            setActiveTab(paymentType);
            setTabContents(
                Object.fromEntries(Object.entries(tabContents).map(([key, val]) => {
                    return [key, (key === paymentType) ? true : false];
                }))
            );
            dispatch(ZuoraPaymentInfoRendering(paymentType));
        }
    }
    return (
        <div id="payment-options-tab" className={`payment-options-comp payment-options-tab-wrapper zuora-payment-wrapper ${cname}`}>
            <h2>{t('PAYMENT_OPTIONS.SELECT_PAYMENT_METHOD')}</h2>
            <div className="payment-option-tab-btn-container tabs-section">
                <div id="payment-options-tab-btns" className="tab-btn-wrapper">
                    <Nav tabs>
                        {
                            paymentOptions.data.length > 0 && paymentOptions.data.map((val) => (
                                <NavItem>
                                    <NavLink active= {activeTab === val.paymentMethodName} onClick={() => handlePaymentOptions(val.paymentMethodName)}>
                                        {paymentOptionsNameTranslation(val.paymentMethodName)}
                                    </NavLink>
                                </NavItem> 
                            ))
                        }
                    </Nav>
                </div>
                <div className="payment-option-tab-content-wrapper">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={PAYMENT_OPTIONS.CREDIT_CARD}>
                            { zrenderParams.loading &&
                                <div className="payment-options-spinner-wrapper">
                                    <div className="spinner-border" role="status">
                                    </div>
                                </div>
                            }
                            { !zrenderParams.loading 
                                && tabContents[PAYMENT_OPTIONS.CREDIT_CARD]
                                && <ZuoraPaymentForm signUpType={signUpType}/> 
                            }
                        </TabPane>
                        <TabPane tabId={PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER}>
                            { zrenderParams.loading &&
                                <div className="payment-options-spinner-wrapper">
                                    <div className="spinner-border" role="status">
                                    </div>
                                </div>
                            }
                            { !zrenderParams.loading 
                                && tabContents[PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER]
                                && <ZuoraPaymentForm signUpType = {signUpType}/> 
                            }
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default PaymentOptionsTab;