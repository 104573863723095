import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DisplayDate } from "../../../../../utils/DisplayDate";
import EcoProButton from "../../../../shared/button/button";
import EcoProOverlayModal from "../../../../shared/modal/OverlayModal/EcoProOverlayModal";
import Feedback from "../../../../../pages/Account/CancelPlan/Feedback/Feedback";
import { useSelector } from "react-redux";
import Loader from "../../../../shared/loader/Loader";
import { currentUserRolesArr } from "../../../../../constants/AppMethod";
import { canAccess_CancelSubscription } from "../../../../../constants/UserRoles";
import { Card, CardHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import {Nav, NavItem, NavLink, TabContent,TabPane} from 'reactstrap';



const SubscriptionLineItem = ({label, value, classNameValues = ""}) => {
    return (
        <ListGroupItem>
            <p>{label}: 
                <span className={`${classNameValues}`}>{value}</span>
            </p>
        </ListGroupItem>
    )
}

const SubscriptionDetails = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const NOT_AVAILABLE = "N/A";
    const ACTIVE = 'ACTIVE'; 
    const customerSubscriptionInfo = useSelector(state => state.CSAGENT.SubscriptionDetails);
  
    const TABS = {
        'SUBSCRIPTION_INFO': 'SUBSCRIPTION_INFO',
        "CANCEL_SUBSCRIPTION": 'CANCEL_SUBSCRIPTION'
    }
     const [activeTab, setActiveTab] = useState(TABS.SUBSCRIPTION_INFO);
    const ENROLLMENT_STATUS = {
        'PENDING_ENROLLMENT': 'PendingEnrollment'
    }
    const enrollmentStatus_InActiveClasses = [
        'cancelled',
        'inactive',
        'pendingenrollment',
        'pendingactivation',
        'pendingcancellation',
        'enrollmentcancelled',
        'enrollmentfailed'
    ];

    const {
        subscriptionDetails: [{enrollmentStatus, serialNumber: deviceSerialNumber} = {}] = []
    } = customerSubscriptionInfo?.subscriptionInfo|| {};
  
    /**
     * Extracted Customer info
     */
    const { displayName, emailAddress, deviceId, countryCode, userId, businessPartnerId: customerBPID } = props?.customerInfo || {};
    
    const cancelOrderResponse = useSelector((state) => state.CancelOrder);
    const [callCancelOrder, setcallCancelOrder] = useState(true);
    const currentUserRoles = currentUserRolesArr();
    const CAN_ACCESS_CANCEL_SUBSCRIPTION = canAccess_CancelSubscription(enrollmentStatus, currentUserRoles);
    const [modalState, setModalState] = useState(false);
    const [modalProps, setModalProps] = useState({
        modal_title: "",
        modal_sub_title: "",
        modal_content: "",
        cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
        confirm_btn_text: t("MODAL_BUTTON_TXT.CONFIRM"),
        toggleModal: "",
        customClassName: "",
        confirm_btn_onClick: "",
        type: "normal"
    })
    const customerInfo = {
        'deviceId': deviceId, 
        'customerCountryCode': countryCode,
        'customerUserId': userId,
        'confirmStatus': true,
        'customerBP_ID': customerBPID
    }
    useEffect(() => {
        if(cancelOrderResponse.loading){
            setModalState(false);
        }
    }, [cancelOrderResponse.loading]);
    
    const constructUserInfoContent = (name, emailId, serialNumber) => {
        return <>
            <p>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.MODAL_CONTENT")}</p>
            <ul>
                <li><span>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.NAME")}:&nbsp;&nbsp;&nbsp;</span> <span>{name}</span></li>
                <li><span>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.EMAIL")}:&nbsp;&nbsp;&nbsp;</span> <span>{emailId}</span></li>
                <li><span>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.SERIAL_NUMBER")}:&nbsp;&nbsp;&nbsp;</span> <span>{serialNumber}</span></li>
            </ul>
        </>
    }
    const toggleModal = (event) => {
        setModalState(false);
    }
    const confirmedCancellation = () => {
        setModalState(true);
        setModalProps({
            modal_title: t("CONFIRM_CANCELLATION.CANCELLATION_FEEDBACK_MODAL_TITLE"),
            modal_content: <Feedback {...customerInfo}/>,
            toggleModal: toggleModal,
            customClassName: "subscription-cancellation-feedback",
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL")
        });
     }
    const handlePlanCancellationModal = () => {
        setModalState(true);
        setModalProps({
            modal_title: t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.MODAL_TITLE"),
            modal_content: constructUserInfoContent(displayName, emailAddress, deviceSerialNumber),
            toggleModal: toggleModal,
            customClassName: "subscription-cancellation-confirmation",
            confirm_btn_onClick: confirmedCancellation,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:   t("MODAL_BUTTON_TXT.CONTINUE"),
        });
    }
    const handleEnrollmentText = (enrollmentStatus) => {
        let EnrollmentStatus_Txt = null, enrollmentTypeClass = null;
        if( enrollmentStatus === ENROLLMENT_STATUS.PENDING_ENROLLMENT){
            EnrollmentStatus_Txt = t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.PRE_ENROLLMENT_COMPLETED');
            enrollmentTypeClass = "preenrollment";
        } else {
            if(enrollmentStatus?.indexOf("InActive") > -1){
                EnrollmentStatus_Txt = t(`CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.INACTIVE`);
                enrollmentTypeClass = "inactive";
            } else {
                if(enrollmentStatus){
                    EnrollmentStatus_Txt = t(`CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.${enrollmentStatus}`)
                    enrollmentTypeClass = (enrollmentStatus_InActiveClasses.indexOf(enrollmentStatus?.toLowerCase()) > -1) ? "inactive": "active";
                } else {
                    EnrollmentStatus_Txt =  'N/A';
                    enrollmentTypeClass = 'inactive'
                }
            }   
        }
        return {EnrollmentStatus_Txt, enrollmentTypeClass}
    }
    const handleSubscriptionStatus = (subscriptionStatus) => {
        let SubscriptionStatus_Txt = null, subscriptionTypeClass = null;
        if(subscriptionStatus){
            SubscriptionStatus_Txt = t(`CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.${subscriptionStatus}`)
            subscriptionTypeClass = (subscriptionStatus.toUpperCase() === ACTIVE) ? subscriptionStatus?.toLowerCase() : 'preenrollment'
        }
        else{
            SubscriptionStatus_Txt = 'N/A';
            subscriptionTypeClass = 'inactive';
        }
        return {SubscriptionStatus_Txt, subscriptionTypeClass}
    }
    if((cancelOrderResponse.success || cancelOrderResponse.error) && callCancelOrder){
       setModalState(true);
        setModalProps({
            modal_title: (cancelOrderResponse.success) 
                            ? t('CONFIRM_CANCELLATION.CANCELLATION_COMPLETION_TITLE.SUCCESS') 
                            : t('CONFIRM_CANCELLATION.CANCELLATION_COMPLETION_TITLE.ERROR'),
            modal_content: <>
                                <p>
                                    {
                                        (cancelOrderResponse.success) 
                                        ? t("CONFIRM_CANCELLATION.CANCELLATION.SUCCESS") 
                                        : t("CONFIRM_CANCELLATION.CANCELLATION.ERROR")
                                    }
                                </p>
                                <p>{t('CONFIRM_CANCELLATION.DATA_REFRESHING_CONTENT')}</p>
                            </>,
            toggleModal: toggleModal,
            customClassName: "subscription-cancellation-final-status"
        });
        setcallCancelOrder(false);
        setTimeout(() => {
            window.location.reload();
        }, 6000);
    }
    if (customerSubscriptionInfo.loading) {
        return <Loader />;
    }
    if (customerSubscriptionInfo.error) {
        return <div className="email-log-error">{t("ERROR_MESSAGES.NO_ACTIVE_SUBSCRIPTION")}</div>;
    }
    return (
        <div className="overview-container subscription-details-wrapper mt-40">
            { cancelOrderResponse.loading && <Loader setOverlay={true}></Loader> }
            <div id="subscription-billing-details">
                <div className="tab-btn-wrapper">
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink active={activeTab === TABS.SUBSCRIPTION_INFO} onClick={() => setActiveTab(TABS.SUBSCRIPTION_INFO)}>
                                    {t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.TABS.SUBSCRIPTION_INFO')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === TABS.CANCEL_SUBSCRIPTION} onClick={() => setActiveTab(TABS.CANCEL_SUBSCRIPTION)}>
                                    {t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.TABS.CANCEL_SUBSCRIPTION')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <div className="subscription-info-content-wrapper">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={TABS.SUBSCRIPTION_INFO}>
                            {
                                customerSubscriptionInfo?.subscriptionInfo?.subscriptionDetails?.length > 0
                                ? (
                                    customerSubscriptionInfo?.subscriptionInfo?.subscriptionDetails?.map((info, index) => (
                                        <Card key={index} className="mt-30">
                                            <CardHeader>
                                                {t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.SERIAL_NUMBER')} :
                                                {info?.serialNumber || NOT_AVAILABLE}
                                            </CardHeader>
                                            <ListGroup flush>
                                                <div className="device-details-info mt-15">
                                                    <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.PLAN_TYPE')} value = {t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.CURRENT_PLAN_NAME', { plan_name: info?.subscriptionPlan?.planName || NOT_AVAILABLE, plan_pages: info?.subscriptionPlan?.planPages || NOT_AVAILABLE })} 
                                                    />
                                                    <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.SUBSCRIPTION_NUMBER')} 
                                                        value= {info?.subscriptionNumber || NOT_AVAILABLE}
                                                    />
                                                    {(info?.enrollmentDate) 
                                                        ? <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.DATE_STARTED')} 
                                                        value={DisplayDate(info?.enrollmentDate, { day: '2-digit', month: 'long', year: 'numeric' }) || NOT_AVAILABLE}
                                                        /> : null
                                                    }
                                                     <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.DEVICE_TYPE')}
                                                        value={info?.enrollmentStatus==="EnrollmentSuccessful" ? info?.userSubscription ? info?.userSubscription?.deviceOption?.toUpperCase() : NOT_AVAILABLE : NOT_AVAILABLE}
                                                        classNameValues = {`label-value subscription-type-text active`}
                                                    />
                                                    <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.ENROLLMENT_STATUS')} 
                                                        value={handleEnrollmentText(info?.enrollmentStatus)?.EnrollmentStatus_Txt || NOT_AVAILABLE}
                                                        classNameValues = {`label-value subscription-type-text ${handleEnrollmentText(info?.enrollmentStatus)?.enrollmentTypeClass}`}
                                                    />
                                                    <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_STATUS')} 
                                                        value={handleSubscriptionStatus(info?.subscriptionStatus)?.SubscriptionStatus_Txt || NOT_AVAILABLE}
                                                        classNameValues = {`label-value subscription-type-text ${handleSubscriptionStatus(info?.subscriptionStatus)?.subscriptionTypeClass}`}
                                                    />
                                                    {(info?.activatedDate) 
                                                        ? <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_ACTIVATION_DATE')} 
                                                        value={DisplayDate(info?.activatedDate, { day: '2-digit', month: 'long', year: 'numeric' }) || NOT_AVAILABLE}
                                                        /> : null
                                                    }
                                                    {(info?.delayedCancellationRequestDate) 
                                                        ? <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.CANCELLATION_SERVICE_REQUESTED_DATE')} 
                                                        value={DisplayDate(info?.delayedCancellationRequestDate, { day: '2-digit', month: 'long', year: 'numeric' }) || NOT_AVAILABLE}
                                                        /> : null
                                                    }
                                                    {(info?.delayedCancellationDate) 
                                                        ? <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.CANCELLATION_SERVICE_END_DATE')} 
                                                        value={DisplayDate(info?.delayedCancellationDate, { day: '2-digit', month: 'long', year: 'numeric' }) || NOT_AVAILABLE}
                                                        /> : null
                                                    }
                                                    {(info?.cancelledDate) 
                                                    ? <SubscriptionLineItem 
                                                        label={t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_CANCELLED_DATE')} 
                                                        value={DisplayDate(info?.cancelledDate, { day: '2-digit', month: 'long', year: 'numeric' }) || NOT_AVAILABLE}/> 
                                                    : null
                                                    }
                                                </div>
                                            </ListGroup>
                                        </Card>
                                    ))
                                )
                                : (
                                    <div className="email-log-error">{t("ERROR_MESSAGES.NO_SUBSCRIPTION_INFO_AVAILABLE")}</div>
                                )
                            }
                        </TabPane>
                        <TabPane tabId={TABS.CANCEL_SUBSCRIPTION}>
                            {   
                                (CAN_ACCESS_CANCEL_SUBSCRIPTION) 
                                ? <div id="subscription-cancellation-wrapper" className="mt-30">
                                    <div className="header">
                                        <div className="title">
                                            <h1>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_CANCELLATION.header')}</h1>
                                        </div>
                                    </div>
                                    <div className="cancellation-btn">
                                        <EcoProButton cname="default" btnID="csagent-subscription-cancellation-btn" onBtnClick={(event) => handlePlanCancellationModal(event)}>{t('MODAL_BUTTON_TXT.CANCEL')}</EcoProButton>
                                    </div>
                                </div> : <div className="email-log-error mt-30">{t("ERROR_MESSAGES.NO_ACTIVE_SUBSCRIPTION")}</div>
                            }
                        </TabPane>
                    </TabContent>
                </div>
            </div>
            <EcoProOverlayModal 
                    {...modalProps} 
                    modal_state={modalState}
            ></EcoProOverlayModal>
        </div>
        
    )
}

export default SubscriptionDetails;