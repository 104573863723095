import { createSlice } from '@reduxjs/toolkit';

const GetUserPrintHistorySlice = createSlice({
  name: 'fetchUserPrintHistory',
  initialState: {
    loading: false,
    printHistory: null,
    error: null,
    success: false,
  },
  reducers: {
    fetchUserPrintHistoryRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserPrintHistorySuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.printHistory = action.payload;
    },
    fetchUserPrintHistoryFailure(state, action) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchUserPrintHistoryRequest,
  fetchUserPrintHistorySuccess,
  fetchUserPrintHistoryFailure,
} = GetUserPrintHistorySlice.actions;

export default GetUserPrintHistorySlice.reducer;
