import React from 'react';
import './PromotionBanner.css'
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
 
const PromotionBanner = (props) => {
    const { t } = useTranslation(['translation']);
    const FeatureSwitchByMultipleValues = useSelector((state) => state.FeatureSwitchByMultipleValues)
    const { featureSwitchByMultipleValues } = FeatureSwitchByMultipleValues;
    const PromotionBanners = featureSwitchByMultipleValues?.PromotionBanners || false;
    const BonusPages = featureSwitchByMultipleValues?.BonusPages || false
    const { toDisplayfreeTrialButton = false, StartFreeTrialButton } = props
 
    return (
        <>
           
                <div className={`promotion-banner-wrapper ${toDisplayfreeTrialButton ? 'promotion-banner-with-button' : ""}`}>
                    <div className='promotion-text'>
                        {
                            PromotionBanners ?
                                <>
                                    <div className='banner-parent'>
                                        <div>
                                            <Trans i18nKey="PROMOTION_BANNER.LIMITED_OFFER">
                                                Limited-time offer: Exclusive  <strong>6 months Free</strong> EcoPro subscription
                                            </Trans>
                                        </div>
                                        {BonusPages ?
                                            <>
                                                <div>{t("PROMOTION_BANNER.BONUS_PAGES")}
                                                </div>
                                            </>
                                            : null}
                                    </div>
 
                                </>
                                :
                                <>
 
                                    {
                                        BonusPages ?
                                            <>
                                                <div className='banner-parent'>
                                                    <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
                                                    <div>{t("PROMOTION_BANNER.BONUS_PAGES")}
                                                    </div>
                                                    <div class="limited-offer">{t("PROMOTION_BANNER.LIMITED_TIME_OFFER")}</div>
                                                </div>
 
                                            </>
                                            :
                                            <>
                                             <div className='banner-parent'>
                                             <div>{t("PROMOTION_BANNER.TWO_OR_FOUR_MONTHS_PROMOTION")} </div>
                                             <div class="limited-offer">{t("PROMOTION_BANNER.LIMITED_TIME_OFFER")}</div>
                                             </div>
 
                                            </>
                                    }
 
 
 
                                </>
                        }
                    </div>
                    {toDisplayfreeTrialButton ? StartFreeTrialButton : null}
                </div>
            
        </>
 
    );
};
 
export default PromotionBanner;
 