import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as RightArrow } from "../../assets/images/rightArrow.svg";
import {
  convertToKG,
  getCarbonSavingTypeTranslation,
} from "../../constants/AppMethod";
import QuestionMarkIcon from "../../assets/images/question-mark.svg";
import "./DisplayCarbonOffsetting.css";
import { DisplayDate } from "../../utils/DisplayDate";
import { UNIQUE_ID_GA } from "../../constants/AppConstants";


const DisplayCarbonOffsetting = (props) => {
  const { onHowToCalculate, carbonSavings } = props;
  const { t } = useTranslation(["translation"]);
  const dateDisplayOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  
  return (
    <div className="carbon-offsetting">
      <div className="header">
        <div className="f-14">{t("CARBON_OFFSETTING.HEADER")}</div>
        <div className="carbon-value">
          {convertToKG(carbonSavings.totalCO2eSavings)}
          {t("CARBON_OFFSETTING.KG")}
        </div>
      </div>
      <div
        id={UNIQUE_ID_GA.ecoSaving+"-how-co2-calculated"}
        className="co2-calculated pointer-cursor"
        onClick={() => onHowToCalculate()}
      >
        <div className="question-bg">
          <div className="question-bg-img">
              <img src={QuestionMarkIcon} alt="carbon-offsetting" />
          </div>
        </div>
        <div className="question-title">
          {t("CARBON_OFFSETTING.HOW_CALCULATED")}
        </div>
        <div className="right-arrow">
          <RightArrow fill="#ffffff" />
        </div>
      </div>
      <div className="co2-info  co2-calculated-border">
        {carbonSavings?.userCarbonSavingSummary && 
         carbonSavings?.userCarbonSavingSummary.length === 0 ? (
          <p className="info-error-message">{t("ERROR_CONTENT.NO_DATA")}</p>
        ) : (
          carbonSavings?.userCarbonSavingSummary && 
          carbonSavings.userCarbonSavingSummary.map((carbonSaving, index) => (
            <div className="row" key={index}>
              <div className="col-8 mt-15">
                <div className="f-14 ta-left">
                    {getCarbonSavingTypeTranslation(carbonSaving?.carbonSavingType)}
                </div>
                <div className="supplies-date f-12 ta-left">
                  {DisplayDate(carbonSaving.date,dateDisplayOptions,'en-GB')}
                </div>
              </div>
              <div className="col supplies-kg mt-15">{`+${convertToKG(
                carbonSaving.cO2e
              )}${t("CARBON_OFFSETTING.KG")}`}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DisplayCarbonOffsetting;
