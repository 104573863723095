import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import { _CALLGETAPI } from '../../../api/apiHandler';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, SUCCESS, WEB_CUSTOMER_API_ENDPOINT, _HARDWARE_API_ENDPOINTS } from '../../../constants/AppConstants';
import { getLocalSessionData, getUserDataByKey, getUserSessionData } from '../../../utils/Authentication/handleUserSession';
import { addPromotionsToPayload, getPromotionsArray, Is_HWInclusive_Signup } from '../../../constants/AppMethod';

function _GETDASHBOARDPREENROLLMENTINFO(){
    const IsHWInclusive_Signup = Is_HWInclusive_Signup();
    const endpoint = (IsHWInclusive_Signup) ? _HARDWARE_API_ENDPOINTS.GET_DASHBOARD_PREENROLL_INFO : WEB_CUSTOMER_API_ENDPOINT.GET_DASHBOARD_PRE_ENROLL_INFO;
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const businessModel = getUserDataByKey('businessModel');
    const planType = 'new';
    const printerModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    // const promotion = ( printerModel && printerModel[printerModel.length - 1] === "E") ? READY_PROMOTION.promotion : "";
    const promotion=getPromotionsArray(printerModel)
    const payload = {
        params: {
            userId: UID,
            personalOrBusiness: businessModel,
            planType: planType,
            model: printerModel
        }
      }
      addPromotionsToPayload(payload,promotion);
      
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handlePreEnrollmentInfo(action){
    try{
        const preEnrollInfoDetails = yield call(_GETDASHBOARDPREENROLLMENTINFO);
        if(preEnrollInfoDetails.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_SUCCESS, preEnrollInfo: preEnrollInfoDetails});
        } else {
            yield put({type: actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_ERROR, message: e.message});
        }
    }
}

function* GetDashboardPreEnrollInfo(){
    yield takeEvery(actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_INIT, handlePreEnrollmentInfo);
}

export default GetDashboardPreEnrollInfo;