import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getLocalSessionData } from "../../../../utils/Authentication/handleUserSession";
import { LOCALSESSIONDATAKEYS, UPGRADE_TO_ECOPRO } from "../../../../constants/AppConstants";
import { routePath } from "../../../../constants/Routes";
import './UpgradeToEcoProBlock.css';
import EcoProButton from "../../../shared/button/button";
import printerImg from "../../../../assets/images/BrotherPlus/ecopro-upgrade-tile-image.svg";
import arrowIcon from "../../../../assets/images/BrotherPlus/ecopro-upgrade-tile-arrow-icon.svg"
import { useDispatch, useSelector } from "react-redux";
import { FetchUserSubscriptionInfoInit } from "../../../../actions/Web/Customer/SignUp";
import { Upgrade_To_Ecopro_Status } from "../../../../actions/Dashboard";
import { FetchUserAccount } from "../../../../actions/UserAccount";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const UpgradeToEcoProBlock = () => {
    const { t } = useTranslation(["translation"]);
    const dispatch = useDispatch();
    const history = useHistory();
    const UID = getLocalSessionData(LOCALSESSIONDATAKEYS.UID);
    const [showUpgradeBlock, setShowUpgradeBlock] = useState(false);
    const UserSubscriptionInfo = useSelector((state) => state.UserSubscriptionInfo);
    const UserAccountResult = useSelector((state) => state.UserAccount);
    const AZURE_AD_B2C_UID = getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);

    useEffect(() => {
        dispatch(FetchUserSubscriptionInfoInit(UID));
         // eslint-disable-next-line
    }, []);

    useEffect(() => {
       const upgradeBlockStatus = (UserSubscriptionInfo.success) ? true : false;
       setShowUpgradeBlock(upgradeBlockStatus);
         // eslint-disable-next-line
    }, [UserSubscriptionInfo]);

    useEffect(() => {
        if(UserAccountResult.error){
            dispatch(Upgrade_To_Ecopro_Status(false));
        }
    }, [dispatch, UserAccountResult])

    const handleUpgrade = () => {
        dispatch(Upgrade_To_Ecopro_Status(true));
        dispatch(FetchUserAccount(AZURE_AD_B2C_UID, UID, deviceId, UPGRADE_TO_ECOPRO));
        history.push({pathname: routePath.REDIRECTION, state:{'upgradeTo_EcoProProcess': true}});
    }
    return (
        <>
            {
                UserSubscriptionInfo?.loading ? (
                    <div className="device-not-connected-wrapper connect-app-section skeleton-web-connection brother-plus-enrolled-device-loader">
                        <div className="printer-icon">
                            <Skeleton square width="90px" height="90px" className="display-device-img-skeleton" />
                        </div>
                        <div className="content">
                            <Skeleton className="rolled-over-overages-skeleton" />
                            <Skeleton className="rolled-over-overages-skeleton" />
                        </div>
                    </div>
                ) : showUpgradeBlock ? (
                    <div className="ecopro-upgrade-block-wrapper">
                        <div className="contents-wrapper">
                            <h2 className="h2">{t('BROTHER_PLUS.DASHBOARD.UPGRADE_TO_ECOPRO_BLOCK.HEADER')}</h2>
                            <div className="contents">
                                <p className="save-more-txt body-large">{t('BROTHER_PLUS.DASHBOARD.UPGRADE_TO_ECOPRO_BLOCK.SAVE_MORE_TXT')}</p>
                                <p className="body-medium">{t('BROTHER_PLUS.DASHBOARD.UPGRADE_TO_ECOPRO_BLOCK.OFFER_CONTENT')}</p>
                            </div>
                            <div className="button-wrapper">
                                <EcoProButton cname="ecopro-upgrade-now-btn" onBtnClick={handleUpgrade}>
                                    <span className="body-medium">{t('BROTHER_PLUS.DASHBOARD.UPGRADE_TO_ECOPRO_BLOCK.UPGRADE_BTN_TXT')}</span>
                                    <img src={arrowIcon} alt="arrow" title="arrow" />
                                </EcoProButton>
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img alt="upgrade-img" title="upgrade-img" src={printerImg} />
                        </div>
                    </div>
                ) : null
            }
        </>
    );    
}

export default UpgradeToEcoProBlock;