import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { setLocalSessionData } from '../../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';

const PagesLeft = (props) => {
    const {deviceInfoResponse} = props
    const subscriptionInfo = deviceInfoResponse?.response
    const { t } = useTranslation(["translation"]);

    let planPages= 0,  planPagesLeft= 0, planCurrency='';

    const checkDeviceInfoProps = (key, dataObject) => {
      const defaultVal = (key === "planName") ? 'N/A' : (key === "currency") ? '' : 0;
      return (dataObject.hasOwnProperty(key) && dataObject[key]) ? dataObject[key] : defaultVal;
    }

    if(deviceInfoResponse?.success && Object.keys(subscriptionInfo).length > 0 && subscriptionInfo?.service !== null){
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentPlan')
          && subscriptionInfo?.service?.currentPlan !== null) {
          planCurrency = checkDeviceInfoProps("currency", subscriptionInfo.service.currentPlan);
          planPages = checkDeviceInfoProps("planPages", subscriptionInfo.service.currentPlan);
          setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY,planCurrency)
      }
      if(subscriptionInfo?.service && subscriptionInfo?.service.hasOwnProperty('currentUsage') 
          && subscriptionInfo?.service?.currentUsage !== null) {
          planPagesLeft = checkDeviceInfoProps("printedPlanPages", subscriptionInfo.service.currentUsage);
      }
  }

  let pagesLeftProgressVal = 0;
  pagesLeftProgressVal = (planPagesLeft * 100) / planPages || 0;
    
    return (
        <div className="pages-left">
            <h2 className="pages-left-lg h2">{t("DASHBOARD.PAGES_USED")}</h2>
            {deviceInfoResponse?.loader ? (
              <div className="circular-skeleton-flex pages-used-block-loader">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : 
            deviceInfoResponse?.success ?
            (
              <CircularProgressbarWithChildren
                value={pagesLeftProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">{planPagesLeft}</span>
                <span className="pages-leftover-count">/{planPages}</span>
              </CircularProgressbarWithChildren>
            )
            : null
            // <div className="info-error-message pages-left-error">{t("ERROR_CONTENT.NO_DATA")}</div>
          }
          </div>
    );
};

export default PagesLeft;