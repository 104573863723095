import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
/**
 * SAMPLE RESPONSE:
 * {
    "preEnrollInfoViewModel": {
        "id": "EM-00003504",
        "userId": "9aa8f422-0592-4d52-adcf-7ae54946854c",
        "date": "2024-10-18T11:53:52+02:00",
        "kind": "web_signup",
        "deviceOption": null,
        "orderNumber": null,
        "parameter": {
            "planId": "8adcc1d186255e7e01862f9fadc635df",
            "discountPlanIds": [
                "8adcddea7d4c63ae017d4ef070cc1d13"
            ],
            "shippingAddress": {
                "addressNumber": null,
                "active": null,
                "firstName": null,
                "lastName": null,
                "street1": "a1",
                "street2": "a2",
                "city": "c1",
                "state": null,
                "stateName": null,
                "postalCode": "WC2N5DU",
                "country": "United Kingdom",
                "currency": null,
                "companyName": null,
                "languageCode": null,
                "businessModel": 0,
                "vatId": null,
                "taxId": null,
                "sapBpId": null,
                "eInvoiceId": null,
                "homePhone": null,
                "mobilePhone": null,
                "nameTag": "",
                "actionStatus": "",
                "actionMessage": "",
                "customCode": ""
            }
        },
        "nameTag": "",
        "actionStatus": "Success",
        "actionMessage": "Data fetched successfully.",
        "customCode": ""
    },
    "plan": {
        "planId": "8adcc1d186255e7e01862f9fadc635df",
        "discountPlanIds": [
            "8adcddea7d4c63ae017d4ef070cc1d13"
        ],
        "overagePrice": "0.89",
        "currency": "GBP",
        "price": "2.99",
        "planImageUrl": "https://ecoproeudevstorage.blob.core.windows.net/dev/images/plans/Default.png",
        "name": "Multi_Attribute_Occasional_k0",
        "description": "",
        "planPages": "50",
        "planName": "Occasional",
        "translatedPlanName": null,
        "planGrade": "10",
        "overageBlockPages": "10",
        "overageThreshold": "10",
        "rolloverRule": "2",
        "planCategory": "Basic",
        "rollOverPages": "100",
        "overageUnit": null,
        "isRecommended": false,
        "hiddenPlan": false,
        "discount": [
            {
                "planId": "8adcddea7d4c63ae017d4ef070cc1d13",
                "name": "XmonthsFreePromotion_k0",
                "description": "",
                "effectiveStartDate": "2000-01-01",
                "effectiveEndDate": "2099-12-31",
                "planCategory": "CampaignReplace",
                "chargeModel": null,
                "discountPercentage": null,
                "discountLabel": null,
                "endDateCondition": null,
                "upToPeriods": null,
                "upToPeriodsType": null,
                "triggerEvent": null,
                "serviceActivateDate": null,
                "promotionEndDate": null,
                "planName": "XmonthsFreePromotion",
                "hiddenPlan": false
            }
        ]
    },
    "nameTag": "",
    "actionStatus": "Success",
    "actionMessage": "Data fetched successfully.",
    "customCode": ""
}
 */
const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const GetDashboardPreEnrollInfo = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_INIT:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.preEnrollInfo,
                'error': false
            }
        case actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                'data': {}
            }
        default:
            return {...state};
    }
}

export default GetDashboardPreEnrollInfo;