import { createSlice } from '@reduxjs/toolkit';

const featureSwitchByMultipleValuesSlice = createSlice({
  name: 'featureSwitchByMultipleValues',
  initialState: {
    loading: false,
    featureSwitchByMultipleValues: null,
    error: true,
  },
  reducers: {
    fetchFeatureSwitchByMultipleValuesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFeatureSwitchByMultipleValuesSuccess(state, action) {
      state.loading = false;
      state.featureSwitchByMultipleValues = action.payload;
      state.error=false;
    },
    fetchFeatureSwitchByMultipleValuesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.featureSwitchByMultipleValues=false;
    },
  },
});

export const { fetchFeatureSwitchByMultipleValuesRequest, fetchFeatureSwitchByMultipleValuesSuccess, fetchFeatureSwitchByMultipleValuesFailure } = featureSwitchByMultipleValuesSlice.actions;
export default featureSwitchByMultipleValuesSlice.reducer;
