import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { 
  LOCALSESSIONDATAKEYS,  
  SIGNUPTYPE, 
  refreshBtn, 
  tryAgainBtn 
} from "../../../../../../constants/AppConstants";
import { setLocalSessionData, WEB_handleSelectedPlanLocalData } from "../../../../../../utils/Authentication/handleUserSession";
import { WebCustomerRoutePaths } from "../../../../../../constants/Routes";
import Loader from "../../../../../../components/shared/loader/Loader";
import WebSubHeader from "../../../../../../components/WebSubHeader/WebSubHeader";
import ListPlan from "../../../../../../components/Web/Customer/ListPlan/ListPlan";
import { FetchServicePlan, GetPromotionBannerStatus } from "../../../../../../actions/Web/Customer/SignUp";
import ErrorPage from "../../../../../../components/shared/errorPage/ErrorPage";
import { getPromotionsArray, Is_BrotherPlus_Signup, pushDataLayer } from "../../../../../../constants/AppMethod";
import check from "../../../../../../assets/images/planFeaturecheck.svg";
import WebSignupProgressBar from "../../../../../../components/WebSubHeader/progressBar/WebSignupProgressBar";
import FAQPlansPage from "../../../../../../components/SelectPlan/FAQ/FAQ";
import LearnMore from "../../../../../../components/SelectPlan/LearnMore/LearnMore";
import PromotionBanner from "../../../../../../components/shared/PromotionBanner/PromotionBanner";
import CaveatText from "../../../../../../components/SelectPlan/CaveatText/CaveatText";
import {fetchFeatureSwitchRequest } from "../../../../../../store/FeatureSwitch/FeatureSwitchslice" ;
import EasyPayPlan from "../../../../../../components/Web/Customer/ListPlan/EasyPayPlan/EasyPayPlan";
import { fetchFeatureSwitchByMultipleValuesRequest } from "../../../../../../store/FeatureSwitchByMultipleValues/FeatureSwitchByMultipleValuesSlice";

const WebSelectPlan = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const result = useSelector((state) => state.WebServicePlan);
  const bannerResult = useSelector(state => state.GetPromotionBannerStatus)
  const { t } = useTranslation(["translation"]);
  const printerModel = location?.state?.printer_model;
  const history = useHistory();
  const totalSlides = result?.webServicePlan?.length;
  const isBrotherPlusProgramEnabled=useSelector((state)=>state.FeatureSwitch)
  const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
  const FeatureSwitchByMultipleValues = useSelector((state) => state.FeatureSwitchByMultipleValues)
  const { featureSwitchByMultipleValues} = FeatureSwitchByMultipleValues;
  const BonusPages= featureSwitchByMultipleValues?.BonusPages || false;

  useEffect(() => {
    dispatch(fetchFeatureSwitchByMultipleValuesRequest());
    dispatch(fetchFeatureSwitchRequest("BrotherPlusProgram"))
  },[dispatch]
)

  useEffect(() => {
    if(printerModel){
      const promotions = getPromotionsArray(printerModel,BonusPages);
      dispatch(FetchServicePlan(printerModel,promotions));
    }
     // eslint-disable-next-line
  }, [dispatch, printerModel, BonusPages]);

  useEffect(() => {
    if(!(bannerResult?.success)){
      if(!(IsBrotherPlus_Signup && isBrotherPlusProgramEnabled?.featureSwitch)){
        dispatch(GetPromotionBannerStatus())
      }
    }
     // eslint-disable-next-line
  }, [dispatch, bannerResult?.success, IsBrotherPlus_Signup]);

  const moveToAccountPage = (selectedPlan) => {
    pushDataLayer('plan_selected','plan_id',selectedPlan.planId)
    setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY, selectedPlan.currency);
    WEB_handleSelectedPlanLocalData("Set", selectedPlan);
    history.push({pathname: WebCustomerRoutePaths.WEB_REDIRECT, state:{'signupType': SIGNUPTYPE.WEB}});
  };
  const moveToPreviousPage = () => {
    history.push(WebCustomerRoutePaths.SELECT_PRINTER);
  }

  if(!printerModel){
    return <ErrorPage errorInfo={t('ERROR_CONTENT.ERR_INFO.SELECT_A_DEVICE')} showTryAgain={tryAgainBtn.SHOW} to={WebCustomerRoutePaths.SELECT_PRINTER}></ErrorPage>
  }
  if (result.loading) {
    return <Loader></Loader>;
  }
  if(result.error) {
    return <ErrorPage errorInfo={result.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }
  if(totalSlides === 0) {
    return <ErrorPage errorInfo={t('ERROR_CONTENT.No_PLANS')} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }

 const planFeature = (
  <div className="plan-feature-wrapper">
    <div className="plan-feature">
      <li>
        <img src={check} alt="plan-point" />
        <span>{t("CUSTOMER_WEB.SELECT_PLAN.EXTENDED_WARRANTY")}</span>
      </li>
      <li>
        <img src={check} alt="plan-point" />
        <span>{t("CUSTOMER_WEB.SELECT_PLAN.CANCEL_ANYTIME")}</span>
      </li>
      <li>
        <img src={check} alt="plan-point" />
        <span>{t("CUSTOMER_WEB.SELECT_PLAN.FREE_DELIVERY")}</span>
      </li>
    </div>
  </div>
 )

  return (
    <>
      <PromotionBanner/>
      <WebSignupProgressBar activeStep = {2} />
      <WebSubHeader
            currentStep="2"
            totalStep="4"
            stepName={t('SUB_HEADER.STEP_2.STEP_NAME')}
            stepInfo={t('SUB_HEADER.STEP_2.STEP_INFO')}
            moveToPreviousPage={moveToPreviousPage}
            previousBtnText={t('CUSTOMER_WEB.SELECT_PLAN.DEVICE_SELECTION')}
            planFeature={planFeature}
            hideStepInfo={true}
            withProgressBar={true}
      />
      <ListPlan isBrotherPlusProgramEnabled={isBrotherPlusProgramEnabled} plansData={result} moveToAccountPage={moveToAccountPage}  isPromotionBannerEnabled = {(bannerResult?.data?.displayPromotionBanner) ? true : false} />
      
      {isBrotherPlusProgramEnabled?.featureSwitch &&
        <EasyPayPlan/>
      }
      <FAQPlansPage />
      <LearnMore />
      <CaveatText signupType={SIGNUPTYPE.WEB}/>
    </>
  );
};

export default WebSelectPlan;
