import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchCountryListByFeature, featureSwitchResetState, fetchFeatureListByCountry, updateFeatureSwitch, updateFeatureSwitchStateReset } from "../../../actions/AdminPortal/FeatureSwitch";
import { ENVSettings } from "../../../constants/ENVSettings";
import { getUserDataByKey } from "../../../utils/Authentication/handleUserSession";
import { extractCountryName } from "../../../utils/Internationalization/handleAppInternationalization";
import AdminFormCheckSwitchType from "../Shared/FormElements/FormCheckSwitchType/FormCheckSwitchType";
import AdminFormSelect from "../Shared/FormElements/FormSelect/FormSelect";
import EcoProButton from "../../shared/button/button";
import AdminAlert from "../AdminAlert/AdminAlert";
import AdminOverlayModal from "../AdminOverlayModal/AdminOverlayModal";

const FeatureSwitch = (props) => {
    const { t } = useTranslation("Admintranslation");
    const dispatch = useDispatch();
    const { featureSwitchType, displayList, updateFeatureSwitchResp } = props;
    const COUNTRY = "COUNTRY";
    const [modalProps, setModalProps] = useState({});
    const [dropDownItemSelected, setdropDownItemSelected] = useState(null);
    const [sortedLists, setSortedLists] = useState([]);
    const [alertMessage, setAlertMessage] = useState({'alertType': null, 'alertMessage': null});
    const countryList = ENVSettings.COUNTRIES;
    let selectOptionLabel, options, elementName, displayedListTitle = null;
  
   useEffect(() => {
            if(updateFeatureSwitchResp.error || updateFeatureSwitchResp.success){
                setModalProps({ isOpen: false });
                setAlertMessage({...alertMessage,
                    'alertType': (updateFeatureSwitchResp.error) ? 'error' : 'success',
                    'alertMessage': (updateFeatureSwitchResp.error) ? updateFeatureSwitchResp.error : t("ADMIN_COMMON_SUCCESS_MSG.UPDATE_SUCCESS")
                });
                if(featureSwitchType === COUNTRY){
                    dispatch(fetchFeatureListByCountry(dropDownItemSelected));
                } else {
                    dispatch(fetchCountryListByFeature(dropDownItemSelected));
                }
                dispatch(updateFeatureSwitchStateReset());
            } else {
                setSortedLists(displayList);
            }
            // eslint-disable-next-line
   }, [displayList]);

    const featuresList = [
        {
            "name": "CarbonSavings",
            "id": 1
        },
        {
            "name": "ChangePlan",
            "id": 2
        },
        {
            "name": "AutomatedOrderPlacement",
            "id": 3
        },
        {
            "name": "ColourLaser",
            "id": 4
        },
        {
            "name": "PromotionBanners",
            "id": 5
        },
        {
            "name": "AutomatedDeviceWarrantyRegistration",
            "id": 6
        },
        {
            "name": "BOCWebEnrollment",
            "id": 7
        },
        {
            "name": "DelayCancelService",
            "id": 8
        },
        {
            "name": "HardwareInclusive",
            "id": 9
        },
        {
            "name": "BrotherPlusProgram",
            "id": 10
        },
        {
            "name": "BrotherPlusPromoCode",
            "id": 11
        },
        {
            "name": "BonusPages",
            "id": 12
        }
    ]
    const handleSelection = (event) => {
        const dropDownSelectedItem = event.target.value ;
        setdropDownItemSelected(dropDownSelectedItem);
        dispatch(featureSwitchResetState());
        if(featureSwitchType === COUNTRY){
            dispatch(fetchFeatureListByCountry(dropDownSelectedItem));
        } else {
            dispatch(fetchCountryListByFeature(dropDownSelectedItem));
        }
    }
    const handleCheckboxChange = (id) => {
        setSortedLists(
            sortedLists.map((item) => {
                if (item.id === id) {
                    item.isEnabled = !item.isEnabled;
                    item.isChangeEventFired = true
                    item.saveBtnDisabled = false
                  } else {
                    if(item.isChangeEventFired){
                        item.isEnabled = !item.isEnabled;
                        item.isChangeEventFired = false
                    }
                    item.saveBtnDisabled = true
                  }
                  return item;
            })
        );
    }
    const toggleAdminOverlayModal = (listName) => {
        setModalProps(prevState => ({ isOpen: !prevState.isOpen }));
        setSortedLists(
            sortedLists.map((item) => {
                if (item.isChangeEventFired && item.name === listName) {
                    item.isEnabled = !item.isEnabled;
                    item.isChangeEventFired = false
                    item.saveBtnDisabled = true
                  }
                  return item;
            })
        );
    }
    const handleModalConfirm = (listID, isEnabled, UserLogDescription) => {
        const displayName = getUserDataByKey("displayName", "personal_details");
        const payload = {  
            "featureSwitchId": listID,
            "isEnabled": isEnabled,
            "updatedBy": displayName,
            "updatedDate": null
        }
        setAlertMessage({...alertMessage, 'alertType': null, 'alertMessage': null});
        dispatch(updateFeatureSwitch(payload, UserLogDescription));
    }
    const buildUserActionDescription = (featureName, isEnabled, countryName) => {
        return `The feature ${featureName} is ${(isEnabled) ? 'enabled' : 'disabled'} for the country ${countryName}`;
    }
    const handleSave = (listID, listName) => {
        const elem = document.querySelector(`#${listName.toLowerCase().split(" ").join("_")}`);
        const isEnabled = elem.checked;
        const status_text = (isEnabled) ? t("FEATURE_SWITCH.ENABLED_STATUS_TXT") : t("FEATURE_SWITCH.DISABLED_STATUS_TXT");
       
        let modal_content, UserLogDescription = "";
        if(featureSwitchType === COUNTRY){
            const featureName =listName;
            const countryName = extractCountryName(dropDownItemSelected);
            modal_content =   <Trans i18nKey="FEATURE_SWITCH.MODAL_CONTENT" featureName={featureName} status_text={status_text} countryName={countryName}>
                The feature <b className="highlight-text">{featureName}</b> is <b className={(isEnabled) ? "feature-switch-enabled-text" : "feature-switch-disabled-text"}>{status_text}</b> for the country <b className="highlight-text">{countryName}</b>
            </Trans>
            UserLogDescription = buildUserActionDescription(featureName, isEnabled, countryName);
        } else {
            const countryName = listName;
            const featureName = dropDownItemSelected;
            modal_content =   <Trans i18nKey="FEATURE_SWITCH.MODAL_CONTENT" featureName={featureName} status_text={status_text} countryName={countryName}>
                The feature <b className="highlight-text">{featureName}</b> is <b className={(isEnabled) ? "feature-switch-enabled-text" : "feature-switch-disabled-text"}>{status_text}</b> for the country <b className="highlight-text">{countryName}</b>
            </Trans>
             UserLogDescription = buildUserActionDescription(featureName, isEnabled, countryName);
        }
        setModalProps({
            isOpen : true, 
            toggleAdminOverlayModal: () => toggleAdminOverlayModal(listName),
            modal_title: t('FEATURE_SWITCH.MODAL.MODAL_TITLE'),
            modal_sub_title:  t('FEATURE_SWITCH.MODAL.MODAL_SUB_TITLE'),
            modal_content: modal_content,
            showCancelBtn : true,
            cancel_btn_text: t('BTN_TEXTS.CANCEL'),
            showConfirmBtn: true,
            confirm_btn_text: t('BTN_TEXTS.CONFIRM_AND_SUBMIT'),
            handleModalConfirm:() => handleModalConfirm(listID, isEnabled, UserLogDescription)
        });
    }
    if(featureSwitchType === COUNTRY){
        selectOptionLabel = t('FEATURE_SWITCH.SELECT_OPTION_LABEL_COUNTRY');
        options = countryList.map((data) =>
                <option key={data.code2} value={data.code2}>
                    {data.name}
                </option>
            );
        elementName = "country";
        displayedListTitle = t('FEATURE_SWITCH.LIST_TITLE_FOR_COUNTRY');
    } else {
        selectOptionLabel = t('FEATURE_SWITCH.SELECT_OPTION_LABEL_FEATURE');
        options = featuresList.map((data) =>
                <option key={data.name} value={data.name}>
                    {data.name}
                </option>
            );
        elementName = "feature_name";
        displayedListTitle = t('FEATURE_SWITCH.LIST_TITLE_FOR_FEATURE');
    }
    return(
        <>
            { 
                alertMessage && alertMessage.alertType === 'error' && 
                <AdminAlert alert_variant="alert-danger">{alertMessage.alertMessage}</AdminAlert>
            }
            { 
                alertMessage && alertMessage.alertType === 'success' && 
                <AdminAlert alert_variant="alert-success">{alertMessage.alertMessage}</AdminAlert>
            }
            <div className="manage-feature-switch-country-body-wrapper">
                <div className="select-country-wrapper">
                    <p className="feature-switch-country-label">{selectOptionLabel}</p>
                    <AdminFormSelect
                    cname = "feature-switch-country-list"
                    name = {elementName}
                    options = {options}
                    onChange = {handleSelection}
                    />
                </div>
                { sortedLists && sortedLists.length > 0 && 
                <div className="country-wise-feature-list-wrapper">
                    <p className="feature-list-side-header">{displayedListTitle}</p>
                    <div className="grid-line"></div>
                        { sortedLists.length > 0 && sortedLists.map((list) => (
                                <div className="feature-list-outer-wrapper" key={list.id}>
                                    <div className="feature-list-item-wrapper">
                                        <p className="feature-name">{list.name}</p>
                                        <div className="enable-disable-wrapper">
                                            <AdminFormCheckSwitchType
                                            inputID = {list.name?.toLowerCase().split(" ").join('_')}
                                            inputName = {list.name?.toLowerCase().split(" ").join('_')}
                                            labelText = {(list.isEnabled) ? t('FEATURE_SWITCH.ENABLED_CHECKBOX_LABEL'): t('FEATURE_SWITCH.ENABLE_CHECKBOX_LABEL')}
                                            onChange={() => handleCheckboxChange(list.id)}
                                            isChecked = {list.isEnabled}
                                            key = {list.id}
                                            />
                                        </div>
                                        <div className="grid-action-btn-wrapper">
                                            <EcoProButton cname="default" onBtnClick= {() => handleSave(list.id, list.name)} disabled={list.saveBtnDisabled}>{t('BTN_TEXTS.SAVE')}</EcoProButton>
                                        </div>
                                    </div>
                                    <div className="grid-line"></div>
                                </div>
                            ))
                        }
                    </div>
                }
                <AdminOverlayModal {...modalProps}></AdminOverlayModal>
            </div>
        </>
    )
}

export default FeatureSwitch;