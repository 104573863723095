import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CALLPOSTAPI } from '../../../api/apiHandler'
import { FAILURE, USER_ACTIVITY_LOGS, _CSAGENT_API_ENDPOINTS } from '../../../constants/AppConstants';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _CALLFETCHEMAILLOGS(customerID){
    const endpoint = _CSAGENT_API_ENDPOINTS.EMAIL_LOGS;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_EMAIL_LOGS,
        description: `Fetch the email logs for userID: ${customerID}`
    }
    const payload = {
        userId: customerID,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
    }
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleEmailLogs(action){
    try {
        
        const customerId = action.customerID;
        if(customerId){
            const emailLogs =  yield call(_CALLFETCHEMAILLOGS, customerId);
           if(emailLogs.actionStatus.toUpperCase() !== FAILURE){
                yield put({type: actionTypes.FETCH_EMAIL_LOGS_SUCCESS, emailLogs: emailLogs});
           } else {
                yield put({
                    type: actionTypes.FETCH_EMAIL_LOGS_ERROR, 
                    message: emailLogs.actionMessage
                });
           }
        } else {
            yield put({
                type: actionTypes.FETCH_EMAIL_LOGS_ERROR, 
                message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
            });
        }
    } catch (e) {
        yield put({type: actionTypes.FETCH_EMAIL_LOGS_ERROR, message: e.message});
    }
}

function* FetchEmailLogs(){
    yield takeEvery(actionTypes.FETCH_EMAIL_LOGS, handleEmailLogs);
}

export default FetchEmailLogs;