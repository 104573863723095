import React from "react";
import { useTranslation } from "react-i18next";
import EcoproAlert from "../../../../shared/alert/alert";
import AlertDanger from "../../../../../assets/images/alert-danger.svg";

const CustomerDetails = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const NOT_AVAILABLE = 'N/A';
    const displayName = props?.displayName || NOT_AVAILABLE;
    const emailID = props?.emailAddress || NOT_AVAILABLE;
    const telephoneNo = props?.phoneNumber || NOT_AVAILABLE;
    const mobileNo = props?.mobileNumber || NOT_AVAILABLE;
    const shippingAddress = {
        "address" : props?.shippingAddress?.addressLine1 || NOT_AVAILABLE,
        "address2": props?.shippingAddress?.addressLine2 || NOT_AVAILABLE,
        "city": props?.shippingAddress?.cityTown || NOT_AVAILABLE,
        "country": props?.shippingAddress?.country || NOT_AVAILABLE,
        "postcode": props?.shippingAddress?.postCode || NOT_AVAILABLE,
    }
    const billingAddress = {
        "address" : props?.billingAddress?.addressLine1 || NOT_AVAILABLE,
        "address2": props?.billingAddress?.addressLine2 || NOT_AVAILABLE,
        "city": props?.billingAddress?.cityTown || NOT_AVAILABLE,
        "country": props?.billingAddress?.country || NOT_AVAILABLE,
        "postcode": props?.billingAddress?.postCode || NOT_AVAILABLE,
    }
    return(
        <div className="overview-container customer-details-wrapper mt-40">
            {
                (props?.error)
                ? <EcoproAlert
                    alertImg = {AlertDanger}
                    alertSubHeader={props.error}
                ></EcoproAlert>
                : null
            }
            <div id="personal-details">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.PERSONAL_INFORMATION')}</h1>
                    </div>
                </div>
                <div className="device-details-info mt-15">
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.DISPLAY_NAME')}: <span>{displayName}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.EMAIL_ADDRESS')}: <span>{emailID}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.MOBILE_TELEPHONE')}: <span>{mobileNo}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.TELEPHONE')}: <span>{telephoneNo}</span></p>
                </div>
            </div>
            <div id="shipping-details" className="mt-40">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.SHIPPING_ADDRESS')}</h1>
                    </div>
                </div>
                <div className="device-details-info mt-15">
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS')}: <span>{shippingAddress.address}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS_LINE _2')}: <span>{shippingAddress.address2}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.TOWN_CITY')}: <span>{shippingAddress.city}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.COUNTRY')}: <span>{shippingAddress.country}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.POSTCODE')}: <span>{shippingAddress.postcode}</span></p>
                </div>
            </div>
            <div id="billing-details" className="mt-40">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.BILLING_ADDRESS')}</h1>
                    </div>
                </div>
                <div className="device-details-info mt-15">
                <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS')}: <span>{billingAddress.address}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS_LINE _2')}: <span>{billingAddress.address2}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.TOWN_CITY')}: <span>{billingAddress.city}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.COUNTRY')}: <span>{billingAddress.country}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.POSTCODE')}: <span>{billingAddress.postcode}</span></p>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails;