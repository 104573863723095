import { createSlice } from '@reduxjs/toolkit';

const GetUserSubscriptionDetailsSlice = createSlice({
  name: 'fetchUserSubscriptionDetails',
  initialState: {
    loading: false,
    subscriptionDetails: null,
    error: null,
    success: false,
  },
  reducers: {
    fetchUserSubscriptionDetailsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserSubscriptionDetailsSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.subscriptionDetails = action.payload;
    },
    fetchUserSubscriptionDetailsFailure(state, action) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchUserSubscriptionDetailsRequest,
  fetchUserSubscriptionDetailsSuccess,
  fetchUserSubscriptionDetailsFailure,
} = GetUserSubscriptionDetailsSlice.actions;

export default GetUserSubscriptionDetailsSlice.reducer;
