export const CUSTOMER_INFO_CALL_INIT = 'CUSTOMER_INFO_CALL_INIT';
export const CUSTOMER_INFO_CALL_SUCCESS = 'CUSTOMER_INFO_CALL_SUCCESS';
export const CUSTOMER_INFO_CALL_ERROR = 'CUSTOMER_INFO_CALL_ERROR';

export const ENABLE_DEVICE_SWAP_INIT = 'ENABLE_DEVICE_SWAP_INIT';
export const ENABLE_DEVICE_SWAP_SUCCESS = 'ENABLE_DEVICE_SWAP_SUCCESS';
export const ENABLE_DEVICE_SWAP_ERROR = 'ENABLE_DEVICE_SWAP_ERROR';

export const UPDATE_ORDER_CONSUMABLES = 'UPDATE_ORDER_CONSUMABLES';
export const UPDATE_ORDER_CONSUMABLES_SUCCESS = 'UPDATE_ORDER_CONSUMABLES_SUCCESS';
export const UPDATE_ORDER_CONSUMABLES_ERROR = 'UPDATE_ORDER_CONSUMABLES_ERROR';

export const RESET_STATE = "RESET_STATE";
export const RESET_ORDER_CONSUMABLES = 'RESET_ORDER_CONSUMABLES'
export const SWAP_RESET_STATE = 'SWAP_RESET_STATE'

export const FETCH_EMAIL_LOGS = 'FETCH_EMAIL_LOGS';
export const FETCH_EMAIL_LOGS_SUCCESS = 'FETCH_EMAIL_LOGS_SUCCESS'
export const FETCH_EMAIL_LOGS_ERROR = 'FETCH_EMAIL_LOGS_ERROR'

export const FETCH_SUPPLY_ORDERS_LOGS = 'FETCH_SUPPLY_ORDERS_LOGS';
export const FETCH_SUPPLY_ORDERS_LOGS_SUCCESS = 'FETCH_SUPPLY_ORDERS_LOGS_SUCCESS'
export const FETCH_SUPPLY_ORDERS_LOGS_ERROR = 'FETCH_SUPPLY_ORDERS_LOGS_ERROR'

export const FETCH_SUBSCRIPTION_DETAILS_INIT = 'FETCH_SUBSCRIPTION_DETAILS_INIT';
export const FETCH_SUBSCRIPTION_DETAILS_SUCCESS = 'FETCH_SUBSCRIPTION_DETAILS_SUCCESS';
export const FETCH_SUBSCRIPTION_DETAILS_ERROR = 'FETCH_SUBSCRIPTION_DETAILS_ERROR';

export const FETCH_DEVICE_AND_USAGE_DETAILS_INIT = 'FETCH_DEVICE_AND_USAGE_DETAILS_INIT';
export const FETCH_DEVICE_AND_USAGE_DETAILS_SUCCESS = 'FETCH_DEVICE_AND_USAGE_DETAILS_SUCCESS';
export const FETCH_DEVICE_AND_USAGE_DETAILS_ERROR = 'FETCH_DEVICE_AND_USAGE_DETAILS_ERROR';

export const FETCH_USER_ACTIVITY_HISTORY_INIT = 'FETCH_USER_ACTIVITY_HISTORY_INIT';
export const FETCH_USER_ACTIVITY_HISTORY_SUCCESS = 'FETCH_USER_ACTIVITY_HISTORY_SUCCESS';
export const FETCH_USER_ACTIVITY_HISTORY_ERROR = 'FETCH_USER_ACTIVITY_HISTORY_ERROR';

export const GET_USER_PRE_ENROLLMENT_INFO_INIT = 'GET_USER_PRE_ENROLLMENT_INFO_INIT';
export const GET_USER_PRE_ENROLLMENT_INFO_SUCCESS = 'GET_USER_PRE_ENROLLMENT_INFO_SUCCESS';
export const GET_USER_PRE_ENROLLMENT_INFO_ERROR = 'GET_USER_PRE_ENROLLMENT_INFO_ERROR';
