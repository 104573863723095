import React from "react";
import Loader from "../../../../shared/loader/Loader";
import { DisplayDate } from "../../../../../utils/DisplayDate";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PreEnrollmentDetails = () => {
    const {t} = useTranslation("CSAgenttranslation");
    const customerPreEnrollmentInfo = useSelector(state => state.CSAGENT.UserPreEnrollmentInfo);
    const { id: preEnrollmentID, date: PreEnrollmentDate, kind, deviceOption = 'BYOD' } = customerPreEnrollmentInfo?.preEnrollmentDetails || {};

    if(customerPreEnrollmentInfo.loading){
        return <Loader />
    }
    if(customerPreEnrollmentInfo.error){
        return <div className="email-log-error">{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.NO_PRE_ENROLLMENT_AVAILABLE')}</div>;
    }
    return (
        <div className="overview-container preenrollment-details-wrapper mt-40">
            {(preEnrollmentID) 
                ? <div id="pre-enrollment-info">
                    <p>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.PRE_ENROLLMENT_ID')}: <span>{preEnrollmentID}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.PRE_ENROLL_DATE')}: <span>{DisplayDate(PreEnrollmentDate, { day: '2-digit', month: 'long', year: 'numeric' })}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.SUBSCRIPTION_KIND')}: <span>{kind}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.DEVICE_OPTION')}: <span className="label-value subscription-type-text active">{deviceOption?.toUpperCase()}</span></p>
                </div>
                : <div className="email-log-error">{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.NO_PRE_ENROLLMENT_AVAILABLE')}</div>
            }
        </div>
    )
}

export default PreEnrollmentDetails;