import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS ,USER_ACTIVITY_LOGS} from '../../../constants/AppConstants';
import { _CALLPOSTAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import {
  fetchUserDeviceStatusRequest,
  fetchUserDeviceStatusSuccess,
  fetchUserDeviceStatusFailure,
} from './GetUserDeviceStatusSlice';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';


async function _FETCHUSERDEVICESTATUS(customerId) {
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.FETCH_USER_DEVICE_STATUS;
    const logDetails = {
      action: USER_ACTIVITY_LOGS.BROTHERPLUS_FETCH_DEVICE_STATUS,
      description: `Fetch Brother plus device status for customer ${customerId}`,
    };
    const payload = {
      userId: customerId,
      servicePortalUser: await UserActivityLogsPayload(logDetails),
    };
  
    return _CALLPOSTAPI(endpoint, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

function* fetchUserDeviceStatusSaga(action) {
  try {
    const customerId = action.payload;
    const deviceStatus = yield call(_FETCHUSERDEVICESTATUS, customerId);
    if (deviceStatus?.actionStatus.toUpperCase() === SUCCESS) {
      yield put(fetchUserDeviceStatusSuccess(deviceStatus));
    } else {
      yield put(fetchUserDeviceStatusFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(fetchUserDeviceStatusFailure(error.message));
  }
}

export default function* watchFetchUserDeviceStatusService() {
  yield takeEvery(fetchUserDeviceStatusRequest.type, fetchUserDeviceStatusSaga);
}
