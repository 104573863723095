import React, { useEffect, useState } from 'react';
import './BonusPages.css';
import { useTranslation } from 'react-i18next';
import { findValueByKey_From_ArrayOfObj, isBonusPagesAvailableInDiscountArray } from '../../../../constants/AppMethod';
import { ONE_TIME_BONUS } from '../../../../constants/AppConstants';
import Skeleton from 'react-loading-skeleton';

const BonusPages = (props) => {
    const { t } = useTranslation(['translation']);
    const [bonusPages, setBonusPagesCount] = useState(0);
    const { deviceInfoResponse, isBlockActive } = props;
    const remainBonusPages = deviceInfoResponse?.response?.service?.currentUsage?.remainBonusPages;
    const activatedDate = deviceInfoResponse?.response?.service?.activatedDate;

    useEffect(() => {
        if(!activatedDate && isBonusPagesAvailableInDiscountArray(deviceInfoResponse)){
            const bonusPagesCount = findValueByKey_From_ArrayOfObj(deviceInfoResponse?.response?.service?.currentPlan?.discount, 'name', ONE_TIME_BONUS, 'planPages')
            setBonusPagesCount((bonusPagesCount) ? bonusPagesCount : 0);
        } else {
            setBonusPagesCount(remainBonusPages);
        }
    }, [activatedDate, deviceInfoResponse, remainBonusPages])

    return (
        <div className={`bonus-pages-container ${(isBlockActive) ? 'active-dashboard-blocks' : 'disabled-dashboard-blocks'}`}>
            <h2 className="bonus-pages-title">{t("ECOPRO_NEW_DASHBOARD.BONUS_PAGES.TITLE")}</h2>
            { deviceInfoResponse?.loader ? 
                <div className="pages-used-loader-wrapper">
                    <Skeleton className="para-skeleton1" />
                    <Skeleton className="para-skeleton2" />
                </div>
            :
                <>
                    <p className="bonus-pages-subtitle">{t("ECOPRO_NEW_DASHBOARD.BONUS_PAGES.YOUR_REMAINING_BONUS_PAGES")}</p>
                    <div className="bonus-pages-count">
                        <span>{bonusPages} <span> {t("ECOPRO_NEW_DASHBOARD.BONUS_PAGES.PAGES")}</span></span>
                    </div>
                </>
            }
        </div>
    );
};

export default BonusPages;