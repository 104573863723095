import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Enrollment, ZuoraPaymentInfoRendering, ZuoraPaymentInfoRenderingResetState } from "../../../../../actions/MyAccount";
import { GetPreEnrollmentInfo, GetPreEnrollmentInfoResetState, GetUserPaymentDetails, GetUserPaymentDetailsResetState, VerifyPreEnrollmentInfo, VerifyPreEnrollmentInfoResetState } from "../../../../../actions/Web/Customer/SignIn";
import PaymentComplete from "../../../../../components/PaymentComplete/PaymentComplete";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../components/shared/loader/Loader";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../../../../constants/AppConstants";
import { routePath } from "../../../../../constants/Routes";
import { getUserData, getUserDataByKey, getUserSessionData, setLocalSessionData, setUserData, WEB_handleSelectedPlanLocalData } from "../../../../../utils/Authentication/handleUserSession";
import { handleSubscriptionOrderPayload } from "../../../../../constants/AppMethod";

const WebPlaceOrder = () => {
    const dispatch = useDispatch();
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const GetPreEnrollmentInfoResp = useSelector((state) => state.GetPreEnrollmentInfo);
    const VerifyPreEnrollmentInfoResp = useSelector((state) => state.VerifyPreEnrollmentInfo);
    const zrenderParams =  useSelector(state => state.ZuoraPaymentInfoRendering);
    const EnrollmentResponse = useSelector(state => state.Enrollment);
    const userPaymentDetailsResp = useSelector(state => state.GetUserPaymentDetails);
    useEffect(() => {
        dispatch(GetPreEnrollmentInfo(UID));
        // eslint-disable-next-line
    }, [])
    const overwriteStoredUserData = (shippingAddrs) => {
        const userDataStored = getUserData();
        const userDataUpdated = {...userDataStored, shipping_address: {
            addressLine1: shippingAddrs.street1,
            addressLine2: shippingAddrs.street2,
            cityTown: shippingAddrs.city,
            companyName: shippingAddrs.companyName,
            country: shippingAddrs.country,
            postCode: shippingAddrs.postalCode,
            state: shippingAddrs.state
        }}
        setUserData(userDataUpdated);
    }
    if(EnrollmentResponse.error){
        return <ErrorPage errorInfo={EnrollmentResponse.error}></ErrorPage>
    }
    if(EnrollmentResponse.success){
        const isDiscountAvailed = (EnrollmentResponse.data?.selectedPlan?.discountPlanIds) ? true : false;
        const planPrice = EnrollmentResponse.data?.selectedPlan?.price;
        const planCurrency = EnrollmentResponse.data?.shippingAddress?.currency;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event':'signup',
          'status':'success'
        });
        return <PaymentComplete isDiscountAvailed={isDiscountAvailed} planPrice={planPrice} planCurrency={planCurrency}></PaymentComplete>
    }
    if(zrenderParams.error){
        return <ErrorPage errorInfo={zrenderParams.error}></ErrorPage>
    }
    if(Object.keys(zrenderParams.zrenderParams).length > 0){
        let offers = getUserDataByKey("marketingEmails");
        offers = (offers === "true") ? true : false;
        const  payloadInfo = {
                accountDetails: {
                    personalDetails: {
                        firstName: getUserDataByKey("firstName", 'personal_details'),
                        lastName: getUserDataByKey("lastName", 'personal_details'),
                        emailAddress: getUserDataByKey("emailAddress", 'personal_details'),
                        offers: offers,
                        telephoneNumber: getUserDataByKey("telephoneNumber", 'personal_details'),
                        mobileNumber: getUserDataByKey("mobileNumber", 'personal_details')
                    }, 
                    shippingAddress: {
                        address: getUserDataByKey("addressLine1", 'shipping_address'),
                        addressLine2: getUserDataByKey("addressLine2", 'shipping_address'),
                        city: getUserDataByKey("cityTown", 'shipping_address'),
                        postcode: getUserDataByKey("postCode", 'shipping_address'),
                        country: getUserDataByKey("country", 'shipping_address'),
                        company: getUserDataByKey("companyName", 'billing_address'),
                        province: getUserDataByKey("state", 'shipping_address')
                    },
                    billingAddress:{
                        address: getUserDataByKey("addressLine1", 'billing_address'),
                        addressLine2: getUserDataByKey("addressLine2", 'billing_address'),
                        city: getUserDataByKey("cityTown", 'billing_address'),
                        postcode: getUserDataByKey("postCode", 'billing_address'),
                        country: getUserDataByKey("country", 'billing_address'),
                        company: getUserDataByKey("companyName", 'billing_address'),
                        province: getUserDataByKey("state", 'billing_address')
                    }
                },
                billingInfo: zrenderParams.zrenderParams,
                selectedplan: WEB_handleSelectedPlanLocalData("Get")
            }
            const handlePayload = async (payloadInfo) => {
                const payload = await handleSubscriptionOrderPayload(payloadInfo);
                dispatch(Enrollment(payload));
            }
            handlePayload(payloadInfo);
            dispatch(ZuoraPaymentInfoRenderingResetState());
    }
    if(userPaymentDetailsResp.error){
        return <ErrorPage errorInfo={userPaymentDetailsResp.error}></ErrorPage>
    }
    if(userPaymentDetailsResp.success){
        const PaymentOption = userPaymentDetailsResp.data.paymentMethod;
        dispatch(ZuoraPaymentInfoRendering(PaymentOption));
        dispatch(GetUserPaymentDetailsResetState());
    }
    if(VerifyPreEnrollmentInfoResp.error){
        return <Redirect to={routePath.NO_DEVICE_FOUND} />
    }
    if(VerifyPreEnrollmentInfoResp.success){
        WEB_handleSelectedPlanLocalData('Set', VerifyPreEnrollmentInfoResp.data.plans[0]);
        setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY, VerifyPreEnrollmentInfoResp.data.plans[0].currency);
        dispatch(GetUserPaymentDetails(UID));
        dispatch(VerifyPreEnrollmentInfoResetState());
    }
    if(GetPreEnrollmentInfoResp.error){
        return <ErrorPage errorInfo={GetPreEnrollmentInfoResp.error}></ErrorPage>
    }
    if(GetPreEnrollmentInfoResp.success){
        setLocalSessionData(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID, GetPreEnrollmentInfoResp.data.id);
        overwriteStoredUserData(GetPreEnrollmentInfoResp.data.parameter.shippingAddress);
       
        
        const preEnrollmentDetails = {
            preEnrollId: GetPreEnrollmentInfoResp.data.id,
            PlanId: GetPreEnrollmentInfoResp.data.parameter.planId,
            DiscountPlanIds: GetPreEnrollmentInfoResp.data.parameter.discountPlanIds
        }
        dispatch(VerifyPreEnrollmentInfo(preEnrollmentDetails));
        dispatch(GetPreEnrollmentInfoResetState());
    }
    return ( <Loader slowResponse={true}></Loader>)
}

export default WebPlaceOrder;