import React from "react";
import DashboardOrderDetails from "../../../components/DashboardOrderDetails/DashboardOrderDetails";
import { useHistory } from "react-router-dom";
import { routePath } from "../../../constants/Routes";
import { Container } from "reactstrap";
import { ReactComponent as ChevronLeft } from "../../../assets/images/chevron_left.svg";
import { useTranslation } from "react-i18next";
import './OrderDetails.css';

const OrderDetails = () => {
    const history = useHistory();
    const moveToPreviousPage = () => {
        history.push(routePath.ORDERS);
    }
    const { t } = useTranslation(["translation"]);
    return (
        <div className="orders-detail-page-wrapper">
           <Container className="web-subheader desktop-app-back-btn">
                <div className="page-title-back-btn" onClick={()=>moveToPreviousPage()}>
                    <ChevronLeft fill="#545454" />
                    <div className="f-12 fw-b">{t("DASHBOARD.ORDERS.ORDERS_LIST")}</div>
                </div>
           </Container>
            <div className="order-details-content"><DashboardOrderDetails></DashboardOrderDetails></div>
        </div>
    )
}

export default OrderDetails;