import React, { useEffect, useState } from "react";
import WelcomeHeader from "../../../components/CustomerDashboard/WelcomeHeader/WelcomeHeader";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesRequest } from "../../../store/brotherPlus/getDevices/getDevicesSlice";
import { getLocalSessionData, getUserSessionData } from "../../../utils/Authentication/handleUserSession";
import "./Dashboard.css";
import { DASHBOARD_REFRESH_INTERVAL, LOCALSESSIONDATAKEYS } from "../../../constants/AppConstants";
import BrotherPlusPagesUsed from "../../../components/CustomerDashboard/BrotherPlus/BrotherPlusPagesUsed/BrotherPlusPagesUsed";
import BrotherPlusEnrolledDevice from "../../../components/CustomerDashboard/BrotherPlus/BrotherPlusEnrolledDevice/BrotherPlusEnrolledDevice";
import DiscountTile from "../../../components/CustomerDashboard/BrotherPlus/DiscountTile/DiscountTile";
import useIntervalAsync from "../../../utils/CustomHooks/useIntervalAsync";
import useBrotherPlusUpdateDeviceStatus from "../../../utils/CustomHooks/useBrotherPlusUpdateDeviceStatus";
import { getPrintHistoryRequest } from "../../../store/brotherPlus/getPrintHistory/getPrintHistorySlice";
import SupplyLevels from "../../../components/CustomerDashboard/SupplyLevels/SupplyLevels";
import UpgradeToEcoProBlock from "../../../components/CustomerDashboard/BrotherPlus/UpgradeToEcoProBlock/UpgradeToEcoProBlock";
import BrotherPlusDeviceStats from "../../../components/CustomerDashboard/BrotherPlus/BrotherPlusDeviceStats/BrotherPlusDeviceStats";
import BrotherPlusPrintActivity from "../../../components/CustomerDashboard/BrotherPlus/BrotherPlusPrintActivity/BrotherPlusPrintActivity";

export const DISPLAY_CONTENT_TYPE = {'pagesUsedCount' : 'PAGES_USED_COUNT', "duplexPagesCount": "DUPLEX_PAGES_COUNT"};

const BrotherPlusDashboard = (props) => {
    const {isBothRegType} = props
    const dispatch = useDispatch()
    const getDevicesResponse = useSelector((state => state.BROTHERPLUS.getDevices))
    const getPrintHistoryResponse = useSelector((state) => state.BROTHERPLUS.getPrintHistory)

  
    const [deviceStatus, setDeviceStatus] = useState({
        success: false,
        loader: false,
        response: null,
        error: false
      });
      

    const userId = getUserSessionData('UserId');
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
    useEffect(()=>{
        setDeviceStatus(prev => ({ ...prev, loader: true }));
        dispatch(getDevicesRequest({userId}))
        dispatch(getPrintHistoryRequest({userId, deviceId}));
        // eslint-disable-next-line
    },[dispatch])

    const updateState = useBrotherPlusUpdateDeviceStatus({ deviceId, userId, setDeviceStatus });

    useIntervalAsync(updateState, DASHBOARD_REFRESH_INTERVAL);

    return(
        isBothRegType ?
        <div>
                <div className="brother-plus-device-stats">
                  <BrotherPlusDeviceStats></BrotherPlusDeviceStats>
                </div>
                <div className="ecorpo-brother-plus-dashboard-wrapper">
                  <div className="tile tile-3">
                    <div className="box"><BrotherPlusPagesUsed getDeviceStatusResponse = {deviceStatus} displayContent = {DISPLAY_CONTENT_TYPE.pagesUsedCount}/></div>
                    <div className="box"><BrotherPlusPagesUsed getDeviceStatusResponse = {deviceStatus} displayContent = {DISPLAY_CONTENT_TYPE.duplexPagesCount}/></div>
                  </div> 
                  <div className="tile tile-4">
                    <SupplyLevels getDeviceStatusResponse = {deviceStatus} isChildComp={true} getDevicesResponse = {getDevicesResponse}/>
                  </div> 
                  <div className="tile tile-1">
                  <BrotherPlusPrintActivity
                      showAvgPagesUsed = {true} 
                      isChildComp = {true} 
                      BrotherPlus_printHistoryResult = {getPrintHistoryResponse}
                  />
                </div> 
                <div className="tile tile-2">
                  <DiscountTile/>
                </div>
                </div>
        </div>
            :
            <div>
            <WelcomeHeader/>
              <div className="ecorpo-brother-plus-dashboard-wrapper">
              {/* <!-- Row 1 --> */}
                <div className="tile tile-1">
                  <BrotherPlusEnrolledDevice getDevicesResponse = {getDevicesResponse} deviceWarrantyStatus = {deviceStatus?.response?.genuineInfo}/>
                </div> 
                <div className="tile tile-2">
                  <DiscountTile/>
                </div> 
                
                {/* <!-- Row 2 --> */}
                <div className="tile tile-3">
                  <div className="box"><BrotherPlusPagesUsed getDeviceStatusResponse = {deviceStatus} displayContent = {DISPLAY_CONTENT_TYPE.pagesUsedCount}/></div>
                  <div className="box"><BrotherPlusPagesUsed getDeviceStatusResponse = {deviceStatus} displayContent = {DISPLAY_CONTENT_TYPE.duplexPagesCount}/></div>
                </div> 
                <div className="tile tile-4">
                  <SupplyLevels getDeviceStatusResponse = {deviceStatus} isChildComp={true} getDevicesResponse = {getDevicesResponse}/>
                </div> 
                
                {/* <!-- Row 3 --> */}
                <div className="tile tile-5">
                  <BrotherPlusPrintActivity
                      showAvgPagesUsed = {true} 
                      isChildComp = {true} 
                      BrotherPlus_printHistoryResult = {getPrintHistoryResponse}
                  />
                </div> 
                <div className="tile tile-6">
                  <UpgradeToEcoProBlock />
                </div> 
              </div>
            </div>
    )
}

export default BrotherPlusDashboard;