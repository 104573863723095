import React, { useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import "./HamburgerMenu.css";
import HamburgerMenuDesktopApp from "../hamburgerMenuDesktopApp/hamburgerMenuDesktopApp";


const HamburgerMenu = () => {
  const [navExpanded, setNavExpanded] = useState(false);

  const sethandleNavExpanded = () => {
    setNavExpanded(!navExpanded);
  };

  return (
    <div className="dashboard-hamburgermenu-container">
      <Navbar
        expand={false}
        onToggle={sethandleNavExpanded}
        expanded={navExpanded}
      >
        <Navbar.Toggle className="hamburger-menu">
          <div className="menu__btn">
            <span></span>
          </div>
        </Navbar.Toggle>
        <Container fluid>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <HamburgerMenuDesktopApp/>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};

export default HamburgerMenu;
