import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    isUpgradeInitiated: false,
  }

const UpgradeToEcoProStatus = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPGRADE_TO_ECOPRO_STATUS_CHECK:
            return {
                ...state,
                isUpgradeInitiated: action.isUpgradeInitiated
            }
            default:
            return {...state}
    }
}

export default UpgradeToEcoProStatus