import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/Authentication/SignIn_SignUp_Auth'
import { UPGRADE_TO_ECOPRO_STATUS_CHECK } from '../../constants/ActionTypes/Dashboard';
import { GET_USER_BOC_ID_BY_ADB2C_ID_SUCCESS  as webSignupActionTypes} from '../../constants/ActionTypes/Web/Customer/SignUp';
import { _GETUSERBOCACCOUNTDETAILS } from '../Web/Customer/GetUserBOCIdByADB2CId';
import i18next from 'i18next';
import { __USERREGISTERATIONSERVICE } from '../../store/UserRegistration/UserRegistrationSaga';
import { storeUserRegistration } from '../../utils/Authentication/handleUserSession';
import { fetchUserAccount } from '../UserAccount/UserAccount';
import { UPGRADE_TO_ECOPRO } from '../../constants/AppConstants';


function* handleError(msg = null){
    yield put({
        type: actionTypes.APP_SIGN_IN_SERVICE_ERROR,
        message: (msg) ? msg : i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR")
    })
}

/**
 * This method calls the API to fetch the user registration type
 * 
 * Logic
 *  - The user can be EcoPro or BrotherPlus member or can belong to both the registration types
 *  - On response, if the user is only BrotherPlus member then the signup type is set respectively.
 *  - On response, the reigistration types are stored into the localstorage.
 *  - On response, if process is Sign_UP then GetBOCIdByADB2CID success action will be dispatched.
 *  - On response, if process is not Sign_UP GetUserAccount API is dispatched irrepsective of success or error response of this API
 * 
 * @param {string} BOC_UID 
 * @param {string} process 
 * @param {object} payload [userInfoPayload or BOCResp]
 * 
 * @returns void
 */
function* call_GetUserRegisterationType(BOC_UID, process, payload){
    try {
        const userRegistrationTypeInfo = yield call(__USERREGISTERATIONSERVICE, BOC_UID);
        storeUserRegistration(userRegistrationTypeInfo);
        if(process !== UPGRADE_TO_ECOPRO){
            (process === "SIGN_UP") 
            ? yield put({type: webSignupActionTypes, userData: payload})
            : yield* fetchUserAccount(payload)
        } else {
            yield put({type: UPGRADE_TO_ECOPRO_STATUS_CHECK, isUpgradeInitiated : false});
        }
    } catch(e){
        throw e;
    }
}

/**
 * This method calls the API to fetch the BOC UserID using the ADB2CID
 * 
 * Logic
 *  - If the response has UserdID, the next action GetUserRegistrationType API is dispatched
 *  - IF the response does not have UserID, the extenal validation process follows until UserId is obtained.
 * 
 * @param {object} action 
 * @returns void
 */
function* call_GetBOCUserIdByADB2CID(action){
    try{
        const userInfoPayload = action;
        const BOCUserIdInfo = yield call (_GETUSERBOCACCOUNTDETAILS);
        const BOC_UID = BOCUserIdInfo.userId;
        (BOC_UID)
        ? yield* call_GetUserRegisterationType(BOC_UID, 'SIGN_IN', userInfoPayload)
        : yield put({type: actionTypes.APP_SIGN_IN_SERVICE_SUCCESS, BOC_NO_UserID_Err: true})
    } catch (e) {
         /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
         if(e instanceof TypeError === false && e.message !== "401"){
            yield* handleError(e.message);
        }
    }

}

function* SignIn_Auth() {
    yield takeEvery(actionTypes.APP_SIGN_IN_SERVICE_INIT, call_GetBOCUserIdByADB2CID);
  }
  
  export default SignIn_Auth;
  export { call_GetUserRegisterationType }