import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS,USER_ACTIVITY_LOGS } from '../../../constants/AppConstants';
import { _CALLPOSTAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import {
  fetchUserSubscriptionDetailsRequest,
  fetchUserSubscriptionDetailsSuccess,
  fetchUserSubscriptionDetailsFailure,
} from './GetUserSubscriptionDetailsSlice';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _FETCHUSERSUBSCRIPTIONDETAILS(customerId) {
  const endpoint = _BROTHER_PLUS_API_ENDPOINTS.FETCH_USER_SUBSCRIPTION_DETAILS;
  const logDetails = {
    action: USER_ACTIVITY_LOGS.BROTHERPLUS_FETCH_SUBSCRIPTION_DETAILS,
    description: `Fetch Brother plus subscription details for customer ${customerId}`,
  };
  const payload = {
    userId: customerId,
    servicePortalUser: await UserActivityLogsPayload(logDetails)
  };

  return _CALLPOSTAPI(endpoint, payload)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

function* fetchUserSubscriptionDetailsSaga(action) {
  try {
    const customerId = action.payload;
    const subscriptionDetails = yield call(_FETCHUSERSUBSCRIPTIONDETAILS, customerId);
    if (subscriptionDetails?.actionStatus.toUpperCase() === SUCCESS) {
      yield put(fetchUserSubscriptionDetailsSuccess(subscriptionDetails));
    } else {
      yield put(fetchUserSubscriptionDetailsFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(fetchUserSubscriptionDetailsFailure(error.message));
  }
}

export default function* watchFetchUserSubscriptionDetailsService() {
  yield takeEvery(fetchUserSubscriptionDetailsRequest.type, fetchUserSubscriptionDetailsSaga);
}
