import React from "react";
import { Container } from "reactstrap";
import PageHeader from "../components/shared/header/PageHeader";

const CSAgentLayout = ({children}) => {
    return (
        <>
            <PageHeader/>
            <Container className="cs-agent-container">
                {children}
            </Container>
        </>
    )
}

export default CSAgentLayout;