import React from "react";
import { useTranslation } from "react-i18next";
import './DeviceDetails.css';
import Loader from "../../../../../shared/loader/Loader";

const DeviceDetails = ({getUserDeviceStatus }) => {
    const { t } = useTranslation("CSAgenttranslation");
    const {deviceStatus,error,loading} =getUserDeviceStatus;
    const {
        printedPages,
        averagePages,
        duplexPages
    } = deviceStatus || {};

    if (loading) {
        return <Loader/>;
    }
    if (error) {
        return <div className="email-log-error">{t("ERROR_MESSAGES.DEVICE_NOT_FOUND")}</div>;
    }
    return (
        <div className="overview-container customer-details-wrapper mt-40">
            <div className="device-details">
                <div className="device-box">
                <h3>{t('CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_DEVICEDETAILS.PRINTED_PAGES')}</h3>
                    <p>{printedPages || 0}</p>
                </div>
                <div className="device-box">
                <h3>{t('CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_DEVICEDETAILS.AVERAGE_PAGES')}</h3>
                    <p>{averagePages || 0}</p>
                </div>
                <div className="device-box">
                <h3>{t('CUSTOMER_DASHBOARD.OVERVIEW.BROTHERPLUS_DEVICEDETAILS.DUPLEX_PAGES')}</h3>
                    <p>{duplexPages || 0}</p>
                </div>
            </div>
        </div>
    );
};

export default DeviceDetails;