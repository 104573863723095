import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SelectPlan from '../../assets/images/landing-page/select-plan.svg';
import ShipSupplies from '../../assets/images/landing-page/shipping.svg';
import EcoproFeature from '../../assets/images/landing-page/ecopro-feature.png'
import Mobile from '../../assets/images/landing-page/mobile.svg';
import Tablet from '../../assets/images/landing-page/tablet.svg';
import Laptop from '../../assets/images/landing-page/laptop.svg';
import Desktop from '../../assets/images/landing-page/desktop.svg';
import EcoproLogo from '../../assets/images/teaser_ecopro_logo.png';
import LandingCover from '../../assets/images/landing-page/landing-cover-mobile.png';
import ConnectWithMobileConnect from '../../assets/images/landing-page/connectWithMobileConnect.svg';
import './LandingPage.css'
import { FALLBACK_LNG, LOCALSESSIONDATAKEYS, SIGNUPTYPE } from '../../constants/AppConstants';
import { getPlanTranslation, getPrinterTypeTranslation, formatNumberOnLocale } from '../../constants/AppMethod';
import { Container } from "reactstrap";
import Slider from "react-slick";
import EcoProButton from '../../components/shared/button/button';
import { Accordion } from 'react-bootstrap';
import Loader from '../../components/shared/loader/Loader';
import { getPlansDataInLandingPage } from '../../actions/MyPlan';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { WebCustomerRoutePaths } from '../../constants/Routes';
import currencies from '../../constants/CurrencyTypes';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import PromotionBanner from '../../components/shared/PromotionBanner/PromotionBanner';
import { GetPromotionBannerStatus } from '../../actions/Web/Customer/SignUp';
import LoginBanner from '../../components/LandingPage/LoginBanner/LoginBanner';
import { ResetUserAccount } from '../../actions/UserAccount';
import { useIsAuthenticated } from '@azure/msal-react';
import { getLocalSessionData } from '../../utils/Authentication/handleUserSession';
import { extractLangcodeFromDomain } from '../../utils/Internationalization/handleAppInternationalization';
import { languageCodes } from '../../constants/Country';
import { fetchFeatureSwitchRequest } from '../../store/FeatureSwitch/FeatureSwitchslice';
import EasyPayPlan from '../../components/Web/Customer/ListPlan/EasyPayPlan/EasyPayPlan';
import { fetchFeatureSwitchByMultipleValuesRequest } from '../../store/FeatureSwitchByMultipleValues/FeatureSwitchByMultipleValuesSlice';


const LandingPage = () => {

    const {t} = useTranslation(['translation']);
    const location = useLocation();
    const MULTIPLE_ATTEMPT_ERR_CODE = "AADB2C90157";
    const history = useHistory()
    const dispatch = useDispatch()
    const [ecoproFeatureContent, setEcoproFeatureContent] = useState(null)
    const [printerType, setPrinterType] = useState()
    const [ecoproFAQContent, setEcoproFAQContent] = useState(null)
    const [ecoproPlanContent,setEcoproPlanContent] = useState(null)
    const [oneTimePlansCall, setOneTimePlansCall] = useState(false)
    const [currency, setCurrency] = useState(null);
    const [lowestPlanPrice, setLowestPlanPrice] = useState(null)
    const plansResult = useSelector(state => state.PlansLandingPage)
    const bannerResult = useSelector(state => state.GetPromotionBannerStatus)
    const planProducts = plansResult?.success && plansResult?.plans?.products
    const isAuthenticated = useIsAuthenticated();
    const isBrotherPlusProgramEnabled=useSelector((state)=>state.FeatureSwitch)

    useEffect(()=>{
      if(plansResult.success && !oneTimePlansCall){
        const initialProduct = planProducts?.find(product=>product.type === planProducts[0].type)
        setEcoproPlanContent(initialProduct)
        setPrinterType(initialProduct?.type)
        setCurrency(initialProduct?.plans[0]?.currency);

        const monoLaserProduct = planProducts?.find(product=>product.type.toUpperCase() === "MONO LASER")
        const monoLaserLowestPlan = monoLaserProduct?.plans?.find(plan=>plan.planPages.toString() === "50")
        setLowestPlanPrice(formatNumberOnLocale(monoLaserLowestPlan?.price, currencies[initialProduct?.plans[0]?.currency]?.code))
        
        setOneTimePlansCall(true)
      }
    },[plansResult,oneTimePlansCall,planProducts])
    
    const onPrinterTypeSelection = (selectedPrinterType) => {
      const selectedProduct = planProducts?.find(product=>product.type === selectedPrinterType)
      setEcoproPlanContent(selectedProduct)
      setPrinterType(selectedPrinterType)
    }

    const handleEcoproFeatureFallBackData = () => {
      const fallbackLanguage = FALLBACK_LNG
      import(`../../contents/${fallbackLanguage}/LandingPageEcoproFeatures.json`)
      .then((data) => {
        if(data.ecoproFeatures){
          setEcoproFeatureContent(data.ecoproFeatures);
        }
      })
      .catch((error) => {
        throw error
      })
    }

    const handleLandingFAQFallBackData = () => {
      const fallbackLanguage = FALLBACK_LNG
      import(`../../contents/${fallbackLanguage}/LandingPageFAQ.json`)
      .then((data) => {
        if(data.FAQ){
          setEcoproFAQContent(data.FAQ);
        }
      })
      .catch((error) => {
        throw error
      }) 
    }

    const fetchEcoproFeature = (languageCode) => {
      new Promise((res, rej) => {
        import(
          `../../contents/${languageCode}/LandingPageEcoproFeatures.json`
        )
          .then((data) => {
            if(data.ecoproFeatures){
              setEcoproFeatureContent(data.ecoproFeatures);
            }
            else{
              handleEcoproFeatureFallBackData()
            }
            
          })
          .catch((error) => {
            handleEcoproFeatureFallBackData()
          });
      });
    }
    const fetchEcoproFAQ = (languageCode) => {
      new Promise((res, rej) => {
        import(
          `../../contents/${languageCode}/LandingPageFAQ.json`
        )
          .then((data) => {
            if(data.FAQ){
              setEcoproFAQContent(data.FAQ)
            }
            else{
              handleLandingFAQFallBackData()
            }
            
          })
          .catch((error) => {
            handleLandingFAQFallBackData()
          });
      });
    }


    useEffect(() => {
      const browserDetectedLang = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE);
      const langFromDomain = extractLangcodeFromDomain();
      const landingPageLang = browserDetectedLang || languageCodes[langFromDomain];
      fetchEcoproFeature(landingPageLang)
      fetchEcoproFAQ(landingPageLang)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const featureSwitches = 'HardwareInclusive,BrotherPlusProgram,BOCWebEnrollment,DelayCancelService,ChangePlan,BonusPages,PromotionBanners,CarbonSavings,AutomatedOrderPlacement,AutomatedDeviceWarrantyRegistration';

    useEffect(() => {
        dispatch(getPlansDataInLandingPage())
        dispatch(GetPromotionBannerStatus())
        dispatch(ResetUserAccount());
        dispatch(fetchFeatureSwitchRequest("BrotherPlusProgram"))
        dispatch(fetchFeatureSwitchByMultipleValuesRequest())
        // eslint-disable-next-line react-hooks/exhaustive-deps      
    },[])
      
    if(location.hash.includes('error') && location.hash.split("%7c")[1] === SIGNUPTYPE.WEB){
      if(location.hash.includes(MULTIPLE_ATTEMPT_ERR_CODE)){
          return <Redirect to={WebCustomerRoutePaths.MULTIPLE_ATTEMPT_ERROR}></Redirect>
      } else {
          return <Redirect to={WebCustomerRoutePaths.SELECT_PRINTER}></Redirect>
      }
    }

    const settings = {
      className: "landing-page-carousel",
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      accessibility:true,      
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            centerMode:true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            customPaging: (i) => {
              return <span>{i + 1}/{ecoproPlanContent && ecoproPlanContent?.plans?.length}</span>;
            },
          }
        }
      ]
    };

    const submit = () => {
      history.push(WebCustomerRoutePaths.SELECT_PRINTER)
    }

    const StartFreeTrialButton = (
      <EcoProButton 
        btnID="start free trial"
        btnType="submit"
        cname='default epic-blue-btn landing-page-btn web-ecopro-btn'
        onBtnClick={() => submit()}>
          <Trans i18nKey="LANDING_PAGE.START_FREE_TRIAL">
            Start your <strong>free</strong> trial
          </Trans>
      </EcoProButton>
    )

    const LandingPageOverlay = (
      <div className='lp-overlay'>
          <img src={EcoproLogo} alt="ecopro logo"/>
        <h1>{t("LANDING_PAGE.HEADER")}</h1>
        <div>{t("LANDING_PAGE.SUB_HEADER")}</div>
        {StartFreeTrialButton}
      </div>
    )

    if(!ecoproFeatureContent || !ecoproFAQContent){
      return <Loader></Loader>
    }
    if(plansResult.loading || bannerResult.loading){
      return <Loader></Loader>
    }
    
    return ( 
        <div className='landing-page-container'>
            <div className='lp-cover-image-wrapper-laptop'>
            <div className='overlay-container'>
              {LandingPageOverlay}
              </div>
            </div>
            <div className='lp-cover-image-wrapper-mobile'>
              {LandingPageOverlay}
              <img className="lp-cover-img" src={LandingCover} alt="landing"/>
            </div>
            <PromotionBanner
              promotionText = {<Trans i18nKey="PROMOTION_BANNER.LIMITED_OFFER">
                Limited-time offer: Exclusive  <strong>6 months Free</strong> EcoPro subscription
                </Trans>}
              submit = {()=>submit()}
              toDisplayfreeTrialButton={true}
              StartFreeTrialButton = {StartFreeTrialButton}
            ></PromotionBanner>
            {(!isAuthenticated) ? <LoginBanner /> : null}
            <div className='lp-work-container'>
                <h1>{t("LANDING_PAGE.HOW_IT_WORKS")}</h1>
                <div className='lp-work-content-wrapper'>
                    <div className='lp-work-content'>
                        <img src={SelectPlan} alt="Select an ink or toner plan"/>
                        <div>{t("LANDING_PAGE.SELECT_PLAN")}</div>
                    </div>
                    <div className='lp-work-content content-center'>
                        <img src={ShipSupplies} alt="Select an ink or toner plan"/>
                        <div>{t("LANDING_PAGE.SHIP_SUPPLIES")}</div>
                    </div>
                    <div className='lp-work-content'>
                        <img src={ConnectWithMobileConnect} alt="Select an ink or toner plan"/>
                        <div>{t("LANDING_PAGE.MANAGE_WITH_MOBILE_CONNECT")}</div>
                    </div>
                </div>
            </div>
            <div className='lp-ecopro-feature-container'>
                <div>
                  <img src={EcoproFeature} alt="ecopro-feature"/>
                </div>
                <div className='lp-ecopro-feature-content-wrapper'>
                    <h1>{t("LANDING_PAGE.PRINT_WAY_YOU_WANT")}</h1>
                    <div className='lp-ep-feature-content'>
                      <ul>
                        {ecoproFeatureContent?.map(data => (
                             <li key={data.key}>
                                {data.content}
                             </li>
                        ))}
                      </ul>
                    </div>
                </div>
            </div>
            <div className='lp-subscription-plans'>
              <h1>{t("LANDING_PAGE.OUR_SUBSCRIPTION_PLANS")}</h1>
              <div className='lp-sp-printer-type-container'>
              <div className='lp-sp-printer-type'>
                {planProducts && planProducts?.map(planProduct=>(
                  <div className='lp-sp-printer-card' key={planProduct.type} onClick={()=>onPrinterTypeSelection(planProduct.type)}>
                    <div className='lp-sp-printer'>{getPrinterTypeTranslation(planProduct.type)}</div>
                    <div className={`border-curve ${printerType === planProduct.type? "lp-sp-card-active":""}`}></div>
                  </div>
                ))}
              </div>
              </div>
              <div className='lp-sp-plan-carousel-container'>
                <Container>
                  <Slider {...settings}>
                    {ecoproPlanContent?.plans?.map(plan=>(
                      <div key={plan.planId}>
                        {plan.isRecommended && 
                        <div className='lp-plan-most-popular-wrapper'><div className='lp-plan-most-popular'>{t("LANDING_PAGE.MOST_POPULAR")}</div></div>}
                        <h2>{getPlanTranslation(plan.planName, null, null, printerType)}</h2>
                        <div className='lp-c-plan-pages'>{t("CHANGE_PLAN.PAGES_PRINTED",{planPages:plan.planPages})}</div>
                        <div className='lp-c-plan-price'>{formatNumberOnLocale(plan.price, currencies[plan.currency]?.code)} {t("LANDING_PAGE.PER_MONTH")}</div>
                      </div>
                    ))}
                  </Slider>
                </Container>
              </div>
              <div className='lp-plans-btn-wrapper'>
                {StartFreeTrialButton}
              </div>
            </div>
            {isBrotherPlusProgramEnabled?.featureSwitch &&
              <EasyPayPlan source="landing-page"/>
            }
            <div className='lp-faq-container'>
                <h1>{t("LANDING_PAGE.FREQUENTLY_ASKED_QUESTIONS")}</h1>
                <div className="lp-faq">
                <Accordion flush>
                  {ecoproFAQContent?.map((data,i) => (
                    <Accordion.Item eventKey={i} key={data.key}>
                    <Accordion.Header>{data.title}</Accordion.Header>
                    <Accordion.Body>
                      {convertHtmlToReact(data.content, [true])}
                    </Accordion.Body>
                  </Accordion.Item>
                  ))}
                </Accordion>
                </div>
            </div>
            <div className='get-started-print'>
              <div className='get-started'>{t("LANDING_PAGE.GET_STARTED")}</div>
              <h1>{t("LANDING_PAGE.GET_STARTED_DESCRIPTION",{currency:currencies[currency]?.code, price:lowestPlanPrice})}</h1>
              <div className='gs-device-list-wrapper'>
                <div className='gs-device-list'>
                  <img src={Mobile} alt="mobile" className="gs-device-img"/>
                  <div className='gs-device-name'>{t("LANDING_PAGE.MOBILE")}</div>
                </div>
                <div className='gs-device-list'>
                  <img src={Tablet} alt="mobile" className="gs-device-img"/>
                  <div className='gs-device-name'>{t("LANDING_PAGE.TABLET")}</div>
                </div>
                <div className='gs-device-list'>
                  <img src={Laptop} alt="mobile" className="gs-device-img"/>
                  <div className='gs-device-name'>{t("LANDING_PAGE.LAPTOP")}</div>
                </div>
                <div className='gs-device-list'>
                  <img src={Desktop} alt="mobile" className="gs-device-img"/>
                  <div className='gs-device-name'>{t("LANDING_PAGE.DESKTOP")}</div>
                </div>
              </div>
              {StartFreeTrialButton}
            </div>
        </div>
    );
};

export default LandingPage;