import React, { useEffect, useState } from "react";
import './WelcomeHeader.css';
import { Is_BrotherPlus_Signup } from "../../../constants/AppMethod";
import { getUserDataByKey } from '../../../utils/Authentication/handleUserSession';
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const WelcomeHeader = () => {
  const [headerText, setHeaderText] = useState("");
  const [isBrotherPlusSignup, setIsBrotherPlusSignup] = useState(false);
 
  const {t} = useTranslation(["translation"])

  const getUserDisplayName = () => {
    return `${getUserDataByKey('firstName', 'personal_details')} ${getUserDataByKey('lastName', 'personal_details')}`
  };

  useEffect(() => {
    const isSignup = Is_BrotherPlus_Signup();
    setIsBrotherPlusSignup(isSignup);

    const userName = getUserDisplayName();

    if (isSignup) {
      setHeaderText(i18next.t('BROTHER_PLUS.DASHBOARD.BROTHERPLUS_HEADER', { userName }));
    } else {
      setHeaderText(i18next.t('DASHBOARD_CONNECT_DEVICE.WELCOME_HEADER', { userName }));
    }
  }, []);

  return (
    <div className="welcome-header-wrapper">
      <h1 className="h1">{headerText}</h1>
      {/* <div className="body-large">{t('BROTHER_PLUS.DASHBOARD.BROTHERPLUS_HEADER_INFO')} </div> */}
      <div className={`bar ${isBrotherPlusSignup ? 'brotherPlusBar' : 'ecoProBar'}`} />
    </div>
  );
};

export default WelcomeHeader;
