import React from 'react';
import PagesLeft from './PagesLeft';
import PagesRolledOver from './PagesRolledOver';
import PagesOverages from './PagesOverages';
import './EcoproPlanUsageDetails.css'
import { useSelector } from 'react-redux';

const EcoproPlanUsageDetails = (props) => {
    const {deviceInfoResponse} = props
    const EnrollmentStatusResult = useSelector(state => state.EnrollmentStatus);

    return (
      <div className={`${(EnrollmentStatusResult?.enrollment_completed) ? 'active-dashboard-blocks' : 'disabled-dashboard-blocks'}`}>
      {/**
      * MOBILE VERSION: This section is account overview section displayed only on mobile devices.
      */}
      <div className="account-info-carousel-view show-xs">
          <div className="xs-pages-left-wrapper col-lg-7">
            <PagesLeft
                deviceInfoResponse={deviceInfoResponse}   
            />
          </div>
          <div className="xs-pages-rollover-wrapper col-lg-7">
            <PagesRolledOver
                deviceInfoResponse={deviceInfoResponse}
            />
          </div>
          <div className="xs-pages-overage-wrapper col-lg-7">
            <PagesOverages
                deviceInfoResponse={deviceInfoResponse}
            />
          </div>
      </div>

      {/**
      * DESKTOP VERSION: This section is account overview section displayed only on tablet landscape orientation and large devices other than mobile if the customer is not eligible for swap.
      */}
      { <div className="account-info-normal-view show-lg dashboard-overview-without-swap-device">
        <div className="ecopro-pages-info-block-wrapper ecopro-plan-details-grid">
          <div className='ecopro-plan-usage-tile-1'>
            <PagesLeft
                deviceInfoResponse={deviceInfoResponse}
            />
          </div>
          <div className="overages-wrapper ecopro-plan-usage-tile-2">
            <PagesRolledOver
                deviceInfoResponse={deviceInfoResponse}
            />
            <PagesOverages
                deviceInfoResponse={deviceInfoResponse}
            />
          </div>
        </div>
      </div> }

    </ div>
    );
};

export default EcoproPlanUsageDetails;