// src/hooks/useUpdateDeviceState.js
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { _CALLGETAPI } from '../../api/apiHandler';
import { _API_ENDPOINT, FAILURE, LOCALSESSIONDATAKEYS } from '../../constants/AppConstants';
import { setLocalSessionData } from '../Authentication/handleUserSession';

const useEcoProUpdateDeviceState = ({ deviceId, userId, setDeviceInfoResponse, isSubscriptionEnrolled }) => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
 
  const updateState = useCallback(async () => {
    const endpoint = _API_ENDPOINT.DEVICE_INFO;
    const payload = {
      params: {
        deviceID: deviceId,
        UserId: userId,
        RequestOrigin: 'Dashboard'
      }
    };

    try {
      if (deviceId && userId) {
        const response = await _CALLGETAPI(endpoint, payload);
        const data = await response

        setDeviceInfoResponse(prev => ({ ...prev, loader: false }));

        if (data.actionStatus.toUpperCase() !== FAILURE) {
          if(data.printerType){
            setLocalSessionData(LOCALSESSIONDATAKEYS.PRINTER_TYPE, data.printerType);
          }
          setDeviceInfoResponse(prev => ({ ...prev, success: true, response: data }));
        } else if (data.actionStatus.toUpperCase() !== FAILURE && !data.service) {
          setDeviceInfoResponse(prev => ({
            ...prev,
            error: t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED')
          }));
        } else {
          setDeviceInfoResponse(prev => ({
            ...prev,
            error: t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
          }));
        }
      } else {
        setDeviceInfoResponse(prev => ({ ...prev, loader: false }));
        setDeviceInfoResponse(prev => ({
          ...prev,
          error: t('ERROR_CONTENT.INFO_GENERAL')
        }));
      }
    } catch (e) {
      if (!(e instanceof TypeError) && e.message !== '401') {
        setDeviceInfoResponse(prev => ({ ...prev, error: e.message }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [deviceId, userId, dispatch, isSubscriptionEnrolled, setDeviceInfoResponse, t]);

  return updateState;
};

export default useEcoProUpdateDeviceState;
