import React from 'react';
import Skeleton from 'react-loading-skeleton';
import EnrolledDevice from '../../EnrolledDevice/EnrolledDevice';
import { useTranslation } from 'react-i18next';
import { getLocalSessionData } from "../../../../utils/Authentication/handleUserSession";
import { LOCALSESSIONDATAKEYS } from "../../../../constants/AppConstants";
import './BrotherPlusEnrolledDevice.css'

const BrotherPlusEnrolledDevice = (props) => {
    const localStorageDeviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
     const { getDevicesResponse, deviceWarrantyStatus } = props;

    const matchingDevice = getDevicesResponse?.devices?.devices.find(
        (device) => device.deviceId === localStorageDeviceId
    );

     const { t } = useTranslation(["translation"]);
     const deviceWarrantyStatusInfo = (deviceWarrantyStatus) => {
        const statusText =  (deviceWarrantyStatus) ? Object.values(deviceWarrantyStatus).every(value => value === 'genuine') : false;
        return (statusText) 
        ? <span className='brother-plus-warranty-status-genuine'>{t("BROTHER_PLUS.DASHBOARD.DEVICE_WARRANTY_STATUS.GENUINE_USER")}</span> 
        : <span className='brother-plus-warranty-status-non-genuine'>{t("BROTHER_PLUS.DASHBOARD.DEVICE_WARRANTY_STATUS.NON_GENUINE_USER")}</span>
    }
 
    return (
        <>
            {getDevicesResponse?.loading ?
                <div className="device-not-connected-wrapper connect-app-section skeleton-web-connection brother-plus-enrolled-device-loader">
                    <div className="printer-icon">
                        <Skeleton
                        square
                        width="90px"
                        height="90px"
                        className="display-device-img-skeleton"
                        />
                    </div>
                    <div className="content">
                        <Skeleton className="rolled-over-overages-skeleton" />
                        <Skeleton className="rolled-over-overages-skeleton"/>           
                    </div>
                </div>
                :
                
                matchingDevice ? (
                    <EnrolledDevice
                        deviceImagePath={matchingDevice.imagePath}
                        deviceModel={matchingDevice.model}
                        deviceSerialNumber={matchingDevice.serialNumber}
                        networkStatus={matchingDevice.networkStatus}
                        printerType={matchingDevice.printerType}
                        deviceWarrantyStatusInfo={deviceWarrantyStatusInfo(deviceWarrantyStatus)}
                    />
                )
                :
                null
            }
        </>
    );
};

export default BrotherPlusEnrolledDevice;