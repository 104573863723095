import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    preEnrollmentDetails: {},
    loading: false,
    error: null,
};

const UserPreEnrollmentInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_PRE_ENROLLMENT_INFO_INIT:
            return {
                ...state,
                loading: true,
            };

        case actionTypes.GET_USER_PRE_ENROLLMENT_INFO_SUCCESS:
            return {
                ...state,
                preEnrollmentDetails: action.preEnrollmentDetails,
                loading: false,
                error: null,
            };

        case actionTypes.GET_USER_PRE_ENROLLMENT_INFO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                preEnrollmentDetails: {},
            };

        case actionTypes.RESET_STATE:
            return {
                ...state,
                loading: false,
                error: null,
                preEnrollmentDetails: {},
            };

        default:
            return { ...state };
    }
};

export default UserPreEnrollmentInfo;
