import { getReasonString } from "../../constants/AppMethod";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { getUserSessionData, getLocalSessionData,getUserDataByKey } from "../Authentication/handleUserSession";

const CancelOrderPayload = (args) => {
    const {
        reasonSelection, 
        otherReason,
        deviceID,
        customerCountryCode = null,
        uid = null,
        displayName,
        serialNumber,
        Model,
        isCancelBrotherPlusService,
        BPID
    } = args;
    const UID = (uid) ? uid : getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const device_ID = (deviceID) ? deviceID : getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const reasonString = getReasonString(reasonSelection);
    const bp_ID = (BPID) ? BPID : getUserDataByKey("sapBpId");
    
    const payload =  {
        UserId:UID,
        DeviceId:device_ID,
        bpId:bp_ID,
        ReasonCodes: (reasonString) ? reasonString : "",
        ReasonText: (otherReason && otherReason.length > 0) ? otherReason : null,
        isCancelBrotherPlusService: isCancelBrotherPlusService
    }
    const finalPayload = {
        ...payload,
        ...(customerCountryCode && { Country: customerCountryCode }),
        ...(displayName && { DisplayName: displayName }),
        ...(serialNumber && {serialNumber: serialNumber}),
        ...(Model && {Model: Model})
    };
    return finalPayload;
}
export default CancelOrderPayload;