import { combineReducers } from "redux";
import CustomerDashboardInfo from "./CSAgentPortal/CustomerDashboard/CustomerDashboardInfo";
import EnableDeviceSwap from "./CSAgentPortal/CustomerDashboard/EnableDeviceSwap";
import UpdateOrderConsumables from "./CSAgentPortal/CustomerDashboard/UpdateOrderConsumables";
import FetchEmailLogs from "./CSAgentPortal/CustomerDashboard/FetchEmailLogs";
import SupplyOrderLogs from "./CSAgentPortal/CustomerDashboard/SupplyOrderLogs";
import SubscriptionDetails from "./CSAgentPortal/CustomerDashboard/SubscriptionDetails";
import DeviceAndDeviceUsageDetails from "./CSAgentPortal/CustomerDashboard/DeviceAndDeviceUsageDetails";
import UserActivityHistory from "./CSAgentPortal/CustomerDashboard/UserActivityHistory";
import UserPreEnrollmentInfo from "./CSAgentPortal/CustomerDashboard/UserPreEnrollmentInfo";

export default combineReducers({
    CustomerDashboardInfo: CustomerDashboardInfo,
    EnableDeviceSwap: EnableDeviceSwap,
    UpdateOrderConsumables: UpdateOrderConsumables,
    FetchEmailLogs: FetchEmailLogs,
    SupplyOrderLogs: SupplyOrderLogs,
    SubscriptionDetails: SubscriptionDetails,
    DeviceAndDeviceUsageDetails: DeviceAndDeviceUsageDetails,
    UserActivityHistory:UserActivityHistory,
    UserPreEnrollmentInfo:UserPreEnrollmentInfo
})