import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import { getDeviceStatusFailure, getDeviceStatusRequest, getDeviceStatusSuccess } from './getDeviceStatusSlice';


function _GETDEVICESTATUS(userId, deviceId){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.GET_DEVICE_STATUS;
    const payload = {
        params:{
            userId: userId,
            deviceId: deviceId
        }
    }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getDeviceStatusSaga(action) {
  try {
    const userId = action.payload.userId
    const deviceId = action.payload.deviceId
    const deviceStatus = yield call(_GETDEVICESTATUS, userId, deviceId);
    if(deviceStatus?.actionStatus.toUpperCase() === SUCCESS){
      yield put(getDeviceStatusSuccess(deviceStatus));
    }
    else{
      yield put(getDeviceStatusFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(getDeviceStatusFailure(error.message));
  }
}

export default function* watchGetDeviceStatusService() {
  yield takeEvery(getDeviceStatusRequest.type, getDeviceStatusSaga);
}
