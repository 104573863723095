import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CancelOrder } from '../../../../actions/Dashboard';
import ErrorPage from '../../../../components/shared/errorPage/ErrorPage';
import Loader from '../../../../components/shared/loader/Loader';
import { routePath } from '../../../../constants/Routes';
import cancelConfirmation from '../../../../assets/images/confirm-cancellation.svg'
import DisplayUpdateConfirmationWithList from '../../../../components/EditMyAccount/DisplayUpdateConfirmationWithList/DisplayUpdateConfirmationWithList';
import { getLocalSessionData, getUserDataByKey, getUserSessionData } from '../../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS, UNIQUE_ID_GA, ECOPROTOKEN_CLAIMS } from '../../../../constants/AppConstants';
import { fetchCancelServiceRequest } from '../../../../store/brotherPlus/cancelService/cancelServiceSlice';
import { Is_BrotherPlus_Signup } from '../../../../constants/AppMethod';
import { getCountryCode } from '../../../../utils/Internationalization/handleAppInternationalization';

const ConfirmCancellation = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);
    const cancelOrderResponse = useSelector((state) => state.CancelOrder);
    const uid = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const reasonCodes = cancelOrderResponse && cancelOrderResponse.selectedFeedback.reasonCodes
    const otherReason = cancelOrderResponse && cancelOrderResponse.selectedFeedback.otherReason;
    const brotherPlusCancelServiceResponse = useSelector((state => state.BROTHERPLUS.cancelService))
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    const countryCode = getCountryCode();
    const isBrotherPlusCancellationEnabled = props?.location?.state?.isBrotherPlusCancellationEnabled; 
   
    const confirmedCancellation = () => {
      
        if(IsBrotherPlus_Signup){
          const displayName = getUserDataByKey("displayName", 'personal_details')
          dispatch(fetchCancelServiceRequest({uid, deviceId, displayName, reasonCodes, otherReason, countryCode}))
        }
        else{
          dispatch(CancelOrder(uid, deviceId, reasonCodes, otherReason, null, countryCode, isBrotherPlusCancellationEnabled));
        }
        
     }
      if(cancelOrderResponse.loading || brotherPlusCancelServiceResponse.loading){
        return <Loader></Loader>
      }
      if(cancelOrderResponse.error){
        return <ErrorPage errorInfo={cancelOrderResponse.error}></ErrorPage>
      }
      if(brotherPlusCancelServiceResponse.error){
        return <ErrorPage errorInfo={brotherPlusCancelServiceResponse.error}></ErrorPage>
      }
      if(cancelOrderResponse.success === true || brotherPlusCancelServiceResponse?.success === true){
        history.push({pathname: routePath.CANCEL_PLAN_SUCCESS, state: {'cancellationsuccess': true, 'isBrotherPlusCancellationEnabled': isBrotherPlusCancellationEnabled, 'cancelOrderResponse' : cancelOrderResponse?.cancelOrder}})
      }

    return (
      props.location.state && props.location.state.confirmStatus === true?
        <div>
          <DisplayUpdateConfirmationWithList
            account="cancelPlan"
            header={t("CONFIRM_CANCELLATION.HEADER")}
            subHeader={IsBrotherPlus_Signup ? t("BROTHER_PLUS.CANCEL_PLAN.BPLUS_CONFIRM_CANCELLATION"): t("CONFIRM_CANCELLATION.SUB_HEADER")}
            imagePath={cancelConfirmation}
            infoListHeading = {(IsBrotherPlus_Signup) ? t("BROTHER_PLUS.CANCEL_PLAN.LIST_HEADER") : null} 
            infoList={IsBrotherPlus_Signup ?t("BROTHER_PLUS.CANCEL_PLAN.LIST") : t("CONFIRM_CANCELLATION.LIST")}
            onBtnClick={confirmedCancellation}
            btnText={t("CONFIRM_CANCELLATION.CONFIRM_CANCELLATION_BTN")}
            linkUrl={routePath.DASHBOARD}
            linkText={t("CONFIRM_CANCELLATION.STOP_CANCELLATION")}
            onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-cancel-plan"}
          ></DisplayUpdateConfirmationWithList>  
        </div>
        :
        <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default ConfirmCancellation;