import React from "react";
import { Progress } from "reactstrap";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import currencies from "../../../constants/CurrencyTypes";
import "./DashboardAccountOverview.css";
import { formatNumberOnLocale } from "../../../constants/AppMethod";

const DashboardAccountOverview = (props) => {
  let pagesLeftProgressVal = 0,
    pagesRolledOverProgressVal = 0,
    overagesProgressVal = 0;
  const {
    planPages,
    planPagesLeft,
    planRolloverPages,
    planRolloverpagesLeft,
    planOveragePages,
    planOveragesPagesLeft,
    planCurrency,
    planPrice,
    overageBlockPages,
    deviceInfoLoader,
    cname,
    daysLeftForFreeTrial
  } = props;
  const { t } = useTranslation(["translation"]);
  const settings = {
    infinite: false,
    speed: 500,
    dots: true,
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  pagesLeftProgressVal = (planPagesLeft * 100) / planPages || 0;
  pagesRolledOverProgressVal =
    (planRolloverpagesLeft * 100) / planRolloverPages || 0;
  overagesProgressVal = (planOveragesPagesLeft * 100) / planOveragePages || 0;

  return (
    <div className={`account-info-wrapper ${cname}`}>
      {/**
      * MOBILE VERSION: This section is account overview section displayed only on mobile devices.
      */}
      <div className="account-info-carousel-view show-xs connect-device-paln-usage">
        <Slider {...settings}>
          <div className="pages-left col-lg-7">
            <h2 className="h2">{t("DASHBOARD.PAGES_USED")}</h2>
            <div className="free-trial-left-txt body-medium">{daysLeftForFreeTrial}</div>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesLeftProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">{planPagesLeft}</span>
                <span className="pages-leftover-count">/{planPages}</span>
              </CircularProgressbarWithChildren>
            )}
          </div>
          <div className="pages-left col-lg-7">
            <h2 className="h2">{t("DASHBOARD.PAGES_ROLLED_OVER")}</h2>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesRolledOverProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">
                  {planRolloverpagesLeft}
                </span>
                <span className="pages-leftover-count">
                  /{planRolloverPages}
                </span>
              </CircularProgressbarWithChildren>
            )}
          </div>
          <div className="pages-left col-lg-7">
            <h2 className="h2">{t("DASHBOARD.OVERAGES")}</h2>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={overagesProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">
                  {planOveragesPagesLeft}
                </span>
                <span className="pages-leftover-count">
                  /{planOveragePages}
                </span>
              </CircularProgressbarWithChildren>
            )}
            {deviceInfoLoader ? (
              <>
                <Skeleton className="para-skeleton1" />
              </>
            ) : (
              <>
                {planCurrency && (
                  <p>
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice, currencies[planCurrency]?.code),
                    })}
                  </p>
                )}
                {!planCurrency && (
                  <p>
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice),
                    })}
                  </p>
                )}
              </>
            )}
          </div>
        </Slider>
      </div>

      {/**
      * DESKTOP VERSION: This section is account overview section displayed only on tablet landscape orientation and large devices other than mobile if the customer is not eligible for swap.
      */}
      { <div className="account-info-normal-view show-lg dashboard-overview-without-swap-device connect-device-paln-usage">
        <div className="ecopro-pages-info-block-wrapper">
          <div className="pages-left">
            <h2 className="pages-left-lg h2">{t("DASHBOARD.PAGES_USED")}</h2>
            <div className="free-trial-left-txt body-medium">{daysLeftForFreeTrial}</div>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex pages-used-block-loader">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesLeftProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">{planPagesLeft}</span>
                <span className="pages-leftover-count">/{planPages}</span>
              </CircularProgressbarWithChildren>
            )}
          </div>
          <div className="overages-wrapper">
            <div className="pages-rolled-over">
              <div className="header">
                <h2 className="h2">{t("DASHBOARD.PAGES_ROLLED_OVER")}</h2>
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoLoader ? (
                  <Skeleton width="10%" />
                ) : (
                  <p className="body-medium">
                    {planRolloverpagesLeft}/{planRolloverPages}
                  </p>
                )}
                {deviceInfoLoader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : (
                  <Progress value={pagesRolledOverProgressVal} />
                )}
              </div>
            </div>
            <div className="pages-overages-wrapper connect-device-overage">
              <div className="header">
                <h2 className="h2">{t("DASHBOARD.OVERAGES")}</h2>
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoLoader ? (
                  <Skeleton width="10%" />
                ) : (
                  <p className="body-medium">
                    {planOveragesPagesLeft}/{planOveragePages}
                  </p>
                )}
                {deviceInfoLoader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : (
                  <Progress value={overagesProgressVal} />
                )}

                {planCurrency && (
                  <span className="body-small">
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice, currencies[planCurrency]?.code),
                    })}
                  </span>
                )}
                {!planCurrency &&
                  (deviceInfoLoader ? null : (
                    <span className="body-small">
                      {t("DASHBOARD.PAGES_PRICE", {
                        plan_pages: overageBlockPages,
                        plan_price: formatNumberOnLocale(planPrice),
                      })}
                    </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> }

    </ div>
  );
};

export default DashboardAccountOverview;
