import React, { useEffect, useState } from 'react';
import './DiscountTile.css';
import { getUserPromoCodeRequest } from '../../../../store/brotherPlus/getUserPromoCode/getUserPromoCodeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureSwitchByKey, getLocalSessionData, getUserSessionData } from '../../../../utils/Authentication/handleUserSession';
import { featureSwitchName, LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';
import { getCountryCode } from '../../../../utils/Internationalization/handleAppInternationalization';
import { ENVSettings } from '../../../../constants/ENVSettings';
import arrowIcon from '../../../../assets/images/BrotherPlus/discount-tile-arrow-icon.svg';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { validateUserPromoCodeRequest } from '../../../../store/brotherPlus/ValidateUserPromoCode/validateUserPromoCodeSlice';

const DiscountTile = () => {
    const { t } = useTranslation(["translation"]);
    const getUserPromoCodeResponse = useSelector((state) => state.BROTHERPLUS.getUserPromoCode)
    const getDevicesResponse = useSelector((state => state.BROTHERPLUS.getDevices))
    const validatePromoCodeResponse = useSelector((state) => state.BROTHERPLUS.validateUserPromoCode);
    const userId = getUserSessionData('UserId');
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
    const deviceModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL)
    const countryCode = getCountryCode();
    const languageCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE)
    const serialnumber = getLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER)
    const dispatch = useDispatch()
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [hasOpenedTab, setHasOpenedTab] = useState(false);
    const [discountTileClicked, setDiscountTileClicked] = useState(false)
    const [showPromoCodeTile, setShowPromoCodeTile] = useState(false);
 
    const handleClick = () => {
        setDiscountTileClicked(true)
        setShowSuccessMessage(false);
        setHasOpenedTab(false);
        dispatch(getUserPromoCodeRequest({userId, deviceId}));
    };
  
    useEffect(() => {
        if (getFeatureSwitchByKey(featureSwitchName.BROTHER_PLUS_PROMO_CODE)) {
            if(userId)
            dispatch(validateUserPromoCodeRequest({userId}));
        }
    }, [dispatch, userId]);

    useEffect(()=>{
        if (validatePromoCodeResponse?.validationResult?.isPromoCodeOfferValid) {
            setShowPromoCodeTile(true);
        } else {
            setShowPromoCodeTile(false);
        }
    },[validatePromoCodeResponse])


 
    useEffect(() => {
        let timeoutId;
        if (getUserPromoCodeResponse?.success && getUserPromoCodeResponse?.userPromoCode?.promoCode && !hasOpenedTab && discountTileClicked) {
            const promoCode = getUserPromoCodeResponse?.userPromoCode?.promoCode;
            const baseurl = (!ENVSettings.BROTHER_STORE_BASE_URL)
                ? window.env[`BROTHER_STORE_EASYBUY_BASKET_BASEURL_${countryCode}`]
                : ENVSettings.BROTHER_STORE_BASE_URL;
 
            setShowSuccessMessage(true);
            timeoutId = setTimeout(() => {
                setShowSuccessMessage(false);

                const newTabUrl = `${baseurl}?model=${deviceModel}&country=${countryCode}&lang=${languageCode}&sn=${serialnumber}&coupon=${promoCode}`;
                window.open(newTabUrl, '_blank');
 
                setHasOpenedTab(true);
                setDiscountTileClicked(false)
            }, 3000);
        }
 
        return () => {
            clearTimeout(timeoutId)
        }
    }, [getUserPromoCodeResponse, countryCode, languageCode, deviceModel, serialnumber, hasOpenedTab, discountTileClicked]);
 
    return (
        <>
      {
        showPromoCodeTile && <>
          {getDevicesResponse?.loading ?
            <div className='discount-skeleton-loader'>
                <div className="content">
                        <Skeleton className="rolled-over-overages-skeleton" width={90} />
                        <Skeleton className="rolled-over-overages-skeleton"/>
                        <Skeleton className="rolled-over-overages-skeleton" />
                        <Skeleton className="rolled-over-overages-skeleton" />
                </div>
            </div>
            :
            <div className="discount-tile" onClick={handleClick}>
                {getUserPromoCodeResponse?.loading ?
                    <div className="spinner-overlay">
                        <div className="web-form-input-spinner-wrapper">
                            <div className="spinner-border" role="status">
                            </div>
                        </div>
                        <div className='spinner-status'>
                            <div>{t('BROTHER_PLUS.DISCOUNT_TILE.PROMO_CODE_STATUS.LOADING.TEXT_1')}</div>
                            <div>{t('BROTHER_PLUS.DISCOUNT_TILE.PROMO_CODE_STATUS.LOADING.TEXT_2')}</div>
                        </div>
                    </div>
                :
                showSuccessMessage ?
                    <div className="spinner-overlay">
                        <div className='spinner-status promo-success'>
                            <div>{t('BROTHER_PLUS.DISCOUNT_TILE.PROMO_CODE_STATUS.SUCCESS.TEXT_1')}</div>
                            <div>{t('BROTHER_PLUS.DISCOUNT_TILE.PROMO_CODE_STATUS.SUCCESS.TEXT_2')}</div>
                        </div>
                    </div>
                :
                getUserPromoCodeResponse?.error ?
                    <div className="spinner-overlay">
                        <div className='spinner-status promo-success'>
                            <div>{t('BROTHER_PLUS.DISCOUNT_TILE.PROMO_CODE_STATUS.ERROR.TEXT_1')}</div>
                            <div>{t('BROTHER_PLUS.DISCOUNT_TILE.PROMO_CODE_STATUS.ERROR.TEXT_2')}</div>
                        </div>
                    </div>
                :
                null
                }
                <div>
                    <h1 className='h1'>{t("BROTHER_PLUS.DISCOUNT_TILE.DESCRIPTION")}</h1>
                    <p className='body-medium'>{t("BROTHER_PLUS.DISCOUNT_TILE.SUB_DESCRIPTION")}</p>
                </div>
                <span className="arrowtile"><img src={arrowIcon} alt='discount-tile-arrow' title='discount-tile-arrow' /></span>
            </div>
        }
        </>
      }
        </>
    );
};
 
export default DiscountTile;