import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  convertToKG,
  getCarbonSavingTypeTranslation,
} from "../../../constants/AppMethod";
import "./CarbonSaving.css";
import { DisplayDate } from "../../../utils/DisplayDate";
import { useDispatch, useSelector } from 'react-redux';
import { GetCarbonSaving } from '../../../actions/CarbonSaving';
import { getUserSessionData } from '../../../utils/Authentication/handleUserSession';
import { ECOPROTOKEN_CLAIMS } from '../../../constants/AppConstants';
import leafIcon from '../../../assets/images/eco-savings-leaf-dashboard-block-icon.svg';
import Skeleton from "react-loading-skeleton";


const CarbonSaving = (props) => {
  const dispatch = useDispatch();
  const { isBlockActive } = props;
  const getCarbonSavingResult = useSelector(state => state.CarbonSaving)
  const carbonSavings = getCarbonSavingResult.carbonSaving;
  const { t } = useTranslation(["translation"]);
  const dateDisplayOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID)

  useEffect(() => {
    dispatch(GetCarbonSaving(UID))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`carbon-offsetting eco-savings-block-dashboard ${(isBlockActive) ? 'active-dashboard-blocks' : 'disabled-dashboard-blocks'}`}>

      <div className="icon-name-container">
        <img src={leafIcon} alt="carbon-savings-icon" className="icon" />
        <h2 className="EcoSavings h2">{t("CARBON_OFFSETTING.HOW_CO2_CALCULATED.PAGE_TITLE")}</h2>
      </div>
      {getCarbonSavingResult?.loading ? 
        <div className="pages-used-loader-wrapper">
          <Skeleton className="para-skeleton1" />
          <Skeleton className="para-skeleton2" />

          <div className="circular-skeleton-flex">
              <Skeleton
              circle
              className="circular-skeleton"
              height="108px"
              width="108px"
              duration={0.9}
              />
          </div>
        </div>
        :
        <div className="co2-info  co2-calculated-border">
          {carbonSavings?.userCarbonSavingSummary &&
            carbonSavings?.userCarbonSavingSummary.length === 0 ? (
            <p className="info-error-message">{t("ERROR_CONTENT.NO_DATA")}</p>
          ) : (
            carbonSavings?.userCarbonSavingSummary &&
            carbonSavings.userCarbonSavingSummary.map((carbonSaving, index) => (
              <div className="carbon-savings-item-row" key={index}>
                <div className="col-8">
                  <div className="f-14 ta-left body-small">
                    {getCarbonSavingTypeTranslation(carbonSaving?.carbonSavingType)}
                  </div>
                  <div className="supplies-date f-12 ta-left caption">
                    {DisplayDate(carbonSaving.date, dateDisplayOptions, 'en-GB')}
                  </div>
                </div>
                <div className="col supplies-kg number-bar-container .body-medium-large">
                  {/* Number */}
                  <span className="carbon-amount">{`+${convertToKG(carbonSaving.cO2e)}${t("CARBON_OFFSETTING.KG")}`}</span>
                  {/* Bar */}
                  <div className="bar_ecosave" style={{ width: `${carbonSaving.cO2e >=100 ? 50 : carbonSaving.cO2e /2}%` }}></div>
                </div>
              </div>
            ))
          )}
        </div>
      }
    </div>
  );
};

export default CarbonSaving;
