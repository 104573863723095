import React from "react";
import { useTranslation } from "react-i18next";
import { getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";
import "./AveragePagesUsed.css";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  chooseMaxOrMinDate,
  getAverageValue,
  getChartLabels,
  getChartValues,
  getGradient,
  getYAxesMaxValue,
  sortDateInDescendingOrder,
} from "../../../constants/AppMethod";
Chart.register(...registerables);
Chart.register(annotationPlugin);

const AveragePagesUsed = (props) => {
  const { printHistoryResult, source } = props;

  const { t } = useTranslation(["translation"]);
  const dateDisplayOptions = { year: "numeric", month: "short" };
  const langCode = getLangCode();
  const monthCount = source === "brother-plus" ? 12 : 6
  const sixMonthsData = sortDateInDescendingOrder(printHistoryResult, source, monthCount);
  const chartLabels = getChartLabels(sixMonthsData, source, monthCount).labels;
  const chartValues = getChartValues(chartLabels, sixMonthsData, source, monthCount);
  const chartAverageValue = getAverageValue(chartValues, source);
  const yAxesMaxValue = getYAxesMaxValue(sixMonthsData, source);
  const endDate = chooseMaxOrMinDate("max",sixMonthsData,dateDisplayOptions,langCode, source);
  const startDate = getChartLabels(sixMonthsData, source, monthCount)?.endDate;
  
  const calculateStepSizeAndMax = (maxValue, numSteps) => {
    // Round the max value to the nearest multiple of 50
    const roundedMax = Math.ceil(maxValue / 50) * 50;
  
    // Calculate the raw step size
    const rawStepSize = roundedMax / numSteps;
  
    // Round step size up to the nearest multiple of 10 for better readability
    const stepSize = Math.ceil(rawStepSize / 10) * 10;
  
    // Adjust max value to be a multiple of the new step size
    const adjustedMax = stepSize * numSteps;
  
    return { stepSize, adjustedMax };
  };
  const numberOfSteps = 6;

  const { stepSize, adjustedMax } = calculateStepSizeAndMax(yAxesMaxValue, numberOfSteps);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: "right",
        min: 0,
        max: adjustedMax,
        ticks: {
          stepSize: stepSize,
          color: "#545454",
          borderDash: [2, 2],
        },
        grid: {
          drawTicks: false,
          display: false,
        },
      },
      y1: {
        position: "left",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        position: "bottom",
        ticks: {
          font: {
            size: 12,
            family: "Arial",
          },
          tickBorderDash: [2, 2],
          color: "#545454",
        },
        grid: {
          tickBorderDash: [2, 2],
          drawBorder: true,
          borderColor: "#DDDDDD",
          borderWidth: 1,
          borderDash: [2, 2],
          color: "#DDDDDD",
          lineWidth: (ctx) => (ctx.index % 6 ? 0.5 : 0),
        },
      },
      x1: {
        position: "top",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      border: {
        display: false,
        dashOffset: 1,
        z: 5,
      },
    },
    plugins: {
      autocolors: false,
      datalabels:{
        display:false
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: chartAverageValue,
            yMax: chartAverageValue,
            borderColor: "#E4E4E4",
            borderWidth: 0.5,
            borderDash: [2, 2],
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const data = {
    legend: {
      display: false,
    },
    labels: chartLabels,
    datasets: [
      {
        data: chartValues,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, "#007E8F", "#00B1C9");
        },
        barThickness: 15,
        borderRadius: 5,
      },
    ],
  };

  return (
    <div className="average-pages-used-wrapper">
      <div className="average-page-trends h2">{source === "brother-plus" ? t("BROTHER_PLUS.DASHBOARD.PRINT_HISTORY.PRINT_HISTORY") : t("PRINT_ACTIVITY.TRENDS")}</div>
      <div className="average-pages-used-content">
        <div className="trends-header body-medium">
          {source === "brother-plus" ? t("BROTHER_PLUS.DASHBOARD.PRINT_HISTORY.TWELVE_MONTHS"):
          t("PRINT_ACTIVITY.MONTHLY_AVERAGE_SIX_MONTHS")}
        </div>
        {source === "brother-plus" ?
          null
          :
          <h1 className="h1">
            <span className="average-page-count">{chartAverageValue}</span>{" "}
            {t("PRINT_ACTIVITY.PAGES")}
          </h1>
        }
        <div className="trends-date caption">
          {startDate} - {endDate}
        </div>
        <div className="trends-bar-chart-wrapper-avg-page">
          <Bar
            id="chart"
            data={data}
            options={options}
            style={{ width: '100%', height: "165px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AveragePagesUsed;
