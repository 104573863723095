import { call, put, takeEvery } from 'redux-saga/effects';
import { LOCALSESSIONDATAKEYS, SUCCESS, _BROTHER_PLUS_API_ENDPOINTS } from '../../../constants/AppConstants';
import { _CALLPUTAPI } from '../../../api/apiHandler';
import { fetchCancelServiceFailure, fetchCancelServiceRequest, fetchCancelServiceSuccess } from './cancelServiceSlice';
import i18next from 'i18next';
import CancelOrderPayload from '../../../utils/MyAccount/CancelOrderPayload';
import { getLocalSessionData } from '../../../utils/Authentication/handleUserSession';


function _CANCELSERVICE(payload){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.CANCEL_SERVICE;
    return _CALLPUTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchCancelServiceSaga(action) {
  try {
    const uid = action?.payload?.uid
    const deviceId = action?.payload?.deviceId
    const displayName = action?.payload?.displayName
    const feedbackSelection = action?.payload?.reasonCodes
    const otherReason = action?.payload?.otherReason
    const customerCountryCode = action?.payload?.countryCode
    const serialNumber = getLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER)
    const Model = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL)
    const cancelOrderPayload = CancelOrderPayload({
      'reasonSelection': feedbackSelection,
      'otherReason': otherReason,
      'deviceID': deviceId,
      'customerCountryCode': customerCountryCode,
      'uid': uid,
      'displayName': displayName,
      'serialNumber': serialNumber,
      'Model': Model
  });
    const cancelService = yield call(_CANCELSERVICE, cancelOrderPayload);
    if(cancelService.actionStatus.toUpperCase() === SUCCESS){
      yield put(fetchCancelServiceSuccess(cancelService));
    }
    else{
      yield put(fetchCancelServiceFailure({message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR")}));
    }
  } catch (error) {
    yield put(fetchCancelServiceFailure({message:error.message}));
  }
}

export default function* watchFetchCancelService() {
  yield takeEvery(fetchCancelServiceRequest.type, fetchCancelServiceSaga);
}
