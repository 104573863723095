import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignIn';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { SUCCESS, WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import i18next from 'i18next';
import { Is_HWInclusive_Signup } from '../../../constants/AppMethod';
import { HW_INCLUSIVE_GET_PRE_ENROLL_INFO } from '../../HardwareInclusive/HWInclusive_ManageInstantPayment';

function _GETPREENROLLMENTINFO(UID){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_PRE_ENROLL_INFO;
    const payload = {
        params: {
            userId: UID
        }
      }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleEnrollmentProcess(action){
    try{
        const UID = action.UID;
        if(UID){
            const IsHWInclusive_Signup = Is_HWInclusive_Signup(); 
            const enrollmentDetails = (IsHWInclusive_Signup) ? yield call(HW_INCLUSIVE_GET_PRE_ENROLL_INFO) : yield call(_GETPREENROLLMENTINFO, UID);
            if(enrollmentDetails.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.GET_PRE_ENROLLMENT_INFO_SUCCESS, enrollmentResponse: enrollmentDetails});
            } else {
                yield put({type: actionTypes.GET_PRE_ENROLLMENT_INFO_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
        } else {
            yield put({type: actionTypes.GET_PRE_ENROLLMENT_INFO_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_PRE_ENROLLMENT_INFO_ERROR, message: e.message});
        }
    }
}
function* GetPreEnrollmentInfo(){
    yield takeEvery(actionTypes.GET_PRE_ENROLLMENT_INFO_INIT, handleEnrollmentProcess);
}

export default GetPreEnrollmentInfo;