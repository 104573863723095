import React from 'react';
import { Link } from 'react-router-dom';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import { useTranslation } from 'react-i18next';
import './DisplayUpdateConfirmationWithList.css';
import EcoProButton from '../../shared/button/button';

const DisplayUpdateConfirmationWithList = (props) => {
    const { account, header, subHeader, imagePath,model, serialNumber, infoList, onBtnClick, btnText, linkUrl, linkText, linkWithState, onUpdateBtnID, infoListHeading = null } = props
    const { t } = useTranslation(["translation"]);
    
    return (
        <div className='confirm-cancellation'>
            <div className='f-20 fc-e header'>{header}</div>
            <div className='f-12 sub-header'>{subHeader}</div>
            {account === "cancelPlan" ?
                <div className='cancel-confirmation-img'><img src={imagePath} alt="plan cancel confirmation"></img></div>
                :
                account === "swapDevice" ?
                <div className='swap-confirmation-img-content'>
                <div className={`swap-confirmation-img ${imagePath ? "":"image-background"}`}><img src={imagePath} alt="change device confirmation"></img></div>
                <div className="f-20 fw-b">{model}</div>
                <div className="display-device f-12">{t("DASHBOARD.DISPLAY_DEVICE.SERIAL_NUMBER")}: {serialNumber}</div>
                </div>
                :
                null
            }
            <div className='list f-12'>
                {(infoListHeading) ? <p className='list-heading'>{infoListHeading}</p> : null}
                <ul>{convertHtmlToReact(infoList, [true])}</ul>
            </div>
            <div className='confirm-cancellation-btn'><EcoProButton cname="default" btnID={onUpdateBtnID} onBtnClick={() => onBtnClick()}>{btnText}</EcoProButton></div>
            <div className="cancellation-link f-14">
                <Link to={{
                pathname: linkUrl,
                state: linkWithState && {'deviceId':linkWithState.deviceId,'device_model': linkWithState.device_model,'serialNumber':linkWithState.serialNumber,'networkStatus':linkWithState.networkStatus}
                }} id="change_plan">{linkText}</Link>
            </div>
        </div>
    );
};

export default DisplayUpdateConfirmationWithList;
