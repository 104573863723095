import { createSlice } from '@reduxjs/toolkit';

const getUserPromoCodeSlice = createSlice({
  name: 'getUserPromoCode',
  initialState: {
    loading: false,
    userPromoCode: null,
    error: null,
    success:false
  },
  reducers: {
    getUserPromoCodeRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getUserPromoCodeSuccess(state, action) {
      state.loading = false;
      state.success = true
      state.userPromoCode = action.payload;
    },
    getUserPromoCodeFailure(state, action) {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    },
  },
});

export const { getUserPromoCodeRequest, getUserPromoCodeSuccess, getUserPromoCodeFailure } = getUserPromoCodeSlice.actions;
export default getUserPromoCodeSlice.reducer;

