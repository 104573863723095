import { createSlice } from '@reduxjs/toolkit';

const UserRegistrationSlice = createSlice({
  name: 'GetUserRegistrationService',
  initialState: {
    loading: false,
    data: null,
    error: null,
    success: false
  },
  reducers: {
    GetUserRegistrationServiceInit(state) {
      state.loading = true;
      state.error = null;
    },
    GetUserRegistrationServiceSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.success = true
    },
    GetUserRegistrationServiceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false
    },
    GetUserRegistrationServiceReset(state) {
      return {...state, loading: false, data: null, success:  false, error: null};
    },
  },
});

export const { 
  GetUserRegistrationServiceInit, 
  GetUserRegistrationServiceSuccess, 
  GetUserRegistrationServiceFailure,
  GetUserRegistrationServiceReset
} = UserRegistrationSlice.actions;

export default UserRegistrationSlice.reducer;
