import * as actionTypes from '../../constants/ActionTypes/Authentication/SignIn_SignUp_Auth';

const initialState = {
    loading: false,
    error: null,
    success:false,
    data:{},
    BOC_NO_UserID_Err: false
  }

const SignIn_Auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.APP_SIGN_IN_SERVICE_INIT:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.APP_SIGN_IN_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                data: action.data,
                BOC_NO_UserID_Err: action.BOC_NO_UserID_Err
            }
        
        case actionTypes.APP_SIGN_IN_SERVICE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return {...state}
    }
}

export default SignIn_Auth;