import { createSlice } from '@reduxjs/toolkit';

const getDevicesSlice = createSlice({
  name: 'getDevices',
  initialState: {
    loading: false,
    devices: null,
    error: null,
  },
  reducers: {
    getDevicesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getDevicesSuccess(state, action) {
      state.loading = false;
      state.devices = action.payload;
    },
    getDevicesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getDevicesRequest, getDevicesSuccess, getDevicesFailure } = getDevicesSlice.actions;
export default getDevicesSlice.reducer;
