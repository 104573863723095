import { call, put, takeEvery } from 'redux-saga/effects';
import { _ADMINISTRATOR_API_ENDPOINTS} from '../../constants/AppConstants';
import { _CALLGETAPI } from '../../api/apiHandler';
import { fetchFeatureSwitchByMultipleValuesFailure, fetchFeatureSwitchByMultipleValuesRequest, fetchFeatureSwitchByMultipleValuesSuccess } from './FeatureSwitchByMultipleValuesSlice';


function _FEATURESWITCH(){

    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.GET_FEATURE_SWITCH_ITEMS_VALUES;
    return _CALLGETAPI(endpoint)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchFeatureSwitchByMultipleValuesSaga(action) {
  try {
    const featureSwitchService = yield call(_FEATURESWITCH);
    yield put(fetchFeatureSwitchByMultipleValuesSuccess(featureSwitchService));
  } catch (error) {
    yield put(fetchFeatureSwitchByMultipleValuesFailure(error.message));
  }
}



export default function* watchFetchFeatureSwitchByMultipleValues() {
  yield takeEvery(fetchFeatureSwitchByMultipleValuesRequest.type, fetchFeatureSwitchByMultipleValuesSaga);
}
