import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import { _CALLPUTAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, ENROLLMENT_ASYNC_FLOW_TYPE, LOCALSESSIONDATAKEYS, SIGNUPTYPE, SUCCESS } from '../../constants/AppConstants';
import i18next from 'i18next';
import { GetUserRegistrationServiceReset } from '../../store/UserRegistration/UserRegistrationSlice';
import { getLocalSessionData, setLocalSessionData } from '../../utils/Authentication/handleUserSession';

function _PLACEORDERCALL(payload){
    const endpoint = (ENROLLMENT_ASYNC_FLOW_TYPE) ? _API_ENDPOINT.ASYNC_ENROLLMENT_PLACEORDER :_API_ENDPOINT.ENROLLMENT_PLACEORDER;
    return _CALLPUTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(message){
    yield put({
        type: actionTypes.ACCOUNT_ENROLLMENT_ERROR, 
        message: message
    });
}
function* handleEnrollmentProcess(action){
    try{
        const enrollmentDetails = yield call(_PLACEORDERCALL, action.placeOrderPayload);
        if(enrollmentDetails.actionStatus.toUpperCase() === SUCCESS){
            if(ENROLLMENT_ASYNC_FLOW_TYPE){
                if(enrollmentDetails?.customCode === "200" || enrollmentDetails?.customCode === '202'){
                    setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, SIGNUPTYPE.WEB);
                    const PreEnrollId = getLocalSessionData(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID);
                    if(PreEnrollId){
                        localStorage.removeItem(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID);
                    }
                    yield put(GetUserRegistrationServiceReset());
                    yield put({type: actionTypes.ACCOUNT_ENROLLMENT_SUCCESS, enrollmentResponse: enrollmentDetails})
                } else {
                    yield* handleError("Device not yet connected");
                }
            } else{
                yield put({type: actionTypes.ACCOUNT_ENROLLMENT_SUCCESS, enrollmentResponse: enrollmentDetails});
            }
        } else {
            const message = (enrollmentDetails?.customCode === "599") 
            ? i18next.t('ERROR_CONTENT.PLACEORDER_API_599_CODE_ERROR_MSG') 
            : i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR');
            yield* handleError(message);
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield* handleError(e.message);
        }
    }
}
function* Enrollment(){
    yield takeEvery(actionTypes.ACCOUNT_ENROLLMENT_INIT, handleEnrollmentProcess);
}

export default Enrollment;