import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import { routePath } from "../../constants/Routes";
import CancellationConfirmed from "../../pages/Account/CancelPlan/CancellationCompleted/CancellationCompleted";
import ConfirmCancellation from "../../pages/Account/CancelPlan/ConfirmCancellation.js/ConfirmCancellation";
import Feedback from "../../pages/Account/CancelPlan/Feedback/Feedback";
import ChangePlan from "../../pages/Account/EditAccount/ChangePlan";
import EditAccount from "../../pages/Account/EditAccount/EditAccount";
import UpdateAccountConfirmation from "../../pages/Account/EditAccount/UpdateAccountConfirmation";
import UpdateAccountSuccess from "../../pages/Account/EditAccount/UpdateAccountSuccess";
import MyAccountLanding from "../../pages/Account/MyAccount/MyAccount";
import ConfirmDevice from "../../pages/Account/SignUp/ConfirmDevice/ConfirmDevice";
import SelectPlan from "../../pages/Account/SignUp/SelectPlan/SelectPlan";
import ZuoraPaymentLanding from "../../pages/Account/SignUp/ZuoraPaymentLanding/ZuoraPaymentLanding";
import AddNewDevice from "../../pages/Account/SwapDevice/AddNewDevice";
import SwapDevice from "../../pages/Account/SwapDevice/SwapDevice";
import SwapDeviceConfirm from "../../pages/Account/SwapDevice/SwapDeviceConfirm";
import SwapDeviceSuccess from "../../pages/Account/SwapDevice/SwapDeviceSuccess";
import CarbonOffsetting from "../../pages/CarbonOffsetting/CarbonOffsetting";
import EcoproFeedback from "../../pages/Dashboard/EcoproFeedback/EcoproFeedback";
import EnrollmentFeedback from "../../pages/Dashboard/EnrollmentFeedback/EnrollmentFeedback";
import OrderDetails from "../../pages/Dashboard/OrderDetails/OrderDetails";
import OrdersHistory from "../../pages/Dashboard/OrdersHistory/OrdersHistory";
import HelpAndSupport from "../../pages/HelpAndSupport/HelpAndSupport";
import MobileHome from "../../pages/Home/MobileHome";
import PaymentOptions from "../../pages/PaymentOptions/PaymentOptions";
import PrintActivity from "../../pages/PrintActivity/PrintActivity";
import ProcessAddDevice from "../../pages/Web/Customer/Account/SignIn/AddDevice/ProcessAddDevice";
import WebAddEligibleDevice from "../../pages/Web/Customer/Account/SignIn/AddDevice/WebAddEligibleDevice";
import WebAddMyDevice from "../../pages/Web/Customer/Account/SignIn/AddDevice/WebAddMyDevice";
import WebNoDeviceFound from "../../pages/Web/Customer/Account/SignIn/AddDevice/WebNoDeviceFound";
import WebPlaceOrder from "../../pages/Web/Customer/Account/SignIn/WebPlaceOrder";
import DeviceList from "../../pages/Account/SignUp/DeviceList/DeviceList"
import LegalDocs_TermsNConditions from "../../pages/LegalDocuments/TermsAndConditions/TermsAndConditions";
import HowCo2Calculated from "../../pages/CarbonOffsetting/HowCo2Calculated"
import FAQSublist from "../../pages/HelpAndSupport/FAQ/SubList/SubList";
import FAQContent from "../../pages/HelpAndSupport/FAQ/Content/Content";
import Redirection from "../../pages/Redirection/Redirection";
import SubscriptionNetworkErrorValidation from "../../pages/SubscriptionNetworkErrorValidation";
import AddAccount from "../../pages/Account/SignUp/AddAccountDetails/AddAccount";
import CustomerEditProfile from "../../pages/Account/EditAccount/CustomerEditProfile";
import ShenlongTeaser from "../../pages/BenefitOfSupplies/ShenlongTeaser/ShenlongTeaser";
import ConnectDeviceDashboard from "../../pages/Web/Customer/Account/SignUp/Dashboard/ConnectDeviceDashboard";
import EditPayment from "../../pages/Account/EditAccount/EditPayment";
import CustomerDashboard from "../../pages/Dashboard/CustomerDashboard/CustomerDashboard";

export const maintenanceServiceRoutePaths = [
  {
    path: routePath.SHENLONG_REDIRECT,
    component: MobileHome,
  },
  {
    path: routePath.REDIRECTION,
    component: Redirection,
  }
]
export const customerUnProtectedRoutePaths = [
  {
    path: routePath.SHENLONG_REDIRECT,
    component: MobileHome,
  },
  {
    path: routePath.BENEFIT_OF_SUPPLIES,
    exact:false,
    component: ShenlongTeaser,
  },
  {
    path: routePath.REDIRECTION,
    component: Redirection,
  },
  {
    path: routePath.LEGAL_TNC_BLANK,
    component: LegalDocs_TermsNConditions,
  }
];

export const customerProtectedRoutePaths = [
  {
    path: routePath.PLANS,
    isSubscribed: false,
    component: SelectPlan
    
  },
  {
    path: routePath.ERROR,
    isSubscribed: false,
    component: ErrorPage,
  },
  {
    path: routePath.ACCOUNT,
    isSubscribed: false,
    component: AddAccount,
  },
  {
    path: routePath.PAYMENT,
    isSubscribed: false,
    component: ZuoraPaymentLanding,
  },
  {
    path: routePath.CONNECT_DEVICE_DASHBOARD,
    isSubscribed: true,
    component: ConnectDeviceDashboard
  },
  {
    path: routePath.DASHBOARD,
    isSubscribed: true,
    component: CustomerDashboard,
  },
  {
    path: routePath.ORDERS,
    isSubscribed: true,
    component: OrdersHistory,
  },
  {
    path: routePath.ORDER_ID,
    isSubscribed: true,
    component: OrderDetails,
  },
  {
    path: routePath.MY_ACCOUNT,
    isSubscribed: true,
    component: MyAccountLanding
   
  },
  {
    path: routePath.EDIT_ACCOUNT,
    isSubscribed: true,
    component: EditAccount,
  },
  {
    path: routePath.UPDATE_ACCOUNT_CONFIRMATION,
    isSubscribed: true,
    component: UpdateAccountConfirmation,
  },
  {
    path: routePath.UPDATE_ACCOUNT_SUCCESS,
    isSubscribed: true,
    component: UpdateAccountSuccess,
  },
  {
    path: routePath.CANCEL_PLAN_FEEDBACK,
    isSubscribed: true,
    component: Feedback,
  },
  {
    path: routePath.CANCEL_PLAN_CONFIRM,
    isSubscribed: true,
    component: ConfirmCancellation,
  },
  {
    path: routePath.CANCEL_PLAN_SUCCESS,
    isSubscribed: false,
    component: CancellationConfirmed,
  },
  {
    path: routePath.CONFIRM_DEVICE,
    isSubscribed: false,
    component: ConfirmDevice,
  },
  {
    path: routePath.DEVICE_LIST,
    isSubscribed: false,
    component: DeviceList,
  },
  {
    path: routePath.LEGAL_TNC,
    isSubscribed: false,
    component: LegalDocs_TermsNConditions,
  },
  {
    path: routePath.CARBON_OFFSETTING,
    isSubscribed: true,
    component: CarbonOffsetting,
  },
  {
    path: routePath.HOW_CO2_CALCULATED,
    isSubscribed: true,
    component: HowCo2Calculated,
  },
  {
    path: routePath.CHANGE_DEVICE,
    isSubscribed: true,
    component: SwapDevice,
  },
  {
    path: routePath.CHANGE_DEVICE_CONFIRM,
    isSubscribed: true,
    component: SwapDeviceConfirm,
  },
  {
    path: routePath.CHANGE_DEVICE_SUCCESS,
    isSubscribed: true,
    component: SwapDeviceSuccess,
  },
  {
    path: routePath.ADD_DEVICE,
    component: AddNewDevice,
  },
  {
    path: routePath.USER_PROFILE_UPDATE,
    isSubscribed: true,
    dontTrack: true,
    component: CustomerEditProfile,
  },
  {
    path: routePath.CHANGE_PLAN,
    isSubscribed: true,
    component: ChangePlan,
  },
  {
    path: routePath.PRINT_ACTIVITY,
    isSubscribed: true,
    component: PrintActivity,
  },
  {
    path: routePath.CORRECT_DEVICE_FOUND,
    isSubscribed: false,
    component: WebAddMyDevice,
  },
  {
    path: routePath.MORE_DEVICE_FOUND,
    isSubscribed: false,
    component: WebAddEligibleDevice,
  },
  {
    path: routePath.NO_DEVICE_FOUND,
    isSubscribed: false,
    component: WebNoDeviceFound,
  },
  {
    path: routePath.CONNECT_DEVICE,
    isSubscribed: false,
    component: ProcessAddDevice,
  },
  {
    path: routePath.WEB_PLACE_ORDER,
    isSubscribed: false,
    component: WebPlaceOrder,
  },
  {
    path: routePath.ENROLLMENT_FEEDBACK,
    isSubscribed: false,
    component: EnrollmentFeedback,
  },
  {
    path: routePath.SELECT_PAYMENT,
    isSubscribed: false,
    component: PaymentOptions,
  },
  {
    path: routePath.ECOPRO_FEEDBACK,
    isSubscribed: true,
    component: EcoproFeedback,
  },
  {
    path: routePath.SUBSCRIPTION_NETWORK_ERROR_VALIDATION,
    isSubscribed: false,
    component: SubscriptionNetworkErrorValidation,
  },
  {
    path: routePath.HELP_SUPPORT,
    exact:true,
    isSubscribed: true,
    component: HelpAndSupport
  },
  {
    path: routePath.HELP_SUPPORT_CAT,
    exact:true,
    isSubscribed: true,
    component: FAQSublist
  },
  {
    path: routePath.HELP_SUPPORT_SUBCAT,
    exact:true,
    isSubscribed: true,
    component: FAQContent
  },
  {
    path: routePath.EDIT_PAYMENT,
    isSubscribed: true,
    component: EditPayment,
  }
]

