import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import "./BrotherPlusPagesUsed.css"
import { formatNumberOnLocale } from '../../../../constants/AppMethod';
import { DISPLAY_CONTENT_TYPE } from '../../../../pages/Dashboard/BrotherPlus/Dashboard';

const BrotherPlusPagesUsed = (props) => {
  const { t } = useTranslation(["translation"]);
  const { getDeviceStatusResponse, displayContent } = props
  let displayInfo = {};

  if(displayContent === DISPLAY_CONTENT_TYPE.duplexPagesCount){
    displayInfo = {...displayInfo, 'title': t("BROTHER_PLUS.DASHBOARD.DUPLEX_PAGES_SECTION.TITLE"), 'pagesCount': getDeviceStatusResponse?.response?.duplexPages || 0}
  } else {
    displayInfo = {...displayInfo, 'title': t("BROTHER_PLUS.DASHBOARD.DEVICE_TOTAL_PAGES.TITLE"), 'pagesCount': getDeviceStatusResponse?.response?.printedPages || 0}
  }

  return (
    <>
      {
        /**
         * Mobile View
         */
      }
      <div className="account-info-carousel-view show-xs  brother-plus-pages-count-wrapper-mobile">
        <div className='pages-printed-wrapper'>
            <div className="pages-printed-info">
            <h2 className="pages-printed-title h2">{displayInfo.title}</h2>
            <div className="pages-printed-sub-title body-medium">{t("BROTHER_PLUS.DASHBOARD.DEVICE_TOTAL_PAGES.ALL_TIME")}</div>
            {getDeviceStatusResponse?.loader  ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <div className='printed-pages-count'>
                {formatNumberOnLocale(displayInfo.pagesCount, null, null, true)}
              </div>
            )}
          </div>
        </div>
      </div>

        {
          /**
         * Desktop View
         */
        }   
      <div className="account-info-normal-view show-lg dashboard-overview-without-swap-device  brother-plus-pages-count-wrapper-desktop">
        <div className='pages-printed-wrapper'>
          <div className="pages-printed-info">
          <h2 className="pages-printed-title h2">{displayInfo.title}</h2>
          <div className="pages-printed-sub-title body-medium">{t("BROTHER_PLUS.DASHBOARD.DEVICE_TOTAL_PAGES.ALL_TIME")}</div>
          {getDeviceStatusResponse?.loader  ? (
            <div className="content">
                <div className="row">
                <div className="col-8 order-status-wrapper">
                    <Skeleton />
                </div>
                </div>
            </div>
          ) : (
            <div className='printed-pages-count'>
              {formatNumberOnLocale(displayInfo.pagesCount, null, null, true)}
            </div>
          )
            }
        </div>
        </div>
      </div>
    </>
  );
};

export default BrotherPlusPagesUsed;