import React from "react";
import { useTranslation } from "react-i18next";
import "./WebSignupProgressBar.css";
import { Is_BrotherPlusFreePlanSelectedFromSitecore, Is_BrotherPlus_Signup, Is_HWInclusive_Signup } from "../../../constants/AppMethod";

const WebSignupProgressBar = (props) => {
  const { t } = useTranslation(['translation']);
  const { completedSteps = [], activeStep = 0} = props;
  const Is_HWInclusive_SignupType = Is_HWInclusive_Signup();
  const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
  const IsBrotherPlusFreePlanSelectedFromSitecore = Is_BrotherPlusFreePlanSelectedFromSitecore()
 
  const STEP_1_TXT = (Is_HWInclusive_SignupType || IsBrotherPlus_Signup) ? t('HARDWARE_SIGNUP.SIGNUP_PROGRESS_BAR_TXTS.STEP_1'): t('CHANGE_PLAN.HEADER');
  const STEP_2_TXT = (Is_HWInclusive_SignupType || IsBrotherPlus_Signup) ? t('HARDWARE_SIGNUP.SIGNUP_PROGRESS_BAR_TXTS.STEP_2'): t('SUB_HEADER.STEP_3.STEP_NAME');
  const STEP_3_TXT = (Is_HWInclusive_SignupType || IsBrotherPlus_Signup) ? t('HARDWARE_SIGNUP.SIGNUP_PROGRESS_BAR_TXTS.STEP_3'): t('DASHBOARD.ORDER_DETAILS.PAYMENT_METHOD');
  const STEP_4_TXT = (Is_HWInclusive_SignupType || IsBrotherPlus_Signup) ? t('HARDWARE_SIGNUP.SIGNUP_PROGRESS_BAR_TXTS.STEP_4'): t("CUSTOMER_WEB.CONNECT_DEVICE.CONNECT_DEVICE_STEP");
  return (
    IsBrotherPlus_Signup && IsBrotherPlusFreePlanSelectedFromSitecore ? 
      <div className="container progress-container">
      <ul className="progress-steps three-steps-progress-bar">
        <li className={`${completedSteps.indexOf(1) > -1 ? 'step-complete' : 'none' } ${activeStep === 1 ? "step-active": "step-no-active"}`}>
          {STEP_1_TXT}
        </li>
        <li className={`${completedSteps.indexOf(2) > -1 ? 'step-complete' : 'none' } ${activeStep === 2 ? "step-active": "step-no-active"}`}>
          {STEP_3_TXT}
        </li>
        <li className={`${completedSteps.indexOf(3) > -1 ? 'step-complete' : 'none' } ${activeStep === 3 ? "step-active": "step-no-active"}`}>
          {STEP_4_TXT}
        </li>
      </ul>
    </div>
    :
    <div className="container progress-container">
      <ul className="progress-steps">
        <li className={`${completedSteps.indexOf(1) > -1 ? 'step-complete' : 'none' } ${activeStep === 1 ? "step-active": "step-no-active"}`}>
          {STEP_1_TXT}
        </li>
        <li className={`${completedSteps.indexOf(2) > -1 ? 'step-complete' : 'none' } ${activeStep === 2 ? "step-active": "step-no-active"}`}>
          {STEP_2_TXT}
        </li>
        <li className={`${completedSteps.indexOf(3) > -1 ? 'step-complete' : 'none' } ${activeStep === 3 ? "step-active": "step-no-active"}`}>
          {STEP_3_TXT}
        </li>
        <li className={`${completedSteps.indexOf(4) > -1 ? 'step-complete' : 'none' } ${activeStep === 4 ? "step-active": "step-no-active"}`}>
          {STEP_4_TXT}
        </li>
      </ul>
    </div>
    
  );
};

export default WebSignupProgressBar;
