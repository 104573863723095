import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    deviceInfo: {},
    loading: false,
    error: null,
    loadedTab: null
}

const DeviceAndDeviceUsageDetails = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_INIT:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_SUCCESS:
            return {
                ...state,
                deviceInfo: action.deviceInfo,
                loading: false,
                error: null,
                loadedTab: action.loadedTab
            }
        
        case actionTypes.FETCH_DEVICE_AND_USAGE_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                deviceInfo: {},
                loadedTab: action.loadedTab
            }
        case actionTypes.RESET_STATE:
            return { ...state, 
                loading: false,
                error:null,
                deviceInfo: {}
              };
        default:
            return {...state}
    }
}

export default DeviceAndDeviceUsageDetails;