import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, SUCCESS } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';
import i18next from 'i18next';
import {
  validateUserPromoCodeRequest,
  validateUserPromoCodeSuccess,
  validateUserPromoCodeFailure,
} from './validateUserPromoCodeSlice';

function _VALIDATEUSERPROMOCODE(userId) {
  const endpoint = _BROTHER_PLUS_API_ENDPOINTS.VALIDATE_USER_PROMO_CODE;
  const payload = {
    params: {
      userId: userId,
    },
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* validateUserPromoCodeSaga(action) {
  try {
    const { userId } = action.payload;
    const validationResult = yield call(_VALIDATEUSERPROMOCODE, userId);
    if (validationResult?.actionStatus.toUpperCase() === SUCCESS) {
      yield put(validateUserPromoCodeSuccess(validationResult));
    } else {
      yield put(validateUserPromoCodeFailure(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')));
    }
  } catch (error) {
    yield put(validateUserPromoCodeFailure(error.message));
  }
}

export default function* watchValidateUserPromoCodeService() {
  yield takeEvery(validateUserPromoCodeRequest.type, validateUserPromoCodeSaga);
}
