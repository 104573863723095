import React from 'react';
import './alert.css'
import CloseIcon from '../../../assets/images/close.svg'
import EcoProButton from '../button/button';

const EcoproAlert = (props) => {
   const {alertHeader, alertSubHeader,dismissAlert,alertBtnText,handleAlertEvent,alertImg,alertClass, showAlertDismiss = true, isSubheaderButton = false, buttonInAlert} = props
   const alertLines = (alertSubHeader) && !isSubheaderButton ? alertSubHeader.split('\n') : [];
    return (
        <div className={`ecopro-alert ${alertClass} body-medium`}>
            <div className='alert-wrapper'>
                { alertImg && <div className='alert-danger-img'>
                    <img src={alertImg} alt="alert-danger"/>
                </div> }
                <div className='alert-wrapper-info fc-g f-14'>
                    {alertHeader &&
                    <div className='alert-header ta-left'>{alertHeader}</div> }
                    {!isSubheaderButton && alertLines?.map((line, index) => (
                    <div className='alert-sub-header ta-left' key={index}>{line}</div>
                    ))}
                    {
                        isSubheaderButton && alertSubHeader && 
                        <div className='alert-sub-header ta-left '>{alertSubHeader}</div>
                    }
                    {isSubheaderButton && 
                        <div className='alert-sub-header ta-left reactivation-button'>
                         {buttonInAlert}
                        </div>
                    }
                </div>
            </div>
            <div className="alert-close">
                {alertBtnText &&
                <EcoProButton cname="default" onBtnClick={() => handleAlertEvent()}>{alertBtnText}</EcoProButton>}
                { showAlertDismiss && <img src={CloseIcon} className = "pointer-cursor" onClick={()=>dismissAlert()} alt="alert-close" /> }
            </div>
        </div>
    );
};

export default EcoproAlert;