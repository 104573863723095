import React, { useState } from "react";
import WelcomeHeader from "../../../components/CustomerDashboard/WelcomeHeader/WelcomeHeader";
import CarbonSaving from "../../../components/CustomerDashboard/CarbonSavings/CarbonSaving.js";
import PrintActivity from "../../PrintActivity/PrintActivity.js";
import { useSelector } from "react-redux";
import { Is_Both_EcoPro_And_BrotherPlus_RegistrationType, Is_BrotherPlus_Signup, isBonusPagesAvailableInDiscountArray } from "../../../constants/AppMethod.js";
import './Dashboard.css'
import BrotherPlusDashboard from "../BrotherPlus/Dashboard.js";
import useEcoProUpdateDeviceState from "../../../utils/CustomHooks/useEcoProUpdateDeviceState.js";
import { getLocalSessionData, getUserSessionData } from "../../../utils/Authentication/handleUserSession.js";
import { DASHBOARD_REFRESH_INTERVAL, LOCALSESSIONDATAKEYS } from "../../../constants/AppConstants.js";
import useIntervalAsync from "../../../utils/CustomHooks/useIntervalAsync.js";
import EcoproDashboardAlert from "../../../components/CustomerDashboard/EcoPro/EcoproDashboardAlert/EcoproDashboardAlert.js";
import EcoproEnrolledDevice from "../../../components/CustomerDashboard/EcoPro/EcoproEnrolledDevice/EcoproEnrolledDevice.js";
import EcoproPlanUsageDetails from "../../../components/CustomerDashboard/EcoPro/EcoporPlanUsageDetails/EcoproPlanUsageDetails.js";
import EcoproOrderHistory from "../../../components/CustomerDashboard/EcoPro/EcoproOrderHistory/EcoproOrderHistory.js";
import EcoproChangeDevice from "../../../components/CustomerDashboard/EcoPro/EcoproChangeDevice/EcoproChangeDevice.js";
import { getFeatureSwitchByKey } from "../../../utils/Authentication/handleUserSession.js";
import { featureSwitchName } from "../../../constants/AppConstants.js";
import BonusPages from "../../../components/CustomerDashboard/EcoPro/BonusPages/BonusPages.js";

const EcoProDashboard = () => {
    const UID = getUserSessionData('UserId');
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
    const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
    const isBothRegType = Is_Both_EcoPro_And_BrotherPlus_RegistrationType();
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
    const IsBrotherPlusFeatureSwitchEnabled = getFeatureSwitchByKey(featureSwitchName.BROTHER_PLUS);
    const [deviceInfoResponse, setDeviceInfoResponse] = useState({
        success: false,
        loader: true,
        response: null,
        error: null
    });
    const updateState = useEcoProUpdateDeviceState({deviceId,userId: UID,setDeviceInfoResponse});
    useIntervalAsync(updateState, DASHBOARD_REFRESH_INTERVAL);
   
    return (
        <>
            <div>
                <WelcomeHeader />
                <EcoproDashboardAlert deviceInfoResponse={deviceInfoResponse}/>
                <div className="ecopro-dashboard-wrapper">
                    <div className="ecopro-tile ecopro-tile-1">
                        <EcoproEnrolledDevice deviceInfoResponse={deviceInfoResponse}/>
                    </div>
                    <div className="ecopro-tile ecopro-tile-1">
                        <EcoproPlanUsageDetails deviceInfoResponse={deviceInfoResponse}/>
                    </div>
                    <div className="ecopro-tile ecopro-tile-1">
                        <EcoproOrderHistory/>
                    </div>
                    <div className="ecopro-tile ecopro-tile-1">
                        <PrintActivity showAvgPagesUsed = {true} isChildComp = {true} isBlockActive = {enrollmentStatus.enrollment_completed}/>
                    </div>
                    <div className="column-3-container">
                        {!IsBrotherPlus_Signup && deviceInfoResponse?.success && deviceInfoResponse?.response?.isSwapEligible ?
                            <div className="ecopro-tile-7">
                                <EcoproChangeDevice/>
                            </div> : null    }
                        <div className="ecopro-tile-7">
                            <CarbonSaving isBlockActive = {enrollmentStatus.enrollment_completed}/>
                        </div>
                       {(isBonusPagesAvailableInDiscountArray(deviceInfoResponse)) &&  <div className="ecopro-tile-7">
                            <BonusPages deviceInfoResponse={deviceInfoResponse} isBlockActive = {enrollmentStatus.enrollment_completed}/>
                        </div>}
                        <div className="ecopro-tile-7">
                            <PrintActivity showPagesUsed = {true} isChildComp = {true} isBlockActive = {enrollmentStatus.enrollment_completed}/>
                        </div>
                    </div>
                </div>
            </div>
            {
                (isBothRegType && IsBrotherPlusFeatureSwitchEnabled) ? <div className="mt-40">
                    <BrotherPlusDashboard isBothRegType={true}/>
                </div>
                : null
            }
        </>
    )
}

export default EcoProDashboard;