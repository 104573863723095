import { call, put, takeEvery } from "redux-saga/effects";
import { _CALLGETAPI, _CALLPOSTAPI } from "../../api/apiHandler";
import * as actionTypes from "../../constants/ActionTypes/PrintActivity"
import { LOCALSESSIONDATAKEYS, USER_ACTIVITY_LOGS, _API_ENDPOINT, _CSAGENT_API_ENDPOINTS } from "../../constants/AppConstants";
import i18next from "i18next";
import { SUCCESS } from "../../constants/AppConstants";
import { getLocalSessionData, getUserDataByKey } from "../../utils/Authentication/handleUserSession"
import UserActivityLogsPayload from "../../utils/AdminPortal/UserActivityLogsPayload";

function _GETPRINTACTIVITY(UID, deviceId, IS_CSAGENT, customerCountryCode) {
    if(IS_CSAGENT){
        return handlePrintHistory_CSAgent(UID);
    } else {
        const endpoint = _API_ENDPOINT.GET_PRINT_HISTORY;
        const personalOrBusinessAccount = getUserDataByKey('businessModel')
        const deviceModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL)
        let payload;
        if(deviceModel){
            payload = {
                params: {
                    userId: UID,
                    deviceId: deviceId,
                    personalOrBusiness: personalOrBusinessAccount,
                    deviceModel:getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL)
                }
            }
        }else{
            payload = {
                params: {
                    userId: UID,
                    deviceId: deviceId,
                    personalOrBusiness: personalOrBusinessAccount
                }
            }
        }
        return _CALLGETAPI(endpoint, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
    }
}
/**
 * This method handles the print history attempted by CS Agent user
 * @param {Object} payload 
 * @returns {Promise}
 */
async function handlePrintHistory_CSAgent(Customer_UID){
    const endpoint = _CSAGENT_API_ENDPOINTS.GET_PRINT_HISTORY;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.FETCH_PRINT_HISTORY,
        description: `Fetch the print history infor for userID: ${Customer_UID}`
    }
    const payload = {
        userId: Customer_UID,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
    }
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(message){
    yield put({
        type: actionTypes.GET_PRINT_ACTIVITY_ERROR, 
        message: message
    });
}

function* handle_CSAGENT_Error(message){
    yield put({
        type: actionTypes.GET_PRINT_ACTIVITY_ERROR, 
        message: message
    });
}

function* handlePrintActivity(action) {
    try {
        const UID = action.UID;
        const deviceId = action.deviceId;
        const IS_CSAGENT = action.IS_CSAGENT;
        const customerCountryCode = action.customerCountryCode;
        if (UID && deviceId) {
            const printActivity = yield call(_GETPRINTACTIVITY, UID, deviceId, IS_CSAGENT, customerCountryCode);
            if (printActivity.actionStatus.toUpperCase() === SUCCESS && printActivity.printHistory.length) {
                yield put({
                    type: actionTypes.GET_PRINT_ACTIVITY_SUCCESS,
                    printActivity: printActivity,
                });
            } else if (printActivity.actionStatus.toUpperCase() === SUCCESS && !printActivity.printHistory.length) {
                (IS_CSAGENT) 
                ? yield* handle_CSAGENT_Error(i18next.t('CSAgenttranslation:PRINT_ACTIVITY.NO_DATA_MSG')) 
                : yield* handleError(i18next.t('PRINT_ACTIVITY.NO_DATA_MSG'));
            } else {
                (IS_CSAGENT) 
                ? yield* handle_CSAGENT_Error(i18next.t('CSAgenttranslation:ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')) 
                : yield* handleError(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'));
            }
        } else {
           (IS_CSAGENT) 
           ? yield* handle_CSAGENT_Error(i18next.t('CSAgenttranslation:ERROR_CONTENT.ERR_INFO.INFO_GENERAL')) 
           : yield* handleError( i18next.t('ERROR_CONTENT.INFO_GENERAL'));
        }
    } catch (e) {
        yield put({
            type: actionTypes.GET_PRINT_ACTIVITY_ERROR,
            message: e.message
        });
    }
}

function* GetPrintActivity() {
    yield takeEvery(actionTypes.GET_PRINT_ACTIVITY_INIT, handlePrintActivity);
}

export default GetPrintActivity;