import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchPlans } from '../../../../actions/MyPlan';
import ErrorPage from '../../../../components/shared/errorPage/ErrorPage';
import { 
  ECOPROTOKEN_CLAIMS, 
  LOCALSESSIONDATAKEYS, 
  ONLINE, 
  refreshBtn, 
  tryAgainBtn,
  SIGNUPTYPE, 
  featureSwitchName 
} from '../../../../constants/AppConstants';
import Loader from '../../../../components/shared/loader/Loader';
import { routePath } from '../../../../constants/Routes';
import { 
  getUserSessionData, 
  setLocalSessionData, 
  getLocalSessionData, 
  getFeatureSwitchByKey 
} from '../../../../utils/Authentication/handleUserSession';
import './SelectPlan.css'
import { fetchDeviceList, getDeviceRegitration } from '../../../../actions/SelectDevice';
import DisplayUpdateAccountConfirmation from '../../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import deviceOffline from '../../../../assets/images/printer-offline.svg';
import SelectPlanDesktop from './SelectPlanDesktop';
import LearnMore from '../../../../components/SelectPlan/LearnMore/LearnMore';
import FAQPlansPage from '../../../../components/SelectPlan/FAQ/FAQ';
import PromotionBanner from '../../../../components/shared/PromotionBanner/PromotionBanner';
import CaveatText from '../../../../components/SelectPlan/CaveatText/CaveatText';
import { getPromotionsArray } from '../../../../constants/AppMethod';
import { fetchFeatureSwitchByMultipleValuesRequest } from '../../../../store/FeatureSwitchByMultipleValues/FeatureSwitchByMultipleValuesSlice';


const SelectPlan = (props) => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();
  const result = useSelector(state => state.MyPlan);
  const deviceId = props?.location?.state?.deviceId;
  const deviceModel =props?.location?.state?.device_model;
  const serialNumber =props?.location?.state?.serialNumber;
  let networkStatus = props?.location?.state?.networkStatus;
  const previousPage = props?.location?.state?.from;
  const deviceLength = props?.location?.state?.deviceLength;
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceRegistrationResult = useSelector((state) => state.DeviceRegistration)
  const deviceListResult = useSelector((state) => state.SelectDevice);
  const totalSlides = (result.plans) ? result.plans.length : 0;
  const [fetchPlanStatus,setFetchPlanStatus] = useState(true)
  const [deviceRegistrationStatus, setDeviceRegistrationStatus] = useState(true)

  const FeatureSwitchByMultipleValues = useSelector((state) => state.FeatureSwitchByMultipleValues)
  const { featureSwitchByMultipleValues } = FeatureSwitchByMultipleValues;
  const BonusPages= featureSwitchByMultipleValues?.BonusPages || null

  useEffect(()=>{
    dispatch(fetchFeatureSwitchByMultipleValuesRequest())
  },[dispatch])


  useEffect(() => {
    if(deviceId){
     setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, deviceId);
    }
    setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, deviceModel);
    setLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER, serialNumber);
    setLocalSessionData(LOCALSESSIONDATAKEYS.NETWORK_STATUS,networkStatus);
    setLocalSessionData(LOCALSESSIONDATAKEYS.PRINTER_TYPE,result?.printerType);
  }, [dispatch, deviceId, deviceModel, serialNumber, networkStatus, result?.printerType]);


  const recheckNetworkStatus = () => {
    dispatch(fetchDeviceList(UID));
  }
  if(result.loading || deviceRegistrationResult.loading || deviceListResult.loading) {
    return <Loader></Loader>
  }
  if(deviceRegistrationResult.error){
    return (
      <ErrorPage
        errorInfo={deviceRegistrationResult.error}
        showRefreshBtn={refreshBtn.HIDE}
        ></ErrorPage>
    )
  }

  if (deviceListResult.error) {
    return (
      <ErrorPage
        errorInfo={deviceListResult.error}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
  if (deviceListResult.deviceList?.devices && deviceListResult.deviceList?.devices.length === 0) {
    return (
      <ErrorPage
        errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }

  if (deviceListResult.success){
      deviceListResult.deviceList?.devices.map((device) =>{
        let selectedDeviceId;
        if(deviceId){
          selectedDeviceId = deviceId
        }
        else{
          selectedDeviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
        }
        if(device.deviceId === selectedDeviceId){
          networkStatus = device.networkStatus
        }
        return device;
      })
  }
  
  if(networkStatus?.toLowerCase() === ONLINE.toLowerCase() 
  ){
    if(deviceRegistrationStatus){
      setDeviceRegistrationStatus(false)
      dispatch(getDeviceRegitration(UID,deviceId))
    }
    if(deviceRegistrationResult.success && fetchPlanStatus){
      let backdrop = document.getElementById("mobile-header-left");
      if(backdrop)backdrop.style.display = "block";
      setFetchPlanStatus(false)
      if( deviceModel ){
        const promotions = getPromotionsArray(deviceModel, BonusPages);
        dispatch(fetchPlans(deviceModel, UID, getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID),promotions))
      }
      else{
        dispatch(fetchPlans(deviceModel, UID, getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)));
      }
    }
  }
  else{
    let backdrop = document.getElementById("mobile-header-left");
    if(backdrop)backdrop.style.display = "none";
    
    return <DisplayUpdateAccountConfirmation
      updateAddressHeader={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_HEADER")}
      updateAddressInfo={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_INFO")}
      updateAddressBtn={t("ECOPRO_BTNS.RETRY")}
      onUpdateClick = {()=>recheckNetworkStatus()}
      image={deviceOffline}
      ></DisplayUpdateAccountConfirmation>
  }
  
  if(!deviceModel){
    return <ErrorPage errorInfo={t('ERROR_CONTENT.ERR_INFO.SELECT_A_DEVICE')} showTryAgain={tryAgainBtn.SHOW} to={routePath.SHENLONG_REDIRECT}></ErrorPage>
  }
  
  if(result.error) {
    return <ErrorPage errorInfo={result.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }
  if(totalSlides === 0) {
    return <ErrorPage errorInfo={t('ERROR_CONTENT.No_PLANS')} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }

  if(deviceLength === "1"){
    let backdrop = document.getElementById("mobile-header-left");
    if(backdrop)backdrop.style.display = "none";
  }
  return (
    <>
       <PromotionBanner/>
      <SelectPlanDesktop
        plans={result.plans} 
        flowType={SIGNUPTYPE.MOBILE} 
        showBackNavArrow={(previousPage === 'device_selection_page') ? true : false}
        isPromotionBannerEnabled = {getFeatureSwitchByKey(featureSwitchName.PROMOTION_BANNERS) ? true : false}
      />
      <FAQPlansPage flowType={SIGNUPTYPE.MOBILE} />
      <LearnMore />
      <CaveatText signupType={SIGNUPTYPE.MOBILE}/>
    </>
  );
};

export default SelectPlan;